import BaseModal from '#components/Dialogs/DialogComponents/BaseModal'
import Modal from '#components/presentational/Modal'
import ButtonForm from '#src/components/AdminPage/NotificationSettings/ButtonCard/ButtonForm'

export interface IAdminNotificationAddButtonProps {
  title: string
  token: string
  afterSuccess?: () => void
}

export default class AddButtonModal extends BaseModal {
  handleAfterSuccess = (): void => {
    const { afterSuccess } = this.props as IAdminNotificationAddButtonProps
    this.onCloseModal()
    afterSuccess && afterSuccess()
  }

  render(): JSX.Element {
    const { title, ...rest } = this.props as IAdminNotificationAddButtonProps
    return (
      <Modal closeModal={this.onCloseModal} size='middle' title={title} {...rest}>
        <div className='message-dialog'>
          <div className='message-dialog__container'>
            <ButtonForm afterSuccess={this.handleAfterSuccess} type='create' />
          </div>
        </div>
      </Modal>
    )
  }
}
