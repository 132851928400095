import cn from 'classnames'
import { FC, memo, useMemo } from 'react'

import Icon from '#components/Icon'

const Stepper: FC<{ stepsCount: number; activeStep: number }> = (props) => {
  const { stepsCount, activeStep } = props
  const steps = useMemo(() => new Array(stepsCount).fill(null).map((_, index) => index + 1), [
    stepsCount
  ])
  return (
    <div className='registration-form__steps'>
      {steps.map((stepNumber) => (
        <span
          className={cn({
            'registration-form__steps-item': true,
            'is-active': stepNumber === activeStep,
            'is-complete': stepNumber < activeStep
          })}
          key={stepNumber}
          data-automation-id={`STEP_VALUE_${stepNumber}`}
        >
          <span>{stepNumber}</span>
          <Icon name='tick' />
        </span>
      ))}
    </div>
  )
}

export default memo(Stepper)
