import { ComponentType, FC } from 'react'
import { useMediaQuery } from 'usehooks-ts'

interface IMedia {
  isMobile: boolean
  isFablet: boolean
  isLessThanFablet: boolean
  isTablet: boolean
  isDesktop: boolean
  isGreaterThanDesktop: boolean
  isDesktopWide: boolean
}

export interface IInjectUserDevice {
  userDevice: IMedia
}

export function withMediaQuerySize<T extends IInjectUserDevice>(
  wrappedComponent: ComponentType<T>
): FC<Omit<T, keyof IInjectUserDevice>> {
  const Component = wrappedComponent
  return function WithMediaQuerySize(props): JSX.Element {
    const isMobile = useMediaQuery('(max-width:479px)')
    const isFablet = useMediaQuery('(min-width:480px) and (max-width:767px)')
    const isLessThanFablet = useMediaQuery('(max-width:768px)')
    const isTablet = useMediaQuery('(min-width:768px) and (max-width:1023px)')
    const isDesktop = useMediaQuery('(min-width:1024px) and (max-width:1439px)')
    const isGreaterThanDesktop = useMediaQuery('(min-width:1024px)')
    const isDesktopWide = useMediaQuery('(min-width:1440px)')
    const userDevice: IMedia = {
      isMobile,
      isFablet,
      isLessThanFablet,
      isTablet,
      isDesktop,
      isGreaterThanDesktop,
      isDesktopWide
    }
    return <Component {...(props as T)} userDevice={userDevice} />
  }
}
