import { Form } from './Form'

const TO_VALIDATE_FIELDS: { [key: string]: string[] } = {
  Articles_Edit_Form: [
    'title',
    'url',
    'description',
    'seoTitle',
    'seoDescription',
    'seoKeyWords',
    'text'
  ],
  Testimonials_Edit_Form: ['info', 'name', 'city', 'email'],
  Content_Form: ['name', 'title', 'url', 'description', 'keywords'],
  NotificationSettings_ButtonCard_ButtonForm: ['text'],
  PersonalAreaPromoSettings_PromoBannerForm: [
    'promoHeader',
    'promoDescription',
    'promoLinkUrl',
    'promoLinkText'
  ],
  Text: ['title', 'description', 'link'],
  NotificationSettings_NotificationCard_NotificationForm: ['text']
}

const MAX_FORMATTED_TEXT_LENGTH = 65535

export class SymbolLengthValidationForm<P, S> extends Form<P, S> {
  symbolLengthErrorTimeOutId?: number

  componentWillUnmount(): void {
    clearTimeout(this.symbolLengthErrorTimeOutId)
  }

  lengthCyrillicAndLatinSymbols(value: string): number {
    const regexpCyrillicSybmols = /[а-яё]/giu
    const matchCyrillicSybmols = value.matchAll(regexpCyrillicSybmols)
    const arrayCyrillicSybmols = Array.from(matchCyrillicSybmols)
    return arrayCyrillicSybmols.length * 2 + (value.length - arrayCyrillicSybmols.length)
  }

  symbolLengthError(name: string, ruleMaxLength: number): void {
    const errorText =
      ruleMaxLength === MAX_FORMATTED_TEXT_LENGTH
        ? 'Превышена длина текста, попробуйте уменьшить длину текста'
        : `Вы превысили максимально допустимую длину ввода в ${ruleMaxLength} символов`
    this.symbolLengthErrorTimeOutId = window.setTimeout(() => {
      this.setState((state) => ({
        ...state,
        errors: {
          ...state.errors,
          [name]: errorText
        }
      }))
    }, 0)
  }

  checkSymbolLength = (name: string, ruleMaxLength: number): boolean => {
    const isValid = true
    const {
      data: { [name]: value = '' }
    } = this.state

    if (
      ruleMaxLength === MAX_FORMATTED_TEXT_LENGTH &&
      value &&
      this.lengthCyrillicAndLatinSymbols(value as string) > ruleMaxLength
    ) {
      this.symbolLengthError(name, ruleMaxLength)
      return !isValid
    }
    if (value && (value as string).length > ruleMaxLength) {
      this.symbolLengthError(name, ruleMaxLength)
      return !isValid
    }
    return isValid
  }

  validateSymbolLength = (type: string): boolean => {
    let isValid = true
    this.model?.forEach((item) => {
      const itemName = item.name || item.systemName
      const { ruleMaxLength } = item
      if (
        TO_VALIDATE_FIELDS[type].indexOf(itemName as string) !== -1 &&
        !this.checkSymbolLength(itemName as string, ruleMaxLength as number)
      )
        isValid = false
    })
    return isValid
  }
}
