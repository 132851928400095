import classnames from 'classnames'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'

import {
  ADDITIONAL_SERVICES_STATEMENT,
  CONSUMER_CREDIT,
  CONSUMER_CREDIT_MONTH,
  CONSUMER_CREDIT_MONTH_SHORT,
  LOAN_AGREEMENT,
  LOAN_AGREEMENT_IL,
  LOAN_AGREEMENT_IL_MONTH,
  MICRO_CREDIT,
  RISK_NOTICE,
  TERMS
} from '#constants/common'
import intl from '#intl'
import { now } from '#services/datetime'

const isShow = () => now().getTime() > new Date(2024, 0, 1).getTime()
const isShowAdditional = () => now().getTime() > new Date(2024, 0, 21).getTime()

const commonAgreements = [
  {
    getText: () => intl.privateTerms,
    isShow: true,
    getDocument: ({ creditType }) =>
      ({
        [MICRO_CREDIT]: LOAN_AGREEMENT,
        [CONSUMER_CREDIT]: LOAN_AGREEMENT_IL,
        [CONSUMER_CREDIT_MONTH]: LOAN_AGREEMENT_IL_MONTH,
        [CONSUMER_CREDIT_MONTH_SHORT]: LOAN_AGREEMENT_IL
      }[creditType] || LOAN_AGREEMENT)
  },
  {
    getText: () => intl.generalTerms,
    getDocument: () => TERMS,
    isShow: true
  },
  {
    getText: () => intl.riskNotice,
    getDocument: () => RISK_NOTICE,
    isShow: isShow()
  },
  {
    getText: () => intl.additionalServicesStatement,
    isShow: isShowAdditional(),
    getDocument: () => ADDITIONAL_SERVICES_STATEMENT
  }
]

const CommonAgreementsLabel = (props) => {
  const { onDownload = noop, loadingItems = {}, creditId = null, creditType, loading } = props

  const mappedCommonAgreements = useMemo(() => {
    const data = { creditId, creditType }
    return commonAgreements
      .filter((item) => item.isShow)
      .map((item) => ({
        text: item.getText(data),
        document: item.getDocument(data)
      }))
  }, [creditId, creditType])

  const handleDocumentDownload = useCallback(
    (document, fileName, type) => (event) => {
      event.preventDefault()
      onDownload(document, fileName, type)
    },
    [onDownload]
  )

  return (
    <div className='agreements'>
      <div className='agreements-loan-custom'>{'Договор микрозайма ('}</div>
      {mappedCommonAgreements.map(({ document: doc, text }, key) => {
        const linkClasses = classnames({
          'link--disabled link--pouring': Boolean(loadingItems[doc])
        })
        const linkDisabled = {
          pointerEvents: 'none',
          cursor: 'default',
          color: '#fff'
        }
        return (
          <div key={key} className='agreements-loan' style={loading ? linkDisabled : null}>
            <a className={linkClasses} onClick={handleDocumentDownload(doc, doc, null)}>
              {text}
            </a>
          </div>
        )
      })}
      {')'}
    </div>
  )
}

CommonAgreementsLabel.propTypes = {
  onDownload: PropTypes.func,
  loadingItems: PropTypes.object,
  creditId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  creditType: PropTypes.string,
  loading: PropTypes.bool
}

export default CommonAgreementsLabel
