import { FC, useCallback, useState } from 'react'

import { ConfirmDeleteDialog } from '#components/Dialogs/ConfirmDeleteDialog'
import intl from '#intl'
import notify from '#services/notify'
import { adminApi } from '#src/modules/api'
import { ResponseCode } from '#src/modules/api/types/common'

const CONFIRM_DELETE_TEXT = 'Вы уверены, что хотите удалить баннер?'

export type TConfirmDeleteBanner = {
  closeModal: () => void
  id: number
  token: string
  updatePromoData: (id: number) => void
}

export const ConfirmDeleteBanner: FC<TConfirmDeleteBanner> = (props) => {
  const { id, closeModal, token, updatePromoData } = props
  const [isLoading, setIsLoading] = useState(false)
  const handleConfirmClick = useCallback(async () => {
    try {
      setIsLoading(true)
      const result = await adminApi.deletePaPromoData({ id, token })
      if (result.code === ResponseCode.success) {
        updatePromoData(id)
        notify.push({
          message: 'Баннер удален',
          type: 'success'
        })
      }
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const message = (err?.message || intl.serverError) as string
      notify.push({ message, type: 'danger' })
    } finally {
      closeModal()
      setIsLoading(false)
    }
  }, [closeModal, id, token, updatePromoData])

  return (
    <ConfirmDeleteDialog
      confirmText={CONFIRM_DELETE_TEXT}
      handleConfirmClick={handleConfirmClick}
      isLoading={isLoading}
      closeModal={closeModal}
    />
  )
}
