import { noop } from 'lodash'
import PropTypes from 'prop-types'

import Button from '#components/Button'
import intl from '#intl'

const ACTION_TYPES = {
  confirm: {
    color: 'green',
    text: intl.confirm
  },
  reject: {
    color: 'red',
    text: intl.cancel
  },
  accept: {
    color: 'green',
    text: intl.confirm
  },
  understand: {
    color: 'blue',
    text: intl.understand
  }
}

const Action = (props) => {
  const { type, callback = noop, loading } = props
  const { text, color } = ACTION_TYPES[type]

  const actionHandler = (event) => {
    event.preventDefault()
    return callback(type)
  }

  return (
    <Button
      loading={loading}
      color={color}
      className='message-dialog__actions_button'
      type='button'
      onClick={actionHandler}
    >
      {text}
    </Button>
  )
}

Action.propTypes = {
  type: PropTypes.string,
  callback: PropTypes.func,
  loading: PropTypes.bool
}

export default Action
