import './card.scss'

import classnames from 'classnames'
import { CSSProperties, FC, MouseEvent, PropsWithChildren } from 'react'

import Icon from '../Icon'

interface ICardProps {
  title?: string
  icon?: string
  className?: string
  onClick?: (event: MouseEvent) => void
  onIconClick?: (event: MouseEvent) => void
  style?: CSSProperties
  hideTitle?: boolean
  'data-qa'?: string
  cardBodyClass?: string
}

const Card: FC<PropsWithChildren<ICardProps>> = (props) => {
  const {
    title,
    icon,
    children,
    className,
    onClick,
    onIconClick,
    style,
    hideTitle,
    cardBodyClass
  } = props
  const classes = classnames(
    {
      card: true
    },
    className
  )

  const cardBodyClasses = classnames(
    {
      card__body: true
    },
    cardBodyClass
  )
  return (
    <div className={classes} style={style} onClick={onClick} data-qa={props['data-qa']}>
      {title && !hideTitle && (
        <div className='card__title'>
          {title}
          {icon && <Icon className='card__title__icon' name={icon} onClick={onIconClick} />}
        </div>
      )}
      {children && <div className={cardBodyClasses}>{children}</div>}
    </div>
  )
}

Card.defaultProps = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick: () => {},
  hideTitle: false,
  style: {}
}

export default Card
