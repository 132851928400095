import classes from 'classnames'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import { useCallback } from 'react'

import Icon from '#components/Icon'
import Spinner from '#components/Spinner'

const outlineStyle = {
  border: 'medium solid',
  borderRadius: '50%',
  padding: '10%',
  width: '100%',
  height: '100%'
}

const TakePhotoButton = (props) => {
  const { onClick, style, className, isLoading, disabled } = props
  const handleClick = useCallback(
    (event) => {
      onClick(event)
    },
    [onClick]
  )
  const classNames = classes(
    {
      'camera__take-button': true
    },
    className
  )

  return (
    <button
      onClick={handleClick}
      style={{
        all: 'unset',
        width: 60,
        height: 60,
        color: 'inherit',
        filter: 'drop-shadow(1px 1px 1px black)',
        ...style
      }}
      className={classNames}
      disabled={disabled}
    >
      {isLoading ? (
        <Spinner style={outlineStyle} />
      ) : (
        <Icon name='camera-solid' style={outlineStyle} />
      )}
    </button>
  )
}

TakePhotoButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool
}

TakePhotoButton.defaultProps = {
  onClick: noop,
  style: {},
  isLoading: false,
  disabled: false
}

export default TakePhotoButton
