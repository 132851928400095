import '#components/RegistrationForm/registration-form.scss'

import FormData from 'form-data'
import { get, isUndefined, noop } from 'lodash'
import PropTypes from 'prop-types'
import { Cookies } from 'react-cookie'

import Button from '#components/Button'
import { CardCropperBroker } from '#components/CardCropper'
import Modal from '#components/Modal'
import RegistrationForm from '#components/RegistrationForm'
import intl from '#intl'
import { mainSiteApi } from '#modules/api'
import { formatDate, parseDate } from '#services/datetime'
import { brokerApi } from '#src/modules/api'

const cookies = new Cookies()

class AddPaymentAccountForm extends RegistrationForm {
  constructor(props) {
    super(props)
    const { personId, token, personToken } = props
    this.state = {
      ...this.state,
      personId,
      token: token || cookies.get('token') || personToken,
      initForm: false,
      form: this.computeForm()
    }
    this.isMainRegistration = false
    this.cardCropperComponent = (props) => <CardCropperBroker {...props} isNewCardPhoto={true} />
  }

  async componentDidMount() {
    const {
      app: { loggedInToken: brokerToken }
    } = this.props
    const { data } = await mainSiteApi.getRegistrationForm({ brokerToken })
    this.model = data.filter(({ step }) => step === 4)
    this.initRegistrationForm()
    this.initModalLinks()
    this.setState({ step: 4 })
  }

  componentWillUnmount() {
    this.model = null
  }

  buildFormData = () => {
    const fieldsToSkip = [
      'getLoanOn',
      'loanWay',
      'cardPhoto',
      'rules',
      'cardDate',
      'billBic',
      'billNumber'
    ]
    const { data, token, personId } = this.state
    const fd = new FormData()
    fd.append('brokerToken', this.props.app?.loggedInToken)
    fd.append('personToken', token)
    fd.append('personId', personId)
    this.model
      .filter(({ systemName }) => systemName && !fieldsToSkip.includes(systemName))
      .map(({ systemName }) => [systemName, String(data[systemName] ?? '').trim?.()])
      .forEach(([key, value]) => fd.append(key, value))
    const { loanWay, billBic, billNumber, cardDate } = data
    if (loanWay === 'bankCard') fd.append('cardDate', formatDate(parseDate(cardDate), 'MM/yy'))
    if (loanWay === 'bankAccount') {
      fd.append('billBic', billBic)
      fd.append('billNumber', billNumber)
    }
    return fd
  }

  handleAddPayment = async (event) => {
    event && event.preventDefault()
    const { loading, data } = this.state
    const { onDone = noop } = this.props

    const action = {
      bankCard: brokerApi.brokerAddCardAccount,
      bankAccount: brokerApi.brokerAddBillAccount
    }[data.loanWay]

    if (loading || !this.validateForm(true) || !action) return

    this.setState({ loading: true, errors: {} })
    try {
      const response = await action(this.buildFormData())
      if (response.code !== 0) throw response
      onDone()
    } catch (e) {
      this.handleResponse(e)
    }
  }

  closeModal = () => this.setState({ modalDocument: null, modalMessage: null })

  render() {
    const {
      form,
      loading,
      token,
      data: { rules },
      modalMessage,
      modalDocument
    } = this.state
    const {
      app: { loggedInToken: brokerToken },
      onDone = noop
    } = this.props

    if (isUndefined(form) || !token) return <div>{'Loading...'}</div>
    const lines = get(form, ['steps', 3, 'lines'], [])

    return (
      <>
        {
          <form
            className='form partner-page__add-payment-form'
            ref={this.wrapper}
            noValidate
            onSubmit={this.handleAddPayment}
          >
            {lines.map(({ items, lineNumber }) => (
              <div className='form__line' key={lineNumber}>
                {items.map((item) => this.renderItem({ ...item, brokerToken }))}
              </div>
            ))}
            {this.renderCommonError()}
            <div className='form__button'>
              <Button onClick={onDone}>{intl.close}</Button>
              {!loading && (
                <Button loading={loading} disabled={!rules}>
                  {intl.submit}
                </Button>
              )}
            </div>
          </form>
        }
        {modalDocument && <Modal {...modalDocument} onClose={this.closeModal} />}
        {modalMessage && (
          <Modal onClose={this.closeModal}>
            <div className='align-center'>
              <div>{modalMessage}</div>
              <Button size='s' onClick={this.closeModal}>
                {intl.close}
              </Button>
            </div>
          </Modal>
        )}
      </>
    )
  }
}

AddPaymentAccountForm.propTypes = {
  onDone: PropTypes.func,
  personToken: PropTypes.string,
  personId: PropTypes.number
}
export default AddPaymentAccountForm
