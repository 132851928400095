import cn from 'classnames'
import { cloneDeep } from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Button from '#components/Button'
import Form from '#components/Form'
import { FormItem } from '#components/Form/FormItem'
import SmartControl from '#components/SmartControl'
import Spinner from '#components/Spinner'
import intl from '#intl'
import { updatePersonRowById } from '#reducers/partnerData/partnerDataSlice'
import { brokerApi } from '#src/modules/api'

import { bidsForm } from './bidsForm'

const THREATMENTS = {
  ACCEPT: 1,
  REFUSE: -1,
  COMMENT: 0
}

class PartnerBidsForm extends Form {
  constructor(props) {
    super(props)
    this.model = bidsForm
    this.state = {
      ...this.state,
      data: cloneDeep(props.data),
      form: this.computeForm()
    }

    this.handleTreatment = this.handleTreatment.bind(this)
    this.handleAccept = this.handleTreatment.bind(this, THREATMENTS.ACCEPT)
    this.handleRefuse = this.handleTreatment.bind(this, THREATMENTS.REFUSE)
    this.handleComment = this.handleTreatment.bind(this, THREATMENTS.COMMENT)
  }

  handleTreatment(treatment = 0) {
    this.setState(
      (state) => ({ data: { ...state.data, treatment } }),
      () => void this.handleSubmit()
    )
  }

  async handleSubmit(evt) {
    evt && evt.preventDefault()
    const { dispatch, brokerToken } = this.props
    const { data, loading } = this.state

    if (loading && !this.validateForm(true)) return
    this.setState({ loading: true })
    try {
      const { personId, id: person2brokerId } = data
      const response = await brokerApi.brokerProcess(data, {
        brokerToken,
        personId,
        person2brokerId
      })
      this.handleResponse(response)
      if (response.code === 0) dispatch(updatePersonRowById({ personId, data }))
    } catch (e) {
      this.handleResponse(e)
    }
  }

  renderItem(item) {
    const { data, errors } = this.state

    return (
      <FormItem key={item.name} {...item} error={errors[item.name]}>
        <SmartControl
          {...item}
          value={data[item.name]}
          valid={!errors[item.name]}
          onFocus={this.handleControlFocus}
          onBlur={this.handleControlBlur}
          onChange={this.handleControlChange}
        />
      </FormItem>
    )
  }

  render() {
    const { className } = this.props
    const { form, errors, loading } = this.state
    const classes = cn({ form: true }, className)

    return (
      <form className={classes} noValidate>
        {form.lines.map((line, key) => (
          <div className='form__line' key={key}>
            {line.items.map((item) => !this.checkDepends(item) && this.renderItem(item))}
          </div>
        ))}
        {errors.common && (
          <div className='form__bottom'>
            {errors.common && <div className='form__error'>{errors.common}</div>}
          </div>
        )}
        {loading ? (
          <div className='form__line'>
            <div className='form__item'>
              <Spinner className='partner-page__bids-form-spinner' />
            </div>
          </div>
        ) : (
          <div className='form__line'>
            <div className='form__item'>
              <Button type='button' size='l' fluid onClick={this.handleAccept}>
                {intl.accept}
              </Button>
            </div>
            <div className='form__item'>
              <Button type='button' size='l' color='red' fluid onClick={this.handleRefuse}>
                {intl.refuse}
              </Button>
            </div>
            <div className='form__item'>
              <Button type='button' size='l' fluid color='blue' onClick={this.handleComment}>
                {intl.saveComment}
              </Button>
            </div>
          </div>
        )}
      </form>
    )
  }
}

PartnerBidsForm.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  onSubmit: PropTypes.func
}
export default connect((state) => ({
  brokerToken: state.app.loggedInToken
}))(PartnerBidsForm)
