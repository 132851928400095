import './partner-payments.scss'

import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { connect } from 'react-redux'

import Spinner from '#components/Spinner'
import { setLoanFormState } from '#reducers/loanFormState/loanFormStateSlice'
import { brokerApi } from '#src/modules/api'

import intl from '../../../intl'
import { Button } from '../../Button/Button'
import { Form } from '../../Form/Form'
import AddPaymentAccountForm from './AddPaymentAccountForm'
import PartnerPaymentItem from './PartnerPaymentItem'

class PartnerPaymentsForm extends Form {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      data: props.data,
      mainAccount: props.loanFormState.mainAccount || 0,
      addForm: false,
      form: null
    }
  }

  async componentDidMount() {
    await this.fetchData()
  }

  generateModel() {
    const { form, accounts } = this.state

    if (!form && accounts) {
      this.model =
        accounts.map((item, key) => ({
          line: key + 1,
          name: 'Payment info',
          type: item.type ? 'card' : 'account',
          ...item
        })) || []
      this.setState({
        form: this.computeForm()
      })
    }
  }

  async fetchData(refresh) {
    const {
      app: { loggedInToken },
      onSubmit
    } = this.props
    const { data, loading } = this.state

    if (!loading) {
      this.setState({ loading: true })
      try {
        const response = await brokerApi.getPerson2BrokerToken({
          personId: data.personId,
          brokerToken: loggedInToken
        })
        const state = { loading: false }

        if (response.code === 0) state.personToken = response.data.token

        this.setState(state)
        const getClientAccountResponse = await brokerApi.brokerGetClientAccount({
          brokerToken: loggedInToken,
          personId: data.personId
        })
        this.handleResponse(getClientAccountResponse)
        if (getClientAccountResponse.code === 0) {
          const stateData = {
            accounts: getClientAccountResponse.accounts
          }
          if (refresh) stateData.form = null

          this.setState(stateData, () => this.generateModel())
          onSubmit && onSubmit(data)
        }
      } catch (err) {
        this.handleResponse(err)
      }
    }
  }

  selectMainAccount(item) {
    const { dispatch } = this.props
    const {
      data: { personId }
    } = this.state

    this.setState({ mainAccount: item.id })
    dispatch(setLoanFormState({ mainAccount: { ...item, personId } }))
  }

  handleHideError() {
    setTimeout(() => {
      this.setState({ errors: { common: null } })
    }, 2000)
  }

  handleTogglePaymentForm() {
    this.setState((state) => ({
      addForm: !state.addForm
    }))
  }

  handlePaymentAdded() {
    this.setState(
      (state) => ({
        addForm: !state.addForm
      }),
      () => {
        void this.fetchData(true)
        this.forceUpdate()
      }
    )
  }

  renderItem(item, key) {
    const { mainAccount } = this.state
    item.isActive = item.id === mainAccount
    return (
      <PartnerPaymentItem
        key={key}
        {...item}
        className='partner-payments'
        onClick={this.selectMainAccount.bind(this, item)}
      />
    )
  }

  render() {
    const {
      form,
      errors,
      loading,
      addForm,
      data: { personId },
      personToken
    } = this.state
    const { app } = this.props

    const className = classnames(
      {
        form: true
      },
      this.props.className
    )
    const buttonAttr = {
      type: 'label',
      disabled: false
    }
    if (!addForm) {
      return (
        <div className={className}>
          <div className='partner-page__cards'>
            {form &&
              form.lines.map((line, inx) => (
                <Fragment key={inx}>
                  {line.items.map(
                    (item, key) => !this.checkDepends(item) && this.renderItem(item, key)
                  )}
                </Fragment>
              ))}
          </div>
          <div className={errors.common ? 'tab-error' : 'tab-error tab-error__hidden'}>
            {errors.common}
          </div>
          {loading && (
            <div className='form__item'>
              <Spinner className='partner-page__bids-form-spinner' />
            </div>
          )}
          {!loading && (
            <Button {...buttonAttr} onClick={this.handleTogglePaymentForm.bind(this)}>
              {intl.add}
            </Button>
          )}
        </div>
      )
    }
    return (
      <AddPaymentAccountForm
        onDone={this.handlePaymentAdded.bind(this)}
        personId={personId}
        personToken={personToken}
        app={app}
      />
    )
  }
}

export default connect((state) => ({
  loanFormState: state.loanFormState,
  app: state.app
}))(PartnerPaymentsForm)

PartnerPaymentsForm.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  loanFormState: PropTypes.object
}
