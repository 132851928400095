import { dadataApi } from '#src/modules/api'

export const getSuggestions = async (query, options, params) => {
  const mutableOptions = options.split(':')
  const data = { query, ...params }

  if (mutableOptions[0] === 'email' && !data.query.includes('@')) return {}

  if (mutableOptions[1] && mutableOptions[0] === 'fio') data.parts = mutableOptions[1].toUpperCase()

  if (mutableOptions[1] && mutableOptions[0] === 'address') {
    const bounds = mutableOptions[1].split('-')
    data.from_bound = { value: bounds[0] }
    data.to_bound = { value: bounds[1] || bounds[0] }
  }

  const response = await dadataApi.post(mutableOptions[0], data)
  const mutableResponse = response.data || {}
  mutableResponse.data = mutableResponse.suggestions || []
  delete mutableResponse.suggestions
  return mutableResponse
}
