import { createAsyncThunk } from '@reduxjs/toolkit'
import { isEmpty, mapValues, merge, snakeCase } from 'lodash'

import DataSaver from '#modules/DataSaver'
import { TMainRootStateType } from '#reducers/entries/main/mainRootTypes'
import { CreatePrefix } from '#reducers/helper'
import { STORAGE_KEY } from '#services/helper'
import { Actions, ActionStages, UserActionLogger } from '#services/userActionLogger'

const prefixHelper = new CreatePrefix('abTest')

export const logAbSettings = createAsyncThunk<Promise<void>, string, { state: TMainRootStateType }>(
  prefixHelper.create('logAbSettings'),
  async (token: string, thunkAPI): Promise<void> => {
    const actionLogger = new UserActionLogger()
    const dataSaver = new DataSaver(STORAGE_KEY)

    const { dataToLog } = thunkAPI.getState().abTest

    if (isEmpty(dataToLog)) return

    const actions = Object.entries(dataToLog).map(([key, value]) => ({
      action: snakeCase(key).toUpperCase() as Actions,
      stage: snakeCase(value).toUpperCase() as ActionStages
    }))

    const isLogged = await actionLogger.logPackage({ token, actions })

    if (!isLogged) return

    const loggedData = mapValues(dataToLog, (value) => ({ value, isLogged: true }))
    dataSaver.saveData(merge(dataSaver.getData(), loggedData))
  }
)
