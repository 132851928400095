import { FormEvent, Fragment, MouseEvent } from 'react'
import { connect } from 'react-redux'
import { NavigateFunction } from 'react-router-dom'

import { TMainAppDispatch } from '#components/App/App'
import Button from '#components/Button'
import { ButtonComponentTypes } from '#components/Button/types'
import { TFormLine } from '#components/Form/Form'
import intl from '#intl'
import { adminAuthTokenSelector } from '#reducers/adminPage/auth/authSlice'
import {
  buttonSelector,
  editButton,
  TButtonsInitialState
} from '#reducers/adminPage/personNotification/buttonSlice'
import {
  addButton,
  adminNotificationButtonErrorItem
} from '#reducers/adminPage/personNotification/buttonsSlice'
import { SymbolLengthValidationForm } from '#src/components/Form'
import { adminApi } from '#src/modules/api'
import { ResponseCode } from '#src/modules/api/types/common'
import { TMainDataStateType } from '#src/reducers'

import { ButtonModel } from './model'

type TState = {
  loading: boolean
}

type TButtonOwnProps = {
  afterSuccess?: () => void
  type: 'edit' | 'create'
  params: TParams
  navigate?: NavigateFunction
  returnButton?: string
}

type TMapStateToProps = {
  token: string
  error: string
  button: TButtonsInitialState
}

type TMapDispatchToProps = {
  addButton: (data: FormData) => void
  editButton: (data: FormData, navigate: NavigateFunction) => void
}

type TButtonFormProps = TMapStateToProps & TMapDispatchToProps & TButtonOwnProps
export type TParams = {
  id: string
}

class ButtonForm extends SymbolLengthValidationForm<TButtonFormProps, TState> {
  constructor(props: TButtonFormProps) {
    super(props)
    this.model = ButtonModel
    this.state = {
      ...this.state,
      loading: false,
      form: this.computeForm()
    }
  }

  async componentDidMount(): Promise<void> {
    const { token, type: formType, params } = this.props
    // если форма редактирования, то необходимо получить информацию по текущей кнопке
    if (formType === 'edit') {
      // запрос к api
      try {
        const result = await adminApi.getNotificationButton(token, Number(params.id))
        if (result.code === ResponseCode.success) {
          const { data } = result
          this.setState({ data })
        }
      } catch (err) {
        console.error(err)
      }
    }
  }

  renderFormLine = (line: TFormLine): JSX.Element => (
    <div className='form__line'>
      {line.items.map((item) => !this.checkDepends(item) && this.renderItem(item))}
    </div>
  )

  handleSubmit = (event?: FormEvent): void => {
    event && event.preventDefault()
    if (!this.validateSymbolLength('NotificationSettings_ButtonCard_ButtonForm')) return
    if (this.validateForm(true)) {
      const formData = this.collectData()
      const { token, addButton, editButton, type: formType } = this.props
      formData.append('token', token)
      if (formType === 'create') addButton(formData)
      if (formType === 'edit') {
        const {
          params: { id }
        } = this.props
        formData.append('id', id)
        editButton(formData, this.props.navigate as NavigateFunction)
      }
    }
  }

  renderCommonError = (): JSX.Element | null => {
    const { error } = this.props
    const {
      button: { error: buttonError }
    } = this.props
    if (error) return <div className='form__error'>{error}</div>
    if (buttonError) return <div className='form__error'>{buttonError}</div>
    return null
  }

  handleReturn = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void => {
    const { navigate } = this.props
    event && event.preventDefault()
    event && event.stopPropagation()
    navigate && navigate('/admin/personal-notifications')
  }

  render(): JSX.Element {
    const { form, loading: isLoading } = this.state
    const {
      button: { isLoading: isButtonLoading },
      returnButton
    } = this.props
    const text = this.state.data?.text || 'Предпросмотр'
    const colorButton = (this.state.data?.colorButton as string) || ''
    const colorText = (this.state.data?.colorText as string) || ''

    return (
      <form noValidate onSubmit={this.handleSubmit} data-qa='adminNotification-button-form'>
        <div className='button-preview my-1'>
          <label className='form__item-label' htmlFor='preview'>
            {'Предпросмотр'}
          </label>
          <Button
            id='preview'
            type={ButtonComponentTypes.LABEL}
            size='l'
            fluid
            loading={false}
            style={{ backgroundColor: colorButton, border: 'none' }}
            data-qa='adminNotification-preview-button'
          >
            <span style={{ color: colorText }}>{text}</span>
          </Button>
        </div>
        {form?.lines.map((line, key) => (
          <Fragment key={key}>{this.renderFormLine(line)}</Fragment>
        ))}
        <div className='form__bottom d-flex my-3 mt-4'>{this.renderCommonError()}</div>
        <div className='form__bottom d-flex my-3 mt-4 flex-column flex-md-row'>
          <Button
            type={ButtonComponentTypes.BUTTON}
            size='l'
            fluid
            loading={isLoading || isButtonLoading}
            data-qa='adminNotification-confirm'
            className='me-md-4 mb-3 mb-md-0'
          >
            {intl.save}
          </Button>
          {returnButton && (
            <Button
              type={ButtonComponentTypes.BUTTON}
              onClick={this.handleReturn}
              size='l'
              fluid
              loading={isLoading || isButtonLoading}
              data-qa='adminNotification-return'
            >
              {intl.return}
            </Button>
          )}
        </div>
      </form>
    )
  }
}

const mapStateToProps = (state: TMainDataStateType): TMapStateToProps => ({
  token: adminAuthTokenSelector(state) as string,
  error: adminNotificationButtonErrorItem(state),
  button: buttonSelector(state)
})

const mapDispatchToProps = (dispatch: TMainAppDispatch): TMapDispatchToProps => ({
  addButton: (data) => dispatch(addButton(data)),
  editButton: (data, navigate) =>
    dispatch(
      editButton({
        data,
        navigate
      })
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(ButtonForm)
