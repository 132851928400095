import intl from '#intl'

import CameraBaseError from './CameraBaseError'

export default class NotReadableError extends CameraBaseError {
  constructor(message = intl.cameraIsNotAvailable) {
    super(message)
    this.name = 'NotReadableError'
  }
}
