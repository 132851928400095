import './assets/style.scss'

import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import classNames from 'classnames'
import { FC, useCallback, useEffect, useRef } from 'react'
import ReactModal from 'react-modal'

import Icon from '#components/Icon'

type TModalSize = 'normal' | 'small' | 'middle' | 'extra-middle'
type TModalClassName = {
  [key: string]: boolean
}

function getModalClassName(className = 'ui-modal', size: TModalSize = 'normal'): TModalClassName {
  const isNormalSize = size === 'normal'
  const isSmallSize = size === 'small'
  const isMiddleSize = size === 'middle'
  const isExtraMiddleSize = size === 'extra-middle'

  return {
    [className]: true,
    [`${className}__normal`]: isNormalSize,
    [`${className}__small`]: isSmallSize,
    [`${className}__middle`]: isMiddleSize,
    [`${className}__extra-middle`]: isExtraMiddleSize
  }
}

export type TModalProps = {
  closeModal: () => void
  size: TModalSize
  classNameBody?: string
  title: string | JSX.Element
  classNameHeader?: string
}
type TClassNamesBody = {
  [key: string]: boolean
}
const Modal: FC<TModalProps> = (props) => {
  const modalContainer = useRef<HTMLDivElement>(null)
  const appElement = useRef(document.getElementById('app'))
  const { classNameHeader, classNameBody, children, closeModal, title, size } = props

  const classNamesContainer = getModalClassName('ui-modal__container', size)
  const classNamesHeader = getModalClassName('ui-modal__container__header', size)
  const classNamesTitle = getModalClassName('ui-modal__container__header__title', size)
  const classNamesCloseIcon = getModalClassName('ui-modal__container__header__close', size)

  if (classNameHeader) classNamesHeader[classNameHeader] = true

  const classNamesBody: TClassNamesBody = {
    'ui-modal__container__content': true
  }

  if (classNameBody) classNamesBody[classNameBody] = true

  const handleAfterOpen = useCallback(() => {
    const container = modalContainer.current as HTMLDivElement
    disableBodyScroll(container)
  }, [])

  const handleAfterClose = useCallback(() => {
    clearAllBodyScrollLocks()
  }, [])

  useEffect(() => () => clearAllBodyScrollLocks(), [])

  return (
    <ReactModal
      isOpen
      contentLabel='Modal'
      onAfterOpen={handleAfterOpen}
      onAfterClose={handleAfterClose}
      appElement={appElement.current as HTMLElement}
      className='Modal'
      overlayClassName='Overlay'
    >
      <div ref={modalContainer} className={classNames(classNamesContainer)}>
        <div className={classNames(classNamesHeader)}>
          {title && (
            <>
              <div className={classNames(classNamesTitle)}>{title}</div>
              <span
                onClick={closeModal}
                className={classNames({
                  modal__close: true,
                  ...classNamesCloseIcon
                })}
              >
                <Icon name='close' className='icon-xs icon-padded' />
              </span>
            </>
          )}
        </div>
        <div className={classNames(classNamesBody)}>{children}</div>
      </div>
    </ReactModal>
  )
}

export default Modal
