import { has, isFunction } from 'lodash'
import PropTypes from 'prop-types'
import { Component } from 'react'

import { fakeNamePrefix } from '../../constants/common'

const fields = [
  'city',
  'city1',
  'passportCity',
  'passportCity1',
  'street',
  'street1',
  'secondName',
  'name',
  'middleName',
  'house',
  'house1',
  'building',
  'building1',
  'apartment',
  'apartment1',
  'index',
  'index1',
  'email'
]

const disabledPlaceHolder = ['street', 'street1', 'birthPlace', 'index', 'index1', 'email']

const fakeFields = fields.map((field) => `${fakeNamePrefix}${field.split('').reverse().join('')}`)

const propNamesWhiteList = ['onBeforeFileUploadDialogOpen']

const withSmartControl = (wrappedComponent) =>
  (class WithSmartControl extends Component {
    static propTypes = {
      setSuggestions: PropTypes.func,
      onAsyncInputChange: PropTypes.func,
      onAsyncOptionChange: PropTypes.func,
      onFilesAdd: PropTypes.func,
      handleClick: PropTypes.func,
      onKeyPress: PropTypes.func,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      id: PropTypes.number
    }

    getProperties = (object) => {
      const componentProperties = {}
      for (const key in object) {
        if ((has(object, key) && !isFunction(object[key])) || propNamesWhiteList.includes(key))
          componentProperties[key] = object[key]
      }
      return componentProperties
    }

    modifyEvent = (evt) => {
      if (!evt) return null
      const {
        target: { name: fakeName, value },
        type
      } = evt
      const itemName = this.decodeName(fakeName)
      evt.detail = { name: itemName, type, value }
      return evt
    }

    encodeName = (itemName) => {
      if (fields.includes(itemName))
        return `${fakeNamePrefix}${itemName.split('').reverse().join('')}`
      return itemName
    }

    decodeName = (itemName) => {
      if (fakeFields.includes(itemName))
        return itemName.replace(fakeNamePrefix, '').split('').reverse().join('')
      return itemName
    }

    handleAction = ({ evt, action }) => {
      const modifiedEvent = this.modifyEvent(evt)
      this.props[`${action}`] && this.props[`${action}`](modifiedEvent)
    }

    handleControlFocus = (evt) => this.handleAction({ evt, action: 'onFocus' })

    handleControlBlur = (evt) => this.handleAction({ evt, action: 'onBlur' })

    handleControlChange = (evt) => this.handleAction({ evt, action: 'onChange' })

    handleRemove = (evt) => this.handleAction({ evt, action: 'onRemove' })

    setSuggestions = ({ name: fakeName, options }) => {
      this.props.setSuggestions &&
        this.props.setSuggestions({
          options,
          name: this.decodeName(fakeName)
        })
    }

    handleAsyncOptionChange = ({ name: fakeName, ...other }) => {
      this.props.onAsyncOptionChange &&
        this.props.onAsyncOptionChange({
          name: this.decodeName(fakeName),
          ...other
        })
    }

    handleAsyncInputChange = ({ name: fakeName, ...other }) => {
      this.props.onAsyncInputChange &&
        this.props.onAsyncInputChange({
          name: this.decodeName(fakeName),
          ...other
        })
    }

    handleCardAdd = async (evt) => {
      this.props.onFilesAdd && (await this.props.onFilesAdd(evt))
    }

    handleKeyPress = (evt) => {
      this.props.onKeyPress && this.props.onKeyPress(evt)
    }

    render() {
      const { name: itemName, placeholder, id = null, handleClick } = this.props
      const warpperProps = this.getProperties(this.props)
      const WrappedComponent = wrappedComponent
      return (
        <WrappedComponent
          {...warpperProps}
          handleClick={handleClick} // для SmartControlLink обязателен handler
          onFilesAdd={this.handleCardAdd}
          onKeyPress={this.handleKeyPress}
          onFocus={this.handleControlFocus}
          onBlur={this.handleControlBlur}
          onChange={this.handleControlChange}
          onRemove={this.handleRemove}
          setSuggestions={this.setSuggestions}
          onAsyncOptionChange={this.handleAsyncOptionChange}
          onAsyncInputChange={this.handleAsyncInputChange}
          placeholder={disabledPlaceHolder.includes(itemName) ? '' : placeholder}
          name={this.encodeName(itemName)}
          id={id || itemName}
        />
      )
    }
  })

export default withSmartControl
