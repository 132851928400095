import intl from '../../intl/ru'

export const columns = [
  { rowId: 0, Header: intl.date, accessor: 'grouper', width: 60 },
  {
    rowId: 1,
    Header: 'Кликов (уников)',
    id: 'metrikaCount',
    accessor: (item) => `${item.metrikaCount} (${item.incomingCount})`,
    width: 50
  },
  {
    rowId: 3,
    Header: 'Заявок OK',
    id: 'requestCountNotBadRefusal',
    accessor: (item) => `${item.requestCountNotBadRefusal} (${item.requestPercentNotBadRefusal} %)`,
    width: 50
  },
  {
    rowId: 6,
    Header: 'Новых ОК',
    id: 'newRequestCountNotBadRefusal',
    accessor: (item) =>
      `${item.newRequestCountNotBadRefusal} (${item.newRequestPercentNotBadRefusal} %)`,
    width: 50
  },
  {
    rowId: 7,
    Header: 'CR1',
    id: 'cr1Metrika',
    accessor: (item) => `${item.cr1Metrika} (${item.cr1Incoming}) %`,
    width: 50
  },
  { rowId: 8, Header: 'Выдано', accessor: 'issueCount', width: 50 },
  { rowId: 9, Header: 'Выдано OK', accessor: 'issueCountInCondition', width: 50 },
  { rowId: 10, Header: 'Выдано к оплате Средний', accessor: 'issueCountGray', width: 50 },
  { rowId: 11, Header: 'Выдано к оплате Хороший', accessor: 'issueCountBlue', width: 50 },
  { rowId: 12, Header: 'Выдано к оплате Отличный', accessor: 'issueCountGreen', width: 50 },
  { rowId: 13, Header: 'Выдачи отклонено', accessor: 'issueCountNotInCondition', width: 50 },
  { rowId: 14, Header: 'Выдачи в холде', accessor: 'issueCountHoldCondition', width: 50 },
  {
    rowId: 15,
    Header: '(Новых)',
    id: 'newIssueCount',
    accessor: (item) => `${item.newIssueCount} (${item.newIssuePercent} %)`,
    width: 50
  },
  {
    rowId: 16,
    Header: '(Новых ОК)',
    id: 'newIssueCountInCondition',
    accessor: (item) => `${item.newIssueCountInCondition} (${item.newIssuePercentInCondition} %)`,
    width: 50
  },
  { rowId: 17, Header: 'CR2', accessor: 'cr2', width: 50 },
  {
    rowId: 18,
    Header: 'Выпл. (с НДС)',
    id: 'bonus',
    accessor: (item) =>
      `${item.bonus.toFixed(2)} (${(Number(item.bonus) + item.bonusVAT).toFixed(2)})`,
    width: 50
  },
  { rowId: 19, Header: 'EPC', accessor: 'eCPC', width: 50 }
]

export const extendedColumns = [
  {
    rowId: 2,
    Header: 'Заявок (подтв.+неп.)',
    id: 'requestCount',
    accessor: (item) =>
      `${item.requestCount} (${item.confirmedRequestCount} + ${item.notConfirmedRequestCount})`,
    width: 50
  },
  {
    rowId: 4,
    Header: 'Неподтв.',
    id: 'notConfirmedRequestPercent',
    accessor: (item) => `${item.notConfirmedRequestPercent.toFixed(2)} %`,
    width: 50
  },
  {
    rowId: 5,
    Header: 'Новых',
    id: 'newRequestCount',
    accessor: (item) => `${item.newRequestCount} (${item.newRequestPercent} %)`,
    width: 50
  }
]
