import classNames from 'classnames'
import { FC, SyntheticEvent, useCallback } from 'react'

import intl from '#intl'
import { formatDate, parseDate } from '#services/datetime'

interface IPartnerPaymentItemProps {
  id: number
  className?: string
  type: 0 | 1
  isActive: boolean
  isApproved: number
  isBillStop: number
  isValid: number
  isVerified: number | null
  onClick?: (id: number) => void
  paymentClass: string
  cardHolder: string | null
  createTs: string
  disableReason: string
  name: string
}

interface ICardOrBillItemProps extends IPartnerPaymentItemProps {
  label: string
  account?: string
  bik?: string
  validTs?: string | null
  bankName?: string
}

type TCardItemProps = Pick<
  ICardOrBillItemProps,
  'label' | 'validTs' | 'isVerified' | 'isActive' | 'cardHolder' | 'account'
>

type TBillItemProps = Pick<
  ICardOrBillItemProps,
  'bankName' | 'label' | 'account' | 'isVerified' | 'isActive'
>

const PartnerPaymentItem: FC<IPartnerPaymentItemProps> = (props) => {
  const { paymentClass, onClick, id, type = 'account', isActive, isVerified } = props
  const paymentStyle = classNames(
    {
      'mt-3': true,
      'partner-payments__card': Boolean(type),
      'partner-payments__account': !type,
      active: isActive,
      error: Boolean(type) && !isVerified
    },
    paymentClass
  )

  const handleClick = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault()
      event.stopPropagation()
      onClick && onClick(id)
    },
    [id, onClick]
  )

  const attr: ICardOrBillItemProps = {
    className: 'partner-payments',
    account: 'N/A',
    bik: 'N/A',
    ...props,
    label: props.type ? intl.paymentTypeCard : intl.paymentTypeAccount
  }

  return (
    <button className={paymentStyle} onClick={handleClick}>
      {props.type !== 0 ? <CardItem {...attr} /> : <BillItem {...attr} />}
    </button>
  )
}

const CardItem: FC<TCardItemProps> = (props) => {
  const { label, validTs, isVerified, isActive, cardHolder, account } = props
  return (
    <>
      <span>{label}</span>
      <span>{account}</span>
      <span>{formatDate(parseDate(validTs), 'MM/yy')}</span>
      <span>{cardHolder}</span>
      {isVerified !== 0 ? (
        <label>
          <input type='radio' checked={Boolean(isActive)} readOnly />
        </label>
      ) : null}
    </>
  )
}

const BillItem: FC<TBillItemProps> = (props) => {
  const { bankName, label, account, isVerified, isActive } = props
  return (
    <>
      <span>{label}</span>
      <span>{account}</span>
      <span>{bankName}</span>
      {isVerified !== 0 ? (
        <label>
          <input type='radio' checked={Boolean(isActive)} readOnly />
        </label>
      ) : null}
    </>
  )
}

export default PartnerPaymentItem
