import intl from '#intl'

import CameraBaseError from './CameraBaseError'

export default class NotImplementedError extends CameraBaseError {
  constructor(message = intl.getUserMediaNotImplemented) {
    super(message)
    this.name = 'NotImplemented'
  }
}
