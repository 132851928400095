import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import ConfirmDeleteButton from '#components/AdminPage/NotificationSettings/ButtonCard/ConfirmDeleteButton'
import ConfirmDeleteNotification from '#components/AdminPage/NotificationSettings/NotificationCard/ConfirmDeleteNotification'
import { ConfirmDeleteBanner } from '#components/AdminPage/PersonalAreaPromoSettings/PromoBannerForm/ConfirmDeleteBanner'
import MarketNotification from '#components/Dialogs/MarketNotification/MarketNotification'
import { closeModal } from '#reducers/ui/modalSlice'

import AdminNotificationAddButton from './AdminNotification/AddButton'
import Info from './Info'
import Message from './Message'
import PersonalMain from './Personal/MainWrapper'

export const DIALOG_COMPONENTS = {
  info: Info,
  message: Message,
  marketNotification: MarketNotification,
  personalMain: PersonalMain,
  adminNotificationAddButton: AdminNotificationAddButton,
  confirmDeleteAction: ConfirmDeleteButton,
  confirmDeleteNotification: ConfirmDeleteNotification,
  confirmDeleteBanner: ConfirmDeleteBanner
}

function Dialogs(props) {
  const {
    closeModal,
    modal: { modalType, modalProps }
  } = props
  const SpecificModal = DIALOG_COMPONENTS[modalType]

  if (modalType && SpecificModal) return <SpecificModal {...modalProps} closeModal={closeModal} />

  return null
}

Dialogs.propTypes = {
  modal: PropTypes.object,
  closeModal: PropTypes.func
}

const mapStateToProps = (state) => ({
  modal: state.ui.modal
})
const mapDispatchToProps = (dispatch) => ({
  closeModal: () => {
    dispatch(closeModal())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Dialogs)
