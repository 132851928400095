import { TModelItem } from '#components/Form/Form'
import {
  ABSOLUT_PROCESSING_PERSONAL_DATA,
  D2_PROCESSING_PERSONAL_DATA,
  DOCTOR_NEARBY_PROCESSING_PERSONAL_DATA,
  SCORISTA_PROCESSING_PERSONAL_DATA
} from '#components/PersonalAreaPage/components/ConfirmLoanForm/model'
import intl from '#intl'

const model: TModelItem[] = [
  {
    line: 1,
    name: 'mobileNumber',
    type: 'tel',
    disabled: true,
    label: intl.mobileNumber,
    mask: '+7 (999) 999-99-99',
    required: false,
    emptyMessage: intl.fieldEmpty,
    validationRule: '^\\+7 \\(9[0-9]{2}\\) [0-9]{3}\\-[0-9]{2}\\-[0-9]{2}|79[0-9]{9}$',
    validationMessageError: intl.fieldInvalid
  },
  {
    line: 1,
    name: 'smsCode',
    type: 'number',
    label: intl.smsCode,
    placeholder: intl.smsCodePlaceholder,
    mask: '999999',
    required: true,
    emptyMessage: intl.fieldEmpty,
    validationRule: '[0-9]{6}',
    validationMessageError: intl.fieldInvalid
  },
  {
    line: 2,
    name: 'inn',
    type: 'number',
    label: intl.yourInnByFNS,
    placeholder: intl.fieldEmpty,
    mask: '999999999999',
    required: true,
    emptyMessage: intl.fieldEmpty,
    validationRule: '[0-9]{12}',
    validationMessageError: intl.fieldInvalid
  },
  {
    line: 3,
    name: 'totalIncome',
    type: 'text',
    label: intl.totalIncome,
    required: true
  },
  {
    line: 4,
    name: 'creditId',
    type: 'hidden',
    required: true,
    emptyMessage: intl.fieldEmpty
  },
  {
    line: 4,
    name: 'brokerToken',
    type: 'hidden'
  },
  {
    line: 4,
    name: 'personId',
    type: 'hidden'
  },
  {
    line: 4,
    name: 'person2brokerId',
    type: 'hidden'
  },
  {
    line: 4,
    name: 'totalIncomeSnap',
    type: 'hidden'
  },
  {
    line: 5,
    name: 'agreement',
    type: 'checkbox',
    required: true
  },
  {
    line: 6,
    label: '',
    type: 'checkbox',
    name: 'personDataAgreementChecked',
    required: true,
    child: [
      {
        line: 1,
        label: 'ПАО «Вымпелком»',
        type: 'checkbox',
        name: 'veonProcessingPersonalDataChecked',
        required: false
      },
      {
        line: 2,
        label: 'ПАО «Мегафон»',
        type: 'checkbox',
        name: 'megafonProcessingPersonalDataChecked',
        required: false
      },
      {
        line: 3,
        label: 'ПАО «МТС»',
        type: 'checkbox',
        name: 'mtsProcessingPersonalDataChecked',
        required: false
      },
      {
        line: 4,
        label: 'ООО «Т2 Мобайл»',
        type: 'checkbox',
        name: 't2ProcessingPersonalDataChecked',
        required: false
      },
      {
        line: 5,
        label: 'ТКБ БАНК ПАО',
        type: 'checkbox',
        name: 'tkbProcessingPersonalDataChecked',
        required: false
      },
      {
        line: 6,
        label: 'ООО «Абсолют Страхование»',
        type: 'checkbox',
        name: ABSOLUT_PROCESSING_PERSONAL_DATA,
        required: false
      },
      {
        line: 7,
        label: 'АО «Д2 Страхование»',
        type: 'checkbox',
        name: D2_PROCESSING_PERSONAL_DATA,
        required: false
      },
      {
        line: 8,
        label: 'ООО «Финкарта»',
        type: 'checkbox',
        name: 'fincardProcessingPersonalDataChecked',
        required: false
      },
      {
        line: 9,
        label: 'ООО «МК Доктор рядом»',
        type: 'checkbox',
        name: DOCTOR_NEARBY_PROCESSING_PERSONAL_DATA,
        required: false
      },
      {
        line: 10,
        label: 'ООО «Скориста»',
        type: 'checkbox',
        name: SCORISTA_PROCESSING_PERSONAL_DATA,
        required: false
      },
      {
        line: 11,
        label: 'ООО СК «Согласие-Вита»',
        type: 'checkbox',
        name: 'vitaProcessingPersonalDataChecked',
        required: false
      }
    ]
  },
  {
    line: 7,
    label: '',
    type: 'checkbox',
    name: 'nbkiAgreementChecked',
    required: true
  }
]

export default model
