import intl from '#intl'

import CameraBaseError from './CameraBaseError'

export default class NotAllowedError extends CameraBaseError {
  constructor(message = intl.cameraIsNotAvailable) {
    super(message)
    this.name = 'NotAllowedError'
  }
}
