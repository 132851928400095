import querystring from 'query-string'
import { Cookies } from 'react-cookie'
import { connect } from 'react-redux'

import { ROUTES } from '#constants/common'
import { updateLoggedInToken, updatePartnerParameters } from '#reducers/app/appSlice'
import { fetchBrokerInfo } from '#reducers/brokerSlice'
import { brokerApi } from '#src/modules/api'

import { LoginForm } from './LoginForm'

const cookies = new Cookies()

class LoginFormPartner extends LoginForm {
  handleSubmit = async (event) => {
    event && event.preventDefault()
    const { loading } = this.state
    const { onLogin } = this.props
    if (!loading && this.validateForm(true)) {
      this.setState({ loading: true })
      try {
        const formData = this.collectData()
        const response = await brokerApi.brokerLogin(formData)
        if (response.code !== 0) throw response
        const infoParams = {
          brokerToken: response.data.brokerToken,
          part: 'info'
        }
        this.props.brokerInfo(infoParams)

        cookies.set('brokerToken', response.data.brokerToken, { path: ROUTES.main })
        cookies.set('partnerParameters', querystring.stringify({ aprt159: response.data.param }))

        this.props.updateLoggedInToken({ token: response.data.brokerToken })
        this.props.updatePartnerParameters({
          partnerParameters: querystring.stringify({
            aprt159: response.data.param
          })
        })

        onLogin && onLogin(querystring.stringify({ aprt159: response.data.param }))
        // window.location.reload()
      } catch (err) {
        this.handleResponse(err)
      } finally {
        this.setState({ loading: false })
      }
    }
  }
}

const mapDispatchToProps = (dispatch) => ({
  brokerInfo: (params) => dispatch(fetchBrokerInfo(params)),
  updatePartnerParameters: ({ partnerParameters }) =>
    dispatch(updatePartnerParameters({ partnerParameters })),
  updateLoggedInToken: ({ token }) => dispatch(updateLoggedInToken({ token }))
})

export default connect(null, mapDispatchToProps)(LoginFormPartner)
