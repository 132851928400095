import './partner-show-form.scss'

import classnames from 'classnames'
import querystring from 'query-string'
import { connect } from 'react-redux'

import { FormItem } from '#components/Form'
import { CONSUMER_CREDIT_MONTH } from '#constants/common'
import intl from '#intl'
import { mainSiteApi } from '#modules/api'
import { restoreForm } from '#services/helper'
import { brokerApi } from '#src/modules/api'

import { Button } from '../../Button/Button'
import { Form } from '../../Form/Form'
import { Icon } from '../../Icon/Icon'
import LoanLoader from '../../LoanLoader'
import SmartControl from '../../SmartControl'

class PartnerShowForm extends Form {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      loading: true,
      step: 1,
      credit: props.data
    }
  }

  async componentDidMount() {
    await this.initForm()
  }

  maskCardNumber = (cardNumber) => {
    if (cardNumber && cardNumber.length > 0) {
      const replacer = 'x'
      return cardNumber.replaceAll(/^(.{4})\s(.)(.{3})\s(.{4})\s(.{4})$/gi, (match, ...args) => {
        const [p1, p2, p3, p4, p5] = args
        return `${p1} ${p2}${p3.replace(/./gi, replacer)} ${p4.replace(/./gi, replacer)} ${p5}`
      })
    }
    return cardNumber
  }

  async recoverData(data, token) {
    const recoverData = await restoreForm(data, token)
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        ...recoverData
      },
      loading: false
    }))
  }

  initForm = async () => {
    try {
      const brokerToken = this.props.app.loggedInToken
      const formResponse = await mainSiteApi.getRegistrationForm({ brokerToken })
      this.model = formResponse.data
      const form = { steps: [] }
      const data = {
        requiredInn: false
      }
      this.model.map((item) => {
        const step = item.step - 1
        const line = item.line - 1

        if (item.default) data[item.systemName] = item.default

        form.steps[step] = form.steps[step] || { lines: [] }
        form.steps[step].lines[line] = form.steps[step].lines[line] || { items: [] }
        form.steps[step].lines[line].items.push(item)
      })
      this.setState({ form, data })
      const { personId } = this.props.data
      if (brokerToken && personId) {
        const response = await brokerApi.getPerson2BrokerToken({ personId, brokerToken })
        if (response.data) {
          this.setState({ token: response.data.token })
          const restoreResponse = await mainSiteApi.registrationRestore(response.data.token)
          if (restoreResponse && restoreResponse.code === 100 && restoreResponse.data)
            await this.recoverData(restoreResponse.data, response.data.token)
        }
      }
    } catch (err) {
      console.error(err)
    }
  }

  handleChangeStep(step) {
    this.setState({ step })
  }

  renderItem(item) {
    const { data, errors } = this.state
    const brokerToken = this.props.app.loggedInToken
    const _item = { ...item }
    _item.type = _item.elementType
    _item.name = _item.systemName
    _item.value = data[_item.name]
    _item.valid = !errors[_item.name]

    if (brokerToken && _item.name === 'tkbLifeChecked') {
      const { productType = null } = data
      if (productType !== CONSUMER_CREDIT_MONTH) return null
    }

    if (['smsCode', 'privateAgreement', 'personalAgreementMarketing'].includes(_item.name))
      _item.hidden = true

    if (_item.name === 'email') _item.placeholder = ''

    if (_item.type === 'html') {
      return (
        <FormItem key={_item.name}>
          <div dangerouslySetInnerHTML={{ __html: _item.label }} />
        </FormItem>
      )
    }
    if (!_item.hidden) {
      return (
        <FormItem key={_item.name} {..._item}>
          <SmartControl {..._item} disabled />
        </FormItem>
      )
    }
  }

  redirectToActivate({ personId, creditId, location, navigate }) {
    const queryObj = {
      ...querystring.parse(location.search),
      creditId,
      personId
    }
    navigate(querystring.stringifyUrl({ url: '/loan', query: queryObj }), { replace: true })
  }

  render() {
    const {
      form,
      loading,
      step,
      credit: { personId, creditStatus = -1, finished = false, creditId, creditCreateTs }
    } = this.state
    const timeStamp = Math.floor(Date.now() / 1000)
    const isCanCreate = Number(timeStamp) - creditCreateTs < 86400

    const buttonAttr = {
      type: 'button',
      disabled: false,
      onClick: () => this.redirectToActivate({ personId, creditId, ...this.props })
    }

    if (!loading && form) {
      return (
        <div className='partner-page__show_data form registration-form'>
          <h2>{intl.handleMoveToShowForm}</h2>
          <div className='partner-show-form__steps'>
            {new Array(form.steps.length).fill().map((item, key) => (
              <span
                className={classnames({
                  'registration-form__steps-item': true,
                  'is-active': key + 1 === step
                })}
                key={key}
                onClick={this.handleChangeStep.bind(this, key + 1)}
              >
                <span>{key + 1}</span>
                <Icon name='tick' />
              </span>
            ))}
          </div>
          {form.steps.map((_step, key) => (
            <div
              className={classnames({
                'partner-show-form__steps-item': true,
                'registration-form__step': true,
                'partner-show-form__steps-item-active': key + 1 === step
              })}
              key={key}
            >
              {_step.lines.map((_line, key2) => (
                <div className='form__line' key={key2}>
                  {_line.items.map((item) => this.renderItem(item))}
                </div>
              ))}
            </div>
          ))}
          {creditStatus === -1 && finished && isCanCreate && (
            <div className='partner-page__show-activate'>
              <Button {...buttonAttr}>{'Подтвердить заявку'}</Button>
            </div>
          )}
        </div>
      )
    }
    return <LoanLoader />
  }
}
export default connect((state) => ({
  app: state.app
}))(PartnerShowForm)
