import { createSelector } from '@reduxjs/toolkit'
import { get } from 'lodash'

import { CONSUMER_CREDIT_MONTH } from '#constants/common'
import { loanConditions } from '#reducers/loanConditions/loanConditionsSlice'
import { loanFormStateSelector } from '#reducers/loanFormState/loanFormStateSlice'

export const getCalculatedData = createSelector(
  loanConditions,
  loanFormStateSelector,
  (loanConditions, formState) => {
    const {
      tkbAgreementValue,
      data: { creditType, term, amount },
      index
    } = formState
    const {
      data: { products }
    } = loanConditions
    const calculatedInfoKey =
      tkbAgreementValue && creditType === CONSUMER_CREDIT_MONTH
        ? 'calculatedInfoWithInsurance'
        : 'calculatedInfo'
    const paths = [
      creditType,
      index,
      calculatedInfoKey,
      `amount_${amount}`,
      `period_${term}`
    ] as string[]
    return get(products, paths, {}) as TReturnAmountInPeriod
  }
)

export const getCurrentDescriptionInfo = createSelector(
  loanConditions,
  loanFormStateSelector,
  (loanConditions, loanFormState) => {
    const { productInfo } = loanConditions
    const { term, creditType } = loanFormState.data
    if (!productInfo && !Array.isArray(productInfo)) return ''
    const productDescription = productInfo?.find(
      (item) =>
        item.creditType === creditType &&
        term >= item.termMin &&
        term <= item.termMax &&
        item.isActive
    )
    return productDescription?.content || ''
  }
)
