/* eslint-disable @typescript-eslint/no-magic-numbers */
import classnames from 'classnames'
import { isEmpty, isFunction, noop } from 'lodash'
import PropTypes from 'prop-types'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'

import { setCameraStateOn } from '#reducers/cameraSlice'

import {
  CameraFailedError,
  InactiveStreamError as InactiveStreamWarning,
  NotImplementedError,
  NotReadableError,
  UserInteractionRequiredError
} from './errors'
import NotAllowedError from './errors/NotAllowedError'
import OverconstrainedError from './errors/OverconstrainedError'
import { calculateDiag, dataUriToBlob, withExif } from './utils'

const constraints = {
  video: {
    facingMode: { exact: 'environment' },
    width: {
      min: 640, // 480P
      ideal: 1280, // 720p
      max: 1920 // 1080p
    },
    height: {
      min: 480, // 480P
      ideal: 720, // 720p
      max: 1080 // 1080p
    },
    aspectRatio: {
      ideal: 16 / 9 // Full HD
    }
  },
  audio: false
}

const CAMERA_RESTART_INTERVAL_MS = 2500
const CAMERA_RESTART_ATTEMPTS_LIMIT = 2
const CAMERA_ACCESS_ATTEMPTS_LIMIT = 2

const Camera = forwardRef((props, ref) => {
  const videoRef = useRef(null)
  const timerRef = useRef(null)
  const turnOnAttemtsCountRef = useRef(0)
  const accessAttemtsCountRef = useRef(0)
  const { onCameraReady, onCameraFailed, children, prevLocation } = props
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const requestUserMedia = useCallback((constraints) => {
    if (typeof navigator === 'undefined') return Promise.reject(new NotImplementedError())
    const actualGetUserMedia = navigator?.mediaDevices?.getUserMedia
    if (isFunction(actualGetUserMedia))
      return actualGetUserMedia.call(navigator.mediaDevices, constraints)
    const deprecatedGetUserMedia =
      navigator?.getUserMedia ||
      navigator?.webkitGetUserMedia ||
      navigator?.mozGetUserMedia ||
      navigator?.msGetUserMedia
    if (isFunction(deprecatedGetUserMedia)) {
      return new Promise((resolve, reject) =>
        deprecatedGetUserMedia.call(navigator, constraints, resolve, reject)
      )
    }
    return Promise.reject(new NotImplementedError())
  }, [])

  const init = async () => {
    try {
      const videoStream = await requestUserMedia(constraints)
      if (isEmpty(videoRef.current)) return onCameraFailed(new CameraFailedError())
      if (!('srcObject' in videoRef.current))
        videoRef.current.src = URL.createObjectURL(videoStream)
      videoRef.current.srcObject = videoStream
      const result = await turnOnCamera()
      if (result instanceof InactiveStreamWarning) return null
      onCameraReady && onCameraReady()
    } catch (err) {
      if (err.name === 'NotAllowedError') return onCameraFailed(new NotAllowedError())
      if (err.name === 'NotReadableError') {
        accessAttemtsCountRef.current++
        if (accessAttemtsCountRef.current >= CAMERA_ACCESS_ATTEMPTS_LIMIT)
          return onCameraFailed(new NotReadableError())
        return onCameraFailed(new UserInteractionRequiredError())
      }
      if (err.name === 'OverconstrainedError') {
        return onCameraFailed(
          new OverconstrainedError({ constraint: err.constraint, errMessage: err.message })
        )
      }
      onCameraFailed(err)
    }
  }

  const clearTimer = () => clearTimeout(timerRef.current)

  const withClearTimer = (fn) => (...args) => {
    clearTimer()
    fn(...args)
  }

  const turnOnCamera = () => {
    void videoRef.current.play()
    return new Promise((resolve, reject) => {
      turnOnAttemtsCountRef.current++
      const onSuccess = withClearTimer(resolve)
      const onWarning = onSuccess
      const onFailure = withClearTimer(reject)

      const { paused, readyState } = videoRef.current
      if (!paused && readyState >= HTMLMediaElement.HAVE_FUTURE_DATA) return onSuccess()

      videoRef.current.onplaying = onSuccess
      videoRef.current.oncanplay = async () => {
        try {
          await videoRef.current.play()
        } catch (err) {
          onFailure(new UserInteractionRequiredError())
        }
      }

      timerRef.current = setTimeout(() => {
        if (turnOnAttemtsCountRef.current > CAMERA_RESTART_ATTEMPTS_LIMIT)
          return onFailure(new CameraFailedError())
        videoRef.current.onplaying = null
        videoRef.current.oncanplay = null
        videoRef.current.load()
        turnOffCamera()
        onWarning(new InactiveStreamWarning())
        void init()
      }, CAMERA_RESTART_INTERVAL_MS)
    })
  }

  const turnOffCamera = () =>
    videoRef.current?.srcObject?.getTracks?.()?.forEach((track) => track?.stop?.())

  useEffect(() => {
    const { innerRef = {} } = props
    innerRef.current = videoRef.current

    void init()

    let videoElement
    if (videoRef.current) videoElement = videoRef.current
    return () => {
      clearTimer()
      dispatch(setCameraStateOn())

      if (videoElement && videoElement.srcObject) {
        videoElement.srcObject.getTracks().forEach((track) => {
          if (track.readyState === 'live') track.stop()
        })
      }
      navigate(prevLocation)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getVideoScreenSize = () => {
    const { width, height } = videoRef.current.getBoundingClientRect()
    return { width, height }
  }

  const getVideoNaturalSize = () => {
    const width = videoRef.current.videoWidth
    const height = videoRef.current.videoHeight
    return { width, height }
  }

  const getVideoScreenToNaturalScale = () => {
    const { width: naturalWidth, height: naturalHeight } = getVideoNaturalSize()
    const { width: screenWidth, height: screenHeight } = getVideoScreenSize()
    const naturalDiag = calculateDiag(naturalWidth, naturalHeight)
    const screenDiag = calculateDiag(screenWidth, screenHeight)
    return screenDiag / naturalDiag
  }

  const captureImage = async (options = {}) => {
    const { width: naturalWidth, height: naturalHeight } = getVideoNaturalSize()

    const { crop = {} } = options

    const {
      x: cropFromX = 0,
      y: cropFromY = 0,
      width: cropWidth = naturalWidth,
      height: cropHeight = naturalHeight
    } = crop

    const canvas = document.createElement('canvas')

    canvas.setAttribute('width', cropWidth)
    canvas.setAttribute('height', cropHeight)

    const context = canvas.getContext('2d')

    context.drawImage(
      videoRef.current,
      cropFromX,
      cropFromY,
      cropWidth,
      cropHeight,
      0,
      0,
      cropWidth,
      cropHeight
    )

    const dataUri = canvas.toDataURL('image/jpeg', 1)
    const dataUriWithExif = withExif(dataUri, {
      width: cropWidth,
      height: cropHeight
    })
    return dataUriToBlob(dataUriWithExif)
  }

  useImperativeHandle(ref, () => ({
    getScreenSize: getVideoScreenSize,
    getNaturalSize: getVideoNaturalSize,
    getScale: getVideoScreenToNaturalScale,
    turnOff: turnOffCamera,
    turnOn: turnOnCamera,
    captureImage
  }))

  const { className, style } = props
  const classes = classnames({ 'd-flex-centered flex-fill': true }, className)

  return (
    <>
      <div
        className={classes}
        style={{
          width: '100%',
          height: '100%',
          maxWidth: '100vw',
          maxHeight: '100vh',
          ...style
        }}
      >
        <video
          autoPlay
          loop
          playsInline
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            pointerEvents: 'none'
          }}
          ref={videoRef}
          controls={false}
        />
      </div>
      {children}
    </>
  )
})

Camera.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  innerRef: PropTypes.shape({ current: PropTypes.any }),
  onCameraReady: PropTypes.func,
  onCameraFailed: PropTypes.func,
  children: PropTypes.any,
  prevLocation: PropTypes.string
}

Camera.defaultProps = {
  onCameraReady: noop,
  onCameraFailed: noop
}

export default Camera
