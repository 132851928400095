import classnames from 'classnames'
import PropTypes from 'prop-types'
import Qr from 'qrcode.react'

import intl from '#intl'

const QrCode = (props) => {
  const { data, className, style, onClose } = props

  const containerClasses = classnames(
    {
      'd-flex-centered flex-column w-100 p-2': true
    },
    className
  )

  return (
    <div className={containerClasses} style={style}>
      <p className='text-secondary mx-4 text-center'>{intl.scanQrCode}</p>
      <Qr value={data} level='M' />
      {onClose && (
        <span className='link link_pseudo mt-3' onClick={onClose}>
          {intl.hide}
        </span>
      )}
    </div>
  )
}

QrCode.propTypes = {
  data: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  onClose: PropTypes.func
}

QrCode.defaultProps = {
  style: {}
}

export default QrCode
