import intl from '#intl'
import { ErrorWithData } from '#src/errors'

export default class OverconstrainedError extends ErrorWithData {
  constructor(data: Record<string, unknown>) {
    super(intl.suitableCameraNotFound)
    this.name = 'OverconstrainedError'
    this.data = {
      ...data,
      name: this.name
    }
  }
}
