/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
'use strict'
// --- Definition
function numeric() {}
// ---
const cloneDeep = require('lodash/cloneDeep')
// --- numeric.dot declaration
numeric._dim = function _dim(x) {
  var ret = []
  while (typeof x === 'object') {
    ret.push(x.length)
    x = x[0]
  }
  return ret
}

numeric.dim = function dim(x) {
  var y, z
  if (typeof x === 'object') {
    y = x[0]
    if (typeof y === 'object') {
      z = y[0]
      if (typeof z === 'object') {
        return numeric._dim(x)
      }
      return [x.length, y.length]
    }
    return [x.length]
  }
  return []
}

numeric.dotMMsmall = function dotMMsmall(x, y) {
  var i, j, k, p, q, r, ret, foo, bar, woo, i0, k0, p0, r0
  p = x.length
  q = y.length
  r = y[0].length
  ret = Array(p)
  for (i = p - 1; i >= 0; i--) {
    foo = Array(r)
    bar = x[i]
    for (k = r - 1; k >= 0; k--) {
      woo = bar[q - 1] * y[q - 1][k]
      for (j = q - 2; j >= 1; j -= 2) {
        i0 = j - 1
        woo += bar[j] * y[j][k] + bar[i0] * y[i0][k]
      }
      if (j === 0) {
        woo += bar[0] * y[0][k]
      }
      foo[k] = woo
    }
    ret[i] = foo
  }
  return ret
}
numeric._getCol = function _getCol(A, j, x) {
  var n = A.length
  var i
  for (i = n - 1; i > 0; --i) {
    x[i] = A[i][j]
    --i
    x[i] = A[i][j]
  }
  if (i === 0) x[0] = A[0][j]
}
numeric.dotMMbig = function dotMMbig(x, y) {
  var gc = numeric._getCol
  var p = y.length
  var v = Array(p)
  var m = x.length
  var n = y[0].length
  var A = new Array(m)
  var xj
  var VV = numeric.dotVV
  var i, j, k, z
  --p
  --m
  for (i = m; i !== -1; --i) A[i] = Array(n)
  --n
  for (i = n; i !== -1; --i) {
    gc(y, i, v)
    for (j = m; j !== -1; --j) {
      z = 0
      xj = x[j]
      A[j][i] = VV(xj, v)
    }
  }
  return A
}

numeric.dotMV = function dotMV(x, y) {
  var p = x.length
  var q = y.length
  var i
  var ret = Array(p)
  var dotVV = numeric.dotVV
  for (i = p - 1; i >= 0; i--) {
    ret[i] = dotVV(x[i], y)
  }
  return ret
}

numeric.dotVM = function dotVM(x, y) {
  var i, j, k, p, q, r, ret, foo, bar, woo, i0, k0, p0, r0, s1, s2, s3, baz, accum
  p = x.length
  q = y[0].length
  ret = Array(q)
  for (k = q - 1; k >= 0; k--) {
    woo = x[p - 1] * y[p - 1][k]
    for (j = p - 2; j >= 1; j -= 2) {
      i0 = j - 1
      woo += x[j] * y[j][k] + x[i0] * y[i0][k]
    }
    if (j === 0) {
      woo += x[0] * y[0][k]
    }
    ret[k] = woo
  }
  return ret
}

numeric.dotVV = function dotVV(x, y) {
  var i
  var n = x.length
  var i1
  var ret = x[n - 1] * y[n - 1]
  for (i = n - 2; i >= 1; i -= 2) {
    i1 = i - 1
    ret += x[i] * y[i] + x[i1] * y[i1]
  }
  if (i === 0) {
    ret += x[0] * y[0]
  }
  return ret
}

numeric.dot = function dot(x, y) {
  var d = numeric.dim
  switch (d(x).length * 1000 + d(y).length) {
    case 2002:
      if (y.length < 10) return numeric.dotMMsmall(x, y)
      else return numeric.dotMMbig(x, y)
    case 2001:
      return numeric.dotMV(x, y)
    case 1002:
      return numeric.dotVM(x, y)
    case 1001:
      return numeric.dotVV(x, y)
    case 1000:
      return numeric.mulVS(x, y)
    case 1:
      return numeric.mulSV(x, y)
    case 0:
      return x * y
    default:
      throw new Error('numeric.dot only works on vectors and matrices')
  }
}

// ---

// --- numeric.solve method definiton
numeric.LU = function (A, fast) {
  fast = fast || false

  var abs = Math.abs
  var i, j, k, absAjk, Akk, Ak, Pk, Ai
  var max
  var n = A.length
  var n1 = n - 1
  var P = new Array(n)
  if (!fast) A = cloneDeep(A)
  for (k = 0; k < n; ++k) {
    Pk = k
    Ak = A[k]
    max = abs(Ak[k])
    for (j = k + 1; j < n; ++j) {
      absAjk = abs(A[j][k])
      if (max < absAjk) {
        max = absAjk
        Pk = j
      }
    }
    P[k] = Pk

    if (Pk != k) {
      A[k] = A[Pk]
      A[Pk] = Ak
      Ak = A[k]
    }

    Akk = Ak[k]

    for (i = k + 1; i < n; ++i) {
      A[i][k] /= Akk
    }

    for (i = k + 1; i < n; ++i) {
      Ai = A[i]
      for (j = k + 1; j < n1; ++j) {
        Ai[j] -= Ai[k] * Ak[j]
        ++j
        Ai[j] -= Ai[k] * Ak[j]
      }
      if (j === n1) Ai[j] -= Ai[k] * Ak[j]
    }
  }

  return {
    LU: A,
    P: P
  }
}

numeric.LUsolve = function LUsolve(LUP, b) {
  var i, j
  var LU = LUP.LU
  var n = LU.length
  var x = cloneDeep(b)
  var P = LUP.P
  var Pi, LUi, LUii, tmp

  for (i = n - 1; i !== -1; --i) x[i] = b[i]
  for (i = 0; i < n; ++i) {
    Pi = P[i]
    if (P[i] !== i) {
      tmp = x[i]
      x[i] = x[Pi]
      x[Pi] = tmp
    }

    LUi = LU[i]
    for (j = 0; j < i; ++j) {
      x[i] -= x[j] * LUi[j]
    }
  }

  for (i = n - 1; i >= 0; --i) {
    LUi = LU[i]
    for (j = i + 1; j < n; ++j) {
      x[i] -= x[j] * LUi[j]
    }

    x[i] /= LUi[i]
  }

  return x
}

numeric.solve = function solve(A, b, fast) {
  return numeric.LUsolve(numeric.LU(A, fast), b)
}

// ---

// --- exports
module.exports = numeric
// ---
