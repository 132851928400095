import './partner-page.scss'

import classnames from 'classnames'
import { isValid, startOfMonth, subDays, subMonths, subYears } from 'date-fns'
import { isEmpty, isUndefined, pick } from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Button from '#components/Button'
import CalendarDatePicker from '#components/CalendarDatePicker'
import { Form, FormItem } from '#components/Form'
import { CalendarDatePicker as RelatedCalendarDatePicker } from '#components/RelatedCalendar/Calendar'
import SmartControl from '#components/SmartControl'
import Spinner from '#components/Spinner'
import { ROUTES } from '#constants/common'
import intl from '#intl'
import { mainSiteApi } from '#modules/api'
import { formatDate, now, parseDate } from '#services/datetime'

import { filtersModel } from './models/filtersModel'

const RU_DATE_FORMAT = 'dd.MM.yyyy'
const ISO_DATE_FORMAT = 'yyyy-MM-dd'

class PartnerBidsFilter extends Form {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      data: {
        treatment: ['0'],
        status: ['-1', '2', '0', '100', '3', '4'],
        period: '1',
        dateFrom: formatDate(startOfMonth(subMonths(now(), 1)), RU_DATE_FORMAT),
        dateTo: formatDate(now(), RU_DATE_FORMAT),
        groupingType: 'by_month',
        periodStart: formatDate(startOfMonth(subMonths(now(), 1)), ISO_DATE_FORMAT),
        periodEnd: formatDate(now(), ISO_DATE_FORMAT),
        createDateFrom: formatDate(now(), RU_DATE_FORMAT),
        createDateTo: formatDate(now(), RU_DATE_FORMAT),
        limit: 300,
        offset: 0,
        isFetchAdditionalFields: false
      },
      csvReport: false,
      filters: {
        periodStartCalendar: {
          min: subYears(now(), 10),
          max: now()
        },
        periodEndCalendar: {
          min: subYears(now(), 10),
          max: now()
        }
      }
    }
    this.model = filtersModel
  }

  async componentDidMount() {
    this.setState({
      isFetchAdditionalFields: true
    })
    try {
      const { code, data } = await mainSiteApi.getSearchableFields()
      if (code === 0) {
        const lastInx = filtersModel.length
        const orderLines = {
          name: lastInx + 1,
          secondName: lastInx + 1,
          middleName: lastInx + 2,
          mobileNumber: lastInx + 2
        }
        const searchFields = Object.keys(data).map((field) => {
          let element = data[field]
          element = { ...element, name: element.systemName, line: orderLines[element.systemName] }
          return element
        })
        this.model = [...this.model, ...searchFields]
        this.setState({
          form: this.computeForm()
        })
      }
    } catch (e) {
      return null
    } finally {
      this.setState({
        isFetchAdditionalFields: false
      })
    }
  }

  getPeriodEnd = (data) => {
    if (data.period === '-1') return formatDate(parseDate(data.periodEnd), ISO_DATE_FORMAT)
    if (data.period === '69') return data.periodEndCalendar
    if (data.period === 'yesterday') return formatDate(subDays(now(), 1), ISO_DATE_FORMAT)
    return data.periodEnd
  }

  getDatePeriods = (data) => {
    if (data.period === '-1') {
      return {
        periodStart: data.periodStart || formatDate(now(), RU_DATE_FORMAT),
        periodEnd: data.periodEnd || formatDate(now(), RU_DATE_FORMAT),
        createDateFrom: data.periodStart || formatDate(now(), RU_DATE_FORMAT),
        createDateTo: data.periodEnd || formatDate(now(), RU_DATE_FORMAT)
      }
    }
    if (data.period === '69') {
      return {
        periodStart: data.periodStartCalendar || formatDate(now(), ISO_DATE_FORMAT),
        periodEnd: data.periodEndCalendar || formatDate(now(), ISO_DATE_FORMAT)
      }
    }
    if (data.period === 'yesterday') {
      return {
        periodStart: formatDate(subDays(now(), 1), ISO_DATE_FORMAT)
      }
    }
    return {
      periodStart: formatDate(subDays(now(), data.period - 1), RU_DATE_FORMAT),
      periodEnd: formatDate(now(), RU_DATE_FORMAT),
      createDateFrom: formatDate(subDays(now(), data.period - 1), RU_DATE_FORMAT),
      createDateTo: formatDate(now(), RU_DATE_FORMAT)
    }
  }

  convertDateFormat = (data) => {
    const convertedDate = {}
    const dateItems = [
      'periodStart',
      'periodEnd',
      'dateFrom',
      'dateTo',
      'createDateFrom',
      'createDateTo'
    ]
    dateItems.forEach((item) => {
      if (data[item]) {
        // проверка на необходимый формат даты YYYY-MM-DD
        if (new RegExp(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/).test(data[item])) return
        convertedDate[item] = data[item].split('.').reverse().join('-')
      }
    })
    return convertedDate
  }

  handleSubmit(evt) {
    evt && evt.preventDefault()
    const { onSubmit, filters, partnerPage } = this.props
    let data = { ...this.state.data }

    if (this.validateForm(true) && this.validateDateFields()) {
      // получение установленных периодов поиска
      const datePeriods = this.getDatePeriods(data)
      data = { ...data, ...datePeriods }
      this.setState({ data: { ...data } })
      data.treatment = filters.includes('treatment') && data.treatment && data.treatment.toString()
      data.status = filters.includes('status') ? data.status.toString() : null

      // конвертация дат в формат YYYY-MM-DD
      const convertedDate = this.convertDateFormat(data)
      data = { ...data, ...convertedDate }

      // получение конечного периода
      const periodEnd = this.getPeriodEnd(data)

      // обязательное условие, если поле пустое то не отправлят пустую строку
      // в searchFields не должен попадать пустой параметр
      const additionalFilterFields = {}
      data?.name && (additionalFilterFields.name = data.name)
      data?.secondName && (additionalFilterFields.secondName = data.secondName)
      data?.middleName && (additionalFilterFields.middleName = data.middleName)
      data?.mobileNumber && (additionalFilterFields.mobileNumber = data.mobileNumber)

      const params = {
        bids: {
          periodStart: data.periodStart,
          periodEnd,
          treatment: data.treatment,
          brokerToken: data.brokerToken,
          searchFields: JSON.stringify(additionalFilterFields) || null
        },
        accounts: {
          periodStart: data.periodStart,
          periodEnd,
          brokerToken: data.brokerToken,
          searchFields: JSON.stringify(additionalFilterFields) || null
        },
        statistics: {
          dateFrom: data.dateFrom,
          dateTo: data.dateTo,
          onlyNew: data.onlyNew,
          groupingType: data.groupingType,
          brokerToken: data.brokerToken
        },
        loans: {
          status: data.status,
          createDateFrom: data.createDateFrom,
          createDateTo: periodEnd,
          brokerToken: data.brokerToken,
          searchFields: JSON.stringify(additionalFilterFields) || null,
          limit: 300,
          offset: 0
        }
      }
      onSubmit(params[partnerPage])
    }
  }

  validateDateFields = () => {
    const dateFields = pick(
      this.state.data,
      'periodStart',
      'periodEnd',
      'dateTo',
      'dateFrom',
      'createDateFrom',
      'createDateTo'
    )
    const errors = Object.entries(dateFields)
      .filter(
        ([_, value]) => !new RegExp(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/).test(value)
      )
      .reduce(
        (acc, [key, value]) =>
          !value || isValid(parseDate(value.split('.').reverse().join('-')))
            ? acc
            : { ...acc, [key]: intl.fieldInvalid },
        {}
      )
    if (isEmpty(errors)) return true

    this.setState((prevState) => ({
      ...prevState,
      valid: false,
      errors: { ...prevState.errors, ...errors }
    }))
  }

  handleChangeConditions = ({ periodStartCalendar, periodEndCalendar }) => {
    this.setState((state) => ({
      ...state,
      ...{
        data: {
          ...state.data,
          period: '69',
          periodStartCalendar,
          periodEndCalendar
        }
      }
    }))
  }

  handleChangeConditionsRelated = ({ periodStartCalendar, periodEndCalendar }) => {
    this.setState((state) => ({
      ...state,
      ...{
        data: {
          ...state.data,
          periodStartCalendar,
          periodEndCalendar
        }
      }
    }))
  }

  filterItemByUrl = (item) => {
    if (item.name === 'period' && window.location.pathname === ROUTES.partnerAccounts) {
      return {
        ...item,
        options: item.options.filter((o) => ['1', '30', '69'].includes(o.value))
      }
    }

    if (item.name === 'period' && window.location.pathname === ROUTES.partnerBids) {
      return {
        ...item,
        options: item.options.filter((o) => !['2', '69'].includes(o.value))
      }
    }

    return { ...item, options: item.options?.filter((o) => !['69'].includes(o.value)) || [] }
  }

  renderItem(_item) {
    const { filters } = this.props
    const { data, errors, filters: stateFilters } = this.state
    const item = this.filterItemByUrl(_item)
    const { type } = item
    if (!isUndefined(stateFilters[_item.name])) {
      const { min, max } = stateFilters[_item.name]
      item.min = min
      item.max = max
    }

    if (type === 'calendarPicker') {
      return (
        <RelatedCalendarDatePicker
          key={item.name}
          periodStart={parseDate(data['periodStart'].split('.').reverse().join('-'))}
          periodFinish={parseDate(data['periodEnd'].split('.').reverse().join('-'))}
          prefixStart={'periodStart'}
          prefixFinish={'periodEnd'}
          labelCalendarFrom={intl.fromTime}
          labelCalendarTo={intl.toTime}
          errors={errors}
          onChange={this.handleControlChange}
          onChangeConditions={this.handleChangeConditionsRelated}
        />
      )
    }

    if (filters.includes(item.name)) {
      return (
        <FormItem key={item.name} {...item} error={errors[item.name]}>
          <SmartControl
            {...item}
            value={data[item.name]}
            valid={!errors[item.name]}
            onFocus={this.handleControlFocus}
            onBlur={this.handleControlBlur}
            onChange={this.handleControlChange}
          />
        </FormItem>
      )
    }
    return null
  }

  render() {
    const { errorMessage, filters } = this.props
    const { form, loading, isFetchAdditionalFields, errors } = this.state
    const className = classnames(
      {
        form: true
      },
      this.props.className
    )

    if (isFetchAdditionalFields) return <Spinner className='common-spinner' />

    return (
      <form className={className} noValidate onSubmit={this.handleSubmit}>
        {form.lines.map((line, key) => (
          <div className='form__line' key={key}>
            {line.items.map((item) => !this.checkDepends(item) && this.renderItem(item))}
          </div>
        ))}
        {filters.includes('calendar') && (
          <CalendarDatePicker
            errors={errors}
            onChange={this.handleControlChange}
            onChangeConditions={this.handleChangeConditions}
          />
        )}
        {errorMessage && (
          <div className='filters-error'>
            {'Ошибка поиска данных: '}
            {errorMessage}
          </div>
        )}
        <div className='form__line'>
          <div className='form__item'>
            <Button fluid loading={loading}>
              {intl.searchAction}
            </Button>
          </div>
        </div>
      </form>
    )
  }
}

PartnerBidsFilter.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.array,
  onSubmit: PropTypes.func
}

const mapStateToProps = (state) => ({
  brokerToken: state.app.loggedInToken,
  partnerId: state.brokerInfo.partnerId
})

export default connect(mapStateToProps)(PartnerBidsFilter)
