import heic2any from 'heic2any'

export interface IFileWithPreview extends File {
  preview?: string
}
export const formatsThatNeedBeConverted = ['image/heic', 'image/heif']
const validFormat = 'image/jpeg'

export class JPEGConverter {
  public async convertToJpeg(file: IFileWithPreview, name: string): Promise<File> {
    if (formatsThatNeedBeConverted.includes(file.type)) {
      try {
        const blob: any = await heic2any({
          blob: file,
          toType: validFormat,
          quality: 0.8
        })
        return await this.addConvertedImage(blob, name)
      } catch {
        // Если девайс самостоятельно конвертировал изображение в jpeg
        // перед загрузкой в форму, то отдаем этот же файл, предварительно поменяв формат
        // (формально), чтобы не получить ошибку в ui
        return this.createJPEG(file, name)
      }
    } else {
      return this.createJPEG(file, name)
    }
  }

  private async addConvertedImage(blob: IFileWithPreview, name: string): Promise<File> {
    return new Promise((resolve) => {
      const reader = new global.FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        const preview = URL.createObjectURL(blob)
        const result = this.createJPEG(blob, name, preview)
        return resolve(result)
      }
    })
  }

  private createJPEG(file: IFileWithPreview, name: string, preview?: string): File {
    const JPEGImage = new File([file], name + '.jpeg', { type: validFormat })
    if (!preview) return Object.assign(JPEGImage, { preview: file?.preview })
    return Object.assign(JPEGImage, { preview })
  }
}
