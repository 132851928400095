import classnames from 'classnames'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import { useCallback } from 'react'

import {
  INSURANCE_AGREEMENT,
  INSURANCE_AGREEMENT_CARD,
  INSURANCE_CONDITIONS,
  INSURANCE_CONDITIONS_CARD,
  INSURANCE_D2_AGREEMENT,
  INSURANCE_D2_AGREEMENT_CARD,
  INSURANCE_D2_APPLICATION,
  INSURANCE_D2_APPLICATION_CARD,
  INSURANCE_STATEMENT,
  INSURANCE_STATEMENT_CARD,
  INSURANCE_STATEMENT_TRANSFER,
  INSURANCE_STATEMENT_TRANSFER_CARD
} from '#constants/common'
import intl from '#intl'

const mapping = {
  agreement: {
    insurance: [INSURANCE_AGREEMENT, intl.insuranceAgreement],
    insurance_card: [INSURANCE_AGREEMENT_CARD, intl.insuranceCardAgreement],
    d2Insurance: [
      INSURANCE_D2_AGREEMENT,
      intl.insuranceD2Agreement,
      'Д2 Страхование - Договор добровольного коллективного страхования от несчастных случаев'
    ],
    d2InsuranceCards: [
      INSURANCE_D2_AGREEMENT_CARD,
      intl.insuranceD2CardAgreement,
      'Д2 Страхование - Договор добровольного коллективного страхования рисков держателей пластиковых карт'
    ]
  },
  statement: {
    insurance: [INSURANCE_STATEMENT, intl.insuranceStatement],
    insurance_card: [INSURANCE_STATEMENT_CARD, intl.insuranceStatement]
  },
  conditions: {
    insurance: [INSURANCE_CONDITIONS, intl.insuranceConditions],
    insurance_card: [INSURANCE_CONDITIONS_CARD, intl.insuranceConditions]
  },
  statementTransfer: {
    insurance: [INSURANCE_STATEMENT_TRANSFER, intl.insuranceStatementTransfer],
    insurance_card: [INSURANCE_STATEMENT_TRANSFER_CARD, intl.insuranceStatementTransfer]
  },
  application: {
    d2Insurance: [
      INSURANCE_D2_APPLICATION,
      'Офертой',
      'Д2 Страхование - Оферта о заключении договора добровольного коллективного страхования от несчастных случаев '
    ],
    d2InsuranceCards: [
      INSURANCE_D2_APPLICATION_CARD,
      'Офертой',
      'Д2 Страхование - Оферта о заключении договора добровольного коллективного страхования рисков держателей пластиковых карт'
    ]
  }
}

export const buildDownloadLinkRenderer = (onDownload, loadingItems) => (documentKey, type) => {
  const [document, text, fileName = null] = mapping[documentKey][type]
  const linkClasses = classnames({
    'link--disabled link--pouring': Boolean(loadingItems[document])
  })
  if (fileName) {
    return (
      <a className={linkClasses} onClick={onDownload(document, fileName, type)}>
        {text}
      </a>
    )
  }
  return (
    <a className={linkClasses} onClick={onDownload(document, document, type)}>
      {text}
    </a>
  )
}

const InsuranceAgreementsLabel = (props) => {
  const { period, amount, cost, type, onDownload = noop, loadingItems = {} } = props
  const handleDocumentDownload = useCallback(
    (document, fileName, type) => (event) => {
      event.preventDefault()
      onDownload(document, fileName, type)
    },
    [onDownload]
  )

  const renderDownloadLink = buildDownloadLinkRenderer(handleDocumentDownload, loadingItems)
  return (
    <div className='agreements d-block'>
      {'Прошу ООО МКК «ЧЕСТНОЕ СЛОВО» распространить на меня действие '}
      {renderDownloadLink('agreement', type)}
      {', страховщиком по которому является ООО «Абсолют Страхование», для чего я подаю '}
      {renderDownloadLink('statement', type)}
      {'. С '}
      {renderDownloadLink('conditions', type)}
      {' ознакомлен и согласен, в том числе с размером страховой суммы в '}
      <b>{amount}</b>
      {' руб. и сроком страхования в '}
      {period}
      {' дней. Оплату страхования в размере '}
      <b>{cost}</b>
      {' руб. прошу произвести на основании '}
      {renderDownloadLink('statementTransfer', type)}
      {'.'}
    </div>
  )
}

InsuranceAgreementsLabel.propTypes = {
  period: PropTypes.number,
  amount: PropTypes.number,
  cost: PropTypes.number,
  type: PropTypes.string,
  onDownload: PropTypes.func,
  loadingItems: PropTypes.object
}

export default InsuranceAgreementsLabel
