import './change-password-form.scss'

import cn from 'classnames'
import { isNull } from 'lodash'
import { Fragment } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { ChangePasswordFormClient } from '#components/ChangePasswordForm/ChangePasswordFormClient'
import appConfig from '#config'
import { ROUTES } from '#constants/common'
import withWindowSize from '#hoc/withWindowSize'
import intl from '#intl'
import { brokerApi } from '#modules/api'
import noty from '#services/notify'
import withErrorLogger from '#src/hoc/withErrorLogger'
import {
  changePasswordFormPartnerRequest,
  changePasswordFormPartnerSubmit
} from '#src/models/changePasswordFormPartner'

import { Button } from '../Button/Button'
import { FormItem } from '../Form/FormItem'

class ChangePasswordFormPartner extends ChangePasswordFormClient {
  constructor(props) {
    super(props)
    this.model = changePasswordFormPartnerRequest
    this.state = {
      ...this.state,
      form: this.computeForm()
    }
  }
  requestConfirmationCode = async () => {
    this.setState({ isLoading: true })
    const { data } = this.state
    try {
      const response = await brokerApi.sendRecoveryCode(data)
      if (response.code !== 0) throw response

      this.setState(
        (state) => ({
          data: {
            ...state.data,
            code: ''
          },
          requestStatus: 'sent',
          disabledItems: {
            ...state.disabledItems,
            login: true
          },
          errors: {
            ...state.errors,
            login: null
          }
        }),
        () => {
          this.model = changePasswordFormPartnerSubmit
          this.setState({
            form: this.computeForm()
          })
          const { data: { clientNotificationType } = {} } = response
          const message =
            {
              call: intl.codeConfirmationViaCallMessage,
              email: intl.codeConfirmationViaEmailMessage,
              sms: intl.codeConfirmationViaSms
            }[clientNotificationType] || intl.codeConfirmationFallbackMessage
          noty.push({
            message,
            type: 'success',
            alarmIcon: false,
            container: 'bottom-center'
          })
          this.setCoolDownTimer()
        }
      )
    } catch (err) {
      this.props.logError(err)
      this.handleResponse(err)
    } finally {
      this.setState(
        (state) => ({
          isLoading: false,
          data: { ...state.data, recaptcha: null }
        }),
        this.recaptchaRef.current?.reset?.()
      )
    }
  }

  // обработчик запроса получения смс кода
  handleConfirmationCodeRequest = async (event) => {
    event.preventDefault()
    await this.trimWhiteSpaces()
    const {
      data: { login },
      isLoading
    } = this.state
    if (isLoading || !this.validateForm()) return
    this.setState(
      (state) => ({
        data: {
          ...state.data,
          login: this.getNormalizedLogin(login)
        }
      }),
      () => void this.requestConfirmationCode()
    )
  }

  // обработчик запроса повторного получения смс кода
  handleConfirmationCodeRerepeat = async (event) => {
    event.preventDefault()
    const { isLoading, codeRepeatCooldown } = this.state
    if (isLoading || codeRepeatCooldown || !this.validateForm({ ignore: ['code'] })) return
    return this.requestConfirmationCode()
  }

  // обработчик после успешной отправки смс кода на телефон
  handlePasswordChangeSubmit = async (event) => {
    event.preventDefault()
    const { isLoading, data } = this.state
    if (isLoading || !this.validateForm()) return
    this.setState({ isLoading: true })
    try {
      const response = await brokerApi.sendNewPassword(data)
      const { code, data: { clientNotificationType } = {} } = response
      if (code !== 0) throw response
      this.setState({ requestStatus: 'submitted' })
      const message =
        {
          sms: intl.newPasswordWasSentBySms,
          email: intl.newPasswordWasSentByEmail
        }[clientNotificationType] || intl.newPasswordWasSent
      noty.push({
        message,
        type: 'success',
        alarmIcon: false,
        container: 'bottom-center'
      })
    } catch (err) {
      this.props.logError(err)
      this.handleResponse(err)
    } finally {
      this.setState(
        (state) => ({
          isLoading: false,
          data: { ...state.data, recaptcha: null }
        }),
        this.recaptchaRef.current?.reset?.()
      )
    }
  }

  handleRecaptchaChange = (name) => (value) =>
    this.setState((state) => ({
      data: { ...state.data, [name]: isNull(value) ? '' : value },
      errors: { ...state.errors, [name]: '' }
    }))

  renderRecaptcha = (item) => {
    const { errors } = this.state
    const {
      userDevice: { isMobile }
    } = this.props
    return (
      <FormItem
        key={item.name}
        {...item}
        error={errors[item.name]}
        className='d-flex-centered flex-column'
      >
        <ReCAPTCHA
          id={item.name}
          ref={this.recaptchaRef}
          hl='ru'
          className={cn({ 'mt-2 mx-auto': true })}
          sitekey={appConfig.recaptchaKey}
          size={isMobile ? 'compact' : 'normal'}
          onChange={this.handleRecaptchaChange(item.name)}
        />
      </FormItem>
    )
  }

  render() {
    const { form, isLoading, requestStatus } = this.state
    const className = cn(
      {
        form: true,
        'change-password-form': true
      },
      this.props.className
    )

    if (requestStatus === 'submitted') return <Navigate to={ROUTES.main} replace />
    return (
      <form
        className={className}
        noValidate
        onSubmit={
          requestStatus === 'sent'
            ? this.handlePasswordChangeSubmit
            : this.handleConfirmationCodeRequest
        }
        data-qa='changePasswordFormPartner'
      >
        {form.lines.map((line, key) => (
          <div className='form__line' key={key}>
            {line.items.map(
              (item) =>
                !this.checkDepends(item) && (
                  <Fragment key={item.name}>{this.renderItem(item)}</Fragment>
                )
            )}
          </div>
        ))}
        <div className='form__bottom'>
          <Button
            type={'button'}
            size='l'
            fluid
            isLoading={isLoading}
            disabled={isLoading}
            data-qa='submitButton'
          >
            {requestStatus === 'sent' ? intl.confirm : intl.recoverPassword}
          </Button>
        </div>
      </form>
    )
  }
}

const mapStateToProps = (state = {}) => ({
  isCaptchaEnabled: state.settings.data.googleCaptcha?.isEnabled
})

export default withWindowSize(withErrorLogger(connect(mapStateToProps)(ChangePasswordFormPartner)))
