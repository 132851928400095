import { FC } from 'react'
import { useSelector } from 'react-redux'

import { TMainDataStateType } from '#src/reducers'

import Icon from '../Icon'

interface IHightAmountProps {
  text: string
}

const AMOUNT_HIGER_FIFTEEN_THOUSAND = 15000

const HightAmountHint: FC<IHightAmountProps> = ({ text }) => {
  const {
    data: { amount: loanAmount }
  } = useSelector(
    (state: TMainDataStateType) => state.loanFormState as { data: { amount: number } }
  )
  if (loanAmount > AMOUNT_HIGER_FIFTEEN_THOUSAND) {
    return (
      <div className={'form__item '}>
        <p
          className='text-black-50 fw-bold text-justify m-0 border rounded p-2'
          style={{ fontSize: 14, lineHeight: '1rem' }}
        >
          <Icon
            name='alarm-warning'
            className='float-start me-2'
            style={{ width: '1.75em', height: '1.75em' }}
          />
          {text}
        </p>
      </div>
    )
  }
  return null
}

export default HightAmountHint
