import classNames from 'classnames'
import { noop } from 'lodash'
import { FC, MouseEvent, useCallback, useMemo } from 'react'

const DOCUMENT_TYPES = {
  service_risk_application: {
    text: 'оферты',
    fileName: 'service_risk_application'
  },
  service_risk_application_money_transfer: {
    text: 'заявления',
    fileName: 'service_risk_application_money_transfer'
  }
}

type TDocumentKeys = keyof typeof DOCUMENT_TYPES

type TServiceRiskAgreementLabelProps = {
  loadingItems: {
    [key in TDocumentKeys]: boolean
  }
  onDownload: (document: TDocumentKeys, fileName: string, type: 'serviceRisk') => Promise<void>
  commission: number
}

const ServiceRiskAgreementLabel: FC<TServiceRiskAgreementLabelProps> = (props) => {
  const { loadingItems, onDownload = noop, commission } = props

  const handleDocumentDownload = useCallback(
    (type: TDocumentKeys) => (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault()
      onDownload(type, DOCUMENT_TYPES[type].fileName, 'serviceRisk')
    },
    [onDownload]
  )

  const downLoadLink = useMemo(
    () => (type: TDocumentKeys) => {
      const linkClasses = classNames({
        'link--disabled link--pouring': Boolean(loadingItems[type])
      })
      return (
        <a className={linkClasses} onClick={handleDocumentDownload(type)}>
          {DOCUMENT_TYPES[type].text}
        </a>
      )
    },
    [handleDocumentDownload, loadingItems]
  )

  return (
    <div className='agreements d-block'>
      {'Присоединяюсь к оферте на оказание услуг по расчету кредитного рейтинга. '}
      {'С условиями '}
      {downLoadLink('service_risk_application')}{' '}
      {'ознакомлен и согласен, в том числе с размером стоимости услуг. '}
      {'Оплату услуг в размере '}
      {commission}
      {' руб. прошу произвести на основании моего '}
      {downLoadLink('service_risk_application_money_transfer')}
      {'.'}
    </div>
  )
}

export default ServiceRiskAgreementLabel
