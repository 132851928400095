import { TModelItem } from '#components/Form/Form'

type TMemoChildNames = {
  [key: string]: {
    parent: string
    name: string
    siblings: string[]
  }
}
export class PersonalDataAgreement {
  private readonly memoModelItemNamesWithChild: (string | undefined)[]
  private readonly memoChildItemNames: TMemoChildNames

  constructor({ model }: { model: TModelItem[] }) {
    this.memoModelItemNamesWithChild = model.filter((item) => item.child).map((item) => item.name)
    /**
     * Для более быстрого поиска В формате: {
     *   child1: {
     *     parent: 'parent',
     *     name: 'child1',
     *     siblings: ['child2', 'child3']
     *   }
     * }
     */
    this.memoChildItemNames = model
      .filter((item) => item.child)
      .reduce((acc, cur) => {
        const { name, child } = cur as { name: string; child: TModelItem[] }
        let childData = {}
        child.forEach((childItem) => {
          childData = {
            ...childData,
            [childItem.name as string]: {
              name: childItem.name,
              parent: name,
              siblings: child
                .filter((item) => item.name !== childItem.name)
                .map((item) => item.name)
            }
          }
        })
        return {
          ...acc,
          ...childData
        }
      }, {})
  }

  public getItemNamesWithChild(): (string | undefined)[] {
    return this.memoModelItemNamesWithChild
  }

  public getChildItemsByName(
    name: string
  ): {
    parent: string
    name: string
    siblings: string[]
  } {
    return this.memoChildItemNames[name]
  }

  public presetChildData = (
    model: TModelItem[],
    legalCheck?: number
  ): {
    [key: string]: number
  } => {
    let childData = {}
    model
      .filter((item) => item.child)
      .forEach((item) => {
        item?.child?.forEach((childItem) => {
          childData = {
            ...childData,
            [childItem.name as string]: legalCheck && Number(legalCheck) ? 0 : 1
          }
        })
      })
    return childData
  }
}
