import './tabs-styles.scss'

import PropTypes from 'prop-types'
import { Component } from 'react'

import Tab from './Tab'

class Tabs extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: this.props.children[0] ? this.props.children[0].props.label : null
    }
  }

  onClickTabItem(tab) {
    this.setState({ activeTab: tab })
  }

  render() {
    const {
      onClickTabItem,
      props: { className, children },
      state: { activeTab }
    } = this
    if (activeTab) {
      return (
        <div className={className || 'tabs'}>
          <ol className='tab-list'>
            {children.map((child) => {
              const { label } = child.props

              return (
                <Tab
                  activeTab={activeTab}
                  key={label}
                  label={label}
                  onClick={onClickTabItem.bind(this)}
                />
              )
            })}
          </ol>
          <div className='tab-content'>
            {children.map((child) => {
              if (child.props.label !== activeTab) return
              return child.props.children
            })}
          </div>
        </div>
      )
    }
    return <div className='tabs'>{' Нет доступных методов '}</div>
  }
}
Tabs.propTypes = {
  className: PropTypes.string,
  children: PropTypes.instanceOf(Array).isRequired
}
export default Tabs
