import { FC } from 'react'
import { useSelector } from 'react-redux'

import { TMainDataStateType } from '#src/reducers'

type TChildren = {
  children: JSX.Element
}
export const RegistrationFormWrapper: FC<TChildren> = ({ children }) => {
  const isFetched = useSelector((state: TMainDataStateType) => state.loanConditions.isFetched)
  if (!isFetched) return null
  return children
}
