import {
  Box,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core'
import cn from 'classnames'
import { isEmpty, merge, noop } from 'lodash'
import { Fragment, ReactNode, UIEvent, useCallback, useEffect, useMemo } from 'react'
import {
  useExpanded,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable
} from 'react-table'

import Spinner from '#components/Spinner'
import intl from '#intl'

import useStyles from './styles'
import TablePagination from './TablePagination'
import { TContentTable, TPagination, TPropsGetterBuilder, TRenderRowSubComponent } from './types'

const none: TPropsGetterBuilder<any> = () => ({})
const nothing: TRenderRowSubComponent = () => null

const Table: TContentTable = (props) => {
  const {
    className = '',
    columns = [],
    data = [],
    caption = '',
    hoverable: isHoverable = false,
    dense: isDense = false,
    style = {},
    pagination = {} as TPagination,
    getHeaderRowProps = none,
    getHeaderCellProps = none,
    getBodyRowProps = none,
    getBodyCellProps = none,
    renderRowSubComponent = nothing,
    loading: isLoading = false,
    fitContainer: shouldFitContainer = false,
    onResize = noop,
    initialState = {},
    expandMode = 'single',
    autoResetPage: shouldAutoResetPage = true,
    overideStyles
  } = props

  const tHeadRowStyle = overideStyles?.tHeadRow ?? {}
  const isPaginationEnabled = !isEmpty(pagination)
  const {
    totalItems = data.length,
    itemsPerPage = Math.max(data.length, 1),
    currentPage: currentPageControlled = 0,
    onChangePage = noop,
    onChangeRowsPerPage = noop,
    mode = 'auto'
  } = pagination

  const clx = useStyles(props)

  const defaultColumn = useMemo(
    () => ({
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      width: shouldFitContainer ? 40 : 150
    }),
    [shouldFitContainer]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    setPageSize,
    gotoPage,
    visibleColumns,
    state: {
      pageIndex,
      pageSize,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      columnResizing: { columnWidths, isResizingColumn }
    }
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      stateReducer: (newState, action, prevState) => {
        if (action.type === 'resetResize')
          return merge(newState, { columnResizing: initialState.columnResizing ?? {} })
        if (action.type === 'toggleRowExpanded') {
          return expandMode === 'single'
            ? { ...newState, expanded: { [action.id]: !prevState.expanded[String(action.id)] } }
            : newState
        }
        return newState
      },
      manualPagination: {
        auto: false,
        manual: true
      }[mode],
      pageCount: 0,
      autoResetPage: shouldAutoResetPage,
      initialState: merge(
        {
          pageIndex: Math.max(currentPageControlled - 1, 0),
          pageSize: itemsPerPage
        },
        initialState
      )
    },
    useSortBy,
    useFlexLayout,
    useResizeColumns,
    useExpanded,
    usePagination
  )

  useEffect(() => {
    if (isResizingColumn === null) onResize(columnWidths)
  }, [columnWidths, isResizingColumn, onResize])

  const stopPropagation: <T extends UIEvent>(e: T) => void = useCallback(
    (evt) => evt.stopPropagation(),
    []
  )

  const renderTableHead = (): ReactNode =>
    headerGroups.map((headerGroup) => {
      const { key, style = {}, ...props } = headerGroup.getHeaderGroupProps([
        getHeaderRowProps(headerGroup)
      ])
      return (
        <TableRow
          {...props}
          key={key}
          classes={{ root: clx.tHeadRow }}
          style={{ ...style, ...tHeadRowStyle }}
        >
          {headerGroup.headers.map((column) => {
            const { getHeaderProps, getSortByToggleProps, isSorted, isSortedDesc, render } = column
            const { key, ...props } = getHeaderProps([
              getSortByToggleProps(),
              getHeaderCellProps(column),
              {
                style: {
                  pointerEvents: column.isResizing ? 'none' : 'auto',
                  position: 'sticky'
                }
              }
            ])
            return (
              <TableCell {...props} key={key} classes={{ root: clx.cell }}>
                <TableSortLabel
                  active={isSorted}
                  direction={isSortedDesc ? 'desc' : 'asc'}
                  hideSortIcon
                >
                  {render('Header')}
                </TableSortLabel>
                <div
                  {...column.getResizerProps()}
                  className={clx.resizer}
                  onClick={stopPropagation}
                />
              </TableCell>
            )
          })}
        </TableRow>
      )
    })

  const renderTableBody = (): ReactNode => {
    if (isLoading) return <Spinner className='common-spinner' />
    if (isEmpty(data) && !isLoading) {
      return (
        <TableRow {...props} className={clx.row}>
          <TableCell
            colSpan={visibleColumns.length}
            classes={{ root: [clx.cell, clx.expandedRowCell].join(' ') }}
          >
            <Box className={clx.noDataLabel}>{intl.noData}</Box>
          </TableCell>
        </TableRow>
      )
    }

    return (isPaginationEnabled ? page : rows).map((row) => {
      prepareRow(row)
      const { key, ...props } = row.getRowProps([getBodyRowProps(row)])

      return (
        <Fragment key={key}>
          <TableRow {...props} hover={isHoverable} className={[clx.row, clx.rowStriped].join()}>
            {row.cells.map((cell) => {
              const { key, ...props } = cell.getCellProps([getBodyCellProps(cell)])
              return (
                <TableCell {...props} key={key} classes={{ root: clx.cell }}>
                  {cell.render('Cell')}
                </TableCell>
              )
            })}
          </TableRow>
          {row.isExpanded ? (
            <TableRow {...props} className={clx.row}>
              <TableCell
                colSpan={visibleColumns.length}
                classes={{ root: [clx.cell, clx.expandedRowCell].join(' ') }}
              >
                {renderRowSubComponent(row)}
              </TableCell>
            </TableRow>
          ) : null}
        </Fragment>
      )
    })
  }

  const handlePageChange = useCallback(
    (pageIndex: number): void => {
      const pageNumber = pageIndex + 1
      gotoPage(pageIndex)
      onChangePage(pageNumber)
    },
    [gotoPage, onChangePage]
  )

  const handleRowsPerPageChange = useCallback(
    (size: number): void => {
      setPageSize(size)
      onChangeRowsPerPage(size)
    },
    [onChangeRowsPerPage, setPageSize]
  )

  const renderPagination = (): ReactNode => {
    if (isEmpty(pagination)) return null
    return (
      <TablePagination
        totalItems={totalItems}
        itemsPerPage={pageSize}
        page={pageIndex}
        onChangePage={handlePageChange}
        onChangeItemsPerPage={handleRowsPerPageChange}
      />
    )
  }

  return (
    <TableContainer
      style={style}
      classes={{
        root: cn({ [clx.container]: true, [clx.containerLoading]: isLoading })
      }}
      className={className}
    >
      <MuiTable
        {...getTableProps()}
        classes={{ root: clx.table }}
        size={isDense ? 'small' : 'medium'}
      >
        <caption>
          <Box css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>{caption}</span>
            {renderPagination()}
          </Box>
        </caption>
        <TableHead>{renderTableHead()}</TableHead>
        <TableBody {...getTableBodyProps()}>{renderTableBody()}</TableBody>
      </MuiTable>
    </TableContainer>
  )
}

export default Table
