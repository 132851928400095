import '#src/components/PartnerPagesNew/partner-page.scss'
import '#src/components/PartnerPagesNew/assets/calendar-container.scss'

import { DatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { addDays, isWithinInterval, subYears } from 'date-fns'
import { subDays } from 'date-fns/esm'
import { isNull } from 'lodash'
import { useCallback, useState } from 'react'

import Button from '#components/Button'
import { FormItem } from '#components/Form'
import intl from '#intl'
import { formatDate, now, parseDate } from '#services/datetime'

type THandlerType = 'periodStartCalendar' | 'periodEndCalendar'
type TObj = {
  [key: string]: string
}
type TRange = {
  [key in THandlerType]: Date | string
}
type TCustomEvent = {
  target: {
    name: string
    value: Date | string | number
  }
}
interface IDatePickerControl {
  prefix: string
  errors: TObj
  onChange: (e: TCustomEvent) => void
  onChangeConditions: (o: TObj) => void
}

const ISOFormat = 'yyyy-MM-dd'

export const CalendarDatePicker = ({
  errors,
  onChange,
  onChangeConditions
}: IDatePickerControl): JSX.Element => {
  const initialStartFilter = {
    minDate: subYears(now(), 10),
    maxDate: addDays(now(), 1)
  }
  const initialEndFilter = {
    minDate: subYears(now(), 10),
    maxDate: addDays(now(), 1)
  }

  const [periodStartCalendar, setPeriodStartCalendar] = useState<Date>(now())
  const [periodEndCalendar, setPeriodEndCalendar] = useState(now())

  const [filterStart, setfilterStart] = useState(initialStartFilter)
  const [filterEnd, setfilterEnd] = useState(initialEndFilter)

  const emitConditions = ({ periodStartCalendar, periodEndCalendar }: TRange): void => {
    const periodStart = parseDate(periodStartCalendar)
    const periodEnd = parseDate(periodEndCalendar)
    onChangeConditions({
      periodStartCalendar: formatDate(periodStart, ISOFormat),
      periodEndCalendar: formatDate(periodEnd, ISOFormat)
    })
  }

  const relatedProps = (propName: THandlerType, value: Date): void => {
    const valueDate = parseDate(value)
    if (propName === 'periodStartCalendar') {
      const minDate = valueDate
      const maxDate = addDays(valueDate, 31)
      const filter = {
        minDate,
        maxDate
      }
      if (!isWithinInterval(periodEndCalendar, { start: minDate, end: maxDate })) {
        setPeriodEndCalendar(maxDate)
        emitConditions({
          periodStartCalendar: minDate,
          periodEndCalendar: maxDate
        })
      } else {
        emitConditions({
          periodStartCalendar,
          periodEndCalendar
        })
      }
      setfilterEnd(filter)
    } else if (propName === 'periodEndCalendar') {
      const minDate = subDays(valueDate, 31)
      const maxDate = valueDate
      const filter = {
        minDate,
        maxDate
      }
      if (!isWithinInterval(periodStartCalendar, { start: minDate, end: maxDate })) {
        setPeriodStartCalendar(minDate)
        emitConditions({
          periodStartCalendar: minDate,
          periodEndCalendar: maxDate
        })
      } else {
        emitConditions({
          periodStartCalendar,
          periodEndCalendar
        })
      }
      setfilterStart(filter)
    }
  }

  const emitEvent = ({ type, value }: TObj): void => {
    const event = {
      target: {
        name: type,
        value
      }
    }
    onChange(event)
  }

  const HANDLERS = {
    periodStartCalendar: (type: THandlerType, date: Date) => {
      const valueDate = parseDate(date)
      setPeriodStartCalendar(date)
      relatedProps(type, date)
      emitEvent({ type, value: formatDate(valueDate, ISOFormat) })
    },
    periodEndCalendar: (type: THandlerType, date: Date) => {
      const valueDate = parseDate(date)
      setPeriodEndCalendar(date)
      relatedProps(type, date)
      emitEvent({ type, value: formatDate(valueDate, ISOFormat) })
    }
  }

  const handleDateChange = (type: THandlerType) => (date: Date | null): void => {
    if (!isNull(date)) return HANDLERS[type](type, date)
  }

  const handleClick = useCallback(
    (event: { preventDefault: () => void }): void => {
      event.preventDefault()
      setfilterStart(initialStartFilter)
      setfilterEnd(initialEndFilter)
      setPeriodStartCalendar(now())
      setPeriodEndCalendar(now())
      onChangeConditions({
        periodStartCalendar: formatDate(now(), ISOFormat),
        periodEndCalendar: formatDate(now(), ISOFormat)
      })
    },
    [initialEndFilter, initialStartFilter, onChangeConditions]
  )

  function renderDayInPicker(
    date: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    dayInCurrentMonth: boolean,
    dayComponent: JSX.Element
  ): JSX.Element {
    if (dayInCurrentMonth) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const day = parseInt(dayComponent['props']['children'], 10)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const isDisabled = Boolean(dayComponent['props']['disabled'])
      return (
        <div className='dayWithQaAttribute' data-qa-day={day} data-qa-active={!isDisabled}>
          {dayComponent}
        </div>
      )
    }
    return dayComponent
  }

  return (
    <div className='form__line'>
      <div className='calendar-container'>
        <div className='partner-page__calendar_container'>
          <div className='partner-page__calendar_item'>
            <FormItem error={errors.periodStartCalendar}>
              <DatePicker
                renderDay={renderDayInPicker}
                margin='normal'
                id='date-picker-periodStartCalendar'
                label='Начальная дата'
                format='dd.MM.yyyy'
                value={periodStartCalendar}
                name='periodStartCalendar'
                autoOk
                onChange={handleDateChange('periodStartCalendar')}
                maxDate={filterStart.maxDate}
                minDate={filterStart.minDate}
              />
            </FormItem>
          </div>
          <div className='partner-page__calendar_item'>
            <FormItem error={errors.periodEndCalendar}>
              <DatePicker
                renderDay={renderDayInPicker}
                margin='normal'
                id='date-picker-periodEndCalendar'
                label='Конечная дата'
                format='dd.MM.yyyy'
                value={periodEndCalendar}
                name='periodEndCalendar'
                autoOk
                onChange={handleDateChange('periodEndCalendar')}
                maxDate={filterEnd.maxDate}
                minDate={filterEnd.minDate}
              />
            </FormItem>
          </div>
        </div>
        <div className='form__item'>
          <Button color='blue' size='m' fluid onClick={handleClick}>
            {intl.emptyDates}
          </Button>
        </div>
      </div>
    </div>
  )
}
