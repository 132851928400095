import { IBasePageProps, Page } from '#components/Page/Page'
import intl from '#intl'

export class PageWithAutomaticTitleSetter<P extends IBasePageProps, S> extends Page<P, S> {
  private readonly title: string | undefined
  private readonly description: string | undefined

  public setDocumentTitle = (): void => {
    document.title = this.title || intl.pageDocumentDefaultTitle
  }
  public setDocumentDescription = (): void => {
    if (this.description) {
      const description = document.createElement('meta')
      description.name = 'Description'
      description.content = this.description
      document.head.append(description)
    }
  }

  componentDidMount(): void {
    this.setDocumentTitle()
    this.setDocumentDescription()
    super.componentDidMount()
  }
}
