import { range } from 'lodash'

import intl from '#intl'
import theme from '#src/assets/scss/Exports.module.scss'

/**
 * Имена параметров доступных для кастомизации
 * @link parameters
 */

// Цвета выставлены в promoTextColor для обратной совместимости,
// в будущем надо будет заводить промокоды указывая все параметры
export const parameters = {
  HATCHING_ANGLE: 'hatchingAngle',
  HATCHING_STEP: 'hatchingStep',
  HATCHING_COLOR: 'hatchingColor',
  PROMO_TEXT_COLOR: 'promoTextColor',
  SUBMIT_BUTTON_TEXT: 'submitButtonText',
  SUBMIT_BUTTON_TEXT_COLOR: 'submitButtonTextColor',
  SUBMIT_BUTTON_BACKGROUND_COLOR: 'submitButtonBackgroundColor',
  SUBMIT_BUTTON_BORDER_COLOR: 'submitButtonBorderColor'
}

/**
 * Настройки стилизации по умолчанию
 * @param {string} promoColor - Цвет промокода
 * @returns {object}
 */
const getDefaults = (promoColor) => {
  const color = promoColor || theme.defaultPromoColor

  return {
    [parameters.HATCHING_ANGLE]: 120,
    [parameters.HATCHING_STEP]: 2,
    [parameters.HATCHING_COLOR]: color,
    [parameters.PROMO_TEXT_COLOR]: theme.primaryColor,
    [parameters.SUBMIT_BUTTON_TEXT_COLOR]: theme.colorWhite,
    [parameters.SUBMIT_BUTTON_BACKGROUND_COLOR]: color,
    [parameters.SUBMIT_BUTTON_BORDER_COLOR]: color,
    [parameters.SUBMIT_BUTTON_TEXT]: intl.getMoney
  }
}

/**
 * Доступные промокоды
 */
const promocodes = {
  FREE_DAYS_IN_MAX_PERIOD: 'freeDaysInMaxPeriod', // количество бесплатных дней при выборе максимального срока займа
  FREE_DAYS: 'freeDays', // количество бесплатных дней
  PERIOD_FREE: 'periodFree', // Объект с датами начала и конца периода без начисления процентов
  FIX_SUM: 'fixSum', // фикированная сумма процентов
  FIX_PERCENT: 'fixPercent', // фиксированный процент (смена процентной ставки)
  SIMPLE: 'simple' // Просто какая-то акция
}

const conditions = {
  CONDITIONS_MIN_POSSIBLE_AMOUNT: 'MIN_POSSIBLE_AMOUNT', // 'Минимальная сумма займа',
  CONDITIONS_MAX_POSSIBLE_AMOUNT: 'MAX_POSSIBLE_AMOUNT', // 'Максимальная сумма займа',
  CONDITIONS_MIN_POSSIBLE_PERIOD: 'MIN_POSSIBLE_PERIOD', // 'Минимальный срок займа',
  CONDITIONS_MAX_POSSIBLE_PERIOD: 'MAX_POSSIBLE_PERIOD', // 'Максимальный срок займа',
  CONDITIONS_BUTTON_TEXT: 'BUTTON_TEXT', // 'Текст кнопки',
  CONDITIONS_BUTTON_TEXT_COLOR: 'BUTTON_TEXT_COLOR', // 'Цвет текста кнопки',
  CONDITIONS_BUTTON_COLOR: 'BUTTON_COLOR', // 'Цвет кнопки',
  CONDITIONS_BUTTON_SIZE: 'BUTTON_SIZE', // 'Размер кнопки',
  CONDITIONS_VIRTUAL_GROUP_PERCENT: 'VIRTUAL_GROUP_PERCENT', // 'Процентная ставка',
  CONDITIONS_VIRTUAL_GROUP_STEP_TERM: 'VIRTUAL_GROUP_STEP_TERM', // 'Шаг дней',
  CONDITIONS_VIRTUAL_GROUP_MAX_TERM: 'VIRTUAL_GROUP_MAX_TERM', // 'Максимальнаое количество дней',
  CONDITIONS_VIRTUAL_GROUP_MIN_TERM: 'VIRTUAL_GROUP_MIN_TERM' // 'Минимальное количество дней',
}

/**
 * Форматирует число как процент CSS
 * @param {number} value
 */
export const asPercents = (value) => `${value}%`

/**
 * Форматирует число как градус CSS
 * @param {number} value
 */
export const asDegrees = (value) => `${value}deg`

/**
 * Возвращает процентное соотношение одного значения от другого
 * @param {number} part
 * @param {number} total
 */
export const getPercents = (part, total) => Math.round((part / total) * 100)

/**
 * Возвращает style object со штриховкой
 * @param {object=} options
 * @param {number=} options.start - Процент ширины целевого объекта с которого надо начать штриховку.
 * @param {number=} options.end - Процент ширины целевого объекта на котором надо закончить штриховку.
 * @param {number=} options.step - Шаг штриховки (чем меньше, тем чаще). Ноль - без штриховки. 100 - сплошная заливка.
 * @param {number=} options.angle - Угол наклона штриховки.
 * @param {number=} options.color- Цвет штриховки.
 */
export const buildHatching = (options = {}) => {
  const { start = 0, end = 0, angle = 0, step = 0, color = theme.defaultPromoColor } = options
  if (step === 0) return {}
  const gradients = []
  gradients.push(`transparent ${asPercents(start)}`)
  for (
    let position = start, transparent = false;
    position < end;
    position += step, transparent = !transparent
  ) {
    const nextPosition = Math.min(position + step, end)
    const currentColor = transparent ? 'transparent' : color
    gradients.push(`${currentColor} ${asPercents(position)} ${asPercents(nextPosition)}`)
  }

  gradients.push(`transparent ${asPercents(end)}`)

  return { background: `linear-gradient(${[asDegrees(angle), ...gradients].join()})` }
}

/**
 * Абстракция для стилизации компонентов формы
 * @constructor - Инициализация (см. {@link update})
 * @method buildSubmitButtonPromoProps - Построение дополнительных стилей для компонента SubmitButton
 * @method buildCalculatedInfoPromoProps - Построение дополнительных стилей для компонента CalculatedInfoItem
 * @method buildSliderPromoProps - Построение дополнительных стилей для компонента SmartControlRange
 */
class PromotedComponentsPropsBuilder {
  /**
   * Инициализация
   */
  constructor(properties) {
    this.update(properties)
  }

  /**
   * Обновление данных класса на основе которых расчитываются стили
   * @param {object=} properties - Свойства для обновления / инициализации
   * @param {('Microcredit'|'ConsumerCredit'|'ConsumerCreditMonth')} properties.creditType - Тип займа
   * @param {object=} properties.amount - Объект с информацией по сумме зайка
   * @param {number=} properties.amount.min - Минимальное значение суммы займа
   * @param {number=} properties.amount.max - Максимальное значение суммы займа
   * @param {number=} properties.amount.step - Шаг суммы на слайдере
   * @param {object=} properties.term - Объект с информацией по срокам зайка
   * @param {number=} properties.term.min - Минимальное значение срока займа
   * @param {number=} properties.term.max - Максимальное значение срока займа
   * @param {number=} properties.term.step - Шаг срока на слайдере
   * @param {string=} properties.promocode - Примененный прокод
   * @param {object=} properties.settings - Опции / настройки стилей для кастомизации дефолтных (см. {@link parameters})
   */
  update = (properties = {}) => {
    const {
      amount = {},
      term = {},
      promocode = {},
      settings = {},
      creditType = '',
      promoConditions = {}
    } = properties
    this.creditType = creditType
    this.amountMin = amount.min || this.amountMin || null
    this.amountMax = amount.max || this.amountMax || null
    this.amountStep = amount.step || this.amountStep || null
    this.termMin = term.min || this.termMin || null
    this.termMax = term.max || this.termMax || null
    this.termStep = term.step || this.termStep || null
    this.currentAmount = amount.current || this.currentAmount || null
    this.currentTerm = term.current || this.currentTerm || null
    this.promoCodeName = promocode.name || null
    this.promoCodeValue = promocode.value || null
    this.promoConditions = promoConditions || null
    this.maxPossibleAmount = Number(promoConditions[conditions.CONDITIONS_MAX_POSSIBLE_AMOUNT] || 0)
    // Костыль для обратной совместимости, чтобы все было одним цветом
    const promoColor =
      settings[parameters.PROMO_TEXT_COLOR] || settings[parameters.SUBMIT_BUTTON_TEXT_COLOR]
    this.settings = { ...getDefaults(promoColor), ...settings }
  }

  /**
   * Валидация возможности применения промокода
   * @returns {boolean} - Возможно ли применить промокод ( true | false )
   */
  validatePromoCode = () => {
    if (this.creditType !== 'MicroCredit') return false
    if (!this.promoCodeName && !this.promoCodeValue) return false
    if (
      this.promoCodeName === promocodes.FREE_DAYS_IN_MAX_PERIOD &&
      this.currentTerm <= this.termMax - this.promoCodeValue
    )
      return false
    // Если не указан диапазон ограничений для промокода FREE_DAYS
    // то ползунок будет бегать за скроллом
    // иначе будет отображаться штриховка в заданном диапазоне
    if (this.promoCodeName === promocodes.FREE_DAYS) {
      let isCurrentAmountAdmissibleMaxAmount = true
      if (this.maxPossibleAmount) {
        isCurrentAmountAdmissibleMaxAmount =
          Number(this.currentAmount) <= Number(this.maxPossibleAmount)
      }

      const {
        minPossiblePeriod = Number.NEGATIVE_INFINITY,
        maxPossiblePeriod = Number.POSITIVE_INFINITY
      } = this.settings
      return (
        Number(minPossiblePeriod) <= Number(this.currentTerm) &&
        Number(this.currentTerm) <= Number(maxPossiblePeriod) &&
        isCurrentAmountAdmissibleMaxAmount
      )
    }
    return true
  }

  /**
   * Возвращает объект trackStyle для rc-slider'а amount
   */
  getAmountTrackStyle = () => {
    const { [parameters.PROMO_TEXT_COLOR]: color } = this.settings
    return { borderColor: color }
  }

  /**
   * Возвращает объект trackStyle для rc-slider'а term
   */
  getTermTrackStyle = () => {
    const { [parameters.PROMO_TEXT_COLOR]: color } = this.settings
    return { borderColor: color }
  }

  /**
   * Возвращает объект c marks и step для rc-slider'а  amount
   */
  getAmountStepAndMarks = () => ({ marks: {}, step: this.amountStep })

  /**
   * Возвращает объект c marks и step для rc-slider'а  term
   */
  getTermStepAndMarks = () => {
    if (this.promoCodeName === promocodes.FREE_DAYS_IN_MAX_PERIOD) {
      const marks = range(this.termMin, this.termMax - this.promoCodeValue + 1, this.termStep)
        .concat(this.termMax)
        .reduce((acc, mark) => ({ ...acc, [mark]: '' }), {})
      return { marks, step: null }
    }
    return { marks: {}, step: this.termStep }
  }

  /**
   * Получение объекта с шагом / метками слайдера
   * @param {('amount'|'term')} sliderName - Тип слайдера (для суммы | для срока)
   * @returns {object} { marks: { ... }, step: <number> | null }
   */
  getStepAndMarks = (sliderName) =>
    ({
      amount: this.getAmountStepAndMarks,
      term: this.getTermStepAndMarks
    }[sliderName]?.())

  /**
   * Получение объекта стилей для индикатора слайдера
   * @param {('amount'|'term')} sliderName - Тип слайдера (для суммы | для срока)
   * @returns {object} Style Object
   */
  getTrackStyle = (sliderName) =>
    ({
      amount: this.getAmountTrackStyle,
      term: this.getTermTrackStyle
    }[sliderName]?.() || {})

  /**
   * Возвращает объект со start и end для построения штриховки для слайдера term
   */
  getTermHatchingBoundaries = () => {
    if (this.promoCodeName === promocodes.FREE_DAYS_IN_MAX_PERIOD) {
      return {
        start: getPercents(this.termMax - this.promoCodeValue, this.termMax),
        end: 100
      }
    }
    if (this.promoCodeName === promocodes.FREE_DAYS) {
      const currentPosition = getPercents(
        this.currentTerm - this.termMin,
        this.termMax - this.termMin
      )
      const areaToHatch = getPercents(this.promoCodeValue, this.termMax)
      const minPossiblePeriod = this.settings?.minPossiblePeriod
      const maxPossiblePeriod = this.settings?.maxPossiblePeriod
      let start = 0
      let end = 0

      // если в промокоде есть доопнительные ограничительные условия по срокам
      if (minPossiblePeriod)
        start = getPercents(Number(minPossiblePeriod) - this.termMin, this.termMax - this.termMin)
      else start = Math.max(currentPosition - areaToHatch, 0)

      // если в промокоде есть доопнительные ограничительные условия по срокам
      if (maxPossiblePeriod)
        end = getPercents(Number(maxPossiblePeriod) - this.termMin, this.termMax - this.termMin)
      else end = currentPosition

      return {
        start,
        end
      }
    }
    return { start: 0, end: 0 }
  }

  /**
   * Возвращает объект со start и end для построения штриховки для слайдера amount
   */
  getAmountHatchingBoundaries = () => ({ start: 0, end: 0 })

  /**
   * Возвращает объект style, содержащий штриховку, для слайдера term
   */
  getTermBackgroundStyle = () => {
    const {
      [parameters.HATCHING_ANGLE]: angle,
      [parameters.HATCHING_STEP]: step,
      [parameters.HATCHING_COLOR]: hatchingColor
    } = this.settings
    return buildHatching({ ...this.getTermHatchingBoundaries(), angle, step, color: hatchingColor })
  }

  /**
   * Возвращает объект style, содержащий штриховку, для слайдера amount
   */
  getAmountBackgroundStyle = () => {
    const {
      [parameters.HATCHING_ANGLE]: angle,
      [parameters.HATCHING_STEP]: step,
      [parameters.HATCHING_COLOR]: hatchingColor
    } = this.settings
    return buildHatching({
      ...this.getAmountHatchingBoundaries(),
      angle,
      step,
      color: hatchingColor
    })
  }

  /**
   * Получение объекта стилей для фона поля слайдера
   * @param {('amount'|'term')} sliderName - Тип слайдера (для суммы | для срока)
   * @returns {object} Style Object
   */
  getBackgroundStyle = (sliderName) =>
    ({
      amount: this.getAmountBackgroundStyle,
      term: this.getTermBackgroundStyle
    }[sliderName]?.() || {})

  /**
   * Получение дополнительных пропсов для кнопки на форме займа
   * @returns {object} Props Object
   */
  buildSubmitButtonPromoProps = () => {
    if (!this.validatePromoCode()) return {}
    const {
      [parameters.SUBMIT_BUTTON_TEXT]: text,
      [parameters.SUBMIT_BUTTON_TEXT_COLOR]: color,
      [parameters.SUBMIT_BUTTON_BACKGROUND_COLOR]: background,
      [parameters.SUBMIT_BUTTON_BORDER_COLOR]: borderColor
    } = this.settings
    return {
      text,
      style: { color, background, borderColor }
    }
  }

  /**
   * Получение дополнительных пропсов для блока расчетных данных по кредиту
   * @returns {object} Props Object
   */
  buildCalculatedInfoPromoProps = () => {
    if (!this.validatePromoCode()) return {}
    const { [parameters.PROMO_TEXT_COLOR]: color } = this.settings
    const freeDaysCount =
      [promocodes.FREE_DAYS, promocodes.FREE_DAYS_IN_MAX_PERIOD].includes(this.promoCodeName) &&
      this.creditType === 'MicroCredit'
        ? this.promoCodeValue
        : null
    return { color, freeDaysCount }
  }

  /**
   * Получение дополнительных пропсов для слайдеров
   * @param {('amount'|'term')} sliderName - Тип слайдера
   * @returns {object} Props Object
   */
  buildSliderPromoProps = (sliderName) => {
    const { marks, step } = this.getStepAndMarks(sliderName)
    if (!this.validatePromoCode()) return { marks, step }
    return {
      trackStyle: this.getTrackStyle(sliderName),
      backgroundStyle: this.getBackgroundStyle(sliderName),
      marks,
      step
    }
  }
}

export default PromotedComponentsPropsBuilder
