import './registration-form.scss'

import cn from 'classnames'
import { addYears, lastDayOfYear, subDays, subYears } from 'date-fns'
import UniversalFormData from 'form-data'
import {
  debounce,
  first,
  get,
  has,
  isEmpty,
  isEqual,
  isNull,
  isUndefined,
  memoize,
  noop,
  omit,
  size
} from 'lodash'
import PropTypes from 'prop-types'
import punycode from 'punycode'
import { createRef } from 'react'
import { Cookies } from 'react-cookie'
import ReCAPTCHA from 'react-google-recaptcha'
import { connect } from 'react-redux'

import Button from '#components/Button'
import CameraCropperContainer from '#components/CameraCropperContainer'
import { CardCropper, CardCropperBroker } from '#components/CardCropper'
import Form, { FormItem } from '#components/Form'
import LoanResult from '#components/LoanResult/LoanResult'
import Modal from '#components/Modal'
import { InsuranceLifeTkbAgreementLabel } from '#components/RegistrationForm/InsuranceLifeTkbAgreementLabel'
import { PrivateAgreementLinks } from '#components/RegistrationForm/PrivateAgreementLinks'
import appConfig from '#config'
import {
  CONSUMER_CREDIT_MONTH,
  innNalogRuHref,
  KEY_CODES,
  LOAN_MAX_AGE,
  LOAN_MIN_AGE,
  RE_MASTERCARD,
  RE_MIR,
  RE_VISA,
  RF_PASSPORT_DATE,
  SUCCESS_RESPONSE_CODE
} from '#constants/common'
import withWindowSize from '#hoc/withWindowSize'
import intl from '#intl'
import { InsuranceList } from '#modules/InsuranceList'
import { logAbSettings } from '#reducers/abTest/effects'
import {
  appendPartnerParameters,
  updatePartnerParameters,
  updateStage
} from '#reducers/app/appSlice'
import { restoreLoanFormState } from '#reducers/loanFormState/effects'
import { setTkbInsuranceAgreementValue } from '#reducers/loanFormState/loanFormStateSlice'
import { openModal } from '#reducers/ui/modalSlice'
import * as dadata from '#services/dadata'
import { formatDate, localeTime, now, parseDate } from '#services/datetime'
import * as fias from '#services/fias'
import {
  animateCSS,
  handleCheckRegistartionDone,
  interpolate,
  logFields,
  nl2br,
  restoreForm,
  returnNull,
  scrollTo as scrollToTarget
} from '#services/helper'
import notify from '#services/notify'
import withErrorLogger from '#src/hoc/withErrorLogger'
import { baseApi, brokerApi, mainSiteApi } from '#src/modules/api'
import { nodeApiUser } from '#src/modules/api/node/nodeApiUser'
import DataSaver from '#src/modules/DataSaver'
import metrika from '#src/modules/metrica'

import ModalsContainer from '../Dialogs'
import {
  CardPhotoUploadItem,
  FormItemPure,
  HightAmountHint,
  SmartControlComponent,
  VirtualAndUnnamedCardsHint
} from './formComponents'
import Stepper from './Stepper'

const cookies = new Cookies()

const DELAY_SHORT = 300

const DELAY_MEDIUM = 600
const DELAY_VERY_LONG = 3000
const SCROLLING_OFFSET = 65
const MULTIPLE_REQUESTS_CODE = 22
const ERROR_FORMAT_CODE = 5
const NOT_FOUND_CODE = 6
const CUSTOM_RESPONSE_CODE = 3
const SPECIAL_RESPONSE_CODE = 100
const ERROR_INN_NOT_FOUND = 7
const FORBIDDEN_ERROR_CODE = 403
const ENGINEERING_WORKS_CODE = 101
const TOO_MANY_REQUESTS_CODE = 429
const REGISTRATION_TYPE_THREE = 3
const REGISTRATION_TYPE_FOUR = 4
const REGISTRATION_TYPE_FIVE = 5
const REGISTRATION_TYPE_SIX = 6
const REGISTRATION_TYPE_SEVEN = 7
const STAGE_ONE = 1
const STAGE_TWO = 2
const STAGE_THREE = 3
const STAGE_FOUR = 4
const STAGE_FIVE = 5
const STAGE_SIX = 6
const MIN_SYMBOLS_FOR_FIAS_REQUEST = 3
const MAX_GENDER_LENGTH = 3
const MAX_FIO_LENGTH = 100
const MAX_CARDNAME_LENGTH = 255
const MAX_BIRTHPLACE_LENGTH = 1020
const MAX_STREET_LENGTH = 880
const CARD_VALIDITY_PERIOD = 20
const ADDRESS_ITEMS = ['city', 'street', 'house']
const FIO_ITEMS = ['secondName', 'name', 'middleName']
const NAME = 'name'
const MIDDLENAME = 'middleName'
const INN_PARAMS = [
  'secondName',
  'name',
  'middleName',
  'birthDate',
  'passportNumber',
  'passportDate',
  'birthPlace'
]

const FIRST_STAGE_PARTNER_PARAMS = new Map([
  ['secondName', 'last_name'],
  ['name', 'first_name'],
  ['middleName', 'patronymic'],
  ['mobileNumber', 'phone'],
  ['email', 'email']
])

const CHANGE_MODE = 'changeMode'
const CANCEL_MODE = 'cancelMode'
const isBrokerCabinet = process.env.__BUILD__ === 'partner'

const REJECT_FETCHING_TIMEOUT_S = 30
const REJECT_FETCHING_TIMEOUT_MS = REJECT_FETCHING_TIMEOUT_S * 1000

const INDEX_BY_RUPOST_DISABLED = Boolean(Number(appConfig.indexByRupostDisabled))
const EMPTY_HOME_PHONE = '+7 (___) ___-__-__'
const IMAGE_JPEG_TYPE = 'image/jpeg'

const symbolValidationRules = {
  [STAGE_ONE]: [
    {
      itemNameValidate: FIO_ITEMS,
      maxLength: MAX_FIO_LENGTH,
      errorText: intl.errorMaxFioLength
    }
  ],
  [STAGE_TWO]: [
    {
      itemNameValidate: ['birthPlace'],
      maxLength: MAX_BIRTHPLACE_LENGTH,
      errorText: intl.errorMaxBirthPlaceLength
    },
    {
      itemNameValidate: ['street', 'street1'],
      maxLength: MAX_STREET_LENGTH,
      errorText: intl.errorMaxStreetLength
    }
  ],
  [STAGE_THREE]: null,
  [STAGE_FOUR]: [
    {
      itemNameValidate: ['cardName'],
      maxLength: MAX_CARDNAME_LENGTH,
      errorText: intl.errorMaxCardNameLength
    }
  ],
  [STAGE_FIVE]: null,
  [STAGE_SIX]: null
}

export class RegistrationForm extends Form {
  recaptchaRef = createRef()
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      step: STAGE_ONE,
      cardPhotoId: null,
      timeIs: new Date(),
      isCardUploaded: false,
      shouldShowCardCropper: false,
      isEmailChanging: false,
      isLogForm: true,
      validationApiErrors: {},
      initializing: false,
      hiddenItems: {
        street: true,
        street1: true
      },
      form: { steps: [] },
      isPassportDataPossibleValid: true
    }
    this.cardCropperRef = createRef()
    this.cardPhotoDropZoneRef = createRef()
    this.wrapper = createRef()

    this.timing = {}
    this.debouncedGetDadataSuggestions = debounce(this.getDadataSuggestions, DELAY_SHORT)
    this.debouncedGetFiasSuggestions = debounce(this.getFiasSuggestions, DELAY_SHORT)
    this.validateItem = this.validateItem.bind(this)

    this.cardCropperComponent = isBrokerCabinet ? CardCropperBroker : CardCropper

    this.dataSaver = null
    this.isMainRegistration = true

    this.memoResolver = (item) => {
      try {
        return JSON.stringify(item, (key, value) => (key.startsWith('_') ? null : value))
      } catch (_e) {
        return noop(_e)
      }
    }

    this.memoizedRenderSmartControl = memoize(
      (properties) =>
        function renderSmartControl() {
          return <SmartControlComponent {...properties} />
        },
      this.memoResolver
    )

    this.memoizedRenderCardPhotoItem = memoize(
      (properties) => () => this.renderCardPhotoItem(properties),
      this.memoResolver
    )

    this.hintAttributeResolver = (...args) => this.memoResolver(args)

    this.memoizedHandleHintClickMobilePhone = memoize(
      this.handleHintClickMobilePhone,
      this.hintAttributeResolver
    )

    this.memoizedHandleHintClickEmail = memoize(
      this.handleHintClickEmail,
      this.hintAttributeResolver
    )

    this.memoizedHandleSmsCodeKeyPress = memoize(this.handleSmsCodeKeyPress, this.memoResolver)

    this.onTheEndOfEventLoopTimerId = null
    this.symbolLengthErrorTimeOutId = null
  }

  runOnTheEndOfEventLoop = (func) => {
    clearTimeout(this.onTheEndOfEventLoopTimerId)
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    this.onTheEndOfEventLoopTimerId = setTimeout(func.bind(this), 0)
  }

  getOriginalPhoto = async () => {
    try {
      const response = await mainSiteApi.cardOriginal(this.state.token)
      const blobImage = new Blob([response], { type: IMAGE_JPEG_TYPE })
      const fileSize = blobImage.size

      const preview = window.URL.createObjectURL(blobImage)
      const cardFile = {
        preview,
        type: IMAGE_JPEG_TYPE,
        fileSize
      }
      this.setState({
        cardFile,
        isCardUploaded: true
      })
    } catch (err) {
      this.props.logError(err, 'RegistrationForm.getOriginalPhoto')
      this.setState({
        cardFile: null,
        isCardUploaded: false
      })
    }
  }

  logUserAction = async (action) => {
    const logData = logFields({ ...this.state, action }, this.model)
    try {
      await baseApi.logChain(logData)
    } catch (_error) {
      noop(_error)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutId)
    clearTimeout(this.messageTimeOutId)
    clearTimeout(this.onTheEndOfEventLoopTimerId)
    clearTimeout(this.symbolLengthErrorTimeOutId)
    const { step, isLogForm } = this.state
    // логирование данных формы, при покидании страницы с шагов 2-3
    if (step > STAGE_ONE && step !== STAGE_FOUR) return this.logUserAction('close')

    // Логирование при покидании страницы с шага 4
    if (step === STAGE_FOUR && isLogForm) return this.logUserAction('close')
  }

  // Не рендерим рег. форму, если изменилась LoanForm
  shouldComponentUpdate(nextProps, nextState) {
    if (isBrokerCabinet) return true
    const isPropsEqual = isEqual(
      omit(nextProps, 'loanFormState'),
      omit(this.props, 'loanFormState')
    )
    const isStatesEqual = isEqual(this.state, nextState)
    return !isPropsEqual || !isStatesEqual
  }

  addDataToFormModel = (data) => {
    // для второго шага необходимо добавить ссылку, при нажатии на которую
    // мы передаем заявку брокеру с комментарием
    const secondStepLines = data
      .filter(({ step }) => Number(step) === STAGE_TWO)
      .map(({ line }) => line)
    const maxLine = Math.max(...secondStepLines)
    const linkControl = {
      step: STAGE_TWO,
      line: maxLine + 1,
      systemName: 'linkcontrol',
      elementType: 'link',
      text: 'Помощь в заполнении формы',
      handleClick: this.handleFillFormProblem
    }
    return [...data, linkControl]
  }

  changeTkbInsuranceAgreement = (event) => {
    const { detail = {}, target = {} } = event
    const { dispatchTkbInsuranceAgreementValue } = this.props
    const itemName = detail.name || target.name
    const value = detail.value || target.value
    super.handleControlChange({ target: { value, name: itemName } })
    dispatchTkbInsuranceAgreementValue(value)
  }

  handleDownLoadByPartner = async ({ documentType, documentName }) => {
    if (isBrokerCabinet) {
      const brokerToken = this.props.app.loggedInToken
      const {
        data: { personId }
      } = this.state
      try {
        const response = await brokerApi.brokerDownloadDocument({
          document: documentType,
          brokerToken,
          personId
        })
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${documentName}.pdf`)
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        this.setState({ errors: { common: error.message || intl.serverError } })
      }
    }
  }

  withCheckTkbInsurance = (data) => {
    // для четвертого шага необходимо добавить checkbox согласия
    if (isBrokerCabinet) {
      const insurance = new InsuranceList(this.props.loanConditions).findInsuranceInList(
        'insuranceLifeTkb'
      )
      if (insurance && insurance.insuranceEnabled) {
        const { insuranceDefault } = insurance
        const { dispatchTkbInsuranceAgreementValue } = this.props

        dispatchTkbInsuranceAgreementValue(insuranceDefault)
        this.setState((state) => ({
          ...state,
          data: {
            ...state.data,
            ...{ tkbLifeChecked: insuranceDefault }
          }
        }))
        const tkbLife = data.find((element) => element.systemName === 'tkbLifeChecked')
        const linkControl = {
          ...tkbLife,
          default: insuranceDefault,
          label: null,
          customLabel: (
            <InsuranceLifeTkbAgreementLabel
              insurance={insurance}
              onDownLoad={this.handleDownLoadByPartner}
            />
          ),
          onChange: this.changeTkbInsuranceAgreement
        }
        return [...data.filter((item) => item.systemName !== 'tkbLifeChecked'), linkControl]
      }
    }
    // фильтрация модели для 4 шага, checkbox необходим только для брокера tkb
    return data.filter((item) => item.systemName !== 'tkbLifeChecked')
  }

  getPerson2BrokerToken = async () => {
    const { row, app } = this.props
    const brokerToken = row && app.loggedInToken
    const personId = row?.personId
    if (!brokerToken || !personId) return null
    try {
      const response = await brokerApi.getPerson2BrokerToken({ personId, brokerToken })
      return response?.data?.token ?? null
    } catch (e) {
      this.props.logError(e, 'RegistrationForm.getPerson2BrokerToken')
      return null
    }
  }

  getPersonToken = () => this.props.token || cookies.get('token') || null

  resolveWithTimeout = (promises) =>
    new Promise((resolve, reject) => {
      const timeOutError = new Error(
        `Время ожидания запроса (${REJECT_FETCHING_TIMEOUT_S} сек.) истекло`
      )
      const timerId = setTimeout(() => reject(timeOutError), REJECT_FETCHING_TIMEOUT_MS)
      const resolveAll = async () => {
        try {
          resolve(await Promise.all(promises))
        } catch (e) {
          reject(e)
        } finally {
          clearTimeout(timerId)
        }
      }
      void resolveAll()
    })

  async componentDidMount() {
    this.sendViewAnalytics('Шаг 1')
    const timeIs = await localeTime()
    this.setState({ timeIs, initializing: true })
    const token = isBrokerCabinet ? await this.getPerson2BrokerToken() : this.getPersonToken()
    const { step } = this.state
    const { parsedParameters } = this.props.app
    try {
      let registrationFormParams = {}
      if (isBrokerCabinet) {
        registrationFormParams = {
          ...registrationFormParams,
          brokerToken: this.props.app.loggedInToken
        }
      }
      const [{ data = [] }] = await this.resolveWithTimeout([
        mainSiteApi.getRegistrationForm(registrationFormParams)
      ])

      /* slov-8637 необходимо убрать ссылки помощи в заполнении формы
      this.model = isBrokerCabinet ? data : this.addDataToFormModel(data)
       */

      this.model = this.withCheckTkbInsurance(data)
      this.initRegistrationForm()
      if (token) await this.restoreFormData(token)
      this.initModalLinks()

      // Из входящей ссылки автоматически подставляем данные пользователя
      // на первый шаг регистрации в анкету (если таковые имеются)
      if (!isBrokerCabinet && step === STAGE_ONE && !isEmpty(parsedParameters))
        this.updateFieldsFromPartnerParams(data)

      this.props.scrollToView?.()
    } catch (err) {
      if (isBrokerCabinet) {
        const { message } = err
        notify.push({ message, type: 'danger' })
      }
      this.props.logError(err, 'RegistrationForm.componentDidMount')
    } finally {
      this.setState({ initializing: false })
    }
  }

  componentDidUpdate(_, prevState) {
    if (
      !isBrokerCabinet &&
      prevState.data !== this.state.data &&
      this.dataSaver instanceof DataSaver
    )
      this.dataSaver.saveData(this.state.data)
  }

  initRegistrationForm = () => {
    const form = { steps: [] }
    const data = { requiredInn: false }

    this.model.map((item) => {
      const step = item.step - 1
      const line = item.line - 1

      if (item.default) {
        if (item.elementType === 'checkbox') data[item.systemName] = Number(item.default)
        else data[item.systemName] = item.default
      }

      // если поле ИНН присутствует, то мы будем запрашивать его у инфохаунд
      if (item.systemName === 'inn') data.requiredInn = true

      form.steps[step] = form.steps[step] || { lines: [] }
      form.steps[step].lines[line] = form.steps[step]?.lines[line] || {
        items: [],
        lineNumber: item.line
      }
      form.steps[step].lines[line].items.push(item)
    })

    this.setState({ form, data })
  }

  initModalLinks = () => {
    const links = this.wrapper.current?.querySelectorAll?.('[data-document]') ?? []
    Array.from(links).forEach((link) => link.addEventListener('click', this.handleModalLinkClick))
  }

  checkResponseCode(response = {}) {
    const { code, data, message } = response
    if (code === 1) return { errors: { common: intl.serverFailureError } }
    if (code === TOO_MANY_REQUESTS_CODE) return { errors: { common: intl.repeatItLater } }
    if (code === CUSTOM_RESPONSE_CODE) this.recaptchaRef.current.reset()
    return data ? { errors: data } : { errors: { common: message || intl.serverFailureError } }
  }

  getBirthDateMinMax = () => ({
    min: formatDate(subYears(now(), LOAN_MAX_AGE)),
    max: formatDate(subYears(now(), LOAN_MIN_AGE))
  })

  getPassportDateMinMax = () => ({
    min: formatDate(parseDate(RF_PASSPORT_DATE)),
    max: formatDate(subDays(now(), 1))
  })

  getCardDateMinMax = () => {
    const {
      data: { cardNumber }
    } = this.state
    if (RE_VISA.test(cardNumber) || RE_MASTERCARD.test(cardNumber)) {
      return {
        /* в связи с санкциями срок действия карт VISA и MASTERCARD автоматически продлен,
         при этом можно указывать старый срок действия карт
         */
        min: formatDate('2022-03-01'),
        max: formatDate(lastDayOfYear(addYears(now(), CARD_VALIDITY_PERIOD)))
      }
    }
    return {
      min: formatDate(now()),
      max: formatDate(lastDayOfYear(addYears(now(), CARD_VALIDITY_PERIOD)))
    }
  }

  getItemSuggestions = async ({ name: itemName, value: itemValue }, request) => {
    if (Object.prototype.toString.call(request) !== '[object Promise]') return
    if (!itemValue) {
      return this.setState((state) => ({
        loadingItems: { ...state.loadingItems, [itemName]: false },
        suggestions: { ...state.suggestions, [itemName]: null }
      }))
    }

    this.setState((state) => ({
      loadingItems: { ...state.loadingItems, [itemName]: true }
    }))

    try {
      const response = await request
      if (!response) return
      const { code, data } = response
      this.setState((state) => ({
        loadingItems: {
          ...state.loadingItems,
          [itemName]: code === MULTIPLE_REQUESTS_CODE
        },
        suggestions: {
          ...state.suggestions,
          [itemName]: data && code !== MULTIPLE_REQUESTS_CODE ? data : null
        }
      }))
      if (itemName.match('[C-c]ity') && isEmpty(data)) {
        this.setState((state) => ({
          errors: { ...state.errors, [itemName]: intl.fiasListEmpty }
        }))
      }
    } catch (err) {
      this.props.logError(err, 'RegistrationForm.getItemSuggestions')
    }
  }

  getDadataSuggestions = (item, options) => {
    const {
      data: { sex }
    } = this.state
    const params = sex ? { gender: sex.toUpperCase() } : {}
    void this.getItemSuggestions(item, dadata.getSuggestions(item.value, options, params))
  }

  updateFieldsFromPartnerParams = (data) => {
    const fieldsPartnerParams = this.props.app.parsedParameters
    data.forEach((item) => {
      if (FIRST_STAGE_PARTNER_PARAMS.has(item.systemName)) {
        const fieldValue = fieldsPartnerParams[FIRST_STAGE_PARTNER_PARAMS.get(item.systemName)]
        if (fieldValue?.match(item.validationRule)) {
          this.setState((state) => ({
            data: {
              ...state.data,
              [item.systemName]: fieldValue
            }
          }))
        }
      }
    })
  }

  getFiasSuggestions = (item, options) => {
    const { data, token } = this.state
    const params = { token }
    const nameLastSymbol = item.name.slice(-1) === '1' ? '1' : ''
    const aoguid = data[`city${nameLastSymbol}Guid`]

    if (item.name.replace(/1$/, '') === 'street' && aoguid) params.aoguid = aoguid

    item.value.length >= MIN_SYMBOLS_FOR_FIAS_REQUEST &&
      void this.getItemSuggestions(item, fias.getSuggestions(item.value, options, params))
  }

  getBicCodeSuggestions = (item) => {
    void this.getItemSuggestions(
      item,
      mainSiteApi.getBicCodes(item.value.replace(/\D/g, ''), this.state.token)
    )
  }

  getFmsByCode = async (itemName, code) => {
    this.setState((state) => ({
      loadingItems: { ...state.loadingItems, passportWhoCode: true },
      errors: { ...state.errors, [itemName]: null },
      data: { ...state.data, passportWhoNotEmpty: false }
    }))
    try {
      const response = await mainSiteApi.getFmsByCode({ code, token: this.state.token })
      const data = (response.data || []).map((item) => ({ value: item.name }))

      if (isEmpty(data)) {
        return this.setState((state) => ({
          errors: {
            ...state.errors,
            passportWhoCode: intl.fmsListEmpty
          }
        }))
      }

      this.setState((state) => ({
        options: { ...state.options, [itemName]: data },
        errors: { ...state.errors, passportWhoCode: null }
      }))

      if (size(data) === 1) {
        const passportWho = first(data)?.value
        await this.handleFmsNameChange('passportWho', passportWho)
        this.setState((state) => ({
          data: { ...state.data, passportWho, passportWhoNotEmpty: false }
        }))
      } else {
        this.setState((state) => ({
          data: { ...state.data, passportWhoNotEmpty: true }
        }))
      }
    } catch (err) {
      this.props.logError(err, 'RegistrationForm.getFmsByCode')
      this.setState((state) => ({
        loadingItems: { ...state.loadingItems, passportWhoCode: false },
        errors: { ...state.errors, passportWhoCode: intl.serverError }
      }))
    } finally {
      this.setState((state) => ({
        loadingItems: { ...state.loadingItems, passportWhoCode: false }
      }))
    }
  }

  getInn = async () => {
    const { data, token } = this.state
    let request = { token }
    INN_PARAMS.forEach((item) =>
      has(data, item) && request ? (request[item] = data[item]) : (request = null)
    )
    if (request) {
      this.setState({
        inn: await mainSiteApi
          .getInn(request)
          .catch((err) => this.props.logError(err, 'RegistrationForm.getInn'))
      })
    }
  }

  handleCopyInn = () =>
    this.setState((state) => ({
      data: { ...state.data, inn: state.inn },
      inn: null
    }))

  setStep = (step) => {
    const { dispatchUpdateStage, scrollToView = noop } = this.props
    this.setState({ step }, () => {
      this.initModalLinks()
      scrollToView(step)
    })
    dispatchUpdateStage({ stage: step })
  }

  setCardNumberIcon = (itemName, value) => {
    let icon = null
    this.setState((state) => ({ errors: { ...state.errors, [itemName]: '' } }))
    if (RE_VISA.test(value)) {
      icon = 'visa'
    } else if (RE_MASTERCARD.test(value)) {
      icon = 'mastercard'
    } else if (RE_MIR.test(value)) {
      icon = 'mir'
    } else {
      this.setState((state) => ({
        errors: { ...state.errors, [itemName]: intl.cantAcceptCard }
      }))
    }

    this.setState((state) => ({ icons: { ...state.icons, [itemName]: icon } }))
  }

  setGender = (itemName, value) =>
    this.setState((state) => ({ data: { ...state.data, [itemName]: value.toLowerCase() } }))

  setAoguid = (itemName, value) =>
    this.setState((state) => ({ data: { ...state.data, [itemName]: value } }))

  setPostalCode = (itemName, value, source) =>
    this.setState((state) => ({
      data: { ...state.data, [itemName]: value },
      errors: { ...state.errors, [itemName]: null },
      disabledItems: { ...state.disabledItems, [itemName]: Boolean(value) },
      [`${itemName}Source`]: value ? source : ''
    }))

  useItemService = (item) => {
    const [service, ...rest] = item.service.split(':')
    const options = rest.join(':')
    if (service === 'dadata') this.debouncedGetDadataSuggestions(item, options)
    if (service === 'fias') this.debouncedGetFiasSuggestions(item, options)
  }

  useItemSuggestion = (item, suggestion) => {
    const gender = suggestion && suggestion.data && suggestion.data.gender
    const aoguid = suggestion && suggestion.aoguid
    const postalcode = suggestion && suggestion.postalcode
    const note = suggestion && suggestion.note
    const nameLastSymbol = item.name.slice(-1) === '1' ? '1' : ''
    gender && this.setGender('sex', gender)
    aoguid && this.setAoguid(`${item.name}Guid`, aoguid)
    if (!INDEX_BY_RUPOST_DISABLED)
      'postalcode' in suggestion && this.setPostalCode('index' + nameLastSymbol, postalcode, 'fias')
    note && this.setState((state) => ({ notes: { ...state.notes, [item.name]: note } }))
  }

  useAddressItemByRupost = (itemName) => async () => {
    const { data } = this.state
    const nameLastSymbol = itemName.slice(-1) === '1' ? '1' : ''
    const indexItemName = 'index' + nameLastSymbol
    const values = []
    ADDRESS_ITEMS.forEach(
      (item) => data[item + nameLastSymbol] && values.push(data[item + nameLastSymbol])
    )
    if (isEmpty(values) || data[indexItemName]) return
    try {
      const { postOffices = [] } = await mainSiteApi.getIndex(values.join(', '))
      this.setPostalCode(indexItemName, first(postOffices)?.index || '', 'rupost')
    } catch (err) {
      this.props.logError(err, 'RegistrationForm.useAddressItemByRupost')
      this.setPostalCode(indexItemName, '')
    }
  }

  checkIsAddressItem = (itemName) => ADDRESS_ITEMS.includes(itemName.replace(/1$/, ''))

  validatePassportNumber = (item) => {
    const value = this.state.data[item.name].replace(/\D/, '')
    return /^(.)\1{2,}$/.test(value) ? intl.sameDigitsNotAllowed : true
  }

  checkDifferentNameMiddleName = () => {
    const { name, middleName } = this.state.data
    return name && middleName && name === middleName ? intl.fieldNameMiddleNameTheSame : ''
  }

  validateItem(item, showError = true) {
    let { isValid, validationErrorMessage } = super.validateItem(item, showError)
    const { data, suggestions } = this.state
    const errors = { ...this.state.errors }
    if (isValid) {
      if ([NAME, MIDDLENAME].includes(item.name)) {
        validationErrorMessage = this.checkDifferentNameMiddleName()
        ;[NAME, MIDDLENAME].forEach((item) => (errors[item] = validationErrorMessage))
        isValid = !validationErrorMessage
      }
      if (
        item.name === 'passportNumber' &&
        (validationErrorMessage = this.validatePassportNumber(item)) !== true
      ) {
        errors[item.name] = validationErrorMessage
        isValid = false
      } else if (
        item.name.match('[C-c]ity') &&
        !data[item.name + 'Guid'] &&
        !suggestions[item.name]
      ) {
        errors[item.name] = intl.citySelectError
      }
    } else if ([NAME, MIDDLENAME].includes(item.name)) {
      const validationMessage = this.checkDifferentNameMiddleName()
      validationMessage && [NAME, MIDDLENAME].forEach((item) => (errors[item] = validationMessage))
      validationErrorMessage &&
        !validationMessage &&
        [NAME, MIDDLENAME].filter((el) => item.name !== el).forEach((item) => (errors[item] = ''))
    }
    showError && this.setState({ errors })
    return { isValid, validationErrorMessage }
  }

  validateForm(showErrors = false) {
    const { step, data, cardFile } = this.state
    const { isCaptchaEnabled } = this.props
    const errors = {}
    let isFormValid = true
    this.model.forEach((item) => {
      const itemName = item.name || item.systemName
      const { isValid, validationErrorMessage } = this.validateItem(
        { ...item, name: itemName },
        showErrors
      )

      if (step === STAGE_ONE && itemName === 'recaptcha' && isCaptchaEnabled) {
        const {
          data: { recaptchaIsNotRequired = false }
        } = this.state
        if (!recaptchaIsNotRequired && !data?.recaptcha) {
          isFormValid = false
          errors[itemName] = validationErrorMessage
        }
      }

      if (item.step === step && this.wrapper.current?.querySelector?.(`#${itemName}`) && !isValid) {
        isFormValid = false
        errors[itemName] = validationErrorMessage
      } else if (
        item.step === step &&
        !this.wrapper.current?.querySelector?.(`#${itemName}`) &&
        itemName === 'cardPhoto' &&
        data.loanWay === 'bankCard' &&
        cardFile &&
        !data.cardPhoto
      ) {
        isFormValid = false
      }
    })

    // если карта загружена пользователем, но не кропнута, то вызываем ошибку
    if (
      !data.cardChecked &&
      data.cardPhoto &&
      data.loanWay !== 'bankAccount' &&
      step === STAGE_FOUR
    ) {
      isFormValid = false
      errors.cardPhoto = 'Подтвердите корректность загруженной карты (нажмите на зеленую галку)'
    }

    const state = { valid: isFormValid }
    if (showErrors) state.errors = errors

    this.setState(state)

    if (!this.validateSymbolLength(STAGE_ONE)) isFormValid = false
    if (!isFormValid) this.runOnTheEndOfEventLoop(this.scrollToError)

    return isFormValid
  }

  scrollToError = () => {
    const invalidItem = this.wrapper.current?.querySelector?.('.smart-control_invalid')

    if (invalidItem) {
      invalidItem.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }
  }

  cardNameToUpperCase = (itemName, value = '') =>
    this.setState((state) => ({ data: { ...state.data, [itemName]: value.toUpperCase() } }))

  punycodeConverter = (value) => {
    this.setState((state) => ({ data: { ...state.data, email: punycode.toUnicode(value) } }))
  }

  emailBlockCyrillic = (itemName, value = '') => {
    this.punycodeConverter(value)
    const reg = /[ЁА-яё]/
    const isHaveCyrillic = (val) => reg.test(val)
    const isCyrillic = isHaveCyrillic(value)
    if (isCyrillic)
      this.setState((state) => ({ errors: { ...state.errors, [itemName]: intl.errorNoCyrillic } }))
    else this.setState((state) => ({ errors: { ...state.errors, [itemName]: '' } }))
  }

  sendTimingAnalytics = (timingLabel) => {
    if (this.timing[timingLabel]) {
      if (process.env.__CLIENT__) {
        window.ga &&
          window.ga('send', 'timing', {
            timingCategory: 'Регистрация',
            timingVar: 'Время заполнения',
            timingValue: Date.now() - this.timing[timingLabel],
            timingLabel
          })
      }
      this.timing[timingLabel] = null
    }
  }

  sendViewAnalytics = (data) => {
    if (process.env.__CLIENT__ && window.ga) window.ga('send', 'pageview', data)
  }

  recoverData = async (data) => {
    const { onSendToViewMode } = this.props
    const { form, step, token } = this.state
    const { phoneIsVerified = 0, frontType = 0, stage } = data
    // регистрация пошла далее 4 шага, анкету редактировать запрещено
    // очищаем токен пользователя на странице регистрации
    if (stage > STAGE_FOUR && frontType === 1 && cookies.get('token')) {
      cookies.remove('token', { path: '/' })
      const currentUrl = new URL(window.location.href)
      if (currentUrl.searchParams.has('continue') && stage === STAGE_SIX) {
        window.location.href = appConfig.lkHost
      } else {
        currentUrl.searchParams.delete('continue')
        window.location.href = currentUrl.href
      }

      return
    }
    // регистрация дошла до шага создания записи о кредите
    // редактирование анкеты запрещено
    if (stage > STAGE_FIVE && frontType === 1 && cookies.get('brokerToken') && onSendToViewMode)
      return onSendToViewMode()

    if (phoneIsVerified === 0 || frontType === 0) {
      this.setStep(STAGE_ONE)
    } else if (data.stage > form.steps.length) {
      if (phoneIsVerified === 0) this.setStep(STAGE_ONE)
      else this.setStep(STAGE_TWO)
    } else if (data.stage !== step) {
      this.setStep(data.stage)
    }
    // устанавливаем значение проверки, на обрезание карты сервером
    data.cardChecked = Number(Boolean(data.cardPhotoId) && Boolean(data.cardPhoto))
    data.cardPhoto = data.cardChecked ? token : ''
    data.cardNumber && this.setCardNumberIcon('cardNumber', data.cardNumber)

    // пользователь ранее зарегистрировался, просить ввод капчи нет необходимости
    data.recaptchaIsNotRequired = true

    const recoveredData = await restoreForm(data, token)
    let prefilledData = recoveredData
    if (!isBrokerCabinet) {
      const guidsList = ['cityGuid', 'streetGuid', 'passportCityGuid', 'city1Guid', 'street1Guid']
      this.dataSaver = new DataSaver('registrationFormData', {
        identityToken: token,
        debounceTime: DELAY_MEDIUM,
        filter: {
          mode: 'include',
          keys: this.model
            .filter(({ step }) => step > STAGE_ONE)
            .map(({ systemName }) => systemName)
            .concat(guidsList)
        }
      })
      prefilledData = { ...this.dataSaver.getData(), ...recoveredData }
    }

    let {
      passportWhoCode = '',
      passportCity = '',
      passportCityGuid = '',
      passportWho: fmsName = ''
    } = prefilledData
    let passportWhoOptions = []
    const hasBeenPassportCityFilled = passportCity && passportCityGuid
    let isPassportCityVisible = false

    if (passportWhoCode) {
      const passportVal = String(passportWhoCode).replace(/-/g, '').trim()
      passportWhoCode = `${passportVal.substr(0, 3)}-${passportVal.substr(3)}`
      const isPassportWhoCodeFilled = passportVal.length === 6

      if (isPassportWhoCodeFilled) {
        try {
          const { data } = await mainSiteApi.getFmsByCode({
            code: passportVal,
            token
          })
          const options = (data || []).map((item) => ({ value: item.name }))

          passportWhoOptions = options
          // Обнуляем поле, если опций нет (введено неверное значение)
          if (isEmpty(options)) passportWhoCode = ''
        } catch (e) {
          // Обнуляем поле, запрос упал
          passportWhoCode = ''
        }

        const isAllowedOptionSelected = passportWhoOptions
          .map(({ value }) => value)
          .includes(fmsName)
        if (isAllowedOptionSelected) {
          try {
            const {
              data: { fullname = '', aoguid = '' } = {}
            } = await mainSiteApi.getPassportCityByFmsNameAndCode(token, fmsName, passportWhoCode)
            if (hasBeenPassportCityFilled) {
              // Показываем поле, если то что ввел пользователь, отличается от того что зафиксировано в базе
              isPassportCityVisible = fullname !== passportCity || aoguid !== passportCityGuid
            } else {
              passportCity = fullname
              passportCityGuid = aoguid
              // Показываем поле, если в базе нет информации
              isPassportCityVisible = !fullname || !aoguid
            }
          } catch (e) {
            // Показываем поле, если запрос упал и поле заполнено
            isPassportCityVisible = hasBeenPassportCityFilled
          }
        }
      } else {
        passportWhoCode = String(passportWhoCode).trim()
      }
    }

    const shouldRestoreCardPhoto = (data.cardPhotoId && !data.cardPhoto) || data.cardChecked

    this.setState(
      (state) => ({
        options: { ...state.options, passportWho: passportWhoOptions },
        data: {
          ...state.data,
          ...data,
          ...prefilledData,
          passportWhoNotEmpty: Boolean(passportWhoCode) && size(passportWhoOptions) !== 1,
          passportWhoCode,
          passportCity,
          passportCityGuid
        },
        cardPhotoId: data.cardPhotoId,
        mobileNumberConfirmed: Boolean(data.mobileNumber),
        registrationType: Number(data.registrationType),
        passportCityVisible: isPassportCityVisible,
        hiddenItems: {
          street: !prefilledData.street,
          street1: !prefilledData.street1
        },
        asyncpassportCity: prefilledData.passportCity,
        asynccity: prefilledData.city,
        asynccity1: prefilledData.city1
      }),
      () =>
        void (async () => {
          this.restoreSliders()
          if (shouldRestoreCardPhoto) await this.getOriginalPhoto()
        })()
    )
  }

  restoreSliders = (configData = {}) => {
    const { dispatchRestoreLoanFormState } = this.props
    const { loanAmount, loanTerm, productType, promocode, gid } = this.state.data
    const groupId = (isBrokerCabinet ? this.getCurrentLoanCondition()?.groupId : gid) ?? null
    dispatchRestoreLoanFormState(
      {
        data: {
          amount: loanAmount,
          term: loanTerm,
          creditType: productType,
          promoCode: promocode,
          ...configData
        }
      },
      { groupId }
    )
  }

  saveLoanWay = (value) => {
    const { token } = this.state
    const fd = new UniversalFormData()

    fd.append('loanWay', value)
    mainSiteApi
      .saveLoanWay(fd, { token })
      .catch((err) => this.props.logError(err, 'RegistrationForm.saveLoanWay'))
  }

  handleRepeatSmsCode = async () => {
    const { token } = this.state
    this.setState((state) => ({
      data: { ...state.data, smsCode: '' },
      loadingItems: { ...state.loadingItems, smsCode: true },
      disabledItems: { ...state.disabledItems, smsCode: true },
      errors: { ...state.errors, smsCode: null }
    }))
    try {
      const response = await mainSiteApi.repeatCode({ token })
      if (response.code !== SUCCESS_RESPONSE_CODE) throw response
    } catch (err) {
      this.props.logError(err, 'RegistrationForm.handleRepeatSmsCode')
      this.setState((state) => ({
        errors: { ...state.errors, smsCode: err.message || intl.serverError }
      }))
    } finally {
      this.setState((state) => ({
        loadingItems: { ...state.loadingItems, smsCode: false },
        disabledItems: { ...state.disabledItems, smsCode: false }
      }))
    }
  }

  repeatEmailLink = async () => {
    const itemName = 'email'
    this.setState((state) => ({
      loadingItems: { ...state.loadingItems, [itemName]: true },
      errors: { ...state.errors, [itemName]: null }
    }))
    try {
      const response = await mainSiteApi.repeatLink({ token: this.state.token })
      // если успешно отправили повторное сообщение на почту, то отобразим сообщение в модальном окне
      if (response.code === 0) {
        this.messageTimeOutId = setTimeout(() => {
          this.props.openModal('message', {
            title: ' ',
            textButton: intl.continue,
            content: this.repeatConfirmEmailMessage()
          })
        }, DELAY_MEDIUM)
      }
      this.setState((prevState) => ({
        loadingItems: { ...prevState.loadingItems, [itemName]: false },
        errors: { ...prevState.errors, ...this.getItemResponseErrors(itemName, response) }
      }))
    } catch (err) {
      this.props.logError(err, 'RegistrationForm.repeatEmailLink')
      this.setState((prevState) => ({
        loadingItems: { ...prevState.loadingItems, [itemName]: false },
        errors: { ...prevState.errors, [itemName]: intl.serverError }
      }))
    }
  }

  redirectToLk = async () => {
    try {
      const { token, registrationType } = this.state
      const { app } = this.props
      const c4s = cookies.get('c4s')
      const fd = new UniversalFormData()
      fd.append('partnerParameters', app.partnerParameters)
      fd.append('c4s', c4s)
      const ymUid = await metrika.getClientId()
      ymUid && fd.append('ymUid', ymUid)

      this.sendViewAnalytics('Проверка персоны')
      this.setState({ loading: true, errors: {} })

      const response = await mainSiteApi.clientSubmitRegistration(fd, { token })
      this.handleResponse(response)

      const isCardCropped = this.checkAndHandleCardCropErrorResponse(response)
      if (!isCardCropped) return
      if (response.code === SUCCESS_RESPONSE_CODE && response.data.link) {
        if (registrationType === REGISTRATION_TYPE_THREE) this.sendViewAnalytics('Переход в ЛК (3)')
        if (registrationType === REGISTRATION_TYPE_FOUR) this.sendViewAnalytics('Переход в ЛК (4)')
        if ([REGISTRATION_TYPE_SIX, REGISTRATION_TYPE_SEVEN].includes(registrationType))
          this.sendViewAnalytics('Переход в ЛК (6,7)')

        this.sendViewAnalytics('Переход в ЛК')
        metrika.sendGoal('stage=6, credit.status=-1')
        cookies.remove('token')
        this.dataSaver && this.dataSaver.clear()
        this.setState({ loading: true, isLogForm: false })
        if (process.env.__CLIENT__) window.location.href = response.data.link
      }
    } catch (err) {
      this.props.logError(err, 'RegistrationForm.redirectToLk')
      this.handleResponse(err)
    }
  }

  checkAndHandleCardCropErrorResponse = (response = {}) => {
    const { data: { type } = {}, code } = response
    if (code !== ERROR_FORMAT_CODE || type !== 'crop_not_found') return true
    this.closeCardCropper()
    this.setState((state) => ({
      ...state,
      cardPhotoId: null,
      cardFile: null,
      errors: { ...state.errors, cardPhoto: response.message },
      data: { ...state.data, cardPhoto: '', cardPhotoId: null, cardChecked: 0 }
    }))
    this.scrollToError()
    return false
  }

  /**
   * Обработчик добавления фотографии карты
   */
  handleCardAdd = async (evt) => {
    const { target } = evt
    const imageForm = first(target.value)
    this.setState({
      cardFile: imageForm
    })

    const cardFile =
      imageForm.type === IMAGE_JPEG_TYPE ? imageForm : await this.getConvertImage(imageForm)

    this.setState({
      cardFile,
      loading: true,
      shouldShowCardCropper: true,
      isCardUploaded: true
    })
    return Promise.resolve(true)
  }

  getConvertImage = async (image) => {
    const bufferImage = await nodeApiUser.convertCardImage(image)
    return new Blob([bufferImage], { type: IMAGE_JPEG_TYPE })
  }

  /**
   * Обработка успешной загрузки карты на сервер
   * */
  handleCardUploaded = (response) =>
    this.setState((state) => ({
      ...state,
      loading: false,
      cardPhotoId: response?.card_id ?? state.cardPhotoId
    }))

  /**
   * Обработчик начала обработки фотографии карты
   */
  handleCardApply = () =>
    this.setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, cardPhoto: null },
      loading: false
    }))

  /**
   * Обработчик успешной обработки фотографии карты
   */
  handleCardSuccess = async (shouldShowCardCropper) => {
    try {
      if (typeof shouldShowCardCropper === 'undefined') {
        // порядок getOriginalPhoto и setState имеет значение
        await this.getOriginalPhoto()
        this.setState((state) => ({
          ...state,
          data: { ...state.data, cardPhoto: state.token, cardChecked: 1 }
        }))
      } else {
        const { data, token } = this.state
        // порядок getOriginalPhoto и setState имеет значение
        this.setState({
          shouldShowCardCropper,
          data: { ...data, cardPhoto: token, cardChecked: 1 }
        })
        await this.getOriginalPhoto()
      }
    } catch (err) {
      this.props.logError(err, 'RegistrationForm.handleCardSuccess')
    }
  }

  /**
   * Обработчик ошибки обработки фотографии карты
   */
  handleCardFail = (response) => {
    const { registerError = null, redirectUrl } = handleCheckRegistartionDone(response)
    if (registerError instanceof Error) {
      this.setState((state) => ({
        ...state,
        cardFile: null,
        shouldShowCardCropper: false,
        cardPhotoId: null,
        loading: false,
        data: { ...state.data, cardPhoto: '', cardPhotoId: null, cardChecked: 0 }
      }))
      void this.resolveDialogAndRedirect(redirectUrl)
    } else {
      this.setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          ...this.getItemResponseErrors('cardPhoto', response)
        },
        loadingItems: { ...prevState.loadingItems, cardPhoto: false },
        loading: false,
        shouldShowCardCropper: false,
        data: { ...prevState.data, cardPhoto: '' }
      }))
    }
  }

  /**
   * Обработчик отмены процедуры кропа карты
   */
  closeCardCropper = () => {
    this.setState((state) => ({
      ...state,
      errors: { ...state.errors, cardPhoto: null },
      cardFile: null,
      cardPhotoId: null,
      loading: false,
      shouldShowCardCropper: false,
      data: { ...state.data, cardPhoto: '', cardPhotoId: null, cardChecked: 0 }
    }))
  }

  handleCardProblem = async () => {
    const { token } = this.state
    if (isBrokerCabinet) return null
    try {
      await this.logUserAction('button')
      const { redirectLink } = await mainSiteApi.canNotUploadCard({ token })
      if (redirectLink) window.location.href = redirectLink
      else this.setState({ modalMessage: intl.supportMessageText })
    } catch {
      return null
    }
  }

  handleFillFormProblem = async () => {
    try {
      await mainSiteApi.canNotFillForm({ token: this.state.token })
      await this.logUserAction('button')
      this.setState({ modalMessage: intl.supportMessageText })
    } catch {
      return null
    }
  }

  handleModalLinkClick = async (evt) => {
    const documentName = evt.target.getAttribute('data-document')

    await evt.stopPropagation()
    const { data } = this.state

    this.setState({
      modalDocument: {
        url: '',
        data: {
          document: documentName,
          fio: `${data.secondName || ''} ${data.name || ''} ${data.middleName || ''}`
        }
      }
    })
  }

  handleControlFocus = (evt) => {
    const { detail = {}, target = {} } = evt
    const itemName = detail.name || target.name
    super.handleControlFocus({ target: { name: itemName } })
    const { step } = this.state
    if (!this.timing[itemName]) this.timing[itemName] = Date.now()
    if (!this.timing[`stage${step}`]) this.timing[`stage${step}`] = Date.now()
    if (itemName === 'email') {
      const {
        data: { email }
      } = this.state
      this.emailBlockCyrillic('email', email)
    }

    // принудительно скролим элемент в верх экрана
    const onFocusScrolledItems = ['city', 'passportCity', 'street', 'city1', 'street1']
    if (onFocusScrolledItems.includes(itemName)) scrollToTarget(evt.target, SCROLLING_OFFSET)
  }

  handleControlChange = (sourceItem) => (evt) => {
    const { detail = {}, target = {}, type: targetType } = evt
    const itemName = detail.name || target.name
    const value = detail.value || target.value
    const type = detail.type || targetType
    super.handleControlChange({ target: { value, name: itemName } })
    const { suggestions } = this.state
    const item = { ...sourceItem, value }

    if (
      ['name', 'middleName', 'secondName', 'passportNumber', 'passportWhoCode'].includes(item.name)
    ) {
      this.setState({
        isPassportDataPossibleValid: true
      })
    }

    if (type === 'change') {
      sourceItem.service && this.useItemService(item)
      itemName === 'smsCode' && this.handleSmsCodeChange(item)
      itemName === 'passportWhoCode' && this.handleFmsCodeChange(item)
      itemName === 'cardNumber' && this.setCardNumberIcon('cardNumber', value)
      itemName === 'billBic' && this.getBicCodeSuggestions(item)
      itemName === 'secondName' && this.setGender('sex', '')
      itemName === 'cardName' && this.cardNameToUpperCase('cardName', value)
      itemName === 'loanWay' && this.saveLoanWay(value)
      itemName === 'passportWho' && this.handleFmsNameChange(itemName, value)
      itemName === 'email' && this.emailBlockCyrillic('email', value)
      if (this.checkIsAddressItem(itemName) || itemName === 'passportCity')
        this.handleAddressItemChange(sourceItem)
    } else if (type === 'select') {
      const itemSuggestions = suggestions[itemName] || []
      const selectedSuggestion = itemSuggestions.find(({ value: suggestionValue }) =>
        RegExp(String(suggestionValue)).test(String(value))
      )
      selectedSuggestion && this.useItemSuggestion(sourceItem, selectedSuggestion)
      this.setState((prevState) => ({
        ...prevState,
        suggestions: { ...prevState.suggestions, [itemName]: null }
      }))
    }
  }

  checkSymbolLength = (itemName, itemNameValidate, maxLength, errorText) => {
    let isValid = true
    const { data } = this.state
    if (
      itemNameValidate.indexOf(itemName) !== -1 &&
      data?.[itemName] &&
      data?.[itemName].length > maxLength
    ) {
      this.symbolLengthErrorTimeOutId = setTimeout(() => {
        this.setState((state) => ({
          ...state,
          errors: { ...state.errors, [itemName]: errorText }
        }))
      }, 0)
      return !isValid
    }
    return isValid
  }

  handleSymbolLength = (event, itemNameValidate, maxLength, errorText) => {
    const {
      target: { name }
    } = event
    this.checkSymbolLength(name, itemNameValidate, maxLength, errorText)
  }
  handleControlBlur = (evt) => {
    const { detail = {}, target = {}, type: targetType } = evt
    const itemName = detail.name || target.name
    const type = detail.type || targetType
    super.handleControlBlur({ target: { name: itemName }, type })
    this.handleSymbolLength(
      { target: { name: itemName } },
      FIO_ITEMS,
      MAX_FIO_LENGTH,
      intl.errorMaxFioLength
    )
    this.handleSymbolLength(
      { target: { name: itemName } },
      ['cardName'],
      MAX_CARDNAME_LENGTH,
      intl.errorMaxCardNameLength
    )
    this.handleSymbolLength(
      { target: { name: itemName } },
      ['birthPlace'],
      MAX_BIRTHPLACE_LENGTH,
      intl.errorMaxBirthPlaceLength
    )
    this.handleSymbolLength(
      { target: { name: itemName } },
      ['street', 'street1'],
      MAX_STREET_LENGTH,
      intl.errorMaxStreetLength
    )
    const { data } = this.state
    INN_PARAMS.includes(itemName) && data.requiredInn && this.runOnTheEndOfEventLoop(this.getInn)
    if (itemName === 'email') {
      const {
        data: { email }
      } = this.state
      this.emailBlockCyrillic('email', email)
    }
    if (!INDEX_BY_RUPOST_DISABLED) {
      this.checkIsAddressItem(itemName) &&
        this.runOnTheEndOfEventLoop(this.useAddressItemByRupost(itemName))
    }
    this.setState((prevState) => ({
      ...prevState,
      suggestions: { ...prevState.suggestions, [itemName]: null }
    }))
    this.sendTimingAnalytics(itemName)
  }

  handleHintClick = ({ mode, savedField, field, visibleField }) => {
    this.setState((state) => {
      const updatedState = {
        ...state,
        disabledItems: { ...state.disabledItems, [field]: mode === CANCEL_MODE },
        smsCodeVisible: mode === CANCEL_MODE && state.disabledItems[visibleField]
      }
      if (mode === CHANGE_MODE) updatedState[savedField] = state.data[field]
      if (mode === CANCEL_MODE) {
        updatedState.data = { ...state.data, [field]: state[savedField] ?? '' }
        updatedState.errors = { ...state.errors, [field]: null }
      }
      return updatedState
    })
  }

  handleHintClickMobilePhone = (mode) => () =>
    this.handleHintClick({
      mode,
      field: 'mobileNumber',
      savedField: 'savedMobileNumber',
      visibleField: 'email'
    })

  handleHintClickEmail = (mode) => () =>
    this.handleHintClick({
      mode,
      field: 'email',
      savedField: 'savedEmail',
      visibleField: 'mobileNumber'
    })

  confirmSmsCode = async (params = {}) => {
    const {
      step,
      token,
      registrationType,
      loading,
      data: { smsCode: smsCodeValue }
    } = this.state

    const { shouldValidate = true } = params

    if (shouldValidate && (loading || !this.validateForm(true))) return

    this.setState((state) => ({
      loadingItems: { ...state.loadingItems, smsCode: true },
      disabledItems: { ...state.disabledItems, smsCode: true },
      errors: { ...state.errors, smsCode: null }
    }))

    try {
      const response = await mainSiteApi.confirmPhoneCode(this.buildFormData(), {
        token,
        registrationType,
        code: smsCodeValue
      })

      const { code, message, data = {} } = response
      const { type: responseType } = data

      if (
        ![SUCCESS_RESPONSE_CODE, SPECIAL_RESPONSE_CODE].includes(code) ||
        responseType === 'error'
      )
        throw response

      if (code === SUCCESS_RESPONSE_CODE) {
        this.sendTimingAnalytics(`stage${step}`)
        this.sendViewAnalytics('Шаг 2')
        metrika.sendGoal('stage=2')
        this.setStep(step + 1)
        await this.restoreFormData(token)

        const needConfirmEmail = !isBrokerCabinet && this.state.data?.email

        if (needConfirmEmail) {
          notify.push({
            dismissible: false,
            message: 'Вы подписаны! Проверьте почту для подтверждения подписки',
            type: 'success',
            alarmIcon: false,
            container: 'bottom-right'
          })
        }
      }

      if (code === SPECIAL_RESPONSE_CODE && !isEmpty(data)) {
        this.sendTimingAnalytics(`stage${step}`)
        this.sendViewAnalytics('Любой шаг')
        this.props.logAbSettings && this.props.logAbSettings(token)
        if (data.uid && !isBrokerCabinet) metrika.sendUserId(data.uid)
        await this.recoverData(data)
      }

      if (code === ENGINEERING_WORKS_CODE) return this.setState({ disableReason: message })

      cookies.set('token', token, { maxAge: 24 * 60 * 60 })
      this.setState({ mobileNumberConfirmed: true })
    } catch (err) {
      this.props.logError(err, 'RegistrationForm.confirmSmsCode')
      const { data: { message: specialErrMessage, systemName = 'smsCode' } = {}, message } = err
      this.setState(
        (state) => ({
          errors: {
            ...state.errors,
            [systemName]: specialErrMessage || message || intl.serverError
          }
        }),
        this.scrollToError
      )
    } finally {
      this.setState((state) => ({
        loadingItems: { ...state.loadingItems, smsCode: false },
        disabledItems: { ...state.disabledItems, smsCode: false }
      }))
    }
  }

  resetPassportCityField = () =>
    this.setState((state) => ({
      data: {
        ...state.data,
        passportCity: '',
        passportCityGuid: ''
      },
      errors: {
        ...state.errors,
        passportCity: null
      },
      passportCityVisible: false,
      asyncpassportCity: ''
    }))

  handleSmsCodeChange = (item) => {
    const { isValid } = this.validateItem(item, false)
    if (!isValid) return
    this.sendViewAnalytics('Проверка смс-кода')
    this.runOnTheEndOfEventLoop(this.confirmSmsCode)
  }

  handleFmsCodeChange = (item) => {
    const { value, name: itemName } = item
    const {
      data: { [itemName]: prevValue }
    } = this.state

    if (prevValue === value) return
    const { isValid } = this.validateItem(item, false)
    this.setState((state) => ({
      ...state,
      options: { ...state.options, passportWho: null },
      data: {
        ...state.data,
        passportWho: '',
        passportWhoNotEmpty: isValid
      }
    }))
    this.resetPassportCityField()
    if (isValid) void this.getFmsByCode('passportWho', item.value.replace(/\D/g, ''))
  }

  handleFmsNameChange = async (itemName, value) => {
    const {
      data: { passportWhoCode, passportWho },
      token
    } = this.state

    if (value === passportWho) return

    this.setState((state) => ({
      loadingItems: { ...state.loadingItems, [itemName]: true }
    }))

    this.resetPassportCityField()

    try {
      const { code, data } = await mainSiteApi.getPassportCityByFmsNameAndCode(
        token,
        value,
        passportWhoCode
      )

      if (code !== SUCCESS_RESPONSE_CODE || isEmpty(data) || !Object.values(data).every(Boolean))
        return this.setState({ passportCityVisible: true })

      this.setState((state) => ({
        data: { ...state.data, passportCity: data.fullname, passportCityGuid: data.aoguid }
      }))
    } catch (err) {
      this.props.logError(err, 'RegistrationForm.handleFmsNameChange')
      this.setState({ passportCityVisible: true })
    } finally {
      this.setState((state) => ({
        loadingItems: { ...state.loadingItems, [itemName]: false }
      }))
    }
  }

  handleAddressItemChange = (item) => {
    const { data } = this.state
    const itemName = item.name.replace(/1$/, '')
    const nameLastSymbol = item.name.slice(-1) === '1' ? '1' : ''
    const indexItemName = 'index' + nameLastSymbol

    if (
      ((itemName === 'house' && this.state[`${indexItemName}Source`] === 'rupost') ||
        ['city', 'street'].includes(itemName)) &&
      data[indexItemName]
    )
      this.setPostalCode(indexItemName, '')

    if (['city', 'street', 'passportCity'].includes(itemName) && data[`${item.name}Guid`]) {
      this.setState((state) => {
        const newData = { ...state.data }
        newData[`${item.name}Guid`] = ''

        if (itemName === 'city') {
          newData[`street${nameLastSymbol}`] = ''
          newData[`street${nameLastSymbol}Guid`] = ''
        }

        return { ...state, data: newData }
      })
    }
  }

  handlePrevStep = () => {
    const { step, mobileNumberConfirmed } = this.state
    if (step > STAGE_ONE) this.setStep(step - 1)
    if (step === STAGE_TWO && mobileNumberConfirmed) this.state.smsCodeVisible = false
  }

  restoreFormData = async (token) => {
    try {
      const { code, data = {}, message = '' } = await mainSiteApi.registrationRestore(token)
      const { uid = null } = data
      if (uid && !isBrokerCabinet) metrika.sendUserId(uid)
      if ([SPECIAL_RESPONSE_CODE, SUCCESS_RESPONSE_CODE].includes(code))
        this.props.logAbSettings && this.props.logAbSettings(token)
      if (code === SPECIAL_RESPONSE_CODE && !isEmpty(data))
        return this.setState({ token }, () => void this.recoverData(data))
      if (code === ENGINEERING_WORKS_CODE) return this.setState({ disableReason: message })
      if (code !== SUCCESS_RESPONSE_CODE) cookies.remove('token')
    } catch (err) {
      this.props.logError(err, 'RegistrationForm.restoreFormData')
    }
  }

  isPromoCodeAppliedAndNotBlocked = () => {
    const {
      appliedPromoCode,
      isPromoBlocked,
      data: { promoCode: promoCodeString } = {}
    } = this.props.loanFormState
    return promoCodeString && appliedPromoCode && !isPromoBlocked
  }

  getPromoString = () => {
    const { data: { promoCode } = {} } = this.props.loanFormState
    return this.isPromoCodeAppliedAndNotBlocked() ? promoCode : null
  }

  getCurrentLoanCondition = () => {
    const { loanConditions, loanFormState } = this.props
    const {
      index,
      data: { creditType }
    } = loanFormState
    return loanConditions.products[creditType]?.[index] || {}
  }

  getLinkToLk = () => {
    const {
      app: { partnerParameters }
    } = this.props
    return this.isPromoCodeAppliedAndNotBlocked()
      ? `${appConfig.lkHost}/?promostring=${this.getPromoString()}${
          partnerParameters && `&${partnerParameters}`
        }`
      : `${appConfig.lkHost}${partnerParameters && `/?${partnerParameters}`}`
  }

  handlePhoneAlreadyTakenLinkClick = (registrationType) => () => {
    const gaPage =
      registrationType === REGISTRATION_TYPE_FIVE ? 'Переход в ЛК (5)' : 'Переход в ЛК (1,2)'
    this.sendViewAnalytics(gaPage)
    this.sendViewAnalytics('Переход в ЛК')
  }

  animateSmsCodeField = (step) => () => {
    if (step !== STAGE_ONE) return
    const smsCodeField = document.querySelector('.form__item_name_smsCode')
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const offset = window.innerHeight / 2.5
    if (smsCodeField) scrollToTarget(smsCodeField, offset, () => animateCSS(smsCodeField, 'pulse'))
  }

  buildFormData = () => {
    const { loanFormState, app } = this.props
    const { data, step } = this.state
    const {
      data: { creditType }
    } = loanFormState
    const { loanConditionId = null, groupId = null } = this.getCurrentLoanCondition()

    const fd = new UniversalFormData()

    fd.append('data[loanAmount]', loanFormState.data.amount)
    fd.append('data[loanTerm]', loanFormState.data.term)
    fd.append('data[creditType]', creditType)
    fd.append('c4s', cookies.get('c4s'))
    fd.append('referer', app.referer || cookies.get('referer') || '')
    fd.append('data[partnerParameters]', app.partnerParameters || '')

    if (process.env.__CLIENT__)
      fd.append('resolution', `${window.screen.width}x${window.screen.height}`)
    if (loanConditionId) fd.append('data[loanConditionId]', loanConditionId)
    if (groupId) fd.append('data[groupId]', groupId)
    if (step === STAGE_ONE && data.sex) fd.append('data[sex]', data.sex)
    if (step === STAGE_ONE && data?.recaptcha) fd.append('g-recaptcha-response', data.recaptcha)

    this.model.forEach(({ step: itemStep, systemName }) => {
      if (itemStep > step || !data[systemName]) return
      if (systemName === 'apartment' && Boolean(Number(data['privateHouse']))) return
      if (systemName === 'apartment1' && Boolean(Number(data['privateHouse1']))) return
      fd.append(`data[${systemName}]`, String(data[systemName]).trim())
      if (data[`${systemName}Guid`]) fd.append(`data[${systemName}Guid]`, data[`${systemName}Guid`])
    })

    if (this.isPromoCodeAppliedAndNotBlocked()) fd.append('data[promocode]', this.getPromoString())

    return fd
  }

  processAdditionInfo = (additionalInfo) => {
    if (isEmpty(additionalInfo)) return
    const { promoCodeNotificationText, promoCodeNotificationCode } = additionalInfo
    if (promoCodeNotificationText) this.setState({ modalMessage: promoCodeNotificationText })
    if (promoCodeNotificationCode === 'promoCodeDetachedDueToPersonIsOld')
      this.restoreSliders({ promoCode: null })
  }

  validateSymbolLength = (step) => {
    let isValid = true
    if (!symbolValidationRules[step]) return isValid
    symbolValidationRules[step].forEach((symbolValidationRule) => {
      const { itemNameValidate, maxLength, errorText } = symbolValidationRule
      this.model.forEach((item) => {
        const itemName = item.name || item.systemName
        if (
          item.step === step &&
          itemNameValidate.indexOf(itemName) !== -1 &&
          !this.checkSymbolLength(itemName, itemNameValidate, maxLength, errorText)
        )
          isValid = false
      })
    })
    return isValid
  }

  refreshHomePhone = () => {
    const {
      data: { homePhone }
    } = this.state
    if (homePhone === EMPTY_HOME_PHONE)
      this.setState((state) => ({ data: { ...state.data, homePhone: '' } }))
  }

  handleSubmit = async (evt) => {
    evt && evt.preventDefault()
    const {
      savedMobileNumber,
      loading,
      step,
      data: { stage, mobileNumber, smsCode }
    } = this.state
    if (!this.validateSymbolLength(step)) return this.scrollToError()

    this.refreshHomePhone()

    if (loading || !this.validateForm(true)) return
    // Если пользователь в действительности не изменил номер телефона
    // то запрос по api не отправляем и просто отображаем обратно поле ввода смс-кода
    if (savedMobileNumber === mobileNumber && stage === STAGE_ONE) {
      this.setState(
        (state) => ({ ...state, disabledItems: { ...state.disabledItems, email: true } }),
        this.memoizedHandleHintClickMobilePhone(CANCEL_MODE)
      )
      const smsCodeValidationRule = this.model?.find?.(({ systemName }) => systemName === 'smsCode')
        ?.validationRule
      if (!smsCodeValidationRule) return
      const regExp = new RegExp(smsCodeValidationRule)
      if (regExp.test(smsCode)) return this.confirmSmsCode({ shouldValidate: false })
    } else {
      return this.continueRegistration()
    }
  }

  continueRegistration = async () => {
    const { onFinish } = this.props
    const { form, data, step, loading, token, registrationType, finished, cardPhotoId } = this.state

    if (loading || !this.validateForm(true)) return

    if (finished) return this.redirectToLk()

    this.setState((state) => ({
      ...state,
      loading: true,
      loadingTimer: step === STAGE_TWO ? 10 : false,
      disabledItems: { ...state.disabledItems, mobileNumber: false },
      errors: {}
    }))

    this.sendViewAnalytics(`Проверка данных шага ${step}`)
    try {
      let registerPersonParams = {
        token,
        registrationType,
        phoneIsVerified: data.phoneIsVerified || 0,
        stage: step
      }
      if (isBrokerCabinet) registerPersonParams['brokerToken'] = this.props.app.loggedInToken

      const response = await mainSiteApi.registerPerson(this.buildFormData(), registerPersonParams)
      const { code, data: responseData = {} } = response
      const { stage = null, additionalInfo = {} } = responseData

      this.processAdditionInfo(additionalInfo)

      const shouldInteruptSubmitting =
        additionalInfo?.promoCodeNotificationCode === 'promoCodeDetachedDueToPersonIsOld' &&
        step === STAGE_FOUR

      if (code === SUCCESS_RESPONSE_CODE) {
        this.setState((state) => ({
          ...state,
          data: { ...state.data, stage, phoneIsVerified: 1 },
          savedMobileNumber: data.mobileNumber,
          savedEmail: data.email,
          loadingTimer: false,
          loading: false
        }))

        if (responseData.registrationType)
          this.setState({ registrationType: Number(responseData.registrationType) })

        if (responseData.token) this.setState({ token: responseData.token })

        if (step === STAGE_ONE) {
          this.setState(
            (state) => ({
              ...state,
              smsCodeVisible: true,
              disabledItems: { ...state.disabledItems, mobileNumber: true, email: true }
            }),
            this.animateSmsCodeField(step)
          )
          this.sendViewAnalytics('Ввод смс-кода')
          metrika.sendGoal('stage=1')
        }

        if (step < form.steps.length) {
          if (stage && stage !== step) {
            this.sendTimingAnalytics(`stage${step}`)
            this.sendViewAnalytics(`Шаг ${stage}`)
            if (stage === 3 || stage === 4) metrika.sendGoal(`stage=${stage}`)

            this.setStep(stage)
          }
          return
        }

        if (shouldInteruptSubmitting) return

        if (onFinish) {
          const ymUid = await metrika.getClientId()
          this.checkAndHandleCardCropErrorResponse(
            await onFinish({ ...data, token, formProps: this.props, cardPhotoId, ymUid })
          )
          return
        }

        this.setState({ finished: true })
        await this.redirectToLk()
      } else {
        await this.handleSubmitError(response)
      }
    } catch (err) {
      const { logError } = this.props
      if (isBrokerCabinet) {
        const { message } = err
        notify.push({ message, type: 'danger' })
      }
      logError(err, 'RegistrationForm.continueRegistration')
    }
  }

  handleSubmitError = async (response) => {
    const { step } = this.state
    const { data = {} } = response
    const { dispatchUpdatePartnerParameters } = this.props

    const { registerError = null, redirectUrl } = handleCheckRegistartionDone(response)
    if (registerError instanceof Error) await this.resolveDialogAndRedirect(redirectUrl)

    this.handleResponse(response)

    if (response.code === FORBIDDEN_ERROR_CODE) {
      const { hostname } = window.location
      let domain = hostname.split('.').slice(-2).filter(Boolean).join('.')
      const cookieOptions = { path: '/', domain: `.${domain}`, maxAge: 1 }
      dispatchUpdatePartnerParameters('')
      cookies.set('partnerParameters', '', cookieOptions)
      window.location.href = new URL('/register', appConfig.host).href
    }

    if (response.code === NOT_FOUND_CODE) cookies.remove('token')

    if (response.code === ERROR_FORMAT_CODE) {
      this.setStep(data.stage)
      this.setState((state) => {
        const hiddenItems = { ...state.hiddenItems }
        if (has(data, 'street')) hiddenItems.street = false
        if (has(data, 'street1')) hiddenItems.street1 = false
        return { hiddenItems }
      })
      this.scrollToError()
    }

    if (response.code === SPECIAL_RESPONSE_CODE && step === STAGE_ONE) {
      const {
        data: { mobileNumber }
      } = this.state
      const clearMobile = mobileNumber.replace(/\D/g, '')
      this.props.dispatchAppendPartnerParams({ partnerParameters: clearMobile })
      const error =
        data.systemName === 'mobileNumber' ? (
          <div>
            <span>{intl.phoneAlreadyTaken} </span>
            <a
              href={this.getLinkToLk()}
              onClick={this.handlePhoneAlreadyTakenLinkClick(data.registrationType)}
            >
              {intl.lk.toLowerCase()}
            </a>
          </div>
        ) : (
          <div>
            <span dangerouslySetInnerHTML={{ __html: data.message }} />
          </div>
        )
      this.setState((state) => ({
        ...state,
        errors: { ...state.errors, [data.systemName]: error }
      }))
    }

    if (response.code === ERROR_INN_NOT_FOUND && step === STAGE_TWO) {
      this.setState((state) => ({
        ...state,
        errors: {
          ...state.errors,
          name: ' ',
          secondName: ' ',
          middleName: ' ',
          passportNumber: ' ',
          passportDate: ' ',
          birthDate: ' ',
          common: intl.innNotFoundText
        },
        loading: false,
        loadingTimer: false,
        isPassportDataPossibleValid: false
      }))
    }
  }

  resolveDialogAndRedirect = async (redirectUrl) => {
    const redirectTo = (url) => () => process.env.__CLIENT__ && (window.location.href = url)
    this.timeOutId = setTimeout(redirectTo(redirectUrl), DELAY_VERY_LONG)
    await this.props
      .openModal('info', {
        content: (
          <div>
            {
              'Заполнение анкеты было успешно завершено, сейчас Вы будете переадресованы на следующий шаг.'
            }
          </div>
        ),
        actions: [
          {
            type: 'confirm',
            callback: redirectTo(redirectUrl)
          }
        ]
      })
      .catch(noop)
  }

  clearCardPhoto = () => {
    this.setState((state) => ({
      ...state,
      cardFile: null,
      cardPhotoId: null,
      data: { ...state.data, cardPhoto: '', cardPhotoId: null, cardChecked: 0 },
      shouldShowCardCropper: false
    }))
  }

  handleRemove = async () => {
    const { brokerForm } = this.props
    if (brokerForm) {
      const fd = new UniversalFormData()
      fd.append('brokerToken', this.props.app.loggedInToken)
      fd.append('personToken', this.state.token)
      const response = await brokerApi.brokerRemoveCardPhoto(fd).catch(noop)
      this.handleResponse(response)
      this.clearCardPhoto()
    } else {
      this.clearCardPhoto()
    }
  }

  setSuggestions = ({ name: itemName, options }) =>
    this.setState((state) => ({
      suggestions: { ...state.suggestions, [itemName]: options }
    }))

  handleAsyncOptionChange = (option) => {
    const { name: itemName, aoguid = '', value = '', hasStreets = false } = option

    this.setState((state) => {
      const data = {
        ...state.data,
        [itemName]: value,
        [`${itemName}Guid`]: aoguid
      }
      const updatedState = { data }
      const map = { city: 'street', city1: 'street1' }
      if (Object.keys(map).includes(itemName)) {
        const streetItem = map[itemName]
        const streetItemGuid = `${streetItem}Guid`
        data[streetItem] = ''
        data[streetItemGuid] = ''
        updatedState.hiddenItems = { ...state.hiddenItems, [streetItem]: !hasStreets }
      }
      return updatedState
    })
  }

  handleAsyncInputChange = ({ value, action, name: itemName }) => {
    if (['set-value', 'input-change'].includes(action))
      this.setState({ [`async${itemName}`]: value })
  }

  handleSmsCodeKeyPress = (item) => (evt) => {
    if (evt.keyCode !== KEY_CODES.enter) return
    const { isValid } = this.validateItem(item, false)
    if (isValid) void this.confirmSmsCode()
  }

  appendItem = (sourceItem) => {
    const { loanConditions = {}, abTest } = this.props
    const {
      smsCodeVisible,
      options,
      suggestions,
      icons,
      notes,
      loadingItems,
      disabledItems,
      mobileNumberConfirmed,
      isEmailChanging,
      data,
      errors,
      cardFile,
      passportCityVisible,
      hiddenItems
    } = this.state
    const { insuranceList = [], promoConditions = null } = loanConditions
    const isInsuranceEnabled = !isEmpty(insuranceList)
    const insuranceWarningMessage = isEmpty(insuranceList)
      ? ''
      : first(insuranceList)?.insuranceWarningMessage

    let item = { ...sourceItem }
    item.type = item.elementType
    item.name = item.systemName
    item.value = data[item.name]
    item.options = suggestions[item.name] || options[item.name] || item.options
    item.icon = icons[item.name] || item.icon
    item.bottomText = notes[item.name] || item.bottomText
    item.valid = !errors[item.name]
    item.loading = loadingItems[item.name]
    item.disabled = disabledItems[item.name]

    switch (item.name) {
      case 'rules':
        if (isInsuranceEnabled && abTest.additionalServicesMessage === 'show') {
          typeof item.label === 'string' &&
            (item.label = item.label
              .concat(insuranceWarningMessage)
              .replace(' стоимостью ###commission### руб.', '')
              .replace('color-red', 'color-orange'))
        }
        promoConditions &&
          (item.label = item.label.concat(
            ` и <a data-document=${promoConditions}>${intl.additionalPromoInfo}</a>`
          ))
        break
      case 'mobileNumber':
        item.disabled = mobileNumberConfirmed || item.disabled
        break
      case 'email':
        item.disabled =
          data.stage > 1 || (data.stage > 1 && data.email && !isEmailChanging) || item.disabled
        // если есть email и он не подтвержден, то отображаем ссылку для повторной отправки сообщения на почту
        item.hint = data.stage > 1 && data.email && !data.emailIsVerified && (
          <span className='link link_pseudo me-sm-2' onClick={this.repeatEmailLink}>
            {intl.repeat}
          </span>
        )
        break
      case 'birthDate':
        item = { ...item, ...this.getBirthDateMinMax() }
        break
      case 'passportDate':
        item = { ...item, ...this.getPassportDateMinMax() }
        break
      case 'cardDate':
        item = { ...item, ...this.getCardDateMinMax() }
        break
      case 'sex':
        item.options[0].icon = 'male'
        item.options[1].icon = 'female'
        item.options[0].text = item.options[0].text.substr(0, MAX_GENDER_LENGTH)
        item.options[1].text = item.options[1].text.substr(0, MAX_GENDER_LENGTH)
        break
      case 'loanWay':
        item.options[0].icon = 'card'
        item.options[1].icon = 'bank'
        item.bottomText = (
          <>
            {item.bottomText}
            <VirtualAndUnnamedCardsHint />
          </>
        )
        break
      case 'cardPhoto':
        item.combineText = true
        item.mask = 'card'
        item.accept = 'image/jpeg, image/jpg, image/png, image/heic, image/heif'
        item.fileExtensionErrorMessage = interpolate(intl.unsupportedCardPhotoFormat, {
          formats: '.png, .jpg, .jpeg, .heic, .heif, .HEIC, .HEIF'
        })
        item.preview = data.cardChecked ? cardFile && cardFile.preview : null
        item.value = !data.cardChecked && null
        item.loading = cardFile && ['image/heic', 'image/heif'].includes(cardFile.type) // dropZone preview не поддерживает HEIC/HEIF
        item.onFilesAdd = this.handleCardAdd

        /* SLOV-8878 убираем отображение ссылки на помощь
        item.bottomText =
          Number(this.appConfig.cardProblemEnabled) && !isBrokerCabinet ? (
            <>
              <div dangerouslySetInnerHTML={{ __html: item.bottomText }} />
              <span
                className='link link_pseudo registration-form__card-problems'
                onClick={this.handleCardProblem}
              >
                {intl.cantUploadCard}
              </span>
            </>
          ) : (
            item.bottomText
          )
         */
        break
      case 'inn':
        // item.bottomText = inn ? (
        //   <span>
        //     {intl.yourInnByFNS}
        //     {': '}
        //     <strong>{inn}</strong>{' '}
        //     <span className='link link_pseudo lowercase' onClick={this.handleCopyInn}>
        //       {intl.copyToField}
        //     </span>
        //   </span>
        // ) : (
        //   <span>
        //     {intl.innText}{' '}
        //     <a
        //       className='lowercase'
        //       href={innNalogRuHref}
        //       rel='noopener noreferrer'
        //       target='_blank'
        //     >
        //       {intl.here}
        //     </a>
        //   </span>
        // )
        item.bottomText = (
          <span>
            {intl.innText}{' '}
            <a
              className='lowercase'
              href={innNalogRuHref}
              rel='noopener noreferrer'
              target='_blank'
            >
              {intl.here}
            </a>
          </span>
        )
        item.disabled = true
        break
      case 'cardNumber':
        item = {
          ...item,
          autocomplete: 'cc-number'
        }
        break
      case 'passportCity':
      case 'city':
      case 'city1':
      case 'street':
      case 'street1':
      case 'registrationAddress':
      case 'residentAddress':
      case 'birthPlace':
        item.customClass = 'ym-record-keys'
        break
    }

    if (item.name === 'passportWho') item.loading = loadingItems.passportWho

    if (['passportCity', 'city', 'city1'].includes(item.systemName) && item.elementType === 'text')
      item.type = 'asyncSelect'

    if (item.name === 'passportCity') item.hidden = !passportCityVisible

    if (item.name === 'smsCode') {
      item.hidden = !smsCodeVisible
      item.hint = !item.loading && (
        <span className='link link_pseudo' onClick={this.handleRepeatSmsCode}>
          {intl.repeat}
        </span>
      )
      item.onKeyPress = this.memoizedHandleSmsCodeKeyPress(item)
    }

    if (['mobileNumber', 'email'].includes(item.name) && data.stage === STAGE_ONE) {
      const isDisabled = disabledItems[item.name]
      const mode = isDisabled ? CHANGE_MODE : CANCEL_MODE
      const handler = {
        mobileNumber: this.memoizedHandleHintClickMobilePhone(mode),
        email: this.memoizedHandleHintClickEmail(mode)
      }[item.name]
      const text = isDisabled ? intl.change : intl.cancel
      const classes = cn({ 'link link_pseudo': true, 'link--disabled': loadingItems.smsCode })
      item.hint = (
        <span className={classes} onClick={handler}>
          {text}
        </span>
      )
    }

    if (['street', 'street1'].includes(item.name)) item.hidden = hiddenItems[item.name]

    return item
  }

  renderCardCropper = () => {
    const { app: { loggedInToken: brokerToken } = {} } = this.props
    const { data, token, cardFile, cardPhotoId, isCardUploaded } = this.state
    const Cropper = this.cardCropperComponent
    return (
      <Cropper
        isCardUploaded={isCardUploaded}
        cardChecked={data.cardChecked}
        cardPhotoId={cardPhotoId}
        loanWay={data.loanWay}
        className={cn({
          'smart-control': true,
          'smart-control_invalid': !data.cardChecked
        })}
        file={cardFile}
        token={token}
        ref={this.cardCropperRef}
        brokerToken={brokerToken}
        onApply={this.handleCardApply}
        onSuccess={() => this.handleCardSuccess()}
        onFail={this.handleCardFail}
        onUploaded={this.handleCardUploaded}
        onRemove={this.closeCardCropper}
      />
    )
  }

  customizeCameraCropperFailureMessage = (err) => {
    const originalMessage = Array.isArray(err?.data)
      ? nl2br(Object.values(err?.data).join('\n'))
      : err?.message || intl.serverError

    const onClick = this.cardPhotoDropZoneRef?.current?.open || noop
    return (
      <div className='p-2'>
        <span dangerouslySetInnerHTML={{ __html: originalMessage }} />
        <div className='mt-1'>
          <span>{'Вы также можете выбрать уже имеющееся в '}</span>
          <label
            htmlFor='cardPhoto'
            className='d-inline link link_pseudo'
            style={{ color: 'teal', userSelect: 'none' }}
            onClick={onClick}
          >
            {'галерее изображение'}
          </label>
          {'.'}
        </div>
      </div>
    )
  }

  renderCardPhotoUploadItem = (props) => {
    const { token } = props
    const { cardFile } = this.state
    const { cardPhoto } = this.state.data
    const { settings = {}, abTest = {} } = this.props

    const ref = this.cardPhotoDropZoneRef
    function RegularCardUpload(otherProps) {
      return <CardPhotoUploadItem {...{ ...props, ...otherProps }} innerRef={ref} />
    }
    const isAlternativeCropperEnabled = get(
      settings,
      ['user_interface', 'isAlternativeCropperEnabled'],
      0
    )

    if (isBrokerCabinet || !isAlternativeCropperEnabled) return <RegularCardUpload />

    const shouldShowPreview = cardFile && cardPhoto
    const shouldShowBoth = abTest.uploadButtons === 'uploadButtonsBoth'
    const shouldShowSingle = abTest.uploadButtons === 'uploadButtonsSingle'

    return (
      <>
        {
          <RegularCardUpload
            className={cn({
              'd-none': !(shouldShowPreview || shouldShowBoth)
            })}
          />
        }
        {!shouldShowPreview && (
          <CameraCropperContainer
            onSuccess={() => this.handleCardSuccess(false)}
            customizeErrorMessage={this.customizeCameraCropperFailureMessage}
            className='smart-control__camera-cropper d-block my-3 mx-auto'
            token={token}
            fallBackComponent={shouldShowSingle ? RegularCardUpload : returnNull}
            variant={shouldShowSingle ? 'standalone' : 'embedded'}
          />
        )}
      </>
    )
  }

  renderCardPhotoItem = (props) => {
    const { data, cardFile, shouldShowCardCropper } = this.state
    if (!cardFile || data.cardChecked) return this.renderCardPhotoUploadItem(props)
    if (shouldShowCardCropper) return this.renderCardCropper()
    return this.renderCardPhotoUploadItem(props)
  }

  buildAsyncSelectedOption = (itemName) => {
    const { data = {} } = this.state
    const label = data[itemName]
    const aoguid = data[`${itemName}Guid`]
    if (!label || !aoguid) return null
    return { label, aoguid }
  }

  preventWhiteSpaceInput = (evt) => {
    if (evt && evt.keyCode === KEY_CODES.space) evt.preventDefault()
  }

  handleRecaptchaChange = (name) => (value) => {
    this.setState((state) => ({
      ...state,
      data: { ...state.data, [name]: isNull(value) ? '' : value },
      errors: { ...state.errors, [name]: '' }
    }))
  }

  renderRecaptcha = (item) => {
    const { errors } = this.state
    const {
      userDevice: { isMobile }
    } = this.props

    return (
      <FormItem
        key={item.name}
        {...item}
        error={errors[item.name]}
        className='d-flex-centered flex-column'
      >
        <ReCAPTCHA
          id={item.name}
          ref={this.recaptchaRef}
          hl='ru'
          className={cn({ 'mt-2 mx-auto': true })}
          sitekey={appConfig.recaptchaKey}
          size={isMobile ? 'compact' : 'normal'}
          onChange={this.handleRecaptchaChange(item.name)}
        />
      </FormItem>
    )
  }

  renderItem = (sourceItem) => {
    if (this.checkDepends(sourceItem)) return null
    const {
      token,
      errors,
      step,
      shouldShowCardCropper,
      data: { recaptchaIsNotRequired = false }
    } = this.state
    const { isCaptchaEnabled } = this.props
    const item = this.appendItem(sourceItem)
    if (item.hidden) return null
    if (item.type === 'html') {
      if (['passAndSelfiNoty'].includes(item.name)) return <HightAmountHint text={item.label} />

      return (
        <FormItemPure key={item.name}>
          <div dangerouslySetInnerHTML={{ __html: item.label }} />
        </FormItemPure>
      )
    }

    if (item.name === 'tkbLifeChecked' && !this.isMainRegistration) return null
    if (isBrokerCabinet && item.name === 'tkbLifeChecked' && this.isMainRegistration) {
      const {
        loanFormState: {
          data: { creditType }
        }
      } = this.props
      if (creditType !== CONSUMER_CREDIT_MONTH) return null
    }

    const isCardPhoto = item.name === 'cardPhoto'
    let onChangeHandler
    if (item?.onChange) onChangeHandler = item.onChange
    else onChangeHandler = this.handleControlChange(item)

    if (item.name === 'recaptcha') {
      if (recaptchaIsNotRequired) return null
      return isCaptchaEnabled ? this.renderRecaptcha(item) : null
    }

    const smartControlProps = {
      ...item,
      token,
      isSearchable: step !== STAGE_THREE && step !== STAGE_TWO,
      onFocus: this.handleControlFocus,
      onBlur: this.handleControlBlur,
      onChange: isCardPhoto ? noop : onChangeHandler,
      onRemove: this.handleRemove,
      setSuggestions: this.setSuggestions,
      onAsyncOptionChange: this.handleAsyncOptionChange,
      asyncInputValue: this.state[`async${item.name}`],
      onAsyncInputChange: this.handleAsyncInputChange,
      selectedOption: this.buildAsyncSelectedOption(item.name)
    }

    // Проп добавляется для форсирования рендеринга кроппера, т.к. остальные
    // пропсы не меняются и возвращается мемоизированный результат
    if (isCardPhoto) smartControlProps.renderTrigger = shouldShowCardCropper

    const renderProp = isCardPhoto
      ? this.memoizedRenderCardPhotoItem(smartControlProps)
      : this.memoizedRenderSmartControl(smartControlProps)

    if (
      [
        'house',
        'house1',
        'building',
        'building1',
        'structure',
        'structure1',
        'apartment',
        'apartment1'
      ].includes(item.name)
    )
      smartControlProps.onKeyPress = this.preventWhiteSpaceInput

    if (['asyncSelect'].includes(item.type)) {
      const value = this.state.data[item.name]
      const aoguid = this.state.data[`${item.name}Guid`]
      if (Boolean(value) && Boolean(aoguid))
        smartControlProps.selectedValue = { aoguid, label: value, value }
      else smartControlProps.selectedValue = null
    }

    if (['privateAgreement', 'rules', 'personalAgreementMarketing'].includes(item.name)) {
      this.refreshHomePhone()
      return (
        <PrivateAgreementLinks
          handleModalLinkClick={this.handleModalLinkClick}
          key={item.name}
          error={errors[item.name]}
          {...smartControlProps}
        />
      )
    }

    return (
      <FormItemPure key={item.name} {...item} error={errors[item.name]} renderProp={renderProp} />
    )
  }

  repeatConfirmEmailMessage = () => (
    <div style={{ 'text-align': 'justify' }}>
      {'На адрес '}
      <b>{this.state.data.email}</b>
      {' повторно отправлено сообщение. Если письмо не пришло, проверьте папку Спам'}
    </div>
  )

  handleModalClose = () => this.setState({ modalMessage: null, modalDocument: null })

  renderModalMessage = (message) => (
    <Modal onClose={this.handleModalClose}>
      <div className='align-center'>
        <div dangerouslySetInnerHTML={{ __html: message }} />
        <p>
          <Button size='s' onClick={this.handleModalClose}>
            {intl.close}
          </Button>
        </p>
      </div>
    </Modal>
  )

  isAgreedWithRules = () => {
    const { step } = this.state
    if (step === 4) return !this.state.data['rules']
    return false
  }

  render() {
    const {
      form,
      step,
      loading,
      errors,
      smsCodeVisible,
      modalDocument,
      modalMessage,
      disableReason,
      loadingTimer,
      initializing: isInitializing
    } = this.state
    const className = cn(
      {
        form: true,
        'registration-form': true
      },
      this.props.className
    )
    if (isUndefined(form) || isInitializing)
      return <div className='registration-form__wait'>{intl.waitLoading}</div>
    if (disableReason) return <div dangerouslySetInnerHTML={{ __html: disableReason }} />
    const stepsCount = form.steps.length
    const lines = get(form, ['steps', step - 1, 'lines'], [])
    return (
      <>
        <form
          className={className}
          ref={this.wrapper}
          noValidate
          onSubmit={this.handleSubmit}
          autoComplete='nope'
        >
          <Stepper stepsCount={stepsCount} activeStep={step} />
          <div className='registration-form__step'>
            {lines.map(({ items, lineNumber }) => (
              <div className='form__line' key={lineNumber}>
                {items.map(this.renderItem)}
              </div>
            ))}
          </div>
          {step === stepsCount && <LoanResult />}
          <div className='form__bottom'>
            {errors.common && <div className='form__error'>{errors.common}</div>}
            {!(step === STAGE_ONE && smsCodeVisible) && (
              <Button
                type={'submit'}
                size='xl'
                fluid
                loading={loading}
                loadingTimer={loadingTimer}
                disabled={loading || this.isAgreedWithRules()}
                data-qa='registrationForm-continue'
              >
                {intl.continue}
              </Button>
            )}
            {step > STAGE_ONE && (
              <div className='registration-form__backward'>
                <span className='link' onClick={this.handlePrevStep}>
                  {intl.returnToPreviousStep}
                </span>
              </div>
            )}
          </div>
          {modalDocument && <Modal {...modalDocument} onClose={this.handleModalClose} />}
          {modalMessage && this.renderModalMessage(modalMessage)}
        </form>
        <ModalsContainer />
      </>
    )
  }
}

RegistrationForm.propTypes = {
  className: PropTypes.string,
  loanFormState: PropTypes.object,
  loanConditions: PropTypes.object,
  dispatch: PropTypes.func,
  onFinish: PropTypes.func,
  app: PropTypes.object,
  dispatchUpdatePartnerParameters: PropTypes.func
}

const mapStateToProps = (state) => ({
  loanFormState: state.loanFormState,
  loanConditions: state.loanConditions.data,
  app: state.app,
  settings: state.settings.data,
  abTest: state.abTest?.data ?? {},
  isCaptchaEnabled: state.settings.data.googleCaptcha?.isEnabled
})

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateStage: ({ stage }) => dispatch(updateStage({ stage })),
  dispatchRestoreLoanFormState: (loanData, requestParams) =>
    dispatch(restoreLoanFormState({ loanData, requestParams })),
  logAbSettings: (token) => dispatch(logAbSettings(token)),
  openModal: (modalType, modalProps) => dispatch(openModal(modalType, modalProps)),
  dispatchAppendPartnerParams: ({ partnerParameters }) =>
    dispatch(appendPartnerParameters({ partnerParameters })),
  dispatchTkbInsuranceAgreementValue: (value) => dispatch(setTkbInsuranceAgreementValue(value)),
  dispatchUpdatePartnerParameters: (value) =>
    dispatch(updatePartnerParameters({ partnerParameters: value }))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWindowSize(withErrorLogger(RegistrationForm)))
