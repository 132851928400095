import cn from 'classnames'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'

import config from '#config'
import { ROUTES } from '#constants/common'

import CopyText from '../CopyText/CopyText'

const PERSONAL_TOKEN_KEY = 'clientToken'
const MAIN_TOKEN_KEY = 'token'

/**
 * Компонент-костыль для пользователей IPhone / Ipad, т.к. на iOS камера работает только в Safari,
 * в остальных браузерах для iOS не имплементировано API MediaDevices.getUserMedia.
 * Генерирует ссылку на ту же страницу на которой находится пользователь в зависимости от билда.
 * Для лендинга формирует continue link.
 * Для системной странице - такую же ссылку как в адресной строке бразуера.
 * Для ЛК ссылку для авторизации через спец. странице loginWithToken
 */
const SafariFallBackStateComponent = (props) => {
  const { className, style, onClose } = props

  const [hyperLink, setHyperLink] = useState('')
  const [cookies] = useCookies([MAIN_TOKEN_KEY, PERSONAL_TOKEN_KEY])

  const build = process.env.__BUILD__
  const systemChannel = useMemo(() => {
    if (build !== 'system') return ''
    const searchParams = new URLSearchParams(window.location.search)
    return searchParams.get('channel') || ''
  }, [build])

  useEffect(() => {
    if (build === 'system') {
      setHyperLink(window.location.href)
      return
    }
    if (build === 'main') {
      const url = new URL(ROUTES.register, config.host)
      url.searchParams.append('continue', cookies[MAIN_TOKEN_KEY])
      setHyperLink(url.href)
    }
    if (build === 'personal') {
      const url = new URL(ROUTES.logInWithToken, config.lkHost)
      url.searchParams.append('token', cookies[PERSONAL_TOKEN_KEY])
      url.searchParams.append('role', 'client')
      setHyperLink(url.href)
    }
  }, [build, cookies])

  const classes = cn({ 'px-3 text-start': true }, className)
  return (
    <div className={classes} style={style}>
      <p>
        {'Похоже что Вы используете устройство компании Apple и браузер отличный от '}
        <strong>{'Safari'}</strong>
        {'.'}
      </p>
      <p>
        {'К сожалению для пользователей '}
        <strong>{'IPhone / IPad'}</strong>
        {' данный функционал доступен только в бразуере '}
        <strong>{'Safari'}</strong>
        {'.'}
      </p>
      {hyperLink && (
        <>
          <p>
            {'Пожалуйста, скопируйте ссылку ниже, откройте браузер '}
            <strong>{'Safari'}</strong>
            {' и вставьте ссылку в адресную строку, после чего Вы сможете продолжить.'}
          </p>
          <CopyText text={hyperLink} legend='Ссылка' className='mx-auto my-3' />
        </>
      )}
      <p>
        {'Вы также можете воспользоваться функционалом '}
        <strong>{'"Выбрать файл"'}</strong>
        {['personal', 'main'].includes(build) && '.'}
        {systemChannel === 'main' && ' на странице регистрации.'}
        {systemChannel === 'personal' && ' на форме добавления реквизитов в личном кабинете.'}
      </p>
      {onClose && (
        <div className='d-flex-centered'>
          <span className='link link_pseudo' onClick={onClose}>
            {'Скрыть'}
          </span>
        </div>
      )}
    </div>
  )
}

SafariFallBackStateComponent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClose: PropTypes.func
}

export default SafariFallBackStateComponent
