import PropTypes from 'prop-types'

import Modal from '../../../presentational/Modal'
import Action from '../../DialogComponents/Action'
import BaseModal from '../../DialogComponents/BaseModal'

export default class Main extends BaseModal {
  static propTypes = {
    closeModal: PropTypes.func,
    actions: PropTypes.array
  }

  static defaultProps = {
    actions: []
  }

  render() {
    const { content, actions, ...rest } = this.props
    return (
      <Modal {...rest} closeModal={this.onCloseModal}>
        <div className='dialog'>
          <div className='dialog__container'>{content}</div>
          {actions.length > 0 && (
            <div className='dialog__actions'>
              {
                // возможные действия на уровне главного модального окна
                actions.map((action, inx) => (
                  <Action key={inx} {...action} />
                ))
              }
            </div>
          )}
        </div>
      </Modal>
    )
  }
}
