import { TModelItem } from '#components/Form/Form'
import intl from '#intl'

export const model: TModelItem[] = [
  {
    line: 1,
    name: 'creditType',
    type: 'button-group',
    placeholder: 'Микрозайм/Потребительский займ',
    required: true,
    options: [
      {
        value: 'MicroCredit',
        text: 'Микрозайм',
        enabled: null,
        disableReason: null
      },
      {
        value: 'ConsumerCredit',
        text: 'Потребительский займ',
        enabled: null,
        disableReason: null
      },
      {
        value: 'ConsumerCreditMonth',
        text: 'Займ (до 5 лет, до 500 000 р.)',
        enabled: null,
        disableReason: null
      },
      {
        value: 'ConsumerCreditMonthShort',
        text: 'Займ (до 6 мес., до 50 000 р.)',
        enabled: null,
        disableReason: null
      }
    ]
  },
  {
    line: 2,
    name: 'amount',
    type: 'range',
    label: intl.loanAmount,
    amountLimitWithoutDocuments: 15000,
    message: intl.passportAndSelfieWillBeRequired,
    warning: true
  },
  {
    line: 2,
    name: 'term',
    type: 'range',
    label: intl.loanTerm
  },
  {
    line: 3,
    name: 'result'
  },
  {
    line: 4,
    name: 'promoCode',
    type: 'text',
    label: intl.promoCode,
    required: true,
    emptyMessage: intl.fieldEmpty
  }
]
