import { head } from 'lodash'
import PropTypes from 'prop-types'
import { Component } from 'react'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'

import { formatDate, parseDate } from '#services/datetime'

import intl from '../../intl/ru.json'
import { getHexColorFromString } from '../../services/helper'

class PartnerStatCharts extends Component {
  constructor(props) {
    super(props)

    this.mapXItemKeysToLabels = {
      grouper: intl.date
    }

    this.mapYItemKeysToLabels = {
      issueCountInCondition: intl.issueCountInCondition,
      requestCountNotBadRefusal: intl.requestCountNotBadRefusal
    }

    this.xItemKey = head(Object.keys(this.mapXItemKeysToLabels))

    this.yItemKeys = Object.keys(this.mapYItemKeysToLabels)
  }

  renderDiagramByKey = (key) => {
    const color = getHexColorFromString(key)
    return (
      <Line
        key={key}
        type='monotone'
        dataKey={key}
        stroke={color}
        name={this.mapYItemKeysToLabels[key]}
      />
    )
  }

  formatXTicks = (tick) => formatDate(parseDate(tick), 'dd MMM yyyy')

  render() {
    const { statisticsList } = this.props
    return (
      <ResponsiveContainer width='100%' height={400}>
        <LineChart data={statisticsList} margin={{ top: 30 }}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            tick={{ angle: -90, dy: 32, fontSize: 12 }}
            height={100}
            margin={{ bottom: 30 }}
            tickFormatter={this.formatXTicks}
            dataKey='grouper'
          />
          <YAxis />
          <Tooltip />
          <Legend verticalAlign='top' height={36} />
          {this.yItemKeys.map((key) => this.renderDiagramByKey(key))}
        </LineChart>
      </ResponsiveContainer>
    )
  }
}

PartnerStatCharts.propTypes = {
  statisticsList: PropTypes.array
}

export default PartnerStatCharts
