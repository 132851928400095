import intl from '#intl'

export const ABSOLUT_PROCESSING_PERSONAL_DATA = 'absolutProcessingPersonalDataChecked'
export const ABSOLUT_INSURANCE_RELATED = ['insuranceCardChecked', 'insuranceLifeChecked']
export const DOCTOR_NEARBY_PROCESSING_PERSONAL_DATA = 'doctorNearbyProcessingPersonalDataChecked'
export const DOCTOR_NEARBY_INSURANCE_RELATED = ['serviceDoctorNearbyChecked', 'doctorNearbyChecked']
export const D2_PROCESSING_PERSONAL_DATA = 'd2ProcessingPersonalDataChecked'
export const D2_CONSENT_INSURANCE_RELATED = ['d2InsuranceCardsChecked', 'd2InsuranceChecked']
export const SCORISTA_PROCESSING_PERSONAL_DATA = 'scoristaProcessingPersonalDataChecked'
export const SCORISTA_PROCESSING_PERSONAL_DATA_RELATED = ['serviceRiskChecked']
export const CHECK_VALUES = {
  agreementChecked: {
    set: 'agreement-confirm-set'
  },
  assignmentOfRightsChecked: {
    set: 'cession-set'
  },
  personDataAgreementChecked: {
    set: 'personal-agreement-set'
  },
  nbkiAgreementChecked: {
    set: 'bki-agreement-set'
  },
  insuranceLifeChecked: {
    set: 'insurance-accident-set',
    off: 'insurance-accident-off'
  },
  insuranceCardChecked: {
    set: 'insurance-card-set',
    off: 'insurance-card-off'
  },
  serviceDoctorNearbyChecked: {
    set: 'insurance-health-set',
    off: 'insurance-health-off'
  },
  serviceRiskChecked: {
    set: 'scoring-confirm-set',
    off: 'scoring-confirm-off'
  }
}
export const lightModel = [
  {
    line: 1,
    name: 'mobileNumber',
    type: 'tel',
    disabled: true,
    label: intl.mobileNumber,
    mask: '+7 (999) 999-99-99',
    required: true,
    emptyMessage: intl.fieldEmpty,
    validationRule: '^\\+7 \\(9[0-9]{2}\\) [0-9]{3}\\-[0-9]{2}\\-[0-9]{2}|79[0-9]{9}$',
    validationMessageError: intl.fieldInvalid
  },
  {
    line: 1,
    name: 'smsCode',
    type: 'number',
    label: intl.smsCode,
    placeholder: intl.smsCodePlaceholder,
    mask: '999999',
    required: true,
    emptyMessage: intl.fieldEmpty,
    validationRule: '[0-9]{6}',
    validationMessageError: intl.fieldInvalid
  },
  {
    line: 2,
    name: 'inn',
    type: 'number',
    label: intl.yourInn,
    placeholder: intl.fieldEmpty,
    mask: '999999999999',
    required: true,
    emptyMessage: intl.fieldEmpty,
    validationRule: '[0-9]{12}',
    validationMessageError: intl.fieldInvalid
  },
  {
    line: 3,
    name: 'totalIncome',
    type: 'text',
    label: intl.totalIncome,
    validationRule: '^[1-9][0-9]*$',
    validationMessageError: intl.fieldInvalid,
    emptyMessage: intl.fieldEmpty,
    required: true,
    noWhiteSpaces: true
  },
  {
    line: 4,
    label:
      '<p class="confirm-loan-form__desc">Подтверждаю своё ознакомление и согласие с документами</p>',
    type: 'html',
    name: 'headerForm',
    required: false
  },
  {
    line: 5,
    label: '',
    type: 'checkbox',
    name: 'agreementChecked',
    required: true
  },
  {
    line: 6,
    label: '',
    type: 'checkbox',
    name: 'personDataAgreementChecked',
    required: true,
    child: [
      {
        line: 1,
        label: 'ПАО «Вымпелком»',
        type: 'checkbox',
        name: 'veonProcessingPersonalDataChecked',
        required: false
      },
      {
        line: 2,
        label: 'ПАО «Мегафон»',
        type: 'checkbox',
        name: 'megafonProcessingPersonalDataChecked',
        required: false
      },
      {
        line: 3,
        label: 'ПАО «МТС»',
        type: 'checkbox',
        name: 'mtsProcessingPersonalDataChecked',
        required: false
      },
      {
        line: 4,
        label: 'ООО «Т2 Мобайл»',
        type: 'checkbox',
        name: 't2ProcessingPersonalDataChecked',
        required: false
      },
      {
        line: 5,
        label: 'ТКБ БАНК ПАО',
        type: 'checkbox',
        name: 'tkbProcessingPersonalDataChecked',
        required: false
      },
      {
        line: 6,
        label: 'ООО «Абсолют Страхование»',
        type: 'checkbox',
        name: ABSOLUT_PROCESSING_PERSONAL_DATA,
        required: false
      },
      {
        line: 7,
        label: 'АО «Д2 Страхование»',
        type: 'checkbox',
        name: D2_PROCESSING_PERSONAL_DATA,
        required: false
      },
      {
        line: 8,
        label: 'ООО «Финкарта»',
        type: 'checkbox',
        name: 'fincardProcessingPersonalDataChecked',
        required: false
      },
      {
        line: 9,
        label: 'ООО «МК Доктор рядом»',
        type: 'checkbox',
        name: DOCTOR_NEARBY_PROCESSING_PERSONAL_DATA,
        required: false
      },
      {
        line: 10,
        label: 'ООО «Скориста»',
        type: 'checkbox',
        name: SCORISTA_PROCESSING_PERSONAL_DATA,
        required: false
      },
      {
        line: 11,
        label: 'ООО СК «Согласие-Вита»',
        type: 'checkbox',
        name: 'vitaProcessingPersonalDataChecked',
        required: false
      }
    ]
  },
  {
    line: 7,
    label: '',
    type: 'checkbox',
    name: 'nbkiAgreementChecked',
    required: true
  }
]

const fullModel = lightModel.concat([
  {
    line: 100,
    label: '',
    type: 'checkbox',
    name: 'insuranceLifeChecked'
  },
  {
    line: 101,
    label: '',
    type: 'checkbox',
    name: 'insuranceCardChecked'
  },
  {
    line: 102,
    label: '',
    type: 'checkbox',
    name: 'serviceDoctorNearbyChecked'
  },
  {
    line: 103,
    label: '',
    type: 'checkbox',
    name: 'serviceRiskChecked'
  },
  {
    line: 104,
    label: '',
    type: 'checkbox',
    name: 'd2InsuranceChecked'
  },
  {
    line: 10,
    label: '',
    type: 'checkbox',
    name: 'd2InsuranceCardsChecked'
  },
  {
    line: 11,
    label: '',
    type: 'checkbox',
    name: 'assignmentOfRightsChecked'
  }
])
export default fullModel
