const structure = {
  bids: {
    savedData: {}
  },
  accounts: {
    savedData: {}
  },
  statistics: {
    savedData: {}
  },
  loans: {
    savedData: {}
  },
  leads: {
    savedData: {}
  }
}

export default structure
