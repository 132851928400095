import { FC, useCallback } from 'react'

type TTabProps = {
  activeTab: string
  label: string
  onClick: (label: string) => void
}
const Tab: FC<TTabProps> = (props) => {
  const { label, onClick, activeTab } = props
  let className = 'tab-list-item'
  if (activeTab === label) className += ' tab-list-active'

  const handleClick = useCallback(() => {
    onClick(label)
  }, [label, onClick])

  return (
    <li className={className} onClick={handleClick}>
      {label}
    </li>
  )
}
export default Tab
