import cn from 'classnames'
import { CSSProperties, FC, memo } from 'react'

import appConfig from '#config'
import { ROUTES } from '#src/constants/common'

interface IProps {
  className?: string
  style?: CSSProperties
}

const LinkToSupport: FC<IProps> = (props) => {
  const { className = '', style = {} } = props
  const classes = cn(
    {
      'text-secondary text-center mx-auto': true
    },
    className
  )
  return (
    <p className={classes} style={style}>
      {'Если возникли вопросы, обратитесь в '}
      <a href={new URL(ROUTES.contact, appConfig.host).href}>{'службу поддержки'}</a>
      {'.'}
    </p>
  )
}

export default memo(LinkToSupport)
