import Button from '@material-ui/core/Button'
import MobileStepper from '@material-ui/core/MobileStepper'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { has, noop } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import intl from '#intl'
import { brokerApi } from '#modules/api'
import Spinner from '#src/components/Spinner'
import { formatDate } from '#src/services/datetime'

import ButtonWithConfirmation from './ButtonWithConfirmation'
require('es6-map/implement')

export type TIMage = {
  id: number
  hash: string
  modifyTs: string
  filename: string
}

export interface ICarousel {
  imageList: TIMage[]
  brokerToken: string
  personToken: string
  onSelect: (f: Blob) => void
  onDelete: (id: number) => void
}

export interface ICardFile {
  src: string
  binary: Blob
  modifyTs: string
  filename: string
  id: number
}

const SUCCESS_RESPONSE_CODE = 0

const READY_STATE = 'readyState'
const IMAGE_LOADING_STATE = 'imageLoadingState'
const IMAGE_DELETING_STATE = 'imageDeletingState'

const Carousel: FC<ICarousel> = (props) => {
  const {
    imageList = [],
    brokerToken = '',
    personToken = '',
    onSelect = noop,
    onDelete = noop
  } = props

  const [activeStep, setActiveStep] = useState<number>(0)
  const [currentFile, setCurrentFile] = useState<ICardFile>({} as ICardFile)
  const [state, setState] = useState<string>(READY_STATE)
  const [error, setError] = useState<string>('')

  const filesCache = useMemo(() => new Map() as Map<number, ICardFile>, [])
  const maxSteps = useMemo(() => imageList.length, [imageList])

  const handleNext = useCallback(() => setActiveStep((prevActiveStep) => prevActiveStep + 1), [])
  const handleBack = useCallback(() => setActiveStep((prevActiveStep) => prevActiveStep - 1), [])

  const handleStepChange = useCallback(
    async (step: number) => {
      const { id, hash, modifyTs: datetime, filename } = imageList[step]
      if (filesCache.has(id)) return setCurrentFile(filesCache.get(id) as ICardFile)
      setState(IMAGE_LOADING_STATE)
      try {
        const binary = await brokerApi.getCardPhotoFile({
          hash,
          brokerToken,
          personToken
        })
        binary.name = filename
        const src = window.URL.createObjectURL(binary)
        const modifyTs = formatDate(datetime, 'dd MMM yyyy hh:mm')
        const cardFile = { binary, src, modifyTs, filename, id }
        filesCache.set(id, cardFile)
        setCurrentFile(cardFile)
      } catch (err) {
        setError((err as Error)?.message || intl.serverError)
      } finally {
        setState(READY_STATE)
      }
    },
    [brokerToken, filesCache, imageList, personToken]
  )

  const handleSelect = useCallback(() => {
    onSelect(currentFile?.binary)
  }, [currentFile, onSelect])

  const handleDelete = useCallback(async () => {
    const { id } = currentFile
    try {
      const { code, message } = await brokerApi.deleteCardPhotoForBroker({
        personToken,
        brokerToken,
        id
      })
      if (code !== SUCCESS_RESPONSE_CODE) return setError(message)
      onDelete(id)
    } catch (err) {
      setError((err as Error)?.message || intl.serverError)
    } finally {
      setState(READY_STATE)
    }
  }, [brokerToken, currentFile, onDelete, personToken])

  useEffect(() => {
    if (has(imageList, activeStep)) return
    if (has(imageList, activeStep + 1)) return setActiveStep(activeStep + 1)
    if (has(imageList, activeStep - 1)) return setActiveStep(activeStep - 1)
    return setActiveStep(0)
  }, [activeStep, imageList])

  useEffect(() => {
    void handleStepChange(activeStep)
  }, [activeStep, handleStepChange, filesCache, imageList])

  return (
    <div className='d-flex-centered flex-column'>
      <div
        className='d-flex-centered'
        style={{ width: 640, height: 480, maxWidth: 640, maxHeight: 480 }}
      >
        {state === IMAGE_LOADING_STATE && (
          <Spinner className='spinner-centered text-primary spinner-lg' />
        )}
        {state === READY_STATE && (
          <img src={currentFile?.src} className='mw-100 mh-100' alt='Фото не доступно' />
        )}
      </div>
      <MobileStepper
        steps={maxSteps}
        className='w-100 px-0 mt-1 bg-transparent'
        position='static'
        variant='dots'
        activeStep={activeStep}
        nextButton={
          <Button
            variant='contained'
            color='primary'
            size='small'
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {'След.'}
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button
            variant='contained'
            color='primary'
            size='small'
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            <KeyboardArrowLeft />
            {'Пред.'}
          </Button>
        }
      />
      <div className='d-flex justify-content-between align-items-center w-100'>
        <p>
          <span className='text-secondary fw-bold'>{'Имя файла: '}</span>
          <span className='fw-bold font-size-small'>{currentFile?.filename}</span>
        </p>
        <p>
          <span className='text-secondary fw-bold'>{'Добавлено: '}</span>
          <span className='fw-bold'>{currentFile?.modifyTs}</span>
        </p>
      </div>
      {error && <p className='text-danger'>{error}</p>}
      <Button
        fullWidth
        variant='contained'
        className='bg-primary text-white mt-3'
        disabled={state === IMAGE_DELETING_STATE}
        onClick={handleSelect}
      >
        {'Обрезать'}
      </Button>
      <ButtonWithConfirmation
        fullWidth
        variant='contained'
        confirmationText={'Вы уверены что хоите удалить этот файл?'}
        className='bg-danger text-white mt-3'
        disabled={state === IMAGE_DELETING_STATE}
        onClick={handleDelete}
      >
        {'Удалить фото'}
      </ButtonWithConfirmation>
    </div>
  )
}

export default Carousel
