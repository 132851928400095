import './assets/style.scss'

import { Dialog, DialogContent, DialogContentText, withStyles } from '@material-ui/core'
import { FC } from 'react'

type TMarketNotificationProps = {
  closeModal: () => void
  content: JSX.Element
}

const WithDialog = withStyles({
  paper: {
    top: '20px',
    margin: 0,
    width: '100%'
  }
})(Dialog)

const WithDialogContent = withStyles({
  root: {
    padding: 0,
    '&:first-child': {
      paddingTop: 0
    }
  },
  dividers: {
    padding: 0
  }
})(DialogContent)

const MarketNotification: FC<TMarketNotificationProps> = (props) => {
  const { content } = props
  const isOpen = true
  const scroll = 'paper'

  const ContentComponent = (): JSX.Element => content

  return (
    <WithDialog
      open={isOpen}
      scroll={scroll}
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      fullWidth={true}
      maxWidth='md'
    >
      <WithDialogContent className='modal__notification__container' dividers={scroll === 'paper'}>
        <DialogContentText component={ContentComponent} />
      </WithDialogContent>
    </WithDialog>
  )
}

export default MarketNotification
