import { AxiosRequestConfig, AxiosResponse } from 'axios'

import appConfig from '#config'
import { HttpClient } from '#src/modules/api/httpClient'

class DadataApi extends HttpClient {
  public constructor(config: AxiosRequestConfig) {
    super(config)
    this._initializeRequestInterceptor()
    this.initializeResponseInterceptor()
  }

  private readonly initializeResponseInterceptor = (): void => {
    this.instance.interceptors.response.use(this.dadataResponseSuccess, this.onResponseError)
  }

  private readonly dadataResponseSuccess = (response: AxiosResponse): AxiosResponse => response
}

const dadata = new DadataApi({
  baseURL: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/',
  headers: {
    Authorization: `Token ${appConfig.dadataToken}`,
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

export default dadata.instance
