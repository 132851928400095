import { debounce, last } from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router'

import { fetchManualPartnerData } from '#reducers/partnerData/effects'
import Table from '#src/components/Table'

import PartnerBidsFilter from './PartnerBidsFilter'

const DEBOUNCE_TIME = 750

const PartnerContent = (props) => {
  const {
    app: { loggedInToken },
    loading,
    title,
    filters,
    columns,
    className,
    data,
    chart,
    onFilterChange,
    SubComponent,
    errorMessage,
    fullWidth = false
  } = props

  const location = useLocation()

  const localStorageKey = useMemo(() => {
    const { pathname } = location
    const lastPart = last(pathname.split('/'))
    return `tableSizes_${lastPart}`
  }, [location])

  const handleFilterChange = useCallback(
    (params) => {
      onFilterChange(params)
    },
    [onFilterChange]
  )

  const onResize = useMemo(
    () =>
      debounce((newResized) => {
        window.localStorage.setItem(localStorageKey, JSON.stringify(newResized))
      }, DEBOUNCE_TIME),
    [localStorageKey]
  )

  const renderSubComponent = useCallback(
    (row) => <SubComponent loggedInToken={loggedInToken} row={row} part={className} />,
    [className, loggedInToken]
  )

  const columnWidths = useMemo(() => {
    const savedData = window.localStorage.getItem(localStorageKey)
    return savedData ? JSON.parse(savedData) : []
  }, [localStorageKey])

  const textNoWrapStyle = useCallback(
    () => ({
      style: {
        whiteSpace: 'nowrap'
      }
    }),
    []
  )

  return (
    <div className={'partner-page__' + className}>
      <h2>{title}</h2>
      {filters && (
        <PartnerBidsFilter
          className='form__filters'
          partnerPage={className}
          filters={filters}
          onSubmit={handleFilterChange}
          errorMessage={errorMessage}
        />
      )}
      {columns && Array.isArray(data) && (
        <Table
          fitContainer={fullWidth}
          data={data}
          columns={columns}
          loading={loading}
          borders
          dense
          striped
          hoverable
          getBodyCellProps={textNoWrapStyle}
          getHeaderCellProps={textNoWrapStyle}
          renderRowSubComponent={renderSubComponent}
          pagination={{
            itemsPerPage: 20
          }}
          onResize={onResize}
          initialState={{
            columnResizing: { columnWidths }
          }}
          autoResetPage={false}
          overideStyles={{
            tHeadRow: {
              zIndex: 1
            }
          }}
        />
      )}
      {chart}
    </div>
  )
}

const mapStateTopProps = (state) => ({
  app: state.app
})

const mapDispatchToProps = (dispatch) => ({
  fetchData: (rules) => dispatch(fetchManualPartnerData(rules))
})

PartnerContent.displayName = 'PartnerContent'

PartnerContent.propTypes = {
  onFilterChange: PropTypes.func,
  title: PropTypes.string,
  filters: PropTypes.array,
  columns: PropTypes.array,
  tabs: PropTypes.array,
  className: PropTypes.string,
  SubComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.elementType]),
  data: PropTypes.array,
  person: PropTypes.object,
  chart: PropTypes.object,
  loading: PropTypes.bool,
  isMobile: PropTypes.bool,
  app: PropTypes.object,
  part: PropTypes.string,
  errorMessage: PropTypes.string,
  fullWidth: PropTypes.bool
}

export default connect(mapStateTopProps, mapDispatchToProps)(PartnerContent)
