import AxiosStatic from 'axios'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'

import config from '#config'
import intl from '#intl'
import { mainSiteApi, personalApi } from '#modules/api'
import { returnNull } from '#services/helper'
import { CancelRequestError } from '#src/modules/api/exceptions'

import CameraCropperComponent, {
  CROPPER_INACTIVE_STATE,
  QR_CODE_ACTIVE_STATE
} from '../CameraCropperComponent/CameraCropperComponent'

export const VARIANTS = {
  standalone: 'standalone',
  embedded: 'embedded'
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const CameraCropperContainer = (props) => {
  const {
    variant,
    component,
    fallBackComponent,
    className,
    style,
    onSuccess,
    customizeErrorMessage,
    token: tokenFromProps
  } = props

  const eventSource = useRef(null)
  const cancelFnRef = useRef(noop)

  const searchParams = useMemo(() => new URLSearchParams(window.location.search), [])

  const sseKey = useMemo(() => searchParams.get('key') || uuidv4(), [searchParams])

  const token = useMemo(() => searchParams.get('token') || tokenFromProps, [
    searchParams,
    tokenFromProps
  ])

  const channel = useMemo(() => searchParams.get('channel') || process.env.__BUILD__, [
    searchParams
  ])

  const cancelPendingRequest = useCallback(() => {
    try {
      cancelFnRef.current()
    } catch (e) {
      noop(e)
    }
  }, [])

  const qrCodeData = useMemo(() => {
    if (!token || !sseKey) return null
    const url = new URL('system/cardCropper/', config.host)
    url.searchParams.append('token', token)
    url.searchParams.append('key', sseKey)
    url.searchParams.append('channel', process.env.__BUILD__)
    return url.href
  }, [sseKey, token])

  useEffect(() => () => {
    cancelPendingRequest()
    if (eventSource.current && eventSource.current.readyState !== eventSource.current.CLOSED)
      eventSource.current.close()
  })

  const handleReceiveSse = useCallback(
    (data) => {
      const { code, key } = data
      if (!key) return
      if (code !== 0) return
      onSuccess()
      eventSource.current.close()
    },
    [onSuccess]
  )

  const onCropperComponentStateChange = useCallback(
    (_, newState) => {
      if (newState === CROPPER_INACTIVE_STATE) return cancelPendingRequest()
      if (newState !== QR_CODE_ACTIVE_STATE) return
      if (!sseKey || !window.EventSource || eventSource.current) return
      eventSource.current = new window.EventSource(new URL('/sse/openSocket', config.host))
      const eventToListen = ['event', sseKey].join(':')
      eventSource.current.addEventListener(eventToListen, (event) => {
        let data = JSON.parse(event.data)
        if (typeof data === 'string') data = JSON.parse(data)
        handleReceiveSse(data)
      })
    },
    [cancelPendingRequest, handleReceiveSse, sseKey]
  )

  const sendCardPhotoStub = useCallback(() => Promise.reject(intl.serverError), [])

  const sendCardPhoto = useMemo(
    () =>
      ({
        main: mainSiteApi.sendCardPhoto.bind(mainSiteApi),
        personal: personalApi.sendCardPhoto.bind(personalApi)
      }[channel] || sendCardPhotoStub),
    [channel, sendCardPhotoStub]
  )

  const handleTakePhoto = useCallback(
    async (file, matrix) => {
      const sse = variant === VARIANTS.standalone ? sseKey : null
      const cancelToken = new AxiosStatic.CancelToken((cancel) => {
        cancelFnRef.current = cancel
      })
      try {
        const response = await sendCardPhoto({ token, file, matrix, sse, cancelToken })
        if (response.code !== 0) throw response
        setTimeout(() => {
          onSuccess()
        }, 0)
      } catch (err) {
        if (!(err instanceof CancelRequestError)) throw err
      }
    },
    [onSuccess, sendCardPhoto, sseKey, token, variant]
  )

  return (
    <CameraCropperComponent
      onTakePhoto={handleTakePhoto}
      qrCodeData={variant === VARIANTS.standalone ? null : qrCodeData}
      fallBackComponent={fallBackComponent}
      onStateChange={onCropperComponentStateChange}
      component={component}
      className={className}
      customizeErrorMessage={customizeErrorMessage}
      style={style}
    />
  )
}

CameraCropperContainer.propTypes = {
  variant: PropTypes.string,
  component: PropTypes.elementType,
  fallBackComponent: PropTypes.elementType,
  token: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  onSuccess: PropTypes.func,
  customizeErrorMessage: PropTypes.func
}

CameraCropperContainer.defaultProps = {
  variant: VARIANTS.embedded,
  fallBackComponent: returnNull,
  style: {},
  onSuccess: noop
}

export default CameraCropperContainer
