import { noop } from 'lodash'
import PropTypes from 'prop-types'
import { useCallback } from 'react'

import Button from '#components/Button'
import intl from '#intl'

const DefaultInactiveStateComponent = (props) => {
  const { activateCropper, className, style } = props
  const handleClick = useCallback(
    (event) => {
      event && event.preventDefault()
      activateCropper()
    },
    [activateCropper]
  )
  return (
    <Button fluid inverted color='gray' onClick={handleClick} className={className} style={style}>
      {intl.takePhoto}
    </Button>
  )
}

DefaultInactiveStateComponent.propTypes = {
  activateCropper: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  state: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object
}

DefaultInactiveStateComponent.defaultProps = {
  state: 'initial',
  activateCropper: noop,
  style: {}
}

export default DefaultInactiveStateComponent
