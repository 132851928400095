import UniversalFormData from 'form-data'

import { mainSiteApi } from '#modules/api'
import { handleCheckRegistartionDone } from '#services/helper'
import withErrorLogger from '#src/hoc/withErrorLogger'

import { CardCropperBase } from './CardCropperBase'

export class CardCropper extends CardCropperBase {
  async requestCardUpload() {
    const { file, token, onUploaded } = this.props
    const response = await mainSiteApi.cardUpload(token, file, {
      cancelToken: this.createCancelToken()
    })
    const checkedResponse = handleCheckRegistartionDone(response)
    this.handleCardUploadSuccess(checkedResponse)
    onUploaded && onUploaded(checkedResponse)
  }

  async requestCardCrop() {
    const { token } = this.props
    const formData = new UniversalFormData()
    this.appendMatrixToFormData(formData)
    const response = await mainSiteApi.cardCrop(token, formData, {
      cancelToken: this.createCancelToken()
    })
    handleCheckRegistartionDone(response)
    return response
  }

  handleCardUploadSuccess = (response) => {
    if (response.code !== 0) throw response
    else this.setState({ isCardUploaded: true })
  }
}

export default withErrorLogger(CardCropper)
