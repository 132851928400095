import { Popover } from '@material-ui/core'
import Button, { ButtonProps } from '@material-ui/core/Button'
import { noop } from 'lodash'
import { FC, useCallback, useRef, useState } from 'react'

import intl from '#intl'

const ButtonWithConfirmation: FC<ButtonProps & { confirmationText: string }> = (props) => {
  const { confirmationText = '', onClick = noop, ...restProps } = props
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [anchorEl, setAnchorEl] = useState<typeof buttonRef.current>(null)
  const handleClick = useCallback(() => setAnchorEl(buttonRef.current), [])
  const handleClose = useCallback(() => setAnchorEl(null), [])
  const handleConfirm = useCallback(
    (evt) => {
      handleClose()
      onClick(evt)
    },
    [handleClose, onClick]
  )
  return (
    <>
      <Button {...restProps} onClick={handleClick} ref={buttonRef} />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <div className='p-2'>
          <p>{confirmationText}</p>
          <div className='d-flex justify-content-between align-items-center'>
            <Button
              onClick={handleConfirm}
              variant='contained'
              color='secondary'
              className='flex-fill me-2'
            >
              {intl.yes}
            </Button>
            <Button
              onClick={handleClose}
              variant='contained'
              color='primary'
              className='flex-fill ms-2'
            >
              {intl.no}
            </Button>
          </div>
        </div>
      </Popover>
    </>
  )
}

export default ButtonWithConfirmation
