import classNames from 'classnames'
import { addDays, startOfMonth, subMonths } from 'date-fns'
import { identity, isEmpty, isUndefined } from 'lodash'
import PropTypes from 'prop-types'

import { Form } from '#components/Form'
import { model } from '#components/PartnerStat/model'
import { CalendarDatePicker } from '#components/RelatedCalendar/Calendar'
import { withRouter } from '#hoc/withRouter'
import intl from '#intl'
import { formatDate, now, parseDate, RU_DATE_FORMAT } from '#services/datetime'
import notify from '#services/notify'
import { brokerApi } from '#src/modules/api'

import { Button } from '../Button/Button'
import { FormItem } from '../Form/FormItem'
import SmartControl from '../SmartControl'

class PartnerStatFilter extends Form {
  constructor(props) {
    super(props)
    this.model = model
    this.state = {
      ...this.state,
      data: {
        ...this.state.data,
        dateFrom: formatDate(startOfMonth(subMonths(now(), 1))),
        dateTo: formatDate(addDays(now(), 1)),
        groupingType: 'by_month'
      },
      form: this.computeForm()
    }
  }

  async componentDidUpdate(prevProps, _prevState) {
    const { location: prevLocation } = prevProps
    const { location } = this.props
    const { fetchData, brokerToken } = this.props
    const { dateFrom, dateTo, groupingType } = this.state.data
    if (location.pathname !== prevLocation.pathname) {
      await fetchData({
        groupingType,
        brokerToken,
        dateFrom,
        dateTo
      })
    }
  }

  async componentDidMount() {
    const { dateFrom, dateTo, groupingType } = this.state.data
    const { fetchData, brokerToken } = this.props
    await fetchData({
      groupingType,
      brokerToken,
      dateFrom,
      dateTo
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    if (!this.validateForm(true)) return
    const { dateFrom, dateTo, groupingType } = this.state.data
    const { fetchData, brokerToken } = this.props
    fetchData({
      groupingType,
      brokerToken,
      dateFrom,
      dateTo
    })
  }

  handleControlChange = (event) => {
    const { name, value } = event.target
    this.setState((state) => ({
      ...state,
      data: {
        ...state.data,
        [name]: value
      }
    }))
  }

  handleControlFocus = (event) => {
    const { name } = event.target
    this.setState((state) => ({ errors: { ...state.errors, [name]: null } }))
  }

  handleReportToCSV = async (evt) => {
    evt && evt.preventDefault()
    if (!this.validateForm(true)) return

    const { dateFrom, dateTo } = this.state.data
    const {
      brokerToken,
      brokerInfo: { partnerId }
    } = this.props

    const params = { dateFrom, dateTo, brokerToken, partnerId }
    try {
      const response = await brokerApi.brokerExportStatistic(params)
      const { data } = response

      const headers = Object.keys(Object.assign({}, ...data))
      const csvHeaders = isEmpty(headers)
        ? ''
        : ['№', ...headers]
            .map((header) => {
              switch (header) {
                case 'bonus':
                  return 'Вознаграждение'
                case 'creditId':
                  return 'ID займа'
                case 'createTs':
                  return 'Дата создания'
                case 'statusText':
                  return 'Статус'
                case 'isRequestNotBadRefusal':
                  return 'Подтверждена заявка'
                case 'isIssueInCondition':
                  return 'Одобрен займ'
                case 'holdTime':
                  return 'Дата выхода из холда'
                case 'period':
                  return 'Период'
                case 'amount':
                  return 'Сумма займа'
                case 'isNewIssueInCondition':
                  return 'Заявка'
                case 'isNewRequestNotBadRefusal':
                  return 'Займ'
                case 'isIssued':
                  return 'Качество клиента'
                case 'webmaster':
                  return 'Вебмастер'
                case 'clickId':
                  return 'Click ID'
                default:
                  return header
              }
            })
            .join(';')

      const mapHeaderNameToParseFn = {
        createTs: (item) => formatDate(parseDate(item.date), RU_DATE_FORMAT),
        holdTime: (item) => formatDate(parseDate(item.date), RU_DATE_FORMAT),
        isRequestNotBadRefusal: (item) => (item === true ? 'да' : 'нет'),
        isIssueInCondition: (item) => (item === true ? 'да' : 'нет'),
        isNewRequestNotBadRefusal: (item) => (item === true ? 'да' : 'нет')
      }

      const getValuesByKeysFrom = (item) =>
        headers.map((header) => {
          const value = item[header]
          if (isUndefined(value)) return ''
          const parse = mapHeaderNameToParseFn[header] || identity
          return parse(item[header])
        })
      const csvData = data.reduce((acc, item, index) => {
        const row = [index, ...getValuesByKeysFrom(item)].join(';')
        return [...acc, row]
      }, [])

      const csv = [csvHeaders, ...csvData].join(';\r\n')

      const hiddenElement = document.createElement('a')
      hiddenElement.href = 'data:text/x-csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv)
      hiddenElement.target = '_blank'
      hiddenElement.download = `creditStatus_${params.dateFrom}_${params.dateTo}.csv`
      hiddenElement.click()
    } catch (err) {
      const { message } = err
      notify.push({ message, type: 'danger' })
      return null
    }
  }

  handleChangeConditions = ({ periodStartCalendar, periodEndCalendar }) => {
    this.setState((state) => ({
      ...state,
      ...{
        data: {
          ...state.data,
          dateFrom: periodStartCalendar,
          dateTo: periodEndCalendar
        }
      }
    }))
  }

  renderItem(item) {
    const { data, errors } = this.state
    const { type } = item
    if (type === 'calendarPicker') {
      return (
        <CalendarDatePicker
          key={item.name}
          periodStart={parseDate(data['dateFrom'])}
          periodFinish={parseDate(data['dateTo'])}
          prefixStart={'dateFrom'}
          prefixFinish={'dateTo'}
          labelCalendarFrom={intl.fromTime}
          labelCalendarTo={intl.toTime}
          errors={errors}
          onChange={this.handleControlChange}
          onChangeConditions={this.handleChangeConditions}
        />
      )
    }
    return (
      <FormItem key={item.name} {...item} error={errors[item.name]}>
        <SmartControl
          {...item}
          value={data[item.name]}
          valid={!errors[item.name]}
          onChange={this.handleControlChange}
          onFocus={this.handleControlFocus}
          onBlur={this.handleControlBlur}
        />
      </FormItem>
    )
  }

  render() {
    const { loading, transferStat = false } = this.props
    const { form } = this.state
    const className = classNames('form', 'form__filters')

    return (
      <form className={className} noValidate onSubmit={this.handleSubmit}>
        {form.lines.map((line, key) => (
          <div className='form__line' key={key}>
            {line.items.map((item) => this.renderItem(item))}
          </div>
        ))}
        <div className='form__line'>
          <div className='form__item'>
            <Button fluid loading={loading}>
              {intl.searchAction}
            </Button>
          </div>
          {!transferStat && (
            <div className='form__item'>
              <Button fluid onClick={this.handleReportToCSV}>
                {intl.reportToCSV}
              </Button>
            </div>
          )}
        </div>
      </form>
    )
  }
}

PartnerStatFilter.propTypes = {
  loading: PropTypes.bool.isRequired,
  fetchData: PropTypes.func.isRequired,
  brokerToken: PropTypes.string.isRequired,
  location: PropTypes.object,
  partnerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  brokerInfo: PropTypes.object,
  transferStat: PropTypes.bool
}

export default withRouter(PartnerStatFilter)
