import '../assets/styles.scss'

import PropTypes from 'prop-types'

import { Button } from '../../Button/Button'
import Modal from '../../presentational/Modal'
import BaseModal from '../DialogComponents/BaseModal'

export default class Message extends BaseModal {
  static propTypes = {
    closeModal: PropTypes.func,
    content: PropTypes.any,
    textButton: PropTypes.string,
    title: PropTypes.string
  }

  render() {
    const { closeModal, content, textButton, title, ...rest } = this.props
    return (
      <Modal closeModal={this.onCloseModal} size='middle' title={title} {...rest}>
        <div className='message-dialog'>
          <div className='message-dialog__container'>{content}</div>
          <div className='message-dialog__actions'>
            <Button
              className='message-dialog__actions_button'
              type='button'
              onClick={() => closeModal()}
            >
              {textButton}
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}
