import './notice.scss'

import classnames from 'classnames'
import { FC } from 'react'

import { Icon } from '../Icon/Icon'

type TNoticeProps = {
  className: string
  text: string
  type?: 'success' | 'error'
  cancelable: boolean
  onClose: () => void
}
export const Notice: FC<TNoticeProps> = (props) => {
  const { type = 'success', text, cancelable, onClose } = props

  const className = classnames(
    {
      notice: true,
      [`notice_type_${type}`]: true
    },
    props.className
  )

  return (
    <div className={className}>
      <Icon className='notice__icon' name='alarm' />
      <div className='notice__text'>{text}</div>
      {cancelable && <Icon className='notice__close' name='close' onClick={onClose} />}
    </div>
  )
}
