import { flowRight } from 'lodash'
import { memo } from 'react'

import { FormItem } from '#components/Form'
import SmartControl from '#components/SmartControl'
import withSmartControl from '#components/SmartControlProxy'

import WithCardPhotoInstructionsModal from './WithCardPhotoInstructionsModal'

export const SmartControlComponent = withSmartControl(SmartControl)

export const CardPhotoUploadItem = flowRight(
  WithCardPhotoInstructionsModal('onBeforeFileUploadDialogOpen'),
  withSmartControl
)(SmartControlComponent)

export const FormItemPure = memo(FormItem)
FormItemPure.displayName = 'FormItemPure'

export { default as VirtualAndUnnamedCardsHint } from './VirtualAndUnnamedCardsHint'
export { default as HightAmountHint } from './HightAmountHint'
