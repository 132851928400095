import { has, isEmpty } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import intl from '#intl'
import { CardCropperBroker } from '#src/components/CardCropper'
import CopyText from '#src/components/CopyText'
import Spinner from '#src/components/Spinner'
import { brokerApi } from '#src/modules/api'

import Carousel, { ICarousel, TIMage } from './Carousel'

const INITIAL_STATE = 'initialState'
const FAILURE_STATE = 'failureState'
const READY_STATE = 'readyState'
const PROCESSING_STATE = 'processingState'
const SUCCESS_STATE = 'successState'
const EMPTY_STATE = 'emptyState'

const SUCCESS_RESPONSE_CODE = 0

type TState =
  | typeof INITIAL_STATE
  | typeof READY_STATE
  | typeof PROCESSING_STATE
  | typeof SUCCESS_STATE
  | typeof FAILURE_STATE
  | typeof EMPTY_STATE

const InitialState: FC = () => <Spinner className='spinner-centered text-primary spinner-lg' />

const FailureState: FC<{ message: string | null; onRepeat: () => void }> = ({
  message,
  onRepeat
}) => (
  <>
    <h3 className='text-secondary'>{'Произошла ошибка!'}</h3>
    <p className='text-danger mb-5 font-size-medium'>{message || intl.serverError}</p>
    <span className='link link--pseudo mx-auto text-uppercase' onClick={onRepeat}>
      {intl.backward}
    </span>
  </>
)

const EmptyState: FC<{ personalLink: string }> = ({ personalLink }) => (
  <>
    <h3 className='text-secondary mb-4'>{'Загруженные пользователем фото отсутствуют'}</h3>
    <CopyText
      text={personalLink}
      legend={'Ссылка'}
      data-qa='brokerToPersonfileUploadLink'
      className='w-50'
    />
  </>
)

const ReadyState: FC<ICarousel & { personalLink: string }> = (props) => (
  <>
    <h3 className='text-secondary mb-4'>{'Загруженные пользователем фото'}</h3>
    <CopyText
      text={props.personalLink}
      legend={'Ссылка'}
      data-qa='brokerToPersonfileUploadLink'
      className='mt-2 mb-5 w-50'
    />
    <Carousel {...props} />
  </>
)

interface IProcessingState {
  cardFile: Blob
  personToken: string
  brokerToken: string
  onSuccess: () => void
  onFailure: (e: Error) => void
  onClose: () => void
}
const ProcessingState: FC<IProcessingState> = (props) => {
  const { cardFile, personToken, brokerToken, onSuccess, onFailure, onClose } = props
  return (
    <CardCropperBroker
      file={cardFile}
      token={personToken}
      brokerToken={brokerToken}
      onSuccess={onSuccess}
      onFail={onFailure}
      onRemove={onClose}
    />
  )
}

const SuccessState: FC<{ onRepeat: () => void }> = (props) => {
  const { onRepeat } = props
  return (
    <>
      <h3 className='text-secondary'>{'Карта успешно загружена и обрезана!'}</h3>
      <p className='text-secondary mt-5'>
        {'Перейдите на вкладку '}
        <strong className='text-black-50'>{'"Редактировать данные"'}</strong>
        {' для завершения оформления заявки или можете '}
        <span className='link link--pseudo' onClick={onRepeat}>
          {'вернуться к просмотру фото'}
        </span>
      </p>
    </>
  )
}

interface ICardPhotoForBrokerForm {
  brokerToken: string
  personToken: string
  personId: number
}

const CardPhotoForBrokerForm: FC<ICardPhotoForBrokerForm> = (props) => {
  const { brokerToken, personToken } = props
  const [state, setState] = useState<TState>(INITIAL_STATE)
  const [imageList, setImageList] = useState<TIMage[]>([])
  const [file, setFile] = useState<Blob>(new window.Blob())
  const [error, setError] = useState<string | null>(null)
  const [personalLink, setPersonalLink] = useState<string>('')

  const handleInitial = useCallback(() => setState(INITIAL_STATE), [])
  const handleSuccess = useCallback(() => setState(SUCCESS_STATE), [])
  const handleReady = useCallback(() => setState(READY_STATE), [])
  const handleProcessing = useCallback(() => setState(PROCESSING_STATE), [])
  const handleEmpty = useCallback(() => setState(EMPTY_STATE), [])

  const handleFailure = useCallback((err: Error | string) => {
    let msg = intl.serverError
    if (typeof err === 'object' && has(err, 'message')) msg = err.message
    if (typeof err === 'string') msg = err
    setState(FAILURE_STATE)
    setError(msg)
  }, [])

  const handleCardFileSelect = useCallback(
    (cardFile: Blob) => {
      setFile(cardFile)
      handleProcessing()
    },
    [handleProcessing]
  )

  const handleCardFileDelete = useCallback(
    (deletedId: number) => {
      const updateImageList = imageList.filter(({ id }) => deletedId !== id)
      setImageList(updateImageList)
      if (isEmpty(updateImageList)) handleEmpty()
    },
    [handleEmpty, imageList]
  )

  const fetchCardPhotos = useCallback(async () => {
    try {
      const response = await brokerApi.getCardPhotosForBroker({
        brokerToken,
        personToken
      })
      const { code, message, data } = response
      if (code !== SUCCESS_RESPONSE_CODE) return handleFailure(message)
      if (isEmpty(data)) return handleEmpty()
      setImageList(data)
      handleReady()
    } catch (err) {
      handleFailure(err as Error)
    }
  }, [brokerToken, handleEmpty, handleFailure, handleReady, personToken])

  const getPersonalLink = useCallback(async () => {
    try {
      const response = await brokerApi.getCardPhotosForBrokerLink({
        brokerToken,
        personToken
      })
      const { code, message, link } = response
      if (code !== SUCCESS_RESPONSE_CODE) return handleFailure(message)
      setPersonalLink(link)
    } catch (err) {
      handleFailure(err as Error)
    }
  }, [brokerToken, handleFailure, personToken])

  useEffect(() => {
    if (state === INITIAL_STATE) void fetchCardPhotos()
    if (!personalLink) void getPersonalLink()
  }, [fetchCardPhotos, getPersonalLink, personalLink, state])

  const statesComponents = useMemo(
    () => ({
      [INITIAL_STATE]: function Initial() {
        return <InitialState />
      },
      [FAILURE_STATE]: function Failure() {
        return <FailureState message={error} onRepeat={handleInitial} />
      },
      [EMPTY_STATE]: function Empty() {
        return <EmptyState personalLink={personalLink} />
      },
      [READY_STATE]: function Ready() {
        return (
          <ReadyState
            imageList={imageList}
            brokerToken={brokerToken}
            personToken={personToken}
            personalLink={personalLink}
            onSelect={handleCardFileSelect}
            onDelete={handleCardFileDelete}
          />
        )
      },
      [PROCESSING_STATE]: function Processing() {
        return (
          <ProcessingState
            onSuccess={handleSuccess}
            onFailure={handleFailure}
            onClose={handleReady}
            cardFile={file}
            brokerToken={brokerToken}
            personToken={personToken}
          />
        )
      },
      [SUCCESS_STATE]: function Success() {
        return <SuccessState onRepeat={handleReady} />
      }
    }),
    [
      brokerToken,
      error,
      file,
      handleCardFileDelete,
      handleCardFileSelect,
      handleFailure,
      handleInitial,
      handleReady,
      handleSuccess,
      imageList,
      personToken,
      personalLink
    ]
  )

  const StateComponent = useMemo(() => statesComponents[state], [state, statesComponents])

  return <StateComponent />
}

export default CardPhotoForBrokerForm
