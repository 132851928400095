import loadable from '@loadable/component'
import cn from 'classnames'
import {
  clamp,
  findKey,
  get,
  isArray,
  isEmpty,
  isEqual,
  isObject,
  noop,
  without,
  zipObject
} from 'lodash'
import { Cookies } from 'react-cookie'

import { Form } from '#components/Form/Form'
import intl from '#intl'
import { formatAsMoney, formatValueByCount, hasProfit } from '#services/helper'
import * as validator from '#services/validator'

import PromotedComponentsPropsBuilder from './PromotedComponentsPropsBuilder'

const FormItem = loadable(() => import('#components/Form/FormItem'), {
  resolveComponent: (component) => component.FormItem
})
const SmartControl = loadable(() => import('#components/SmartControl'))
const PromoCodeItem = loadable(() =>
  import('#src/components/LoanFormBase/PromotedComponents/PromoCodeItem')
)
const PromoCodeLabel = loadable(() =>
  import('#src/components/LoanFormBase/PromotedComponents/PromoCodeLabel')
)

export class LoanFormBase extends Form {
  constructor(props) {
    super(props)
    // --- Вспомогательные методы класса
    this.getSliderDisplayValue = this.getSliderDisplayValue.bind(this)
    this.getSliderProps = this.getSliderProps.bind(this)
    this.getPromoCodeType = this.getPromoCodeType.bind(this)
    this.validatePromoCodeField = this.validatePromoCodeField.bind(this)
    // ---

    this.updateLoanConditionsWithPromoCode = this.updateLoanConditionsWithPromoCode.bind(this)

    // --- Хендлеры
    this.onCreditTypeChange = this.onCreditTypeChange.bind(this)
    this.handleCancelPromoCode = this.handleCancelPromoCode.bind(this)
    this.handleApplyPromoCode = this.handleApplyPromoCode.bind(this)
    this.onSliderChange = this.onSliderChange.bind(this)
    this.handleControlChange = this.handleControlChange.bind(this)
    this.handleTogglePromoCodeVisibility = this.handleTogglePromoCodeVisibility.bind(this)
    // ---

    // --- Колбэки
    this.onAfterFieldValueChange = this.onAfterFieldValueChange.bind(this)
    this.onAfterPromoCodeApply = this.onAfterPromoCodeApply.bind(this)
    this.onAfterPromoCodeCancel = this.onAfterPromoCodeCancel.bind(this)
    this.onBeforePromoCodeApply = this.onBeforePromoCodeApply.bind(this)
    this.onBeforePromoCodeCancel = this.onBeforePromoCodeCancel.bind(this)
    // ---

    // --- Рендер методы, переопределяются через super
    this.renderFormItem = this.renderFormItem.bind(this)
    this.renderAmountItem = this.renderAmountItem.bind(this)
    this.renderTermItem = this.renderTermItem.bind(this)
    this.renderCalculatedResult = this.renderCalculatedResult.bind(this)
    this.renderItem = this.renderItem.bind(this)
    this.renderPromoCode = this.renderPromoCode.bind(this)
    this.renderSliderItem = this.renderSliderItem.bind(this)
    this.renderCreditType = this.renderCreditType.bind(this)
    this.renderSubmitButton = this.renderSubmitButton.bind(this)
    this.getRenderHandler = this.getRenderHandler.bind(this)
    // ---
    this.setLoanFormState = props.setLoanFormState
    this.resetSliders = props.resetSliders
    // ---
    this.PromotedComponentsPropsBuilder = new PromotedComponentsPropsBuilder()
    this.logError = props.logError || noop
  }

  // --- Выключает повторный рендер при изменении стейта (все обновляется через props из Redux)
  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps, this.props)
  }

  // Валидирует промокод  и устанавливает индикатор заблокированности промокода
  // Т.е. isPromoBlocked = true, если промокод есть, но текущее положение на слайдерах
  // делает невозможным его применение
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidUpdate() {
    const { isPromoBlocked, appliedPromoCode } = this.loanFormState
    const isValid = this.PromotedComponentsPropsBuilder.validatePromoCode()
    if (appliedPromoCode && isPromoBlocked !== !isValid)
      this.setLoanFormState({ isPromoBlocked: !isValid })

    if (!appliedPromoCode && isPromoBlocked) this.setLoanFormState({ isPromoBlocked: false })
  }

  /**
   * Переопределение родительского метода для работы через Redux
   * @param {object} item - Элемент из модели формы
   * @returns {object} - результат валидации ( isValid: true | false, validationErrorMessage: text )
   */
  validateItem(item = {}) {
    const { data } = this.loanFormState
    const value = data[item.name] || ''
    const { isValid, validationErrorMessage } = validator.validateItem({ ...item, value })
    const error = isValid ? null : validationErrorMessage
    this.setLoanFormState({ errors: { [item.name]: error } })
    return { isValid, validationErrorMessage }
  }

  get loanFormState() {
    return this.props.loanFormState
  }

  get isLoanInMainSite() {
    return this.ExtendedForm === 'LoanForm'
  }

  get isProfit() {
    const { calculatedData } = this.props
    return hasProfit(calculatedData)
  }

  /**
   * Колбэк, выполняется после onChange события на элменте формы
   * @param {object} event
   */
  onAfterFieldValueChange(event) {
    noop(event)
  }

  /**
   * Колбэк, выполняется до применения промокода
   * @param {string} appliedPromoCode - Применямый промокод
   */
  onBeforePromoCodeApply(appliedPromoCode) {
    return Promise.resolve(appliedPromoCode)
  }

  /**
   * Колбэк, выполняется после удаления промокода
   * @param {string} canceledPromoCode - Удаляемый промокод
   */
  onBeforePromoCodeCancel(canceledPromoCode) {
    return Promise.resolve(canceledPromoCode)
  }

  /**
   * Колбэк, выполняется после применения промокода
   * @param {string} appliedPromoCode - Активированный промокод
   */
  onAfterPromoCodeApply(appliedPromoCode) {
    return Promise.resolve(appliedPromoCode)
  }

  /**
   * Колбэк, выполняется после удаления промокода
   * @param {string} _canceledPromoCode - Удаленный промокод
   */
  async onAfterPromoCodeCancel(_canceledPromoCode) {
    return Promise.resolve()
  }

  /**
   * Возвращает форматированное значение для отображения на слайдере
   * @param {('term'|'amount')} sliderName
   */
  getSliderDisplayValue(sliderName) {
    const value = this.loanFormState.data[sliderName]
    return (
      {
        amount: formatAsMoney(value),
        term: formatValueByCount(value, intl.days).toLowerCase()
      }[sliderName] || null
    )
  }

  /**
   * Возвращает пропсы для отображения слайдера
   * @param {('term'|'amount')} sliderName
   */
  getSliderProps(sliderName) {
    const { min, max, step } = this.loanFormState[sliderName]
    const displayValue = this.getSliderDisplayValue(sliderName)
    const promoProps = this.PromotedComponentsPropsBuilder.buildSliderPromoProps(sliderName)
    return { min, max, step, displayValue, ...promoProps }
  }

  /**
   * Находит и возвращает первый тип промокода не равный false | null
   * @returns {string} - Тип промокода
   */
  getPromoCodeType() {
    return findKey(this.props.promo)
  }

  /**
   * Кастомизация метода handleControlChange
   * Не переопределять
   * @param {object} event
   */
  handleControlChange(event) {
    const {
      target: { name, value }
    } = event
    this.setLoanFormState({ data: { [name]: value }, errors: { [name]: null } })
    this.onAfterFieldValueChange(event)
  }

  /**
   * onChange для поля creditType
   * @param {object} event
   */
  onCreditTypeChange(event) {
    const creditType = event.target.value
    this.setLoanFormState({ data: { creditType } })
    this.resetSliders()
    this.onAfterFieldValueChange(event)
  }

  /**
   * onChange для полей amount и term
   * @param {object} event
   */
  onSliderChange(event) {
    const { target } = event
    const targetSliderName = target.name

    const { loanConditions } = this.props
    const {
      index,
      data,
      data: { creditType }
    } = this.loanFormState

    const conditions = loanConditions[creditType]

    const [relatedSliderName] = without(['term', 'amount'], targetSliderName)

    const newTargetSliderValueCandidate = Number(target.value)
    const currentRelatedSliderValue = data[relatedSliderName]

    const minTargetValueInCurrentRange = Number(
      get(conditions, [index, 'sliderInfo', targetSliderName, 'min'])
    )
    const maxTargetValueInCurrentRange = Number(
      get(conditions, [index, 'sliderInfo', targetSliderName, 'max'])
    )

    const { indexList } = this.loanFormState[targetSliderName]

    let newIndex = index
    if (newTargetSliderValueCandidate < minTargetValueInCurrentRange)
      newIndex = get(indexList, [indexList.indexOf(index) - 1], newIndex)

    if (newTargetSliderValueCandidate > maxTargetValueInCurrentRange)
      newIndex = get(indexList, [indexList.indexOf(index) + 1], newIndex)

    const minTargetValueInNewRange = Number(
      get(conditions, [newIndex, 'sliderInfo', targetSliderName, 'min'])
    )
    const maxTargetValueInNewRange = Number(
      get(conditions, [newIndex, 'sliderInfo', targetSliderName, 'max'])
    )

    const minRelatedValueInNewRange = Number(
      get(conditions, [newIndex, 'sliderInfo', relatedSliderName, 'min'])
    )
    const maxRelatedValueInNewRange = Number(
      get(conditions, [newIndex, 'sliderInfo', relatedSliderName, 'max'])
    )

    const newTargetSliderValue = clamp(
      newTargetSliderValueCandidate,
      minTargetValueInNewRange,
      maxTargetValueInNewRange
    )

    const newRelatedSliderValue = clamp(
      currentRelatedSliderValue,
      minRelatedValueInNewRange,
      maxRelatedValueInNewRange
    )

    this.setLoanFormState({
      data: {
        [targetSliderName]: newTargetSliderValue,
        [relatedSliderName]: newRelatedSliderValue
      },
      index: newIndex
    })

    const targetSliderEvent = {
      ...event,
      target: { ...event.target, value: newTargetSliderValue }
    }
    const relatedTargetEvent = {
      ...event,
      target: { ...event.target, name: relatedSliderName, value: newRelatedSliderValue }
    }
    this.onAfterFieldValueChange(targetSliderEvent)
    this.onAfterFieldValueChange(relatedTargetEvent)
  }

  /**
   * Валидирует введенное значение в поле с промокодом
   * @returns {boolean}
   */
  validatePromoCodeField() {
    const { isValid } = this.validateItem(this.model.find(({ name }) => name === 'promoCode'))
    return isValid
  }

  /**
   * Выполняет запрос на получение расчетных данный по займу с учетом введенного промокода
   * @param {string|null} promoCode
   */
  updateLoanConditionsWithPromoCode(promoCode) {
    const { updateLoanConditions } = this.props
    return updateLoanConditions(promoCode)
  }

  /**
   * Выполняет отмену промокода
   */
  async handleCancelPromoCode() {
    const { promoCode: canceledPromoCode } = this.loanFormState.data
    const cookies = new Cookies()
    try {
      await this.onBeforePromoCodeCancel(canceledPromoCode)
      cookies.set('isUseDefaultPromo', false, { path: '/' })
      await this.updateLoanConditionsWithPromoCode(null)
      this.setLoanFormState({
        isPromoCodeVisible: false
      })
      await this.onAfterPromoCodeCancel(canceledPromoCode)
    } catch (err) {
      this.logError(err, 'LoanFormBase.handleCancelPromoCode')
      this.setLoanFormState({ errors: { promoCode: err.message || intl.serverError } })
    } finally {
      this.setLoanFormState({ updating: false })
    }
  }

  /**
   * Выполняет применение промокода
   */
  async handleApplyPromoCode() {
    if (!this.validatePromoCodeField()) return
    const { promoCode } = this.loanFormState.data
    this.setLoanFormState({ updating: true })
    const cookies = new Cookies()
    try {
      await this.onBeforePromoCodeApply(promoCode)
      cookies.remove('isUseDefaultPromo', { path: '/' })
      await this.updateLoanConditionsWithPromoCode(promoCode)
      this.setLoanFormState({ isPromoCodeVisible: false })
      await this.onAfterPromoCodeApply(promoCode)
    } catch (err) {
      this.logError(err, 'LoanFormBase.handleApplyPromoCode')
      this.setLoanFormState({ errors: { promoCode: err.message || intl.serverError } })
    } finally {
      this.setLoanFormState({ updating: false })
    }
  }

  /**
   *  Хендлер на изменение видимости поля с промокодом
   */
  handleTogglePromoCodeVisibility(isVisible) {
    this.setLoanFormState({ isPromoCodeVisible: isVisible })
  }

  /**
   * Рендер метод для отображения элемента формы FormItem
   * @param {object} item
   */
  renderFormItem(item) {
    const { name } = item
    const { style, className, ...otherProps } = item
    const { data, errors } = this.loanFormState
    const error = errors[name]
    const value = data[name]

    return (
      <FormItem {...otherProps} error={error} className={className} style={style}>
        <SmartControl
          value={value}
          valid={isEmpty(error)}
          onChange={this.handleControlChange}
          onFocus={this.handleControlFocus}
          onBlur={this.handleControlBlur}
          {...otherProps}
        />
      </FormItem>
    )
  }

  /**
   * Служебный рендер метод для слайдеров (общий для двух слайдеров)
   * Кастомизацию лучше выполнять через рендер метод соответствующего слайдера
   * @param {object} props
   */
  renderSliderItem(props) {
    const { name } = props
    const sliderProps = this.getSliderProps(name)

    const onChange = this.onSliderChange
    const mergedProps = { ...props, ...sliderProps, onChange, style: { minWidth: 255 } }

    return this.renderFormItem(mergedProps)
  }

  /**
   * Рендер метод для слайдера суммы займа
   * @param {object} props
   */
  renderAmountItem(props) {
    const { min, max } = this.loanFormState.amount
    const hint = `${min} – ${formatAsMoney(max)}`

    return this.renderSliderItem({
      ...props,
      hint
    })
  }

  /**
   * Рендер метод для слайдера срока займа
   * @param {object} props
   */
  renderTermItem(props) {
    const { min, max } = this.loanFormState.term
    const { disableRenderLoanTermHint } = this.props
    let hint = ''
    let dimensionList = intl.days
    if (this.loanFormState.dimension === 'month') dimensionList = intl.monthsList
    if (!disableRenderLoanTermHint)
      hint = `${min} – ${formatValueByCount(max, dimensionList).toLowerCase()}`
    return this.renderSliderItem({
      ...props,
      hint
    })
  }

  /**
   * Рендер метод - точка входа, вызывает соответствующий рендер метод по имени поля формы (если определен)
   * или renderFormItem если специальный рендер метод не определен
   * @param {object} props
   */
  renderItem(props) {
    const { name } = props
    return this.getRenderHandler(name)(props)
  }

  /**
   * Рендер метод для поля промокода
   * @param {object} props
   */
  renderPromoCode(props) {
    const {
      data: { creditType }
    } = this.loanFormState
    if (creditType !== 'MicroCredit') return null
    const { name } = props
    const {
      data,
      errors,
      submitting,
      updating,
      appliedPromoCode,
      isPromoCodeVisible,
      isPromoBlocked,
      isPromoCodeDescriptionVisible
    } = this.loanFormState
    const value = data[name]
    const error = errors[name]
    const { promoDescription } = this.props
    const classes = cn({
      'd-flex-centered flex-column position-relative w-100': true,
      'mt-2': !appliedPromoCode || isPromoBlocked,
      'mt-4': appliedPromoCode && !isPromoBlocked
    })

    return (
      <div className={classes}>
        {appliedPromoCode && value && !isPromoCodeVisible && (
          <PromoCodeLabel
            value={value}
            visible={!isPromoBlocked}
            className='position-absolute'
            style={isPromoBlocked ? { top: '-2px' } : { top: '-12px' }}
          />
        )}
        <PromoCodeItem
          value={value}
          error={error}
          promoDescription={promoDescription}
          onApply={this.handleApplyPromoCode}
          onCancel={this.handleCancelPromoCode}
          onChange={this.handleControlChange}
          onFocus={this.handleControlFocus}
          onBlur={this.handleControlBlur}
          isLoading={submitting || updating}
          isApplied={Boolean(appliedPromoCode)}
          isDisabled={Boolean(appliedPromoCode) || submitting || updating}
          isVisible={isPromoCodeVisible}
          isPromoDescriptionVisible={isPromoCodeDescriptionVisible}
          onTogglePromoDescriptionVisibility={noop} // Клик по кнопке "Условия промокода"
          onTogglePromoCodeVisibility={this.handleTogglePromoCodeVisibility}
          {...props}
        />
      </div>
    )
  }

  /**
   * Рендер метод для поля с расчетами по кредиту
   * @param {object} props
   */
  renderCalculatedResult(props) {
    const {
      data: { creditType, term },
      dimension
    } = this.loanFormState
    const { promoType, styles: storeStyles, promo, calculatedData } = this.props
    const promoStyle =
      isArray(storeStyles) && isObject(storeStyles) ? zipObject(storeStyles) : storeStyles
    const { styles: propStyle } = props

    const promoInfo = this.PromotedComponentsPropsBuilder.buildCalculatedInfoPromoProps()

    const mergedProps = {
      ...calculatedData,
      dimension,
      term,
      creditType,
      promoType,
      ...promoInfo,
      ...props,
      promo,
      styles: { ...propStyle, ...promoStyle }
    }
    const CalculatedInfoItem = loadable(() =>
      import('#src/components/LoanFormBase/PromotedComponents/CalculatedInfoItem')
    )
    return <CalculatedInfoItem {...mergedProps} />
  }

  /**
   * Рендер метод для переключателя типа продукта
   * @param {object} item
   */
  renderCreditType(item) {
    const { loanConditions } = this.props
    if (Object.keys(loanConditions).length < 2) return null
    const { options } = item
    return this.renderFormItem({
      onChange: this.onCreditTypeChange,
      ...item,
      options: options.filter((option) => Object.keys(loanConditions).includes(option.value))
    })
  }

  /**
   * Рендер метод кнопки сабмита формы
   * @param {object} props
   */
  renderSubmitButton(props) {
    const promoProps = this.PromotedComponentsPropsBuilder.buildSubmitButtonPromoProps()
    const SubmitButton = loadable(() =>
      import('#src/components/LoanFormBase/PromotedComponents/SubmitButton')
    )
    return <SubmitButton {...promoProps} {...props} data-qa='submitButton' />
  }

  getRenderHandler(name) {
    switch (name) {
      case 'result':
        return this.renderCalculatedResult
      case 'promoCode':
        return this.renderPromoCode
      case 'creditType':
        return this.renderCreditType
      case 'amount':
        return this.renderAmountItem
      case 'term':
        return this.renderTermItem
      default:
        return this.renderFormItem
    }
  }

  render() {
    // --- Выполняет расчет стилей, если имеется промокод
    // --- В методе render производного компонента необходимо вызывать super.render()
    const { amount, term, data, appliedPromoCode } = this.loanFormState
    const { promo, styles } = this.props

    this.PromotedComponentsPropsBuilder.update({
      amount: { min: amount.min, max: amount.max, step: amount.step, current: data.amount },
      term: { min: term.min, max: term.max, step: term.step, current: data.term },
      promocode: { name: appliedPromoCode, value: promo[appliedPromoCode] },
      creditType: data.creditType,
      settings: styles,
      promoConditions: promo.conditions
    })
  }
}

LoanFormBase.defaultProps = {
  // Объект state (работа через redux)
  loanFormState: {},
  // Объект с условиями займа
  loanConditions: {},
  // Объект с условиями займа
  promo: {},
  // Объект со стилями ( дополнительными ограничениями ) для примененного промокода
  styles: {},
  // Строка с описанием условий акции
  promoDescription: '',
  // Action, аналог setState, но для redux
  setLoanFormState: noop,
  // Action для обновления loanConditions при применении / удалении промокода
  // Вызывается с агрументом promoCode - значение поля input с промокодом
  updateLoanConditions: noop,
  // Action для выставления слайдеров в дефлотное положение для данных условий займа
  resetSliders: noop,
  calculatedData: {}
}

export default LoanFormBase
