import { render } from 'react-dom'

import PartnerContainer from './rootContainers/partnerContainer'

const container = document.createElement('div')
container.id = 'app'

document.addEventListener('DOMContentLoaded', () => {
  document.body.appendChild(container)
  render(<PartnerContainer />, container)
})

if (module.hot) {
  module.hot.accept('./rootContainers/partnerContainer.jsx', () => {
    const NextRootContainer = require('./rootContainers/partnerContainer.jsx').default
    render(<NextRootContainer />, container)
  })
}
