import { Box, IconButton } from '@material-ui/core'
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions'
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@material-ui/icons'
import { FC, MouseEvent, useCallback } from 'react'

type THandleClick = (event: MouseEvent<HTMLButtonElement>) => void

const TablePaginationActions: FC<TablePaginationActionsProps> = (props) => {
  const { count, page, rowsPerPage, onChangePage } = props

  const lastPageIndex = Math.ceil(count / rowsPerPage) - 1

  const handleFirstPageButtonClick: THandleClick = useCallback((event) => onChangePage(event, 0), [
    onChangePage
  ])
  const handleBackButtonClick: THandleClick = useCallback(
    (event) => onChangePage(event, page - 1),
    [onChangePage, page]
  )
  const handleNextButtonClick: THandleClick = useCallback(
    (event) => onChangePage(event, page + 1),
    [onChangePage, page]
  )
  const handleLastPageButtonClick: THandleClick = useCallback(
    (event) => onChangePage(event, lastPageIndex),
    [lastPageIndex, onChangePage]
  )

  return (
    <Box css={{ display: 'flex' }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page <= 0}>
        <FirstPage />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page <= 0}>
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= lastPageIndex}>
        <KeyboardArrowRight />
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= lastPageIndex}>
        <LastPage />
      </IconButton>
    </Box>
  )
}

export default TablePaginationActions
