import './loan-loader.scss'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Spinner } from '../Spinner/Spinner'

export const LoanLoader = (props) => (
  <div className={classNames('loan-loader', props.className)}>
    <Spinner className={classNames(props.spinnerClassName)} />
  </div>
)

LoanLoader.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  spinnerClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}
