import cn from 'classnames'
import PropTypes from 'prop-types'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill'

const getAbsSin = (deg) => Math.abs(Math.sin((deg * Math.PI) / 180))
const getAbsCos = (deg) => Math.abs(Math.cos((deg * Math.PI) / 180))

const CARD_RATIO = 1.585772508

const CardMask = forwardRef((props, ref) => {
  const {
    maskedElement,
    className,
    style,
    rotate,
    fillCoefficient,
    offset,
    innerRef,
    cameraRef
  } = props
  const { color = 'white' } = style
  const [maskSize, setMaskSize] = useState({ width: 0, height: 0 })
  const maskRef = useRef(null)

  useEffect(() => {
    if (!innerRef) return
    innerRef.current = maskRef.current
  }, [innerRef])

  const handleResize = useCallback(
    (width, height) => {
      const maxBoundingWidth = width * fillCoefficient
      const maxBoundingHeight = height * fillCoefficient

      const maskWidth = Math.max(maxBoundingWidth, maxBoundingHeight)
      const maskHeight = maskWidth / CARD_RATIO

      const boundingWidth = maskWidth * getAbsCos(rotate) + maskHeight * getAbsSin(rotate)
      const boundingHeight = maskWidth * getAbsSin(rotate) + maskHeight * getAbsCos(rotate)

      const scaleW = Math.min(maxBoundingWidth / boundingWidth || 0, 1)
      const scaleH = Math.min(maxBoundingHeight / boundingHeight || 0, 1)
      const scale = Math.min(scaleW, scaleH)

      setMaskSize({ width: maskWidth * scale, height: maskHeight * scale })
    },
    [fillCoefficient, rotate]
  )

  useResizeDetector({
    handleWidth: true,
    handleHeight: true,
    onResize: handleResize,
    targetRef: cameraRef
  })

  useEffect(() => {
    const { width, height } = maskedElement.getBoundingClientRect()
    handleResize(width, height)
  }, [handleResize, maskedElement, rotate])

  const getMaskPosition = (options = {}) => {
    const { x: absX, y: absY, width, height } = maskRef.current.getBoundingClientRect()

    let x
    let y

    const { variant = 'absolute' } = options

    if (variant === 'absolute') {
      x = absX
      y = absY
    }

    if (variant === 'relative') {
      const { x: maskedElX, y: maskedElY } = maskedElement.getBoundingClientRect()
      x = absX - maskedElX
      y = absY - maskedElY
    }

    return {
      topLeft: [x, y],
      topRight: [x + width, y],
      bottomLeft: [x, y + height],
      bottomRight: [x + width, y + height],
      center: [(x + width) / 2, (y + height) / 2]
    }
  }

  const getMaskSize = () => {
    const { width, height } = maskRef.current.getBoundingClientRect()
    return { width, height }
  }

  useImperativeHandle(ref, () => ({
    getPosition: getMaskPosition,
    getSize: getMaskSize,
    innerRef: maskRef.current
  }))

  const transform = { transform: `rotate(${rotate}deg) translate(${offset.join()})` }

  return (
    <div
      className={cn({ 'd-flex-centered position-absolute': true }, className)}
      data-role='outer-shadow'
      style={{
        boxShadow: '0px 0px 0px 9999px rgba(0, 0, 0, 0.35)',
        // Уменьшаем размеры чтобы затенение начиналось от средней линии SVG маски
        // Т.е. по центру толщины уголка маски
        width: Math.floor(0.975 * maskSize.width),
        height: Math.floor(0.975 * maskSize.height),
        borderRadius: '5%',
        ...transform
      }}
    >
      <svg
        ref={maskRef}
        // Увеличиваем viewBox на 1px на сторону, т.к. Safari не поддерживате overflow: visible для SVG
        // И обрезает половину уголков
        viewBox='-1 -1 88.5 55.92'
        xmlns='http://www.w3.org/2000/svg'
        style={{
          pointerEvents: 'none',
          userSelect: 'none',
          ...style,
          position: 'absolute',
          filter: 'drop-shadow(1px 1px 1px black)',
          width: Math.floor(maskSize.width),
          height: Math.floor(maskSize.height)
        }}
      >
        <defs>
          <symbol id='card-number-block' style={{ overflow: 'visible' }}>
            <rect rx={0.8} ry={0.8} width={14} height={5} y={30} />
          </symbol>
          <symbol id='card-corner' style={{ overflow: 'visible' }}>
            <path d='M 0 10 L 0 3 A 3 3 0 0 1 3 0 L 10 0' fill='transparent' />
          </symbol>
          <symbol id='card-corner' style={{ overflow: 'visible' }}>
            <path d='M 0 10 L 0 3 A 3 3 0 0 1 3 0 L 10 0' fill='transparent' />
          </symbol>
          <symbol id='card-holder' style={{ overflow: 'visible' }}>
            <path d='M 0 0 L 0 3 L 40 3' fill='transparent' />
          </symbol>
        </defs>

        <g strokeWidth={2} stroke={color} strokeLinecap='round'>
          <use xlinkHref='#card-corner' x={0} y={0} />
          <use xlinkHref='#card-corner' x={0} y={53.92} transform='rotate(-90 0 53.92)' />
          <use xlinkHref='#card-corner' x={86.5} y={0} transform='rotate(90 86.5 0)' />
          <use xlinkHref='#card-corner' x={86.5} y={53.92} transform='rotate(180 86.5 53.92)' />
        </g>

        <g
          fill='transparent'
          strokeWidth={0.35}
          stroke={color}
          strokeDasharray={1}
          strokeLinecap='round'
        >
          <use xlinkHref='#card-number-block' x={8.5} />
          <use xlinkHref='#card-number-block' x={26.66} />
          <use xlinkHref='#card-number-block' x={45.33} />
          <use xlinkHref='#card-number-block' x={64} />
          <use xlinkHref='#card-holder' x={6.5} y={43.92} />
        </g>

        <g
          textAnchor='middle'
          style={{ fontSize: 2.75, fill: color, textTransform: 'uppercase' }}
          strokeLinecap='round'
        >
          <text y={25.5} x='50%'>
            {'номер карты (16 цифр)'}
          </text>
          <text y={46.92} x='75%' style={{ fontSize: 2.25 }}>
            {'имя держателя карты'}
          </text>
        </g>
      </svg>
    </div>
  )
})

CardMask.propTypes = {
  maskedElement: PropTypes.object,
  cameraRef: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  rotate: PropTypes.number,
  fillCoefficient: PropTypes.number,
  offset: PropTypes.arrayOf(PropTypes.string)
}

CardMask.defaultProps = {
  style: {},
  maskedElement: null,
  rotate: 0,
  fillCoefficient: 1,
  offset: []
}

export default CardMask
