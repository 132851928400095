import intl from '#intl'

export const bidsForm = [
  {
    line: 1,
    name: 'comment',
    type: 'text',
    label: intl.transferredWithComment,
    disabled: true
  },
  {
    line: 2,
    name: 'brokerComment',
    type: 'textarea',
    label: intl.brokerComment
  },
  {
    line: 3,
    name: 'treatment',
    type: 'hidden'
  }
]
