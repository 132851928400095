import { ComponentType, FC, useEffect, useState } from 'react'

import { ResponseCode } from '#modules/api/types/common'
import { brokerApi } from '#src/modules/api'

interface IInjectPersonToken {
  token: string
}
interface IBrokerProps extends IInjectPersonToken {
  brokerToken: string
  personId: string
}

function withBrokerToPersonToken<T extends IBrokerProps>(
  wrappedComponent: ComponentType<T>
): FC<Omit<T, keyof IInjectPersonToken>> {
  const Component = wrappedComponent
  return function WithBrokerToPersonToken(props) {
    const [personToken, setPersonToken] = useState<TNullable<string>>(null)
    const { brokerToken, personId } = props
    useEffect(() => {
      async function getPersonToken(): Promise<void> {
        const response = await brokerApi.getPerson2BrokerToken({ brokerToken, personId })
        if (response.code === ResponseCode.success) {
          const {
            data: { token }
          } = response
          setPersonToken(token)
        }
      }
      if (brokerToken && personId) void getPersonToken()
    }, [brokerToken, personId])
    return <Component {...(props as T)} token={personToken} />
  }
}

export default withBrokerToPersonToken
