import './partner-page.scss'

import PropTypes from 'prop-types'
import { Route, Routes } from 'react-router-dom'

import { PageWithAutomaticTitleSetter } from '#components/Page/PageWithAutomaticTitleSetter'
import PartnerLoanInfo from '#components/PartnerPagesNew/PartnerLoanInfo'
import { partnerBidsAndAccountsNested, ROUTES } from '#constants/common'

import { Page404 } from '../Page404/Page404'
import PartnerStat from '../PartnerStat'
import partnerStructure from './models/partnerStructure'
import Navigation from './Navigation'
import PartnerContent from './PartnerContent'
import PartnerLoan from './PartnerLoan'
import PartnerMain from './PartnerMain'
import withData from './withData'

export class PartnerPage extends PageWithAutomaticTitleSetter {
  constructor(props) {
    super(props)
    this.state = {
      ...this.initPage()
    }
  }

  initPage() {
    const partnerComponents = {}
    Object.keys(partnerStructure).forEach((key) => {
      const componentName = 'Partner' + key.replace(/^\w/, (c) => c.toUpperCase()) + 'WithData'
      partnerComponents[componentName] = withData(PartnerContent, key, partnerStructure[key])
    })
    return partnerComponents
  }

  render() {
    const {
      brokerToken,
      params,
      location: { pathname }
    } = this.props
    const { PartnerAccountsWithData, PartnerBidsWithData, PartnerLoansWithData } = this.state

    // определяем основной url, откуда попытались просмотреть анкету на прямую
    const mainUrl = pathname.split('/')

    return (
      <div className='page partner-page'>
        <Navigation match={params} pathname={pathname} />
        <div className='partner-page__content'>
          <Routes>
            <Route
              path={ROUTES.main}
              element={<PartnerMain {...this.props} brokerToken={brokerToken} className='main' />}
            />
            <Route
              path={ROUTES.partnerLoan}
              element={<PartnerLoan {...this.props} brokerToken={brokerToken} />}
            />
            <Route path={ROUTES.partnerBids} element={<PartnerBidsWithData />} />
            <Route path={ROUTES.partnerAccounts} element={<PartnerAccountsWithData />} />
            {partnerBidsAndAccountsNested.map((url, index) => (
              <Route
                key={index}
                path={url}
                element={
                  <PartnerLoanInfo
                    openFrom={`/${mainUrl[1]}`}
                    personId={mainUrl[2]}
                    brokerToken={brokerToken}
                    {...this.props}
                  />
                }
              />
            ))}
            <Route
              path={ROUTES.partnerStat}
              element={<PartnerStat {...this.props} brokerToken={brokerToken} />}
            />
            <Route
              path={ROUTES.partnerBrokerStat}
              element={<PartnerStat {...this.props} brokerToken={brokerToken} transferStat />}
            />
            <Route path={ROUTES.partnerClientBids} element={<PartnerLoansWithData />} />
            <Route path={'*'} element={<Page404 />} />
          </Routes>
        </div>
      </div>
    )
  }
}
PartnerPage.displayName = 'PartnerPage'
PartnerPage.propTypes = {
  brokerToken: PropTypes.string
}
