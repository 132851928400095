export class EXIFeraser {
  constructor(file) {
    this.file = file
    this.status = 'with EXIF'
    this.preview = null
  }

  readFile() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    const jpegMime = 'image/jpeg'
    return new Promise((resolve) => {
      const fr = new window.FileReader()
      fr.onload = function () {
        if (!(this.result instanceof ArrayBuffer)) resolve(this.result)
        const dv = new DataView(this.result)
        let offset = 0
        let recess = 0
        const pieces = []
        let i = 0
        // проверяем, что файл - jpeg (любой JPG начинается с 0xFFD8 и заканчивается 0xFFD9)
        // 0xFF+Marker Number(1 byte)+Data size(2 bytes)+Data(n bytes)
        if (dv.getUint16(offset) === 0xffd8) {
          offset += 2
          let app1 = dv.getUint16(offset)
          offset += 2
          while (offset < dv.byteLength) {
            // Exif всегда пищется в маркер APP1(0xFFE1)
            if (app1 === 0xffe1) {
              pieces[i] = {
                recess,
                offset: offset - 2
              }
              recess = offset + dv.getUint16(offset)
              i++
            } else if (app1 === 0xffda) {
              break
            }
            offset += dv.getUint16(offset)
            app1 = dv.getUint16(offset)
            offset += 2
          }
          if (pieces.length > 0) {
            const newPieces = []
            pieces.forEach(function (v) {
              newPieces.push(this.result.slice(v.recess, v.offset))
            }, this)
            newPieces.push(this.result.slice(recess))
            that.status = 'without EXIF'
            that.blob = new window.Blob(newPieces, { type: jpegMime })
            const urlCreator = window.URL || window.webkitURL
            const imageUrl = urlCreator.createObjectURL(that.blob)
            resolve(imageUrl)
          } else {
            that.status = 'EXIF not found'
            const urlCreator = window.URL || window.webkitURL
            that.blob = new window.Blob([dv], { type: jpegMime })
            resolve(urlCreator.createObjectURL(that.blob))
          }
        }
      }
      fr.readAsArrayBuffer(that.file)
    })
  }
}

export default EXIFeraser
