import ReactNotification from 'react-notifications-component'
import { connect } from 'react-redux'
import { Route, Routes } from 'react-router-dom'

import { LogInWithToken } from '#components/App/LogInWithToken'
import { ChangePasswordPagePartner } from '#components/ChangePasswordPage'
import PartnerLoginPage from '#components/PartnerPagesNew/PartnerLoginPage'
import { ROUTES } from '#constants/common'
import { withRouter } from '#hoc/withRouter'
import { updateLoggedInToken, updateReferer } from '#reducers/app/appSlice'
import { fetchLoanConditionsWithDefaultPromoCode } from '#reducers/loanConditions/effects'
import { fetchPartnerData } from '#reducers/partnerData/effects'
import { updateProp } from '#reducers/partnerData/partnerDataSlice'

import { Modal } from '../Modal/Modal'
import { PartnerPage } from '../PartnerPagesNew/PartnerPage'
import { Layout } from './Layout'

class PartnerLayout extends Layout {
  counterId
  constructor(props) {
    super(props)
    props.dispatch(updateProp({ value: this.logout }))
    this.handlePartnerLogin = this.handlePartnerLogin.bind(this)
  }

  componentDidUpdate(prevProps) {
    const {
      broker: { groupId }
    } = this.props

    if (prevProps.app.loggedInToken !== this.props.app.loggedInToken) {
      this.props.dispatch(
        fetchPartnerData({
          part: 'info',
          brokerToken: this.props.app.loggedInToken
        })
      )
    }
    // если расширенные данные по брокеру потеряны, но необходимо запросить новые condition
    // потому как могут появиться новые условия у брокера
    if (prevProps.broker !== this.props.broker && groupId)
      this.props.dispatch(fetchLoanConditionsWithDefaultPromoCode({ groupId }))
  }

  loggedInToken() {
    return super.loggedInToken('brokerToken')
  }

  logout() {
    super.logout('brokerToken', 'brokerLogout')
  }

  handlePartnerLogin() {
    const { dispatch } = this.props
    dispatch(updateLoggedInToken({ token: this.loggedInToken() }))
    dispatch(updateReferer({ referer: window.location.href }))
  }

  handleCloseModal = () => this.setState({ modalDocument: null })

  render() {
    const { modalDocument } = this.state
    const {
      broker: { loading, isFetched, params },
      location
    } = this.props

    const brokerToken = this.loggedInToken()
    if (loading && !isFetched) return null

    return (
      <div id='layout'>
        <ReactNotification />
        <Routes>
          <Route
            path={'/*'}
            element={
              brokerToken ? (
                <PartnerPage {...this.props} brokerToken={brokerToken} />
              ) : (
                <PartnerLoginPage {...this.props} handlePartnerLogin={this.handlePartnerLogin} />
              )
            }
          />
          <Route
            path={ROUTES.logInWithTokenBroker}
            element={<LogInWithToken logInFrom={'broker'} {...this.props} />}
          />
          <Route
            path={ROUTES.changePassword}
            element={<ChangePasswordPagePartner match={params} location={location} />}
          />
        </Routes>
        {modalDocument && <Modal {...modalDocument} onClose={this.handleCloseModal} />}
      </div>
    )
  }
}

export default withRouter(
  connect((state) => ({
    app: state.app,
    broker: state.brokerInfo
  }))(PartnerLayout)
)
