import { TModelItem, TSelectOption } from '#components/Form/Form'
import intl from '#intl'

import { TButtonType } from './types'

interface IButtonSelectOption extends TSelectOption {
  value: TButtonType
}

type TButtonModel = TModelItem & {
  options?: IButtonSelectOption[]
}

export const ButtonModel: TButtonModel[] = [
  {
    line: 1,
    name: 'text',
    label: intl.buttonText,
    placeholder: intl.buttonText,
    required: true,
    emptyMessage: intl.fieldEmpty,
    ruleMaxLength: 65535
  },
  {
    line: 2,
    name: 'type',
    type: 'select',
    label: intl.buttonType,
    placeholder: intl.buttonType,
    required: true,
    emptyMessage: intl.fieldEmpty,
    options: [
      {
        disableReason: null,
        enabled: 1,
        text: 'Подтвердить',
        value: 'ACCEPT'
      },
      {
        disableReason: null,
        enabled: 1,
        text: 'Отклонить',
        value: 'REJECT'
      },
      {
        disableReason: null,
        enabled: 1,
        text: 'Понятно',
        value: 'CLOSE'
      }
    ]
  },
  {
    line: 3,
    name: 'colorButton',
    type: 'color',
    label: intl.buttonColor,
    placeholder: intl.buttonColor,
    required: true,
    emptyMessage: 'Выберите цвет'
  },
  {
    line: 4,
    name: 'colorText',
    type: 'color',
    label: intl.buttonTextColor,
    placeholder: intl.buttonTextColor,
    required: true,
    emptyMessage: 'Выберите цвет'
  }
]
