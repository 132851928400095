import { TModelItem } from '#components/Form/Form'
import intl from '#intl'

export const changePasswordForm: TModelItem[] = [
  {
    line: 1,
    name: 'login',
    type: 'text',
    label: intl.phoneOrEmail,
    placeholder: intl.loginPlaceholder,
    required: true,
    emptyMessage: intl.fieldEmpty
  }
]

export const changePasswordFormPartnerRequest: TModelItem[] = [
  {
    line: 1,
    name: 'login',
    type: 'text',
    label: intl.emailLabel,
    placeholder: '',
    required: true,
    emptyMessage: intl.fieldEmpty
  },
  {
    line: 2,
    name: 'recaptcha',
    type: 'recaptcha',
    required: true,
    emptyMessage: intl.fieldEmpty
  }
]

export const changePasswordFormPartnerSubmit: TModelItem[] = [
  {
    line: 1,
    name: 'login',
    type: 'text',
    label: intl.emailLabel,
    placeholder: '',
    required: true,
    emptyMessage: intl.fieldEmpty
  },
  {
    line: 2,
    name: 'code',
    type: 'number',
    label: intl.confirmationCode,
    maxLength: 4,
    placeholder: '',
    required: true,
    emptyMessage: intl.fieldEmpty
  }
]
