import './change-password-page.scss'

import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { PageWithAutomaticTitleSetter } from '#components/Page/PageWithAutomaticTitleSetter'
import { ROUTES } from '#constants/common'
import intl from '#intl'

import { ChangePasswordFormPartner } from '../ChangePasswordForm'
import LinkToSupport from '../PersonalAreaPage/components/LinkToSupport'

export class ChangePasswordPagePartner extends PageWithAutomaticTitleSetter {
  constructor(props) {
    super(props)

    this.name = 'change-password'
    this.title = intl.changePasswordPageDocumentTitle
    this.description = intl.changePasswordPageDocumentDescription
  }

  render() {
    return (
      <div className='page change-password-page'>
        <div className='page__content'>
          <div className='container'>
            <h1 className='page__title'>{intl.changePasswordPageTitle}</h1>
            <ChangePasswordFormPartner {...this.props} />
          </div>
          <div className='change-password-form__link'>
            <Link to={ROUTES.main}>{intl.returnToMain}</Link>
          </div>
          <LinkToSupport style={{ maxWidth: 300 }} />
        </div>
      </div>
    )
  }
}

ChangePasswordPagePartner.propTypes = {
  className: PropTypes.string
}
