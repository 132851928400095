import { ROUTES } from '#constants/common'
import intl from '#intl'

export const navigationList = [
  {
    url: ROUTES.partnerLoan,
    caption: intl.newLoan,
    icon: 'plus-circle-solid'
  },
  {
    url: ROUTES.partnerClientBids,
    caption: intl.partnerClientBids,
    icon: 'folder-solid'
  },
  {
    url: ROUTES.partnerBids,
    caption: intl.transferredBids,
    icon: 'exchange-alt-solid'
  },
  {
    url: ROUTES.partnerAccounts,
    caption: intl.allAccounts,
    icon: 'address-book-solid'
  },
  {
    url: ROUTES.partnerStat,
    caption: intl.statistics,
    icon: 'chart-pie-solid'
  },
  {
    url: ROUTES.partnerBrokerStat,
    caption: intl.statisticsBroker,
    icon: 'coins-solid',
    partnerOnly: true // возможно необходимо поменять на роут ROUTES.partnerStat
  }
]
