import classNames from 'classnames'
import { noop } from 'lodash'
import { createRef } from 'react'
import { Cookies } from 'react-cookie'
import { connect } from 'react-redux'

import Button from '#components/Button'
import AssignmentOfRightsAgreementLabel from '#components/PersonalAreaPage/components/ConfirmLoanForm/AssignmentOfRightsAgreementLabel'
import CommonAgreementsLabel from '#components/PersonalAreaPage/components/ConfirmLoanForm/CommonAgreementsLabel'
import DoctorNearbyAgreementLabel from '#components/PersonalAreaPage/components/ConfirmLoanForm/DoctorNearbyAgreementLabel'
import { DropDownIcon } from '#components/PersonalAreaPage/components/ConfirmLoanForm/DropDownIcon'
import InsuranceAgreementsLabel from '#components/PersonalAreaPage/components/ConfirmLoanForm/InsuranceAgreementsLabel'
import PersonDataAgreementsLabel from '#components/PersonalAreaPage/components/ConfirmLoanForm/PersonDataAgreementsLabel'
import ServiceRiskAgreementLabel from '#components/PersonalAreaPage/components/ConfirmLoanForm/ServiceRiskAgreementLabel'
import DocumentsUploadModalForm from '#components/PersonalAreaPage/components/DocumentsUploadModalForm'
import { AML_CFT_AGREEMENT, LOAN_AGREEMENT, NBKI_AGREEMENT, TERMS } from '#constants/common'
import intl from '#intl'
import { InsuranceList } from '#modules/InsuranceList'
import { isTkbForPartnerSelector } from '#reducers/loanFormState/loanFormStateSlice'
import { legalCheck } from '#reducers/settingsSlice'
import model from '#src/models/confirmFormBroker'
import { brokerApi } from '#src/modules/api'

import { ConfirmForm } from './ConfirmForm'
const cookies = new Cookies()

class ConfirmFormPartner extends ConfirmForm {
  constructor(props) {
    super(props)
    this.model = model
    this.initPersonalDataAgreementHelper(model)
    this.state = {
      ...this.state,
      data:
        {
          ...props.data,
          ...this.personalDataAgreementHelper.presetChildData(model, props.legalCheck),
          personDataAgreementChecked: props.legalCheck && Number(props.legalCheck) ? 0 : 1
        } || {},
      insuranceCardEnabled: false,
      insuranceLifeEnabled: false,
      doctorNearbyEnabled: false,
      serviceRiskEnabled: false,
      isRepeated: false,
      amountLimitWithoutDocuments: null,
      insuranceLife: { insuranceType: 'insurance' },
      insuranceCard: { insuranceType: 'insurance_card' },
      doctorNearby: { insuranceType: 'serviceDoctorNearby' },
      serviceRisk: { insuranceType: 'serviceRisk' },
      form: this.computeForm(),
      loadingDocuments: {}
    }
    this.handleControlBlur = this.handleControlBlur.bind(this)
    this.handleRepeatSmsCode = this.handleRepeatSmsCode.bind(this)

    this.agreements = [
      {
        label: intl.documentFT,
        type: AML_CFT_AGREEMENT
      },
      {
        label: intl.agreementNBKI,
        type: NBKI_AGREEMENT
      },
      {
        label: intl.agreementLoan,
        type: LOAN_AGREEMENT
      },
      {
        label: intl.generalTerms,
        type: TERMS
      }
    ]
    this.isTkbProduct = props.isTkbProduct
    this.modalRef = createRef()
    this.insuranceHelper = new InsuranceList(props.loanConditions.data)
  }

  setInsuranceFor = (type) => {
    const { insuranceType } = this.state[type]
    const insurance = this.insuranceHelper.findInsuranceInList(insuranceType)
    if (insurance) {
      const { insuranceDefault } = insurance
      this.setState((state) => ({
        ...state,
        data: {
          ...state.data,
          ...{ [`${type}Checked`]: this.isTkbProduct ? 0 : insuranceDefault }
        },
        [`${type}Enabled`]: true,
        [type]: insurance
      }))
    }
  }

  setInsuranceState = () => {
    this.setInsuranceFor('insuranceLife')
    this.setInsuranceFor('insuranceCard')
    this.setInsuranceFor('doctorNearby')
    this.setInsuranceFor('serviceRisk')
  }

  async componentDidMount() {
    // получаем данные по совокупному доходу
    this.setState((state) => ({
      ...state,
      data: {
        ...state.data,
        ...{ agreement: 0, assignmentOfRightsChecked: 0, nbkiAgreementChecked: 0 }
      }
    }))

    this.setInsuranceState()

    const {
      data: { brokerToken, personId },
      legalCheck
    } = this.props
    // пытаемся восстановить данные по совокупному доходу
    // totalIncome: responseData.totalIncome,
    // totalIncomeSnap: responseData.totalIncome // snapshot для последующего расчета совокупного расхода,
    // и выдачи предупреждений пользователю в случае выполнения условий
    try {
      const response = await brokerApi.restoreIncome({
        brokerToken,
        personId
      })
      if (response.code === 0) {
        const {
          data: { default: totalIncome, min }
        } = response

        this.setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            ...{
              totalIncome: legalCheck && Number(legalCheck) ? '' : totalIncome,
              totalIncomeSnap: min
            }
          }
        }))
      }
    } catch (err) {
      console.error(err)
    }

    // пытаемся восстановить данные по ИНН
    // inn: responseData.predictedInn || responseData.inn || null
    try {
      const { code: codeInn, data: dataInn = null } = await brokerApi.restoreINN({
        brokerToken,
        personId
      })
      if (codeInn === 0) {
        const { inn } = dataInn
        this.setState((prevState) => ({
          ...prevState,
          data: { ...prevState.data, ...{ inn } }
        }))
      }
    } catch (err) {
      console.error(err)
    }
  }

  componentWillUnmount() {
    cookies.remove('token')
  }

  async handleSubmit(evt) {
    evt && evt.preventDefault()
    const { onSubmit = noop, legalCheck } = this.props
    const {
      data,
      loading,
      data: {
        veonProcessingPersonalDataChecked,
        megafonProcessingPersonalDataChecked,
        mtsProcessingPersonalDataChecked,
        t2ProcessingPersonalDataChecked
      }
    } = this.state

    if (loading || !this.validateForm(true)) return

    if (
      !veonProcessingPersonalDataChecked ||
      !megafonProcessingPersonalDataChecked ||
      !mtsProcessingPersonalDataChecked ||
      !t2ProcessingPersonalDataChecked
    ) {
      return this.setState((state) => ({
        ...state,
        errors: {
          ...state.errors,
          common:
            'Необходимо выбрать согласие на обработку персональных данных всех операторов связи'
        }
      }))
    }

    if (legalCheck && Number(legalCheck)) {
      const {
        data: { loanWay, fincardProcessingPersonalDataChecked, tkbProcessingPersonalDataChecked }
      } = this.state
      if (loanWay === 'bankCard' && !fincardProcessingPersonalDataChecked) {
        return this.setState((state) => ({
          ...state,
          errors: {
            ...state.errors,
            common: 'Необходимо выбрать согласие на обработку персональных данных: ООО "Финкарта"'
          }
        }))
      }
      if (this.isTkbProduct && !tkbProcessingPersonalDataChecked) {
        return this.setState((state) => ({
          ...state,
          errors: {
            ...state.errors,
            common: 'Необходимо выбрать согласие на обработку персональных данных: ТКБ БАНК ПАО'
          }
        }))
      }
    }

    this.setState({ loading: true })
    try {
      const response = await brokerApi.brokerConfirmCredit(data)
      if (response.code === 0) return onSubmit()
      this.checkResponseCode(response)
    } catch (err) {
      this.setError(err)
    } finally {
      this.setState({ loading: false })
    }
  }

  setError(err) {
    this.setState((state) => ({
      ...state,
      errors: { ...state, common: err.message || intl.serverError }
    }))
  }

  checkResponseCode(response) {
    if (response.code === 100 && response.data.amountLimitWithoutDocuments) {
      this.setState({ amountLimitWithoutDocuments: response.data.amountLimitWithoutDocuments })
      this.modalRef.current.show()
    } else {
      this.setError(response)
    }
  }

  handleDownLoadInsurance = async (documentType, insuranceType = 'insurance_card') => {
    const {
      data: { brokerToken, creditId, personId }
    } = this.props
    this.setState((state) => ({
      ...state,
      loadingDocuments: { ...state.loadingDocuments, [documentType]: true }
    }))
    try {
      const response = await brokerApi.brokerDownloadDocument({
        document: documentType,
        brokerToken,
        creditId,
        personId,
        type: insuranceType
      })
      const url = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${documentType}.pdf`)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      this.setState({ errors: { common: error.message || intl.serverError } })
    } finally {
      this.setState((state) => ({
        ...state,
        loadingDocuments: { ...state.loadingDocuments, [documentType]: false }
      }))
    }
  }

  handleControlBlur({ target }) {
    const { value, name } = target
    const {
      data: { totalIncomeSnap }
    } = this.state

    if (name === 'totalIncome') {
      if (isNaN(value)) {
        this.setState((prevState) => ({
          ...prevState,
          errors: { ...prevState.errors, [name]: 'Значение должно быть числом' }
        }))
      } else if (Number(value) < totalIncomeSnap) {
        const { legalCheck } = this.props
        const totalIncome = legalCheck && Number(legalCheck) ? Number(value) : totalIncomeSnap
        this.setState((prevState) => ({
          ...prevState,
          data: { ...prevState.data, totalIncome }
        }))
      }
    }
  }

  async handleRepeatSmsCode() {
    const itemName = 'smsCode'
    const {
      data: { brokerToken, personId }
    } = this.props
    try {
      this.setState((prevState) => ({
        ...prevState,
        isRepeated: true,
        data: { ...prevState.data, [itemName]: '' },
        loadingItems: { ...prevState.loadingItems, [itemName]: true },
        disabledItems: { ...prevState.disabledItems, [itemName]: true },
        errors: { ...prevState.errors, [itemName]: null }
      }))
      const response = await brokerApi.repeatCreditConfirmCode({ brokerToken, personId })

      this.setState((prevState) => ({
        ...prevState,
        loadingItems: { ...prevState.loadingItems, [itemName]: false },
        disabledItems: { ...prevState.disabledItems, [itemName]: false },
        errors: { ...prevState.errors, ...this.getItemResponseErrors(itemName, response) }
      }))
    } catch (error) {
      this.setState((prevState) => ({
        ...prevState,
        loadingItems: { ...prevState.loadingItems, [itemName]: false },
        disabledItems: { ...prevState.disabledItems, [itemName]: false },
        errors: { ...prevState.errors, [itemName]: intl.serverError }
      }))
    }
  }

  uploadPersonDocuments = async (params) => {
    const { brokerToken, personId } = this.props.data
    const { data = {} } = await brokerApi.getPerson2BrokerToken({ personId, brokerToken })
    return brokerApi.brokerUploadPersonDocument({ personToken: data.token, brokerToken, ...params })
  }

  mapInsuranceTypeToModelItem = (type) =>
    ({
      insuranceLife: {
        label: '',
        type: 'checkbox',
        name: 'insuranceLifeChecked'
      },
      insuranceCard: {
        label: '',
        type: 'checkbox',
        name: 'insuranceCardChecked'
      },
      serviceDoctorNearby: {
        label: '',
        type: 'checkbox',
        name: 'doctorNearbyChecked'
      },
      assignmentOfRights: {
        label: '',
        type: 'checkbox',
        name: 'assignmentOfRightsChecked'
      },
      serviceRisk: {
        label: '',
        type: 'checkbox',
        name: 'serviceRiskChecked'
      }
    }[type])

  getOverridePropsForInsurance = (insurance) => {
    let overrideProps = {}
    const { insuranceDefault } = insurance
    if (insuranceDefault && Number(insuranceDefault) === 2)
      overrideProps = { ...overrideProps, disabled: true }
    return overrideProps
  }

  renderInsuranceAgreement = (insurance, type) => {
    const { loadingDocuments } = this.state
    const {
      insuranceCost,
      insuranceEnabled,
      insurancePeriod,
      insuranceSumInsured,
      insuranceType
    } = insurance
    const componentProps = {
      ...insurance,
      period: insurancePeriod,
      amount: insuranceSumInsured, // надо глянуть для ЛК чему равна сумма
      cost: insuranceCost,
      type: insuranceType
    }
    const overrideProps = this.getOverridePropsForInsurance(insurance)
    return insuranceEnabled
      ? super.renderItem({
          ...this.mapInsuranceTypeToModelItem(type),
          customLabel: (
            <InsuranceAgreementsLabel
              {...componentProps}
              loadingItems={loadingDocuments}
              onDownload={this.handleDownLoadInsurance}
            />
          ),
          ...overrideProps
        })
      : null
  }

  renderDoctorNearbyAgreement = (insurance, type) => {
    const { loadingDocuments } = this.state
    const { insuranceCost, insuranceEnabled, insurancePeriod, insuranceCommission } = insurance
    const componentProps = {
      ...insurance,
      period: insurancePeriod,
      cost: insuranceCost,
      commission: insuranceCommission
    }
    const overrideProps = this.getOverridePropsForInsurance(insurance)
    return insuranceEnabled
      ? super.renderItem(
          {
            ...this.mapInsuranceTypeToModelItem(type),
            customLabel: (
              <DoctorNearbyAgreementLabel
                {...componentProps}
                loadingItems={loadingDocuments}
                onDownload={this.handleDownLoadInsurance}
              />
            )
          },
          overrideProps
        )
      : null
  }

  renderServiceRisk = (insurance, type) => {
    const { loadingDocuments } = this.state
    const { insuranceEnabled, insuranceCommission } = insurance
    const componentProps = {
      ...insurance,
      commission: insuranceCommission
    }
    const overrideProps = this.getOverridePropsForInsurance(insurance)
    return insuranceEnabled
      ? super.renderItem(
          {
            ...this.mapInsuranceTypeToModelItem(type),
            customLabel: (
              <ServiceRiskAgreementLabel
                {...componentProps}
                loadingItems={loadingDocuments}
                onDownload={this.handleDownLoadInsurance}
              />
            )
          },
          overrideProps
        )
      : null
  }

  renderAssignmentOfRightsAgreement = (type) =>
    super.renderItem({
      ...this.mapInsuranceTypeToModelItem(type),
      customLabel: <AssignmentOfRightsAgreementLabel />
    })

  renderCommonAgreements = (item) => {
    const {
      data: { creditId },
      loanFormState: {
        data: { creditType }
      }
    } = this.props
    const { loadingDocuments } = this.state
    return super.renderItem({
      ...item,
      customLabel: (
        <CommonAgreementsLabel
          creditId={creditId}
          creditType={creditType}
          loadingItems={loadingDocuments}
          onDownload={this.handleDownLoadInsurance}
        />
      )
    })
  }

  renderInsuranceLifeCheckbox = () => {
    const { insuranceLifeEnabled, insuranceLife } = this.state
    if (insuranceLifeEnabled) {
      return (
        <div className='form__line'>
          {this.renderInsuranceAgreement(insuranceLife, 'insuranceLife')}
        </div>
      )
    }
    return null
  }

  renderInsuranceCardCheckbox = () => {
    const {
      data: { loanWay },
      insuranceCardEnabled,
      insuranceCard
    } = this.state

    if (loanWay === 'bankCard' && insuranceCardEnabled) {
      return (
        <div className='form__line'>
          {this.renderInsuranceAgreement(insuranceCard, 'insuranceCard')}
        </div>
      )
    }
    return null
  }

  renderServiceDoctorNearbyCheckbox = () => {
    const { doctorNearbyEnabled, doctorNearby } = this.state
    if (doctorNearbyEnabled) {
      return (
        <div className='form__line'>
          {this.renderDoctorNearbyAgreement(doctorNearby, 'serviceDoctorNearby')}
        </div>
      )
    }
    return null
  }

  renderServiceRiskCheckbox = () => {
    const { serviceRiskEnabled, serviceRisk } = this.state
    if (serviceRiskEnabled)
      return <div className='form__line'>{this.renderServiceRisk(serviceRisk, 'serviceRisk')}</div>
    return null
  }

  handleNbkiDocumentDownload = (document, fileName, type) =>
    this.handleDownLoadInsurance(document, type)

  renderNbkiAgreement = (item) => {
    const {
      data: { creditId },
      loanFormState: {
        data: { creditType }
      }
    } = this.props
    const { loadingDocuments } = this.state

    return super.renderItem({
      ...item,
      customLabel: (
        <PersonDataAgreementsLabel
          creditId={creditId}
          creditType={creditType}
          loadingItems={loadingDocuments}
          onDownload={this.handleNbkiDocumentDownload}
          documentType={NBKI_AGREEMENT}
        />
      )
    })
  }

  renderPersonDataAgreement = (item) => {
    const { loadingDocuments, isAgreementExpanded } = this.state
    const {
      data: { creditId },
      loanFormState: {
        data: { creditType }
      }
    } = this.props
    const { child, name } = item
    const documentType = this.mapDocumentTypeToModel[name]
    const isSelectedAllAgreements = this.isSelectedAllAgreements(item)

    return (
      <div className={'agreement-wrapper'}>
        {super.renderItem({
          ...item,
          iconName: isSelectedAllAgreements ? null : 'minus-solid',
          customLabel: (
            <div className={'d-flex justify-content-between'}>
              <PersonDataAgreementsLabel
                creditId={creditId}
                creditType={creditType}
                loadingItems={loadingDocuments}
                onDownload={this.handleNbkiDocumentDownload}
                documentType={documentType}
              />
              <DropDownIcon
                isExpanded={isAgreementExpanded[name]}
                onClick={this.handleExpandAgreement(name)}
              />
            </div>
          )
        })}
        <div
          className={classNames({
            'ms-4': true,
            'agreement-extended-list': true,
            'agreement-expanded': isAgreementExpanded[name]
          })}
        >
          {child.map((item) => {
            const { name } = item
            const documentType = this.mapDocumentTypeToModel[name]
            return super.renderItem({
              ...item,
              customLabel: item.label ? null : (
                <PersonDataAgreementsLabel
                  creditId={creditId}
                  creditType={creditType}
                  loadingItems={loadingDocuments}
                  onDownload={this.handleDocumentDownload}
                  documentType={documentType}
                />
              )
            })
          })}
        </div>
      </div>
    )
  }

  render() {
    const { form, errors, loading, isRepeated, amountLimitWithoutDocuments } = this.state
    const { className } = this.props

    const classes = classNames(
      {
        form: true,
        'confirm-form': true
      },
      className
    )

    return (
      <>
        <form className={classes} noValidate onSubmit={this.handleSubmit}>
          {form.lines.map((line, key) => (
            <div className='form__line' key={key}>
              {line.items.map((item) => {
                switch (item.name) {
                  case 'smsCode':
                    if (isRepeated) {
                      return (
                        !this.checkDepends(item) &&
                        this.renderItem({
                          ...item,
                          ...{
                            hidden: false,
                            disabled: item.loading,
                            hint: !item.loading && (
                              <span className='link link_pseudo' onClick={this.handleRepeatSmsCode}>
                                {intl.repeat}
                              </span>
                            ),
                            bottomText:
                              'Если в течение минуты Вы не получили СМС - код, проверьте корректность номера и запросите его повторно. Вы  можете изменить номер в поле выше.'
                          }
                        })
                      )
                    }
                    return (
                      !this.checkDepends(item) &&
                      this.renderItem({
                        ...item,
                        ...{
                          hidden: false,
                          disabled: item.loading,
                          hint: !item.loading && (
                            <span className='link link_pseudo' onClick={this.handleRepeatSmsCode}>
                              {intl.repeat}
                            </span>
                          )
                        }
                      })
                    )
                  case 'agreement':
                    return this.renderCommonAgreements(item)
                  case 'nbkiAgreementChecked':
                    return this.renderNbkiAgreement(item)
                  case 'personDataAgreementChecked':
                    return this.renderPersonDataAgreement(item)
                  default:
                    return !this.checkDepends(item) && this.renderItem(item)
                }
              })}
            </div>
          ))}
          <div className='form__line'>
            {this.renderAssignmentOfRightsAgreement('assignmentOfRights')}
          </div>
          {!this.isTkbProduct && this.renderInsuranceLifeCheckbox()}
          {!this.isTkbProduct && this.renderInsuranceCardCheckbox()}
          {!this.isTkbProduct && this.renderServiceDoctorNearbyCheckbox()}
          {!this.isTkbProduct && this.renderServiceRiskCheckbox()}
          <div className='form__bottom'>
            {errors.common && <div className='form__error'>{errors.common}</div>}
            <Button type={'button'} size='l' fluid loading={loading}>
              {intl.confirm}
            </Button>
          </div>
        </form>
        <DocumentsUploadModalForm
          ref={this.modalRef}
          uploadDocumentsAction={this.uploadPersonDocuments}
          maxAmount={amountLimitWithoutDocuments}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  loanFormState: state.loanFormState,
  loanConditions: state.loanConditions,
  isTkbProduct: isTkbForPartnerSelector(state),
  legalCheck: legalCheck(state)
})

export default connect(mapStateToProps, null)(ConfirmFormPartner)
