import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'
import { filter, noop, pickBy, size } from 'lodash'
import PropTypes from 'prop-types'
import { forwardRef, Fragment, useCallback, useImperativeHandle, useRef, useState } from 'react'

import Button from '#components/Button'
import Card from '#components/Card'
import { Form, FormItem } from '#components/Form'
import Icon from '#components/Icon'
import Overlay from '#components/Overlay'
import SmartControl from '#components/SmartControl'
import { AJAX_CONFIG_FIELD_NAME } from '#constants/common'
import intl from '#intl'
import { CancelRequestError } from '#modules/api/exceptions'
import { formatsThatNeedBeConverted, JPEGConverter } from '#modules/JPEGConverter'
import { formatAsMoney } from '#services/helper'

const model = [
  {
    name: 'passport',
    type: 'file',
    label: intl.passportPhoto,
    placeholder: intl.filesPlaceholder,
    required: true
  },
  {
    name: 'selfie',
    type: 'file',
    label: intl.passportSelfie,
    placeholder: intl.filesPlaceholder,
    required: true
  }
]

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 8,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor: '#CACFD2'
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#50E3C2'
  }
}))(LinearProgress)

const filesRequired = size(filter(model, { type: 'file' }))
const imageConverter = new JPEGConverter()
class UploadDocumentsForm extends Form {
  state = {
    ...this.state,
    loadingItems: {},
    uploadProgress: {
      passport: 0,
      selfie: 0
    },
    aborts: {
      passport: new AbortController(),
      selfie: new AbortController()
    }
  }
  dataQa = 'uploadDocumentsForm'

  onUploadProgress = (fileName) => (event) => {
    const percentCompleted = Math.round((event.loaded * 100) / event.total)
    this.setState((state) => ({
      ...state,
      uploadProgress: {
        ...state.uploadProgress,
        [fileName]: percentCompleted
      }
    }))
  }
  handleAddFile = (name) => async ({ target }) => {
    const [file] = target.value
    const { uploadDocumentsAction } = this.props
    const { aborts } = this.state
    this.setState((state) => ({ ...state, loadingItems: { ...state.loadingItems, [name]: true } }))
    try {
      const response = await uploadDocumentsAction({
        type: name,
        file: await imageConverter.convertToJpeg(file, name),
        onUploadProgress: this.onUploadProgress(name),
        signal: aborts[name].signal
      })
      if (response.code !== 0) throw response
      const { data } = response[AJAX_CONFIG_FIELD_NAME]
      this.setState((state) => ({
        ...state,
        errors: { ...state.errors, [name]: null },
        data: { ...state.data, [name]: data?.file?.preview }
      }))
    } catch (err) {
      if (!(err instanceof CancelRequestError)) {
        this.setState((state) => ({
          ...state,
          data: { ...state.data, [name]: null },
          errors: { ...state.errors, [name]: err.message || intl.serverError }
        }))
        throw err // Прокидываем ошибку в smartControlComponent для очистки превью
      }
    } finally {
      this.setState((state) => ({
        ...state,
        loadingItems: { ...state.loadingItems, [name]: false }
      }))
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { onSubmit = noop } = this.props
    onSubmit()
  }

  handleRemoveFile = (name) => () => {
    const { aborts } = this.state
    aborts[name].abort()
    this.setState((state) => ({
      ...state,
      data: {
        ...state.data,
        [name]: null
      },
      uploadProgress: {
        ...state.uploadProgress,
        [name]: 0
      },
      loadingItems: { ...state.loadingItems, [name]: false },
      aborts: {
        ...state.aborts,
        [name]: new AbortController()
      }
    }))
  }

  renderFileItem = (item) => {
    const { name } = item
    const { errors, data, loadingItems, uploadProgress } = this.state

    const error = errors[name]
    let value = data[name]
    let isLoading = loadingItems[name]
    const progress = uploadProgress[name]

    if (
      Boolean(value) &&
      typeof value === 'object' &&
      formatsThatNeedBeConverted.includes(value[0]?.type)
    ) {
      value = null
      isLoading = true
    }

    return (
      <>
        <FormItem {...item} error={error} className='mb-4'>
          <SmartControl
            {...item}
            value={value}
            valid={!error}
            loading={isLoading}
            onFilesAdd={this.handleAddFile(name)}
            onChange={this.handleControlChange}
            onRemove={this.handleRemoveFile(name)}
            previewStyle={{ height: '200px' }}
            accept='image/jpg, image/jpeg, image/png, image/heic, image/heif'
          />
        </FormItem>
        {['passport', 'selfie'].includes(name) ? (
          <div className={'mb-4'}>
            {Boolean(progress) && <BorderLinearProgress variant='determinate' value={progress} />}
          </div>
        ) : null}
      </>
    )
  }

  isDisabledButton = () => {
    const {
      loadingItems: { passport, selfie },
      data
    } = this.state
    if (size(pickBy(data)) < filesRequired || passport || selfie) return true
  }

  render() {
    const isDisabled = this.isDisabledButton()
    return (
      <form className='form' onSubmit={this.handleSubmit} data-qa={this.dataQa}>
        {model.map((item) => (
          <Fragment key={item.name}>{this.renderFileItem(item)}</Fragment>
        ))}
        <p className='text-secondary font-size-small my-2'>{intl.passportUploadDescription}</p>
        <Button
          fluid
          disabled={isDisabled}
          className='my-2'
          onClick={this.handleSubmit}
          data-qa='submitButton'
          type={'button'}
        >
          {intl.continue}
        </Button>
      </form>
    )
  }
}
UploadDocumentsForm.propTypes = {
  onSubmit: PropTypes.func
}

const DocumentsUploadModal = forwardRef((props, ref) => {
  const { maxAmount, uploadDocumentsAction } = props
  const [isOpen, setIsOpen] = useState(false)
  const awaitingPromiseRef = useRef(null)

  useImperativeHandle(ref, () => ({
    show: handleOpenModal,
    close: handleCloseModal
  }))

  const handleOpenModal = () => {
    setIsOpen(true)
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject }
    })
  }

  const handleCloseModal = useCallback(() => {
    setIsOpen(false)
    awaitingPromiseRef.current.reject('closed')
  }, [])

  const handleSubmit = useCallback(() => {
    setIsOpen(false)
    awaitingPromiseRef.current.resolve('success')
  }, [])

  if (!isOpen) return null

  return (
    <Overlay className='d-flex-centered' isOpen={isOpen} onClose={handleCloseModal}>
      <Card className='overflow-auto' style={{ width: 500, maxWidh: '100vw' }}>
        <div className='d-flex align-items-center font-size-large overflow-hidden'>
          {intl.uploadDocuments}
          <Icon
            className='icon-xs icon-padded icon-hover-red icon-hover-scale ms-auto'
            name='close'
            onClick={handleCloseModal}
          />
        </div>
        <p className='mb-4 text-secondary fw-bold'>
          {intl.uploadDocumentsDescription.replace('{{amount}}', formatAsMoney(maxAmount))}
        </p>
        <UploadDocumentsForm
          onSubmit={handleSubmit}
          uploadDocumentsAction={uploadDocumentsAction}
        />
      </Card>
    </Overlay>
  )
})

DocumentsUploadModal.propTypes = {
  uploadDocumentsAction: PropTypes.func,
  maxAmount: PropTypes.number
}

DocumentsUploadModal.defaultProps = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  uploadDocumentsAction: async () => {}
}

export default DocumentsUploadModal
