import intl from '#intl'

import CameraBaseError from './CameraBaseError'

export default class CameraFailedError extends CameraBaseError {
  constructor(message = intl.cameraFailed) {
    super(message)
    this.name = 'CameraFailedError'
  }
}
