/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import querystring from 'query-string'
import { Component } from 'react'
import { Cookies } from 'react-cookie'
import { connect } from 'react-redux'

import { RegistrationFormWrapper } from '#components/PartnerPagesNew/RegistrationFormWrapper'
import Spinner from '#components/Spinner'
import intl from '#intl'
import { mainSiteApi } from '#modules/api'
import { fetchLoanConditionsWithDefaultPromoCode } from '#reducers/loanConditions/effects'
import { isTkbForPartnerSelector } from '#reducers/loanFormState/loanFormStateSlice'
import { brokerApi } from '#src/modules/api'

import ConfirmFormPartner from '../ConfirmForm/ConfirmFormPartner'
import LoanForm from '../LoanForm/LoanForm'
import Notice from '../Notice'
import RegistrationForm from '../RegistrationForm/RegistrationForm'

const cookies = new Cookies()

export class PartnerLoan extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleConfirm = this.handleConfirm.bind(this)
    this.handleTokenExpired = this.handleTokenExpired.bind(this)
    this.handleResetStage = this.handleResetStage.bind(this)
  }

  async componentDidMount() {
    const { location } = this.props
    const query = querystring.parse(location.search)
    if (query.personId || query.creditId) {
      await this.getPersonToken(query.personId)
      if (query.creditId) await this.initConfirm(query.creditId)
      else this.setState({ stage: 1 })
    } else {
      cookies.remove('token')
      this.setState({ stage: 1 })
    }
  }

  componentWillUnmount() {
    cookies.remove('token')
  }

  initConfirm = async (creditId) => {
    const { navigate, location, brokerToken } = this.props
    const query = querystring.parse(location.search)
    const personToken = this.state.personToken || cookies.get('token')
    this.setState({ loading: true })
    try {
      const response = await mainSiteApi.registrationRestore(personToken)
      /* eslint-disable-next-line @typescript-eslint/no-magic-numbers */
      if (response.code === 5) {
        return this.setState({
          stage: 1,
          loading: false
        })
      }
      const responseData = response.data || {}

      this.setState({
        confirmData: {
          loanWay: responseData.loanWay,
          loanAmount: responseData.loanAmount,
          loanTerm: responseData.loanTerm,
          creditId,
          brokerToken,
          person2brokerId: query.person2brokerId,
          personId: query.personId || responseData.personId,
          mobileNumber: responseData.mobileNumber,
          tkbLifeChecked:
            (responseData.tkbLifeChecked && Number(responseData.tkbLifeChecked)) || null
        },
        innRequired: Boolean(responseData.inn),
        stage: 2,
        loading: false
      })
    } catch (e) {
      this.setState({
        stage: 1,
        loading: false
      })
      navigate(location.pathname, { replace: true })
    }
  }

  getPersonToken = async (personId) => {
    const { brokerToken } = this.props
    this.setState({ loading: true })
    const response = await brokerApi.getPerson2BrokerToken({ personId, brokerToken })
    if (response.code === 0) this.setState({ personToken: response.data.token })
    this.setState({ loading: false })
  }

  handleRegistrationFinish = async (data) => {
    const { ymUid = null } = data
    const { location, navigate, brokerToken } = this.props
    const query = querystring.parse(location.search)
    try {
      let submitData = {
        token: data.token,
        brokerToken,
        person2brokerId: query.person2brokerId,
        personId: query.personId,
        c4s: cookies.get('c4s')
      }
      if (ymUid) submitData = { ...submitData, ymUid }

      const response = await brokerApi.brokerSubmitRegistration(submitData)
      if (response.code !== 0) throw response

      this.setState({
        loading: false,
        personToken: data.token
      })

      await this.initConfirm(response.data.creditId)

      const queryObj = {
        ...querystring.parse(location.search),
        creditId: response.data.creditId
      }
      navigate(querystring.stringifyUrl({ url: location.pathname, query: queryObj }), {
        replace: true
      })
    } catch (err) {
      console.error(err)
      return err
    }
  }

  handleConfirm() {
    const { location, navigate } = this.props

    this.setState({ stage: 3 })
    navigate(location.pathname, { replace: true })
  }

  handleTokenExpired() {
    this.context.logout()
  }

  handleResetStage() {
    const {
      location,
      navigate,
      brokerInfo: { groupId },
      dispatch
    } = this.props

    dispatch(fetchLoanConditionsWithDefaultPromoCode({ groupId }))

    cookies.remove('token')

    this.setState({
      stage: 1,
      personToken: null
    })
    navigate(location.pathname)
  }

  appendStylesForGidOffline = (styles) => ({
    ...styles,
    lineStyle: {
      2: { flexDirection: 'column' }
    }
  })

  appendStylesForTkbProduct = (styles) => ({
    ...styles,
    forTkbStyles: {
      maxWidth: '700px'
    }
  })

  render() {
    const { personToken, stage, confirmData, innRequired } = this.state
    const { isLoanConditionsLoading, brokerInfo, isTkbProduct } = this.props
    let loanFormProps = {
      promoCodeEnabled: true,
      view: 'vertical'
    }
    if (brokerInfo && brokerInfo.gidOffline)
      loanFormProps = this.appendStylesForGidOffline(loanFormProps)
    if (isTkbProduct)
      loanFormProps = this.appendStylesForTkbProduct(this.appendStylesForGidOffline(loanFormProps))

    if (isLoanConditionsLoading) {
      return (
        <div className='partner-page__loan'>
          <Spinner className='common-spinner' />
        </div>
      )
    }
    return (
      <div className='partner-page__loan'>
        <h2>{intl.newLoan}</h2>
        {stage === 1 && (
          <RegistrationFormWrapper>
            <div className='partner-page__loan-top'>
              <LoanForm {...loanFormProps} />
            </div>
            <RegistrationForm token={personToken} onFinish={this.handleRegistrationFinish} />
          </RegistrationFormWrapper>
        )}
        {stage === 2 && (
          <ConfirmFormPartner
            data={confirmData}
            onSubmit={this.handleConfirm}
            onTokenExpired={this.handleTokenExpired}
            innRequired={innRequired}
          />
        )}
        {
          /* eslint-disable-next-line @typescript-eslint/no-magic-numbers */
          stage === 3 && (
            <Notice
              type={'success'}
              className='partner-page__loan-notice'
              cancelable
              text={intl.bidConfirmed}
              onClose={this.handleResetStage}
            />
          )
        }
      </div>
    )
  }
}

PartnerLoan.propTypes = {
  brokerToken: PropTypes.string,
  location: PropTypes.object,
  navigate: PropTypes.func
}

const mapStateToProps = (state) => ({
  app: state.app,
  partnerData: state.partnerData,
  isLoanConditionsLoading: state.loanConditions.loading,
  brokerInfo: state.brokerInfo,
  isTkbProduct: isTkbForPartnerSelector(state)
})

export default connect(mapStateToProps)(PartnerLoan)

PartnerLoan.contextTypes = {
  logout: PropTypes.func
}
