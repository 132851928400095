import { LabelDisplayedRowsArgs, TablePagination as MuiTablePagination } from '@material-ui/core'
import { ChangeEvent, FC, useCallback } from 'react'

import TablePaginationActions from './PaginationActions'
import TablePaginationInfo from './PaginationInfo'

interface ITablePagination {
  page: number
  totalItems: number
  itemsPerPage: number
  onChangePage: (page: number) => void
  onChangeItemsPerPage: (page: number) => void
}

const TablePagination: FC<ITablePagination> = (props) => {
  const { page, totalItems, itemsPerPage, onChangePage, onChangeItemsPerPage } = props
  const handlePageChange = useCallback((_evt: unknown, page: number): void => onChangePage(page), [
    onChangePage
  ])
  const handleItemsPerPageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void =>
      onChangeItemsPerPage(Number(event.target.value)),
    [onChangeItemsPerPage]
  )

  const renderTablePaginationInfo = useCallback(
    (props: LabelDisplayedRowsArgs) => (
      <TablePaginationInfo {...props} itemsPerPage={itemsPerPage} />
    ),
    [itemsPerPage]
  )

  return (
    <MuiTablePagination
      component='div'
      count={totalItems}
      rowsPerPage={itemsPerPage}
      page={page}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handleItemsPerPageChange}
      ActionsComponent={TablePaginationActions}
      labelRowsPerPage={'Строк на странице: '}
      labelDisplayedRows={renderTablePaginationInfo}
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      rowsPerPageOptions={[10, 20, 25, 50, 100]}
    />
  )
}

export default TablePagination
