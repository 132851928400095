import App from '#components/App/App'
import Layout from '#components/App/PartnerLayout'

const partnerContainer = () => (
  <App>
    <Layout />
  </App>
)

export default partnerContainer
