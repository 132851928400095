import './login-form.scss'

import classnames from 'classnames'
import { FormEvent, Fragment } from 'react'
import { Link } from 'react-router-dom'

import { ROUTES } from '#constants/common'
import intl from '#intl'
import { loginForm } from '#src/models/loginForm'

import { Button } from '../Button/Button'
import { Form, TFormLine, TModelItem, TParentState } from '../Form/Form'

type TFormProps = {
  className?: string
}

export class LoginForm<P, S> extends Form<P & TFormProps, TParentState & S> {
  model: TModelItem[]
  constructor(props: P & TFormProps) {
    super(props)

    this.model = loginForm
    this.state = {
      ...this.state,
      form: this.computeForm()
    }
  }

  handleSubmit = (event: FormEvent): void => {
    event && event.preventDefault()
  }

  renderFormLine = (line: TFormLine): JSX.Element => (
    <div className='form__line'>
      {line.items.map((item) => !this.checkDepends(item) && this.renderItem(item))}
    </div>
  )

  render(): JSX.Element {
    const { form, loading } = this.state

    const className = classnames(
      {
        form: true,
        'login-form': true
      },
      this.props.className
    )

    return (
      <form className={className} noValidate onSubmit={this.handleSubmit}>
        {form?.lines.map((line, key) => (
          <Fragment key={key}>{this.renderFormLine(line)}</Fragment>
        ))}
        <div className='form__bottom'>
          {this.renderCommonError()}
          <Button size='l' fluid loading={loading} data-qa='loginButton'>
            {intl.loginAction}
          </Button>
        </div>
        <div className='login-form__bottom clearfix'>
          <Link className='login-form__change-password' to={ROUTES.changePassword}>
            {intl.forgotPassword}
          </Link>
        </div>
      </form>
    )
  }
}
