import '../assets/styles.scss'

import PropTypes from 'prop-types'

import Modal from '../../presentational/Modal'
import Action from '../DialogComponents/Action'
import BaseModal from '../DialogComponents/BaseModal'

export default class Message extends BaseModal {
  static propTypes = {
    closeModal: PropTypes.func,
    content: PropTypes.any,
    title: PropTypes.string,
    onClose: PropTypes.func,
    actions: PropTypes.array
  }

  static defaultProps = {
    actions: []
  }

  render() {
    const { content, title, actions, ...rest } = this.props
    return (
      <Modal closeModal={this.onCloseModal} size='middle' title={title} {...rest}>
        <div className='dialog'>
          <div className='dialog__container'>{content}</div>
          <div className='message-dialog__actions'>
            {actions.map((action, inx) => (
              <Action key={inx} {...action} />
            ))}
          </div>
        </div>
      </Modal>
    )
  }
}
