import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'

import Table from '#src/components/Table'

import { columns as regularColumns, extendedColumns } from './columns'

const overideStyles = {
  tHeadRow: {
    zIndex: 1
  }
}

export const PartnerStatTable = ({ loading, data, isBroker }) => {
  const columns = useMemo(
    () => regularColumns.concat(isBroker ? extendedColumns : []).sort((a, b) => a.rowId - b.rowId),
    [isBroker]
  )
  const textNoWrapStyle = useCallback(
    () => ({
      style: {
        whiteSpace: 'nowrap'
      }
    }),
    []
  )

  return (
    <Table
      data={data}
      loading={loading}
      columns={columns}
      borders
      dense
      striped
      hoverable
      fitContainer
      pagination={{ itemsPerPage: 20 }}
      getBodyCellProps={textNoWrapStyle}
      getHeaderCellProps={textNoWrapStyle}
      overideStyles={overideStyles}
    />
  )
}

PartnerStatTable.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  isBroker: PropTypes.bool
}

export default PartnerStatTable
