import { Box, Divider, LabelDisplayedRowsArgs } from '@material-ui/core'
import { FC } from 'react'

const PaginationInfo: FC<LabelDisplayedRowsArgs & { itemsPerPage?: number }> = (props) => {
  const { from, to, count, page, itemsPerPage } = props
  const perPage = itemsPerPage ?? to - from + 1
  const totalPages = Math.ceil(count / perPage)
  return (
    <Box
      component='span'
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: 'small',
        marginRight: 12
      }}
    >
      <Box component='span'>{`${from} - ${to} / ${count}`}</Box>
      <Divider component='span' style={{ width: '100%' }} />
      <Box component='span'>{`Стр. ${page + 1} / ${totalPages}`}</Box>
    </Box>
  )
}

export default PaginationInfo
