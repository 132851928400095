import { TModelItem } from '#components/Form/Form'
import intl from '#intl'
import { formatValueByCount } from '#services/helper'

export const CALENDAR_FIELDS = ['periodStartCalendar', 'periodEndCalendar']

export type TRelatedTo = {
  type: string
  name: string
}

type TFilterModel = TModelItem & {
  relatedTo?: {
    type: string
    name: string
  }
}

const dateRegexp =
  '^[0-9]{4}-[0-9]{2}-[0-9]{2}$|(0[1-9]|[12][0-9]|3[01])\\.(0[1-9]|1[012])\\.2[0-9]{3}'
const dateMask = '99.99.9999'
export const daysBetween = 31
export const filtersModel: TFilterModel[] = [
  {
    line: 1,
    name: 'treatment',
    type: 'reactSelect',
    label: intl.status,
    placeholder: intl.select,
    multiple: true,
    options: [
      { value: '0', text: intl.notProcessed },
      { value: '1', text: intl.accepted },
      { value: '-1', text: intl.refused }
    ],
    required: 1
  },
  {
    line: 2,
    name: 'period',
    type: 'reactSelect',
    label: intl.period,
    placeholder: intl.select,
    options: [
      { value: '1', text: intl.today },
      { value: 'yesterday', text: intl.yesterday },
      { value: '2', text: `${intl.forTime} ${formatValueByCount(2, intl.days).toLowerCase()}` },
      { value: '7', text: `${intl.forTime} ${formatValueByCount(7, intl.days).toLowerCase()}` },
      { value: '30', text: `${intl.forTime} ${formatValueByCount(30, intl.days).toLowerCase()}` },
      { value: '-1', text: intl.other },
      { value: '69', text: intl.other }
    ]
  },
  {
    line: 3,
    name: 'periodStartCalendar',
    type: 'date',
    label: intl.fromTime,
    mask: 'YYYY-MM-DD',
    depends: 'period:69',
    relatedTo: {
      type: 'date',
      name: 'periodEnd'
    },
    required: 1,
    validationMessageError: 'Укажите дату начала периода полностью',
    validationRule: dateRegexp,
    emptyMessage: 'Укажите дату начала периода полностью'
  },
  {
    line: 3,
    name: 'periodEndCalendar',
    type: 'date',
    label: intl.toTime,
    mask: 'YYYY-MM-DD',
    depends: 'period:69',
    relatedTo: {
      type: 'date',
      name: 'periodStart'
    },
    required: 1,
    validationMessageError: 'Укажите дату окончания периода полностью',
    validationRule: dateRegexp,
    emptyMessage: 'Укажите дату окончания периода полностью'
  },
  {
    line: 3,
    name: 'calendarPicker',
    type: 'calendarPicker',
    depends: 'period:-1',
    required: true
  },
  {
    line: 4,
    name: 'report',
    type: 'text',
    label: 'Выгрузить отчет'
  },
  {
    line: 5,
    name: 'dateFrom',
    type: 'text',
    label: intl.fromTime,
    placeholder: intl.datePlaceholder,
    mask: dateMask,
    validationRule: '[0-9]{2}\\.[0-9]{2}\\.[0-9]{4}',
    validationMessageError: intl.fieldInvalid
  },
  {
    line: 5,
    name: 'dateTo',
    type: 'text',
    label: intl.toTime,
    placeholder: intl.datePlaceholder,
    mask: dateMask,
    validationRule: '[0-9]{2}\\.[0-9]{2}\\.[0-9]{4}',
    validationMessageError: intl.fieldInvalid
  },
  {
    line: 6,
    name: 'onlyNew',
    type: 'checkbox',
    required: false,
    label: intl.newLoans
  },
  {
    line: 7,
    name: 'groupingType',
    type: 'reactSelect',
    label: intl.groupFormat,
    placeholder: intl.select,
    multiple: false,
    options: [
      { value: 'by_month', text: intl.month },
      { value: 'by_day', text: intl.day }
    ]
  },
  {
    line: 8,
    name: 'status',
    type: 'reactSelect',
    label: intl.status,
    placeholder: intl.select,
    multiple: true,
    options: [
      { value: '-1', text: intl.notAccepted },
      { value: '0', text: intl.inProcessing },
      { value: '100', text: intl.refused },
      { value: '2', text: intl.issued },
      { value: '3', text: intl.returned },
      { value: '4', text: intl.creditExpired }
    ],
    required: 1
  }
]

export const pageFields = {
  bids: {
    model: filtersModel.filter((modelItem) =>
      ['treatment', 'period', 'name', 'secondName', 'middleName', 'mobileNumber'].includes(
        modelItem.name as string
      )
    )
  }
}
