import './page.scss'

import { Component } from 'react'

import { getRouteValue } from '#constants/common'
import { scrollTo } from '#services/helper'

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    ga?: (a: string, u: string, d: string) => void
  }
}
export interface IBasePageProps {
  location: {
    pathname: string
  }
}
export class Page<P extends IBasePageProps, S> extends Component<P, S> {
  public name: string | undefined

  componentDidMount(): void {
    const { pathname } = this.props.location
    const layout = document.getElementById('layout')
    if (layout && this.name) layout.className = `layout-${this.name}`
    scrollTo(1)
    scrollTo(0)

    pathname !== getRouteValue('register') &&
      window.ga &&
      window.ga('send', 'pageview', 'Любая страница кроме регистрации')
  }
}
