import './login-page.scss'

import PropTypes from 'prop-types'

import { PageWithAutomaticTitleSetter } from '#components/Page/PageWithAutomaticTitleSetter'
import intl from '#intl'

export class LoginPage extends PageWithAutomaticTitleSetter {
  constructor(props) {
    super(props)

    this.name = 'login'
    this.title = intl.loginPageDocumentTitle
  }

  render() {
    return (
      <div className='page login-page'>
        <div className='page__content'>
          <div className='container'>
            <h1 className='page__title'>{intl.loginPageTitle}</h1>
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}

LoginPage.propTypes = {
  className: PropTypes.string
}
