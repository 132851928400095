import { AxiosRequestConfig } from 'axios'

import appConfig from '#config'
import { HttpClient } from '#src/modules/api/httpClient'

export class NodeApiUser extends HttpClient {
  public constructor(axiosConfig: AxiosRequestConfig) {
    super(axiosConfig)
    this._initializeRequestInterceptor()
    this._initializeResponseInterceptor()
  }

  public convertCardImage(file: File, token: string): Promise<Blob> {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('token', token)
    return this.instance.post('/files/convertImageToJpeg', formData, {
      params: { token },
      responseType: 'arraybuffer'
    })
  }
}

const nodeConfig: AxiosRequestConfig = {
  baseURL: new URL('/user/api/v1', appConfig.host).href
}

export const nodeApiUser = new NodeApiUser(nodeConfig)
