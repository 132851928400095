import { TModelItem } from '#components/Form/Form'

import intl from '../intl/ru.json'

export const loginForm: TModelItem[] = [
  {
    line: 1,
    name: 'login',
    type: 'text',
    label: intl.phoneOrEmail,
    placeholder: intl.loginPlaceholder,
    required: true,
    emptyMessage: intl.fieldEmpty,
    dataQa: 'loginInput'
  },
  {
    line: 2,
    name: 'password',
    type: 'password',
    label: intl.password,
    placeholder: intl.passwordPlaceholder,
    required: true,
    emptyMessage: intl.fieldEmpty,
    dataQa: 'passwordInput'
  },
  {
    line: 3,
    name: 'remember',
    type: 'checkbox',
    required: false,
    label: intl.rememberMe
  }
]

export const loginAdminForm: TModelItem[] = loginForm
  .filter((item) => item.name !== 'remember')
  .map((item) => {
    if (item.name === 'password') return { ...item, name: 'pass' }
    return item
  })

export const smsCodeFormItem: TModelItem = {
  line: 1,
  name: 'code',
  orderId: 1,
  type: 'number',
  mask: '9999',
  validationRule: '[0-9]{4}',
  label: 'СМС - код',
  placeholder: 'Введите код из СМС',
  bottomText:
    'Если в течение минуты Вы не получили СМС - код,' +
    ' проверьте корректность номера и запросите его повторно. Вы можете изменить номер в поле выше.',
  emptyMessage: 'Введите код подтверждения',
  validationMessageError: 'Неверный код подтверждения',
  required: false
}
