import { IGetSliderInfoSuccess } from '#modules/api/personal/types'

export class InsuranceList {
  private readonly insuranceList: IInsuranceItem[]
  constructor(loanConditions: IGetSliderInfoSuccess['data']) {
    this.insuranceList = loanConditions.insuranceList
  }

  public findInsuranceInList(insuranceType: string): IInsuranceItem | undefined {
    return this.insuranceList.find((el) => el.insuranceType === insuranceType)
  }
}
