import classnames from 'classnames'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'

import { navigationList } from '#components/PartnerPagesNew/models/navigation'
import { ROUTES } from '#constants/common'
import intl from '#intl'
import { brokerSelector } from '#reducers/brokerSlice'
import { togglePartnerMenu } from '#reducers/partnerData/partnerDataSlice'
import {
  isScreenDesktop,
  isScreenMobile,
  isScreenTablet,
  preventWindowScrollWithOpenMenu
} from '#services/helper'

import { Icon } from '../Icon/Icon'

const RESIZE_TIMEOUT = 500

class Navigation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isPreventWindowScroll: false,
      menuOpened: props.partnerData.menuOpened || false,
      nav: navigationList
    }
    this.handleWindowResize = debounce(this.handleWindowResizeImmediate.bind(this), RESIZE_TIMEOUT)
  }

  handleWindowResizeImmediate = () => {
    const { isPreventWindowScroll } = this.state
    const {
      partnerData: { menuOpened }
    } = this.props
    if (menuOpened) {
      if ((isScreenMobile() || isScreenTablet()) && !isPreventWindowScroll) {
        this.setState({ isPreventWindowScroll: true }, () => {
          preventWindowScrollWithOpenMenu(menuOpened)
        })
      }
      if (isScreenDesktop()) {
        this.setState({ isPreventWindowScroll: false }, () => {
          preventWindowScrollWithOpenMenu(false)
        })
      }
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  handleToggleMenu = (menuOpened) => {
    if (isScreenMobile() || isScreenTablet()) preventWindowScrollWithOpenMenu(menuOpened)
    this.props.togglePartnerMenu(menuOpened)
  }

  handleMenuLinkClick = (event) => {
    const { onLinkClick } = this.props
    onLinkClick && onLinkClick()
    event.stopPropagation()
  }

  handleClickOutside = () => {
    this.handleToggleMenu(false)
  }

  handleClickOnBurger = () => {
    const {
      partnerData: { menuOpened }
    } = this.props
    this.handleToggleMenu(!menuOpened)
  }

  render() {
    let nav = [...this.state.nav]
    const { partnerData, broker } = this.props
    const { menuOpened } = partnerData
    if (!broker.isBroker) nav = nav.filter((item) => [ROUTES.partnerStat].includes(item.url))
    else nav = nav.filter((item) => !item.partnerOnly)

    return (
      <div className={'partner-page__bar' + (menuOpened ? ' is-active' : '')}>
        <span
          className={classnames({
            burger: true,
            'is-active': menuOpened
          })}
          onClick={this.handleClickOnBurger}
        >
          <i className='burger__icon' />
        </span>
        <span className='partner-page__logout' onClick={this.context.logout}>
          <Icon className='partner-page__logout-icon' name='logout' />
          <span className='partner-page__logout-caption'>{intl.logoutAction}</span>
        </span>
        <Link className='partner-page__logo' to='/'>
          <Icon name='logo-short' />
          <Icon name='logo' />
        </Link>
        <div
          onClick={this.handleClickOutside}
          className={classnames({
            'partner-page__nav': true,
            'is-visible': menuOpened
          })}
        >
          {nav.map((item, key) => (
            <NavLink
              className={({ isActive }) =>
                classnames({
                  'partner-page__nav-item': true,
                  'is-active': isActive
                })
              }
              to={item.url}
              key={key}
              onClick={this.handleMenuLinkClick}
            >
              <span className='partner-page__nav-content'>{item.caption}</span>
              <Icon name={item.icon} />
            </NavLink>
          ))}
        </div>
      </div>
    )
  }
}

Navigation.propTypes = {
  onLinkClick: PropTypes.func,
  partnerData: PropTypes.object,
  togglePartnerMenu: PropTypes.func,
  broker: PropTypes.object
}
Navigation.contextTypes = {
  logout: PropTypes.func
}

const mapStateToProps = (state) => ({
  broker: brokerSelector(state),
  partnerData: state.partnerData
})

const mapDispatchToProps = (dispatch) => ({
  togglePartnerMenu: (menuOpened) => dispatch(togglePartnerMenu(menuOpened))
})

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
