/* eslint-disable @typescript-eslint/no-magic-numbers */
import piexif from 'piexifjs'

const buildExifDateStamp = (datetime) => {
  const date = new Date(datetime)
  const padWithZero = (num) => String(num).padStart(2, '0')
  return [
    [date.getFullYear(), date.getMonth() + 1, date.getDate()].map(padWithZero).join(':'),
    [date.getHours(), date.getMinutes(), date.getSeconds()].map(padWithZero).join(':')
  ].join(' ')
}

export const withExif = (image, options = {}) => {
  const zeroth = {}
  const exif = {}
  const gps = {}

  zeroth[piexif.ImageIFD.Make] = options.make || '4slovo-make'
  zeroth[piexif.ImageIFD.Model] = options.model || '4slovo-model'
  zeroth[piexif.ImageIFD.Software] = options.software || '4slovo-software'
  zeroth[piexif.ImageIFD.XResolution] = options.xResolution || [72, 1]
  zeroth[piexif.ImageIFD.YResolution] = options.yResolution || [72, 1]

  exif[piexif.ExifIFD.DateTimeOriginal] = buildExifDateStamp(options.dateTimeOriginal || new Date())
  exif[piexif.ExifIFD.PixelXDimension] = options.width
  exif[piexif.ExifIFD.PixelYDimension] = options.height

  gps[piexif.GPSIFD.GPSVersionID] = options.gpsVersionId || [2, 0, 0, 0]
  gps[piexif.GPSIFD.GPSDateStamp] = buildExifDateStamp(options.gpsDateStamp || new Date())

  const exifObj = { '0th': zeroth, Exif: exif, GPS: gps }
  const exifStr = piexif.dump(exifObj)
  return piexif.insert(exifStr, image)
}

export const dataUriToBlob = async (dataURI) => (await fetch(dataURI)).blob()

/**
 * Check whether device has one or more cameras
 * If returns undefined - than browser has no MediaDevices API implemented
 * @returns true | false | undefined
 */
export const hasCamera = async () => {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices()
    return devices.some(({ kind }) => kind === 'videoinput')
  } catch (err) {
    // eslint-disable-next-line no-undefined
    return undefined
  }
}

export const multiplyMatrix = (matrixA, matrixB) => {
  const rowsCountA = matrixA.length
  const rowsCountB = matrixB.length
  const [firstRowB] = matrixB
  const columnsCountB = firstRowB.length
  const matrixC = []

  for (let row = 0; row < rowsCountA; row += 1) {
    matrixC[row] = []
    for (let column = 0; column < columnsCountB; column += 1) {
      let sum = 0
      for (let i = 0; i < rowsCountB; i += 1) sum += matrixA[row][i] * matrixB[i][column]

      matrixC[row][column] = sum
    }
  }

  return matrixC
}

export const buildAfineMatrix = (imgWidth, imgHeight, orientation = 0) => {
  const CARD_WIDTH = 710
  const CARD_HEIGHT = 460
  const angle = (orientation * Math.PI) / 180
  const cos = Math.cos(angle)
  const sin = Math.sin(angle)

  let matrix = [
    [cos, -sin, 0],
    [sin, cos, 0],
    [0, 0, 1]
  ]

  // Конечные преобразования в соотв. с https://confluence.4slovo.ru/pages/viewpage.action?pageId=3277709
  matrix = multiplyMatrix(matrix, [
    [1, 0, -imgWidth / 2],
    [0, 1, -imgHeight / 2],
    [0, 0, 1]
  ])

  const scaleX = CARD_WIDTH / Math.max(imgWidth, imgHeight)
  const scaleY = CARD_HEIGHT / Math.min(imgWidth, imgHeight)

  matrix = multiplyMatrix(
    [
      [scaleX, 0, CARD_WIDTH / 2],
      [0, scaleY, CARD_HEIGHT / 2],
      [0, 0, 1]
    ],
    matrix
  )

  return matrix
}

export const calculateDiag = (w, h) => Math.sqrt(w ** 2 + h ** 2)

export const isFirefox = () =>
  /Firefox\//.test(navigator.userAgent) && !/Seamonkey\//.test(navigator.userAgent)

export const isOperaMini = () => {
  const ua = navigator.userAgent || {}
  const isOperaMiniOrOperaMobileInRegularMode =
    ua.includes('OPR/') && ua.includes('Mobile') && !ua.includes('Presto/')
  const isOperaMiniInExtremeMode = ua.includes('Opera Mini/') && ua.includes('Presto/')
  return isOperaMiniOrOperaMobileInRegularMode || isOperaMiniInExtremeMode
}

export const isAppleDevice = () =>
  (typeof navigator !== 'undefined' &&
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform
    )) ||
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document)

export const hasMediaDevicesApi = () =>
  typeof navigator !== 'undefined' && typeof navigator?.mediaDevices?.getUserMedia === 'function'
