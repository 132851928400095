import { noop } from 'lodash'
import PropTypes from 'prop-types'
import { Component } from 'react'

export default class BaseModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    onClose: PropTypes.func
  }

  onCloseModal = () => {
    const { closeModal, onClose = noop } = this.props
    closeModal()
    if (typeof onClose === 'function') onClose()
  }
}
