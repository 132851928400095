/* eslint-disable @typescript-eslint/no-magic-numbers */
import './card-cropper.scss'

import { CancelToken } from 'axios'
import classnames from 'classnames'
import { debounce, has, initial, noop } from 'lodash'
import PropTypes from 'prop-types'
import { Component, createRef } from 'react'

import intl from '#intl'
import { mainSiteApi } from '#modules/api'
import { animateCSS, EXIFeraser, nl2br, responseError } from '#services/helper'
import numeric from '#services/numericLight'

import { Icon } from '../Icon/Icon'
import Overlay from '../Overlay'
import { Spinner } from '../Spinner/Spinner'

const CARD_WIDTH = 710
const CARD_HEIGHT = 460
const initialMatrix = [
  [1, 0, 0],
  [0, 1, 0],
  [0, 0, 1]
]

export class CardCropperBase extends Component {
  constructor(props) {
    super(props)
    this.cancelTokens = []
    this.wrapperRef = createRef()
    this.errorNodeRef = createRef()
    this.previewOverlayRef = createRef()
    this.previewRef = createRef()
    this.containerRef = createRef()
    this.imageRef = createRef()
    this.errorMessageTimerId = null
    this.state = {
      errors: '',
      isCropped: false,
      isCardUploaded: props.isCardUploaded || false
    }
    this.matrix = initialMatrix

    this.validateFile = this.validateFile.bind(this)
    this.sticking = this.sticking.bind(this)
    this.stopSticking = this.stopSticking.bind(this)
    this.handleMoveStart = this.handleMoveStart.bind(this)
    this.handleMoveEnd = this.handleMoveEnd.bind(this)
    this.handleMove = this.handleMove.bind(this)
    this.handleApply = this.handleApply.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
    this.handleWindowResize = debounce(this.handleWindowResize.bind(this), 100)
    this.logError = this.props.logError || noop
    this.stickingTimeout = null
  }

  removeFile = (message) => {
    const { onFail } = this.props
    this.handleRemove()
    onFail &&
      onFail({
        code: 5,
        message
      })
  }

  previewUploadedFile = () => {
    if (cardChecked) return
    const { file, cardPhotoId = null, cardChecked, onUploaded } = this.props
    let validationResult = this.validateFile()
    if (validationResult !== true) {
      const { error } = validationResult
      this.removeFile(error)
      return
    }
    if (onUploaded) onUploaded({ code: 0, card_id: cardPhotoId })
    if (cardPhotoId) {
      this.setState(
        {
          isCardUploaded: true,
          loading: false,
          cardPhotoId
        },
        () => this.initCropper(file.preview)
      )
    }
  }

  asyncPreviewUploadedFile = () =>
    new Promise((resolve) => {
      this.previewUploadedFile()
      resolve()
    })

  preventTouch = (evt) => evt.preventDefault()

  async componentDidMount() {
    const { cardPhotoId, loanWay, brokerToken = null } = this.props
    document.addEventListener('touchstart', this.preventTouch, { passive: false })
    if (loanWay === 'bankCard' && brokerToken && cardPhotoId) return this.asyncPreviewUploadedFile()
    const validationResult = this.validateFile()
    if (validationResult === true) return this.cardUpload()
    this.removeFile(validationResult.error)
  }

  componentWillUnmount() {
    document.removeEventListener('touchstart', this.preventTouch)
    window.removeEventListener('resize', this.handleWindowResize)
  }

  /**
   * Получение поддерживаемого css-префикса
   * @returns {string/null} - prefix/null
   */
  getSupportedTransform() {
    const prefixes = ['transform', 'WebkitTransform', 'MozTransform', 'OTransform', 'msTransform']
    const div = document.createElement('div')
    return prefixes.find((prefix) => has(div.style, prefix)) || 'transform'
  }

  /**
   * Вычисление матрицы для отправки на сервер
   * @returns {[*,*,*]}
   */
  getFinalMatrix() {
    const containerElement = this.containerRef.current
    const viewPortWidth = containerElement.offsetWidth
    const viewPortHeight = containerElement.offsetHeight
    const scaleX = CARD_WIDTH / viewPortWidth
    const scaleY = CARD_HEIGHT / viewPortHeight
    let { matrix } = this

    matrix = numeric.dot(matrix, [
      [1, 0, -this.imageRef.current.width / 2],
      [0, 1, -this.imageRef.current.height / 2],
      [0, 0, 1]
    ])

    matrix = numeric.dot(
      [
        [scaleX, 0, CARD_WIDTH / 2],
        [0, scaleY, CARD_HEIGHT / 2],
        [0, 0, 1]
      ],
      matrix
    )

    return matrix
  }

  /**
   * Инициализация кроппера
   */
  initCropper(preview) {
    const previewElement = this.previewRef.current
    if (!previewElement) return
    previewElement.onload = () => {
      this.imageRef.current = {
        width: previewElement.naturalWidth,
        height: previewElement.naturalHeight
      }

      this.supportedPrefix = this.getSupportedTransform()

      this.handleWindowResize()
      this.previewOverlayRef.current.addEventListener('mousedown', this.handleMoveStart)
      this.previewOverlayRef.current.addEventListener('touchstart', this.handleMoveStart)
      window.addEventListener('resize', this.handleWindowResize)
    }

    previewElement.src = preview
  }

  setSizes() {
    const containerElement = this.containerRef.current
    const previewElement = this.previewRef.current
    const containerWidth = containerElement.offsetWidth
    const containerHeight = containerWidth / (CARD_WIDTH / CARD_HEIGHT)
    // Рассчитываем масштаб картинки онтосительно контейнера
    const scale =
      this.imageRef.current.width * (containerWidth * 2) <
      this.imageRef.current.height * (containerHeight * 2)
        ? containerHeight / this.imageRef.current.height
        : containerWidth / this.imageRef.current.width

    // Позиционируем изображение по центру контейнера
    previewElement.style.left = -(this.imageRef.current.width - containerWidth) / 2 + 'px'
    previewElement.style.top = -(this.imageRef.current.height - containerHeight) / 2 + 'px'

    // Устанавливаем высоту контейнера относительно ширины по пропорциям карты
    containerElement.style.height = containerHeight + 'px'

    this.applyMatrix([
      [scale, 0, 0],
      [0, scale, 0],
      [0, 0, 1]
    ])

    // Масштабируем кроппер чтобы вписаться в высоту экрана на небольших landscape мобильниках
    const wrapper = this.wrapperRef.current
    if (wrapper) {
      const wrapperScaleRatio =
        wrapper.offsetHeight > window.innerHeight ? window.innerHeight / wrapper.offsetHeight : 1
      wrapper.style.transform = `scale(${wrapperScaleRatio})`
    }
  }

  /**
   * Валидация файла
   */
  validateFile() {
    const { file } = this.props
    const cardPhotoMaxSize = 16 * 1024 * 1024 // bytes
    const allowTypes = ['image/jpeg', 'image/png', 'image/heic', 'image/heif']

    if (!allowTypes.includes(file.type)) {
      return {
        error: `${intl.invalidFormatImage}`,
        type: 'type'
      }
    }

    if (file.size > cardPhotoMaxSize) {
      return {
        error: `${intl.photoSizeExceeds} 16 MB`,
        type: 'size'
      }
    }

    return true
  }

  /**
   * Применение матрицы к изображению
   */
  applyMatrix(matrix) {
    this.matrix = numeric.dot(matrix, this.matrix)

    this.previewRef.current.style[this.supportedPrefix] =
      'matrix3d(' +
      this.matrix[0][0] +
      ', ' +
      this.matrix[1][0] +
      ', 0, 0, ' +
      this.matrix[0][1] +
      ', ' +
      this.matrix[1][1] +
      ', 0, 0, ' +
      '0, 0, 1, 0, ' +
      this.matrix[0][2] +
      ', ' +
      this.matrix[1][2] +
      ', 0, 1)'
  }

  /**
   * Применение поворота изображения
   * @param {number} radius - радиус поворота
   */
  applyRotate(radius) {
    const angle = (radius * Math.PI) / 180
    const cos = Math.cos(angle)
    const sin = Math.sin(angle)
    this.applyMatrix([
      [cos, -sin, 0],
      [sin, cos, 0],
      [0, 0, 1]
    ])
  }

  /**
   * Применение масштаба изображения
   * @param {number} zoom - масштаб
   */
  applyZoom(zoom) {
    this.applyMatrix([
      [zoom, 0, 0],
      [0, zoom, 0],
      [0, 0, 1]
    ])
  }

  /**
   * Применение смещения изображения
   * @param {number} x - смещение по горизонтали
   * @param {number} y - смещение по вертикали
   */
  applyOffset(x, y) {
    this.applyMatrix([
      [1, 0, x],
      [0, 1, y],
      [0, 0, 1]
    ])
  }

  multiTouch(s1x, s1y, s2x, s2y, e1x, e1y, e2x, e2y) {
    if (s1x === e1x && s2x === e2x && s1y === e1y && s2y === e2y) return

    const vec1 = { x: s2x - s1x, y: s2y - s1y }
    const vec2 = { x: e2x - e1x, y: e2y - e1y }

    vec1.len = Math.sqrt(vec1.x * vec1.x + vec1.y * vec1.y)
    vec2.len = Math.sqrt(vec2.x * vec2.x + vec2.y * vec2.y)

    if (vec1.len * vec2.len === 0) return

    const matrix = [
      [s1x, -s1y, 1, 0],
      [s1y, s1x, 0, 1],
      [s2x, -s2y, 1, 0],
      [s2y, s2x, 0, 1]
    ]
    const free = [e1x, e1y, e2x, e2y]
    const solve = numeric.solve(matrix, free)

    if (solve[2] > 100 || solve[3] > 100) return

    this.applyMatrix([
      [solve[0], -solve[1], solve[2]],
      [solve[1], solve[0], solve[3]],
      [0, 0, 1]
    ])
  }

  extractTouches(touches) {
    const extracted = []
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let idx = 0; idx < touches.length; idx += 1)
      extracted.push({ x: touches[idx].clientX, y: touches[idx].clientY })

    return extracted
  }

  /**
   * Повторение (залипание) метода
   * @param {function} func - повторяемый метод
   * @param {number} interval - промежуток времени повторения
   */
  sticking(func, interval = 500) {
    clearTimeout(this.stickingTimeout)
    document.addEventListener('mouseup', this.stopSticking)
    document.addEventListener('touchend', this.stopSticking)
    func()
    this.stickingTimeout = setTimeout(this.sticking.bind(this, func, 100), interval)
  }

  /**
   * Прекращение залипания
   */
  stopSticking() {
    clearTimeout(this.stickingTimeout)
    document.removeEventListener('mouseup', this.stopSticking)
    document.removeEventListener('touchend', this.stopSticking)
  }

  /**
   * Метод который в случае, если в ответе поле cardPhotoId называется как-то по-другому можно переопределить
   */
  handleCardUploadSuccess = (response) => {
    if (response.code !== 0) throw response
    else this.setState({ isCardUploaded: true, cardPhotoId: response.cardPhotoId })
  }

  setError = (error) => {
    clearTimeout(this.errorMessageTimerId)
    let normalizedError = intl.serverError
    if (typeof error === 'string') normalizedError = nl2br(error)
    if (typeof error === 'object' && error.message) normalizedError = error.message

    this.setState(
      { errors: normalizedError },
      () => this.errorNodeRef?.current && animateCSS(this.errorNodeRef.current, 'bounceIn')
    )

    this.errorMessageTimerId = setTimeout(() => this.handleCloseErrorMessage(), 8000)
  }

  handleCloseErrorMessage = () => {
    if (this.errorNodeRef?.current)
      animateCSS(this.errorNodeRef.current, 'fadeOutUp', () => this.setState({ errors: '' }))
    else this.setState({ errors: '' })
  }

  /**
   * Загрузка фотографии карты
   * @returns {Promise}
   */
  async cardUpload() {
    const { onFail, file } = this.props
    this.setState({ loading: true })
    try {
      await this.requestCardUpload()
      const preview = await new EXIFeraser(file).readFile()
      this.initCropper(preview)
    } catch (err) {
      this.logError(err)
      this.setError(err)
      onFail && onFail(err)
    } finally {
      this.setState({ loading: false })
    }
  }

  /**
   * Хелпер для присоединия матрицы к объекту formData
   * @param {FormData} formData - Объект к которому надо присоединить матрицу
   */
  appendMatrixToFormData = (formData) => {
    initial(this.getFinalMatrix()).forEach((row, rInd) =>
      row.forEach((cell, cInd) => formData.append(`matrix[${rInd}][${cInd}]`, cell.toFixed(7)))
    )
  }

  /**
   * Запрос на обрезку фотографии карты
   * @returns {Promise}
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  requestCardCrop() {}

  /**
   * Запрос на загрузку фотографии карты
   * @returns {Promise}
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  requestCardUpload() {}

  /**
   * Масштабирование изображения по клику на кнопках
   * @param {number} offset - коэффициент сдвига (-1|1)
   */
  handleZoomClick = (offset) => (evt) => {
    if (evt.nativeEvent.which > 1) return

    this.sticking(() => {
      this.applyZoom(0.02 * offset + 1)
    })
  }

  /**
   * Поворот изображения по клику на кнопках
   * @param {number} offset - коэффициент сдвига (-1|1)
   */
  handleRotateClick = (offset) => (evt) => {
    if (evt.nativeEvent.which > 1) return
    this.sticking(() => {
      this.applyRotate(offset)
    })
  }

  /**
   * Начало перетаскивания изображения
   * @param {object} evt - событие
   */
  handleMoveStart(evt) {
    if (evt.which > 1) return
    const isTouch = Boolean(evt.touches)
    this.touches = isTouch ? this.extractTouches(evt.touches) : null
    this.moveStartPoint = {
      x: isTouch ? evt.touches[0].clientX : evt.clientX,
      y: isTouch ? evt.touches[0].clientY : evt.clientY
    }

    document.addEventListener('mousemove', this.handleMove, true)
    document.addEventListener('touchmove', this.handleMove, true)
    document.addEventListener('mouseup', this.handleMoveEnd)
    document.addEventListener('touchend', this.handleMoveEnd)
  }

  /**
   * Конец перетаскивания изображения
   * @param {object} evt - событие
   */
  handleMoveEnd(_evt) {
    document.removeEventListener('mousemove', this.handleMove, true)
    document.removeEventListener('touchmove', this.handleMove, true)
    document.removeEventListener('mouseup', this.handleMoveEnd)
    document.removeEventListener('touchend', this.handleMoveEnd)
  }

  /**
   * Перетаскивание изображения
   * @param {object} evt - событие
   */
  handleMove(evt) {
    const isTouch = Boolean(evt.touches)
    const containerElement = this.containerRef.current
    const containerOffset = containerElement.getBoundingClientRect()
    const containerCenter = {
      x: containerOffset.left + containerElement.offsetWidth / 2,
      y: containerOffset.top + containerElement.offsetHeight / 2
    }

    if (isTouch && this.touches.length > 1 && evt.touches.length > 1) {
      const newTouches = this.extractTouches(evt.touches)
      const s1x = this.touches[0].x - containerCenter.x
      const s1y = this.touches[0].y - containerCenter.y
      const s2x = this.touches[1].x - containerCenter.x
      const s2y = this.touches[1].y - containerCenter.y
      const e1x = newTouches[0].x - containerCenter.x
      const e1y = newTouches[0].y - containerCenter.y
      const e2x = newTouches[1].x - containerCenter.x
      const e2y = newTouches[1].y - containerCenter.y

      this.touches = newTouches
      this.multiTouch(s1x, s1y, s2x, s2y, e1x, e1y, e2x, e2y)

      return
    }

    const currentPoint = {
      x: isTouch ? evt.touches[0].clientX : evt.clientX,
      y: isTouch ? evt.touches[0].clientY : evt.clientY
    }

    const delta = {
      x: currentPoint.x - this.moveStartPoint.x,
      y: currentPoint.y - this.moveStartPoint.y
    }

    this.moveStartPoint = currentPoint
    this.applyOffset(delta.x, delta.y)
  }

  /**
   * Сохранение изображения
   */
  async handleApply() {
    const { onFail = noop } = this.props
    this.setState({ loading: true })
    try {
      const response = await this.requestCardCrop()
      const { registerError = null } = response
      if (registerError instanceof Error) throw response
      this.handleCropResponse(response)
    } catch (e) {
      this.logError(e)
      onFail(e)
    } finally {
      this.setState({ loading: false })
    }
  }

  createCancelToken() {
    return new CancelToken((cancelFn) => {
      this.cancelTokens.push(cancelFn)
    })
  }

  cancelPendingRequests() {
    while (this.cancelTokens.length) {
      const cancelFn = this.cancelTokens.shift()
      try {
        cancelFn()
      } catch (e) {
        noop(e)
      }
    }
  }

  /**
   * Удаление изображения
   */
  handleRemove() {
    this.cancelPendingRequests()
    const { onRemove, token } = this.props
    mainSiteApi.cardClear(token).catch(noop)
    onRemove && onRemove()
    this.setState({
      file: null,
      isCropped: false,
      isCardUploaded: false,
      loading: false
    })
  }

  handleWindowResize() {
    if (!this.imageRef.current) return

    this.matrix = initialMatrix
    this.setSizes()
  }

  handleCropResponse = (response) => {
    const { onSuccess, onApply } = this.props
    this.setState({ loading: false })
    if (response.code === 0) {
      onSuccess && onSuccess(response)
      onApply && onApply()
      this.setState({
        isCropped: true
      })
    } else {
      const errResponse = {
        code: response.code,
        message: response.data ? Object.values(response.data).join('\n') : response.message
      }
      const errorText = responseError(errResponse)
      this.setState({ isCropped: false })
      this.setError(errorText)
    }
  }

  render() {
    const { loading, isCardUploaded, isCropped, errors } = this.state
    const className = classnames(
      {
        'card-cropper': true,
        'is-visible': isCardUploaded
      },
      this.props.className
    )

    if (!isCardUploaded) {
      return (
        <div className='card-cropper__wrapper'>
          <Spinner className='common-spinner' />
        </div>
      )
    }

    return (
      <Overlay
        className='d-flex-centered'
        opacity={0.5}
        isOpen={isCardUploaded && !isCropped}
        style={{ minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%' }}
      >
        <div className='card-wrapper-parent shadow-lg' ref={this.wrapperRef}>
          <div className='card-wrapper'>
            <div className={className}>
              <div className='card-cropper__wrapper clearfix'>
                <div className='card-cropper__container' ref={this.containerRef}>
                  <img className='card-cropper__preview' ref={this.previewRef} />
                  <div className='card-cropper__preview-overlay' ref={this.previewOverlayRef} />
                </div>
                <div>
                  <div className='card-cropper__controls-1'>
                    {!loading ? (
                      <span
                        className='card-cropper__control'
                        onMouseDown={this.handleApply}
                        onTouchStart={this.handleApply}
                      >
                        <Icon className='icon_apply' name='tick' />
                      </span>
                    ) : (
                      <Spinner className='card-cropper__spinner' />
                    )}
                    <span
                      className='card-cropper__control'
                      onMouseDown={this.handleRemove}
                      onTouchStart={this.handleRemove}
                    >
                      <Icon className='icon_remove' name='close' />
                    </span>
                  </div>
                  <div className='card-cropper__controls-2'>
                    <span
                      className='card-cropper__control'
                      onMouseDown={this.handleRotateClick(1)}
                      onTouchStart={this.handleRotateClick(1)}
                    >
                      <Icon className='icon_rotate-right' name='rotate-right' />
                    </span>
                    <span
                      className='card-cropper__control'
                      onMouseDown={this.handleRotateClick(-1)}
                      onTouchStart={this.handleRotateClick(-1)}
                    >
                      <Icon className='icon_rotate-left' name='rotate-left' />
                    </span>
                  </div>
                  <div className='card-cropper__controls-3'>
                    <span
                      className='card-cropper__control'
                      onMouseDown={this.handleZoomClick(1)}
                      onTouchStart={this.handleZoomClick(1)}
                    >
                      <Icon className='icon_zoom-in' name='zoom-in' />
                    </span>
                    <span
                      className='card-cropper__control'
                      onMouseDown={this.handleZoomClick(-1)}
                      onTouchStart={this.handleZoomClick(-1)}
                    >
                      <Icon className='icon_zoom-out' name='zoom-out' />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {errors && (
          <div
            className='form__item-error card-cropper__error_wrapper card-cropper__message'
            ref={this.errorNodeRef}
          >
            <div
              dangerouslySetInnerHTML={{ __html: errors }}
              className='card-cropper__message__text'
            />
            <button
              onMouseDown={this.handleCloseErrorMessage}
              onTouchStart={this.handleCloseErrorMessage}
              className='card-cropper__message__close-button'
            >
              <Icon name='close' className='icon-xs' />
            </button>
          </div>
        )}
      </Overlay>
    )
  }
}

CardCropperBase.propTypes = {
  className: PropTypes.string,
  file: PropTypes.object,
  token: PropTypes.string,
  onApply: PropTypes.func,
  onSuccess: PropTypes.func,
  onUploaded: PropTypes.func,
  onFail: PropTypes.func,
  onRemove: PropTypes.func,
  brokerToken: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  cardPhotoId: PropTypes.number,
  cardChecked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  loanWay: PropTypes.string,
  isCardUploaded: PropTypes.bool,
  logError: PropTypes.func
}

export default CardCropperBase
