import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { FC, useCallback } from 'react'

import Button from '#components/Button'

type TConfirmDeleteDialogProps = {
  confirmText: string
  isLoading: boolean
  handleConfirmClick: () => Promise<unknown>
  closeModal: () => void
}

export const ConfirmDeleteDialog: FC<TConfirmDeleteDialogProps> = (props) => {
  const { confirmText, isLoading, handleConfirmClick, closeModal } = props
  const handleCloseClick = useCallback(() => {
    closeModal()
  }, [closeModal])

  return (
    <Dialog
      open={true}
      onClose={handleCloseClick}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{''}</DialogTitle>
      <DialogContent>
        <DialogContentText data-qa='alert-dialog-description'>{confirmText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button loading={isLoading} onClick={handleConfirmClick} data-qa='confirm'>
          {'Подтвердить'}
        </Button>
        <Button loading={isLoading} onClick={handleCloseClick} color='red' data-qa='decline'>
          {'Отклонить'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
