import UniversalFormData from 'form-data'

import { handleCheckRegistartionDone } from '#services/helper'
import withErrorLogger from '#src/hoc/withErrorLogger'
import { brokerApi } from '#src/modules/api'

import { CardCropperBase } from './CardCropperBase'

export class CardCropperBroker extends CardCropperBase {
  async requestCardUpload() {
    const { file, token, brokerToken, onUploaded, isNewCardPhoto } = this.props
    const data = new UniversalFormData()
    data.append('cardImage', file, file.name)
    data.append('personToken', token)
    data.append('brokerToken', brokerToken)
    if (isNewCardPhoto) data.append('isNewCardPhoto', isNewCardPhoto)

    const response = await brokerApi.brokerAddCardPhoto(data, {
      cancelToken: this.createCancelToken()
    })

    if (response.code === 303) throw response

    const checkedResponse = handleCheckRegistartionDone(response)
    this.handleCardUploadSuccess(checkedResponse)
    onUploaded && onUploaded(checkedResponse)
  }

  requestCardCrop() {
    const { token, brokerToken } = this.props
    const { cardPhotoId } = this.state
    const data = new UniversalFormData()
    this.appendMatrixToFormData(data)
    data.append('brokerToken', brokerToken)
    data.append('personToken', token)
    data.append('cardPhotoId', cardPhotoId)

    return brokerApi.brokerCropCardPhoto(data, { cancelToken: this.createCancelToken() })
  }

  handleCardUploadSuccess = (response) => {
    if (response.code !== 0) throw response
    else this.setState({ isCardUploaded: true, cardPhotoId: response.card_id })
  }
}

export default withErrorLogger(CardCropperBroker)
