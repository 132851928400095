import '#components/PartnerPagesNew/partner-page.scss'
import '#components/PartnerPagesNew/assets/calendar-container.scss'

import { DatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { addYears, isWithinInterval, subYears } from 'date-fns'
import { isNull } from 'lodash'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'

import Button from '#components/Button'
import { FormItem } from '#components/Form'
import { TErrorObject } from '#components/Form/Form'
import intl from '#intl'
import { formatDate, now, parseDate } from '#services/datetime'

type THandlerType = 'periodStartCalendar' | 'periodEndCalendar'
type TObj = {
  [key: string]: string
}
export type TRange = {
  [key in THandlerType]: Date | string
}
export type TChangeConditions = {
  [key in THandlerType]: string
}
interface IDatePickerControl {
  prefixStart: string
  prefixFinish: string
  errors: TErrorObject
  onChange: (e: ChangeEvent) => void
  onChangeConditions?: (o: TChangeConditions) => void
  periodStart?: Date
  periodFinish?: Date
  emptyDatesLabel?: string
  isEmptyDates?: boolean
  labelCalendarFrom?: string
  labelCalendarTo?: string
}

export const ISOFormat = 'yyyy-MM-dd'

export const CalendarDatePicker = ({
  emptyDatesLabel,
  periodStart,
  periodFinish,
  errors,
  onChange,
  onChangeConditions,
  prefixStart,
  prefixFinish,
  isEmptyDates,
  labelCalendarFrom,
  labelCalendarTo
}: IDatePickerControl): JSX.Element => {
  const minPeriodStartDate = useMemo(() => subYears(periodStart || now(), 5), [periodStart])
  const minPeriodFinishDate = useMemo(() => parseDate(periodStart || now()), [periodStart])
  const maxPeriodFinishDate = useMemo(() => addYears(periodFinish || now(), 5), [periodFinish])

  const initialStartFilter = useMemo(
    () => ({
      minDate: minPeriodStartDate
    }),
    [minPeriodStartDate]
  )
  const initialEndFilter = useMemo(
    () => ({
      minDate: minPeriodFinishDate,
      maxDate: maxPeriodFinishDate
    }),
    [minPeriodFinishDate, maxPeriodFinishDate]
  )

  const [periodStartCalendar, setPeriodStartCalendar] = useState<Date>(periodStart || now())
  const [periodEndCalendar, setPeriodEndCalendar] = useState(periodFinish || now())

  const [filterStart, setFilterStart] = useState(initialStartFilter)
  const [filterEnd, setFilterEnd] = useState(initialEndFilter)

  const emitConditions = ({ periodStartCalendar, periodEndCalendar }: TRange): void => {
    const periodStart = parseDate(periodStartCalendar)
    const periodEnd = parseDate(periodEndCalendar)
    onChangeConditions &&
      onChangeConditions({
        periodStartCalendar: formatDate(periodStart, ISOFormat),
        periodEndCalendar: formatDate(periodEnd, ISOFormat)
      })
  }

  /**
   * Обработка выбора дат
   * @param propName
   * @param value
   */
  const relatedProps = (propName: string, value: Date): void => {
    const valueDate = parseDate(value)
    if (propName === prefixStart) {
      /**
       * Необходимо сгенерировать фильтр для конечной даты
       */
      const filter = {
        minDate: valueDate,
        maxDate: addYears(valueDate, 3)
      }
      /**
       * Если текущая конечная дата не подпадает под новый интервал времени
       */
      if (!isWithinInterval(periodEndCalendar, { start: filter.minDate, end: filter.maxDate })) {
        /**
         * дата окончания равна дате начала
         */
        setPeriodEndCalendar(filter.minDate)
        /**
         * Эмитим изменение с новыми датами начала и окончания
         */
        emitConditions({
          periodStartCalendar: filter.minDate,
          periodEndCalendar: filter.minDate
        })
      } else {
        emitConditions({
          periodStartCalendar,
          periodEndCalendar
        })
      }
      setFilterEnd(filter)
    }
  }

  const emitEvent = ({ type, value }: TObj): void => {
    const event = {
      target: {
        name: type,
        value
      }
    } as ChangeEvent<HTMLInputElement>
    onChange(event)
  }

  const HANDLERS = {
    [prefixStart]: (type: string, date: Date) => {
      const valueDate = parseDate(date)
      setPeriodStartCalendar(date)
      relatedProps(type, date)
      emitEvent({ type, value: formatDate(valueDate, ISOFormat) })
    },
    [prefixFinish]: (type: string, date: Date) => {
      const valueDate = parseDate(date)
      setPeriodEndCalendar(date)
      relatedProps(type, date)
      emitEvent({ type, value: formatDate(valueDate, ISOFormat) })
    }
  }

  const handleDateChange = (type: string) => (date: Date | null): void => {
    if (!isNull(date)) return HANDLERS[type](type, date)
  }

  const handleClick = useCallback(
    (event: { preventDefault: () => void }): void => {
      event.preventDefault()
      const afterResetDatesFilter = {
        minDate: now()
      }
      const afterResetFinishDatesFilter = {
        minDate: now(),
        maxDate: addYears(now(), 3)
      }
      setFilterStart(afterResetDatesFilter)
      setFilterEnd(afterResetFinishDatesFilter)
      setPeriodStartCalendar(now())
      setPeriodEndCalendar(now())
      onChangeConditions &&
        onChangeConditions({
          periodStartCalendar: formatDate(now(), ISOFormat),
          periodEndCalendar: formatDate(now(), ISOFormat)
        })
    },
    [onChangeConditions]
  )

  function renderDayInPicker(
    date: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    dayInCurrentMonth: boolean,
    dayComponent: JSX.Element
  ): JSX.Element {
    if (dayInCurrentMonth) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const day = parseInt(dayComponent['props']['children'], 10)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const isDisabled = Boolean(dayComponent['props']['disabled'])
      return (
        <div className='dayWithQaAttribute' data-qa-day={day} data-qa-active={!isDisabled}>
          {dayComponent}
        </div>
      )
    }
    return dayComponent
  }

  function renderEmptyDatesButton(): JSX.Element {
    return (
      <div className='form__item'>
        <Button color='blue' size='m' fluid onClick={handleClick}>
          {emptyDatesLabel ?? intl.emptyDates}
        </Button>
      </div>
    )
  }

  return (
    <div className='form__line'>
      <div className='calendar-container' style={{ border: 'none' }}>
        <div className='partner-page__calendar_container'>
          <div className='partner-page__calendar_item'>
            <FormItem error={errors[prefixStart]}>
              <DatePicker
                renderDay={renderDayInPicker}
                margin='normal'
                id='date-picker-periodStartCalendar'
                label={labelCalendarFrom ? labelCalendarFrom : intl.periodStartPromo}
                format='dd.MM.yyyy'
                value={periodStartCalendar}
                name={prefixStart}
                autoOk
                onChange={handleDateChange(prefixStart)}
                minDate={filterStart.minDate}
              />
            </FormItem>
          </div>
          <div className='partner-page__calendar_item'>
            <FormItem error={errors[prefixFinish]}>
              <DatePicker
                renderDay={renderDayInPicker}
                margin='normal'
                id='date-picker-periodEndCalendar'
                label={labelCalendarTo ? labelCalendarTo : intl.periodFinishPromo}
                format='dd.MM.yyyy'
                value={periodEndCalendar}
                name={prefixFinish}
                autoOk
                onChange={handleDateChange(prefixFinish)}
                maxDate={filterEnd.maxDate}
                minDate={filterEnd.minDate}
              />
            </FormItem>
          </div>
        </div>
        {isEmptyDates && renderEmptyDatesButton()}
      </div>
    </div>
  )
}
