import { FC, useCallback, useState } from 'react'

import appConfig from '#config'

import Icon from '../Icon'

const VirtualAndUnnamedCardsHint: FC = () => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggleHintVisibility = useCallback(() => setIsOpen((isOpen) => !isOpen), [])

  if (!appConfig.cardProblemEnabled) return null

  return (
    <>
      <div className='mt-3 mb-2'>
        <p
          className='text-black-50 fw-bold text-justify m-0 border rounded p-2'
          style={{ fontSize: 14 }}
        >
          <Icon
            name='alarm'
            className='float-start me-2'
            style={{ width: '1.75em', height: '1.75em' }}
          />
          {'Если у Вас вирутальная или неименная карта, '}
          {
            'или Вы не хотите загружать фото своей карты, воспользуйтесь способом выдачи займа на банковский счет,'
            // eslint-disable-next-line react/jsx-no-literals
          }
          &ensp;
          <span className='link link_pseudo text-nowrap' onClick={handleToggleHintVisibility}>
            {'используя счет карты'}
          </span>
          {'.'}
        </p>
      </div>
      {isOpen && (
        <div className='text-black-50 border rounded py-3 px-2 text-justify'>
          <p className='mt-0'>
            {'Оформите выдачу на '}
            <b>{'НОМЕР СЧЕТА КАРТЫ'}</b>
            {', деньги зачислятся на карту автоматически.'}
          </p>
          <p>
            {'Для этого перейдите на вкладку '}
            <b>{'БАНКОВСКИЙ СЧЕТ'}</b>
            {' и введите реквизиты счета.'}
          </p>
          <p>
            <b>{'НОМЕР СЧЕТА КАРТЫ'}</b>
            {' доступен в онлайн-банке, также можно обратиться в службу поддержки банка.'}
          </p>
          <p className='mb-0 d-flex align-items-center'>
            <b>{'Номер, указанный на карте, НЕ ЯВЛЯЕТСЯ номером счета.'}</b>
          </p>
        </div>
      )}
    </>
  )
}

export default VirtualAndUnnamedCardsHint
