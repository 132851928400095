import './assets/partner-info.scss'

import classnames from 'classnames'
import FormData from 'form-data'
import PropTypes from 'prop-types'
import querystring from 'query-string'
import { Component } from 'react'
import { Cookies } from 'react-cookie'
import { Navigate, NavLink, Route, Routes } from 'react-router-dom'

import { RegistrationFormWrapper } from '#components/PartnerPagesNew/RegistrationFormWrapper'
import intl from '#intl'
import { brokerApi } from '#src/modules/api'

import LoanForm from '../LoanForm/LoanForm'
import RegistrationForm from '../RegistrationForm/RegistrationForm'
import { Spinner } from '../Spinner/Spinner'
import PartnerShowForm from './PartnerSubComponents/PartnerShowForm'

const cookies = new Cookies()

const WarningMessage = (props) => {
  const { message } = props
  return (
    <div className='partner-info__warning'>
      <div className='partner-info__warning-message'>{message}</div>
    </div>
  )
}

WarningMessage.propTypes = {
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired
}

class PartnerLoanInfo extends Component {
  state = {
    loading: false,
    token: null,
    tabs: [
      {
        label: 'Просмотр анкеты',
        link: 'info',
        active: false,
        component: <div>Просмотр анкеты</div>
      },
      {
        label: 'Редактировать анкету',
        link: 'edit',
        active: true
      }
    ],
    isWarning: false
  }

  async componentDidMount() {
    const { personId, brokerToken } = this.props
    this.setState({
      loading: true
    })
    try {
      // получаем token персоны для восстановления данных и возмоных действий
      const { code, data } = await brokerApi.getPerson2BrokerToken({
        personId,
        brokerToken
      })
      if (code === 0) this.setState({ token: data.token })
      else this.setState({ token: null })
      this.setState({
        loading: false
      })
    } catch (e) {
      this.setState({
        loading: false
      })
    }
  }

  renderContent() {
    const { tabs } = this.state
    return tabs.filter((tab) => tab.active === true).reduce((acc, cur) => cur).component
  }

  handleTabClick = (inx) => {
    const { tabs } = this.state
    const activeTab = tabs.map((tab, index) => {
      if (index === inx) return { ...tab, active: true }
      return { ...tab, active: false }
    })
    this.setState({
      tabs: activeTab
    })
  }

  RenderPartnerRegistrationForm = () => {
    const { personId } = this.props
    return (
      <div className='partner-info__wrapper'>
        <div className='page partner-page'>
          <RegistrationFormWrapper>
            <div className='partner-page__loan'>
              <div className='partner-page partner-page__loan-top partner-page__edit-loan'>
                <LoanForm promoCodeEnabled />
              </div>
              <RegistrationForm
                row={{ personId }}
                onFinish={this.handleFinishAction}
                onSendToViewMode={this.handleViewMode}
                brokerForm
              />
            </div>
          </RegistrationFormWrapper>
        </div>
      </div>
    )
  }

  RenderShowForm = () => {
    const { personId } = this.props
    const { isWarning } = this.state
    return (
      <div className='partner-info__wrapper'>
        {isWarning && <WarningMessage message={intl.warningPartnerLoan} />}
        <div className='page partner-page'>
          <div className='partner-page__loan'>
            <PartnerShowForm
              data={{ personId }}
              location={this.props.location}
              navigate={this.props.navigate}
            />
          </div>
        </div>
      </div>
    )
  }

  handleFinishAction = async (data) => {
    // если мы здесь, значит персона создана, доведена до конца брокером
    const {
      formProps: { app, row }
    } = data
    const { navigate, location } = this.props

    const fd = new FormData()
    fd.append('brokerToken', app.loggedInToken)
    fd.append('personToken', data.token)
    fd.append('personId', row.personId)
    // создаем новый займ
    const requestData = {
      token: data.token,
      brokerToken: app.loggedInToken,
      c4s: cookies.get('c4s') || ''
    }
    try {
      const response = await brokerApi.brokerSubmitRegistration(requestData)
      if (response.code !== 0) throw response
      const queryObj = {
        ...querystring.parse(location.search),
        creditId: response.data.creditId,
        personId: row.personId
      }
      navigate(querystring.stringifyUrl({ url: '/loan', query: queryObj }), { replace: true })
    } catch (err) {
      console.error(err)
      return err
    }
  }

  handleViewMode = () => {
    const { navigate, personId, openFrom } = this.props
    this.setState({
      isWarning: true
    })
    navigate(`${openFrom}/${personId}/info`)
  }

  render() {
    const { loading, tabs } = this.state
    const { personId, openFrom } = this.props
    if (loading) {
      return (
        <div className='ticket-container'>
          <Spinner className='common-spinner' />
        </div>
      )
    }
    return (
      <div className='partner-info__container'>
        <div className='partner-info__actions'>
          <div className='tabs'>
            <ol className='tab-list'>
              {tabs.map((tab, inx) => {
                const { label, link } = tab
                return (
                  <NavLink
                    key={inx}
                    to={`${openFrom}/${personId}/${link}`}
                    className={({ isActive }) =>
                      classnames({
                        'tab-list-item': true,
                        'tab-list-active': isActive
                      })
                    }
                  >
                    <li>{label}</li>
                  </NavLink>
                )
              })}
            </ol>
          </div>
        </div>
        <div className='partner-info__content'>
          <Routes>
            <Route path={`${personId}/edit`} element={this.RenderPartnerRegistrationForm()} />
            <Route path={`${personId}/info`} element={this.RenderShowForm()} />
            <Route
              path={`/${personId}`}
              element={<Navigate to={`${openFrom}/${personId}/edit`} />}
            />
          </Routes>
        </div>
      </div>
    )
  }
}

PartnerLoanInfo.propTypes = {
  personId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  brokerToken: PropTypes.string,
  navigate: PropTypes.func,
  location: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  openFrom: PropTypes.string
}

export default PartnerLoanInfo
