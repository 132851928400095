/* eslint-disable react/prop-types */
import '../../Tabs/tabs-styles.scss'

import FormData from 'form-data'
import PropTypes from 'prop-types'
import querystring from 'query-string'
import { Component } from 'react'
import { Cookies } from 'react-cookie'

import { RegistrationFormWrapper } from '#components/PartnerPagesNew/RegistrationFormWrapper'
import Spinner from '#components/Spinner'
import withBrokerToPersonToken from '#hoc/withBrokerToPersonToken'
import { withRouter } from '#hoc/withRouter'
import intl from '#intl'
import { getTabsByRow } from '#services/helper'
import { brokerApi } from '#src/modules/api'

import LoanForm from '../../LoanForm/LoanForm'
import RegistrationForm from '../../RegistrationForm/RegistrationForm'
import Tabs from '../../Tabs/Tabs'
import PartnerAddCreditForm from './AddPartnerCreditForm'
import CardPhotoForBrokerForm from './CardPhotoForBrokerComponent/CardPhotoForBrokerForm'
import PartnerBidsForm from './PartnerBidsForm'
import PartnerPaymentsForm from './PartnerPaymentsForm'
import PartnerShowForm from './PartnerShowForm'

const cookies = new Cookies()

const WithTokenLoanForm = withBrokerToPersonToken(LoanForm)

class SubComponentEntity extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      personToken: '',
      tabs: []
    }
    this.handleInjectFormHandler = this.handleInjectFormHandler.bind(this)
  }

  async componentDidMount() {
    const { row, part, loggedInToken, location } = this.props
    const {
      original: { finished = false, personId }
    } = row

    this.setState({ loading: true })

    try {
      const tabs = getTabsByRow({ row: row.original, part })

      if (location.pathname !== '/accounts') tabs.push('handleBrokerProcessing')

      const {
        data: { token = null }
      } = await brokerApi.getPerson2BrokerToken({
        personId,
        brokerToken: loggedInToken
      })
      if (token) {
        const params = {
          brokerToken: loggedInToken,
          personToken: token
        }
        const response = await brokerApi.brokerCheckCredit(params)
        if (response.code === 0 && finished) tabs.push('handleGetNewCredit')
        this.setState({ tabs, personToken: token, personId })
      }
    } catch (error) {
      return null
    }

    this.setState({ loading: false })
  }

  async handleInjectFormHandler(data) {
    // если мы здесь, значит персона создана, доведена до конца брокером
    const {
      formProps: {
        app,
        row: { personId }
      }
    } = data
    const { navigate, location } = this.props

    const fd = new FormData()
    fd.append('brokerToken', app.loggedInToken)
    fd.append('personToken', data.token)
    fd.append('personId', personId)
    // создаем новый займ
    const requestData = {
      token: data.token,
      brokerToken: app.loggedInToken,
      c4s: cookies.get('c4s')
    }
    try {
      const response = await brokerApi.brokerSubmitRegistration(requestData)
      if (response.code !== 0) throw response
      const queryObj = {
        ...querystring.parse(location.search),
        creditId: response.data.creditId,
        personId
      }
      navigate(querystring.stringifyUrl({ url: '/loan', query: queryObj }), { replace: true })
    } catch (err) {
      console.error(err)
      return err
    }
  }

  render() {
    const { loading, tabs, personToken } = this.state
    const { row, location, navigate } = this.props
    const attr = {
      position: 'relative',
      minHeight: '100px'
    }
    const pageStyle = {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column'
    }
    const loanStyle = {
      maxWidth: '540px',
      margin: 'auto',
      marginBottom: '30px'
    }
    return loading ? (
      <div className='partner-page__tabs p-0 border-0 w-100 shadow-none' style={attr}>
        <Spinner className='button__spinner' />
      </div>
    ) : (
      <Tabs className='partner-page__tabs p-0 border-0 w-100 shadow-none'>
        {tabs.map((tab, inx) => (
          <li label={intl[tab]} key={inx}>
            {tab === 'handleMoveToCreditForm' && (
              <>
                <div
                  className='partner-page partner-page__loan-top partner-page__edit-loan'
                  style={loanStyle}
                >
                  <WithTokenLoanForm
                    brokerToken={this.props.loggedInToken}
                    personId={row.original.personId}
                    promoCodeEnabled
                  />
                </div>
                <div style={{ ...pageStyle, ...{ left: 'calc(100% /64)' } }}>
                  <RegistrationFormWrapper>
                    <RegistrationForm
                      row={row.original}
                      onFinish={this.handleInjectFormHandler}
                      brokerForm
                    />
                  </RegistrationFormWrapper>
                </div>
              </>
            )}
            {tab === 'handleBrokerProcessing' && (
              <div style={pageStyle}>
                <PartnerBidsForm className='partner-page__bids-form' data={row.original} />
              </div>
            )}
            {tab === 'handlePaymentsMethod' && (
              <PartnerPaymentsForm className='partner-page__payments-form' data={row.original} />
            )}
            {tab === 'handleGetNewCredit' && (
              <div className='partner-page__new-loan'>
                <PartnerAddCreditForm row={row.original} data={row.original} />
              </div>
            )}
            {tab === 'handleMoveToShowForm' && (
              <div style={pageStyle}>
                <PartnerShowForm data={row.original} location={location} navigate={navigate} />
              </div>
            )}
            {tab === 'handleCardPhotosForBroker' && (
              <div style={pageStyle}>
                <CardPhotoForBrokerForm
                  brokerToken={this.props.loggedInToken}
                  personToken={personToken}
                />
              </div>
            )}
          </li>
        ))}
      </Tabs>
    )
  }
}

SubComponentEntity.propTypes = {
  row: PropTypes.object,
  part: PropTypes.string
}

export default withRouter(SubComponentEntity)
