/* eslint-disable react/no-danger */
import loadable from '@loadable/component'
import classnames from 'classnames'
import FormData from 'form-data'
import { flowRight } from 'lodash'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { Cookies } from 'react-cookie'
import { connect } from 'react-redux'

import { getRouteValue } from '#constants/common'
import intl from '#intl'
import { mainSiteApi } from '#modules/api'
import { updateLoanConditions } from '#reducers/loanConditions/effects'
import { getCalculatedData } from '#reducers/loanConditions/selectors'
import { resetSliders } from '#reducers/loanFormState/effects'
import { setLoanFormState } from '#reducers/loanFormState/loanFormStateSlice'
import { formatAsMoney, formatValueByCount } from '#services/helper'
import theme from '#src/assets/scss/Exports.module.scss'
import LoanFormBase from '#src/components/LoanFormBase'
import withErrorLogger from '#src/hoc/withErrorLogger'
import { withMediaQuerySize } from '#src/hoc/withMediaQuerySize'
import { model } from '#src/models/loanForm'

import { Modal } from '../Modal/Modal'

const cookies = new Cookies()

export class LoanForm extends LoanFormBase {
  constructor(props) {
    super(props)
    this.model = model
    this.state = {
      shouldNotifyUser: false,
      form: this.computeForm()
    }
  }

  componentDidMount() {
    /* Используется командой тестирования (так как у нас lazyLoad) */
    const eventAwesome = new CustomEvent('loanFormLoaded', {
      bubbles: true,
      detail: { status: 'ok' }
    })
    document.dispatchEvent(eventAwesome)
  }

  componentDidUpdate(_prevProps, _prevState) {
    super.componentDidUpdate()
  }

  getToken = () => cookies.get('token') || this.props.token || null

  async onBeforePromoCodeApply(appliedPromoString) {
    const token = this.getToken()
    if (!token) return
    const formData = new FormData()
    formData.append('promocode', appliedPromoString)
    const respose = await mainSiteApi.attachPromoCode(formData, { token })
    if (respose.code !== 0) throw respose
  }

  async onAfterPromoCodeCancel(canceledPromoString) {
    const token = this.getToken()
    if (!token) return
    const formData = new FormData()
    formData.append('promocode', canceledPromoString)
    try {
      await mainSiteApi.detachPromoCode(formData, { token })
    } catch (error) {
      this.props.logError(error, 'LoanForm.onAfterPromoCodeCancel')
    }
  }

  handleOpenModal = () => {
    const { promoDescription: document } = this.props
    this.setLoanFormState({ modalDocument: { url: '', data: { document } } })
  }

  handleOpenPromoDescription = () => {
    const { isPromoCodeDescriptionVisible } = this.loanFormState
    this.setLoanFormState({ isPromoCodeDescriptionVisible: !isPromoCodeDescriptionVisible })
  }

  handleCloseModal = () => {
    this.setLoanFormState({ modalDocument: null })
  }

  renderPromoCode(props) {
    if (!this.props.promoCodeEnabled) return null
    return super.renderPromoCode({
      ...props,
      onTogglePromoDescriptionVisibility: this.handleOpenPromoDescription
    })
  }

  renderSliderItem(props) {
    return super.renderSliderItem({
      ...props,
      className: 'loan-form__slider',
      size: 'l',
      ...this.props.sliderStyle
    })
  }

  renderWarningMessage = (props) => {
    const { amount } = this.loanFormState.data
    const { amountLimitWithoutDocuments, message } = props
    let warningMessage = ''
    if (amount > amountLimitWithoutDocuments) warningMessage = message

    return warningMessage
  }

  renderAmountItem(props) {
    const { min, max } = this.loanFormState.amount
    const hint = `${min} – ${formatAsMoney(max)}`
    const message = this.renderWarningMessage(props)
    return this.renderSliderItem({
      ...props,
      hint,
      message
    })
  }

  renderCreditType(props) {
    const { disableRenderCreditType } = this.props
    if (disableRenderCreditType) return null
    return super.renderCreditType({
      ...props,
      className: 'loan-form__creditType'
    })
  }

  renderCalculatedResult(props) {
    const {
      userDevice: { isTablet },
      view,
      isLoanResultBordered
    } = this.props
    let variant = 'default'
    if (view === 'horizontal') variant = 'stickyPanelCompact'
    if (isTablet && view === 'horizontal') variant = 'stickyPanelFullWidth'

    return super.renderCalculatedResult({
      ...props,
      borderless: !isLoanResultBordered,
      variant,
      className: 'loan-form__result',
      styles: this.props.calculatedResultStyle
    })
  }

  renderSubmitButton(props) {
    const { view = 'vertical' } = this.props
    const { submitting, updating } = this.loanFormState
    const submitButtonClasses = classnames({
      'mt-4': view === 'vertical',
      'loan-form__submit-button': true
    })
    return super.renderSubmitButton({
      ...props,
      inverted: true,
      type: 'link',
      fluid: view === 'vertical',
      size: 'l',
      url: getRouteValue('register'),
      loading: submitting,
      disabled: submitting || updating,
      className: submitButtonClasses
    })
  }

  getSliderDisplayValue(sliderName) {
    const value = this.loanFormState.data[sliderName]
    return (
      {
        amount: formatAsMoney(value),
        term:
          this.loanFormState.dimension === 'month'
            ? formatValueByCount(value, intl.monthsList).toLowerCase()
            : formatValueByCount(value, intl.days).toLowerCase()
      }[sliderName] || null
    )
  }

  render() {
    super.render()
    const { fetching, modalDocument } = this.loanFormState
    const {
      className,
      view = 'vertical',
      buttonExist,
      children,
      lineStyle,
      forTkbStyles
    } = this.props
    const formClasses = classnames(
      {
        'form loan-form': true,
        'loan-form_vertical': view === 'vertical',
        'd-flex align-items-center justify-content-between flex-grow-1': view === 'horizontal'
      },
      className
    )

    if (fetching) {
      const LoanLoader = loadable(() => import('#components/LoanLoader'))
      return <LoanLoader spinnerClassName='spinner-centered w-100 text-primary spinner-md' />
    }

    return (
      <>
        <div className={formClasses} data-qa='loanForm' style={forTkbStyles || {}}>
          {this.state.form.lines.map((line, key) => {
            const [item] = line.items
            const extraStyle = lineStyle[item.line] ?? {}
            const isLoanResultLine = line.items.some(({ name }) => name === 'result')
            const formLineClasses = classnames({
              form__line: true,
              'loan-form__result-line': isLoanResultLine
            })
            return (
              <div key={key} className={formLineClasses} style={extraStyle}>
                {line.items.map((item) => (
                  <Fragment key={item.name}>{this.renderItem(item)}</Fragment>
                ))}
              </div>
            )
          })}
          {children}
          {buttonExist && this.renderSubmitButton()}
        </div>
        {modalDocument && (
          <Modal onClose={this.handleCloseModal}>
            <div dangerouslySetInnerHTML={{ __html: this.props.promoDescription }} />
          </Modal>
        )}
      </>
    )
  }
}

LoanForm.propTypes = {
  className: PropTypes.string,
  view: PropTypes.oneOf(['vertical', 'horizontal']),
  promoCodeEnabled: PropTypes.bool,
  buttonExist: PropTypes.bool,
  loanConditions: PropTypes.object,
  loanFormState: PropTypes.object,
  isLoanResultBordered: PropTypes.bool,
  promoType: PropTypes.number,
  disableRenderCreditType: PropTypes.bool,
  disableRenderLoanTermHint: PropTypes.bool,
  lineStyle: PropTypes.object
}

LoanForm.defaultProps = {
  ...LoanFormBase.defaultProps,
  calculatedResultStyle: {
    returnSumLabel: { color: 'inherit' },
    returnSumValue: { color: theme.primaryColor },
    percentsLabel: { color: 'inherit' },
    percentsValue: { color: theme.primaryColor },
    returnDate: { color: 'inherit' },
    paymentsCountLabel: { color: 'inherit' },
    paymentsCountValue: { color: theme.primaryColor },
    firstPaymentLabel: { color: 'inherit' },
    firstPaymentValue: { color: theme.primaryColor },
    nextPaymentsLabel: { color: 'inherit' },
    nextPaymentsValue: { color: theme.primaryColor }
  },
  sliderStyle: {
    hintStyle: {
      color: 'inherit',
      fontWeight: 'bold',
      fontSize: 'small'
    }
  },
  lineStyle: {}
}

const mapStateToProps = (state) => ({
  loanConditions: state.loanConditions.data.products,
  promo: state.loanConditions.data.promo,
  styles: state.loanConditions.data.promoStyle,
  promoDescription: state.loanConditions.data.promoDescription,
  loanFormState: state.loanFormState,
  promoType: state.loanConditions.data.promoType,
  notifications: state.notifications.data,
  calculatedData: getCalculatedData(state)
})

const mapDispatchToProps = (dispatch) => ({
  updateLoanConditions: (promoCode) =>
    dispatch(updateLoanConditions({ promoCode, apiType: 'main' })),
  setLoanFormState: (newState) => dispatch(setLoanFormState(newState)),
  resetSliders: () => dispatch(resetSliders())
})

const componentWithHocs = flowRight(withMediaQuerySize, withErrorLogger)(LoanForm)

export default connect(mapStateToProps, mapDispatchToProps)(componentWithHocs)
