import './confirm-form.scss'

import classnames from 'classnames'
import PropTypes from 'prop-types'

import {
  ABSOLUT_INSURANCE_RELATED,
  ABSOLUT_PROCESSING_PERSONAL_DATA,
  CHECK_VALUES,
  D2_CONSENT_INSURANCE_RELATED,
  D2_PROCESSING_PERSONAL_DATA,
  DOCTOR_NEARBY_INSURANCE_RELATED,
  DOCTOR_NEARBY_PROCESSING_PERSONAL_DATA,
  SCORISTA_PROCESSING_PERSONAL_DATA,
  SCORISTA_PROCESSING_PERSONAL_DATA_RELATED
} from '#components/PersonalAreaPage/components/ConfirmLoanForm/model'
import intl from '#intl'
import metrika from '#modules/metrica'
import { PersonalDataAgreement } from '#modules/PersonalDataAgreement'

import { Button } from '../Button/Button'
import { Form } from '../Form/Form'
import { FormItem } from '../Form/FormItem'
import SmartControl from '../SmartControl'

const mapAgreementToInsurance = {
  [ABSOLUT_PROCESSING_PERSONAL_DATA]: ABSOLUT_INSURANCE_RELATED,
  [DOCTOR_NEARBY_PROCESSING_PERSONAL_DATA]: DOCTOR_NEARBY_INSURANCE_RELATED,
  [D2_PROCESSING_PERSONAL_DATA]: D2_CONSENT_INSURANCE_RELATED,
  [SCORISTA_PROCESSING_PERSONAL_DATA]: SCORISTA_PROCESSING_PERSONAL_DATA_RELATED
}

export class ConfirmForm extends Form {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      data: props.data || {},
      form: this.computeForm(),
      isAgreementExpanded: {
        agreementChecked: false,
        personDataAgreementChecked: false
      }
    }
    this.mapDocumentTypeToModel = {
      agreementChecked: 'agreement',
      personDataAgreementChecked: 'person_data_agreement',
      privateTermsChecked: 'privateTerms',
      generalTermsChecked: 'generalTerms',
      nbkiAgreementChecked: 'agreements_nbki'
    }
  }

  initPersonalDataAgreementHelper = (model) => {
    this.personalDataAgreementHelper = new PersonalDataAgreement({ model })
  }

  handleSubmit(event) {
    event && event.preventDefault()
  }

  /**
   * @override Form renderItem
   */
  renderItem(item) {
    const { data, errors, loadingItems } = this.state
    const { innRequired } = this.props
    const rowElement = {
      ...item,
      loading: loadingItems[item.name]
    }

    if (rowElement.name !== 'inn') {
      return (
        <FormItem key={rowElement.name} {...rowElement} error={errors[rowElement.name]}>
          <SmartControl
            value={data[rowElement.name]}
            valid={!errors[rowElement.name]}
            onFocus={this.handleControlFocus}
            onBlur={this.handleControlBlur}
            onChange={this.handleControlChange}
            {...rowElement}
            style={{ ...rowElement.style, ...rowElement.smartControlStyle }}
          />
        </FormItem>
      )
    }

    if (!innRequired) {
      return (
        <FormItem key={rowElement.name} {...rowElement} error={errors[rowElement.name]}>
          <SmartControl
            {...rowElement}
            value={data[rowElement.name]}
            valid={!errors[rowElement.name]}
            onFocus={this.handleControlFocus}
            onBlur={this.handleControlBlur}
            onChange={this.handleControlChange}
          />
        </FormItem>
      )
    }

    return null
  }

  handleExpandAgreement = (name) => () => {
    this.setState((state) => ({
      ...state,
      isAgreementExpanded: {
        ...state.isAgreementExpanded,
        [name]: !state.isAgreementExpanded[name]
      }
    }))
  }

  isSelectedAllAgreements = (item) => {
    const { child = [] } = item
    if (child && child.length > 0) {
      let isAllSelected = true
      child.forEach((item) => {
        if (!this.state.data[item.name]) isAllSelected = false
      })
      return isAllSelected
    }
    return true
  }

  checkAndSetCheckedValueToParent = (name) => {
    const { siblings, parent } = this.personalDataAgreementHelper.getChildItemsByName(name)
    let isChecked = 0
    siblings.every((itemName) => {
      if (this.state.data[itemName]) {
        isChecked = 1
        return false
      }
      return true
    })

    this.setState((state) => ({
      ...state,
      data: {
        ...state.data,
        [parent]: isChecked
      }
    }))
  }

  setCheckedValueToParent = (name) => {
    const { parent } = this.personalDataAgreementHelper.getChildItemsByName(name)
    this.setState((state) => ({
      ...state,
      data: {
        ...state.data,
        [parent]: 1
      },
      errors: { ...state.errors, [parent]: null }
    }))
  }

  // slov-8253 изменения в родительском чекбоксе, у которого имеются дочерние
  calcChildValuesFromParent = (event) => {
    const {
      target: { name }
    } = event
    if (this.personalDataAgreementHelper.getItemNamesWithChild().includes(name)) {
      const {
        target: { value }
      } = event
      // в родительском компоненте проставляют выбор, дочерним выставляем/убираем галки
      const modelItem = this.model.find((item) => item.name === name)
      const nextData = modelItem.child.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.name]: value
        }),
        {}
      )
      // если у всех снимается checked, то у связанных с данными дочерними
      // так же необходимо снять данный флаг
      let relatedData = {}
      if (!value) {
        relatedData = [
          ...ABSOLUT_INSURANCE_RELATED,
          ...DOCTOR_NEARBY_INSURANCE_RELATED,
          ...SCORISTA_PROCESSING_PERSONAL_DATA_RELATED
        ].reduce(
          (acc, cur) => ({
            ...acc,
            [cur]: value
          }),
          {}
        )
      }
      this.setState((state) => ({
        ...state,
        data: {
          ...state.data,
          ...nextData,
          ...relatedData
        }
      }))

      return super.handleControlChange(event)
    }
  }

  /**
   * slov-8253 Дочерний элемент изменяется необходимо проверить какие значения имеются у соседних элементах
   */
  calcParentValueFromChild = (event) => {
    const {
      target: { name }
    } = event
    if (this.personalDataAgreementHelper.getChildItemsByName(name)) {
      const {
        target: { value }
      } = event
      // если какой-то дочерний элемент checked, то и родитель становится checked
      if (value) {
        this.setCheckedValueToParent(name)
        return super.handleControlChange(event)
      }
      // иначе, у дочернего элемента снимают checked, то проверяем есть ли еще с checked
      this.checkAndSetCheckedValueToParent(name)
      // логика для "Абсолют Страхование|Доктор рядом|Д2 страхование|Scorista", если ее снимают
      if (
        [
          ABSOLUT_PROCESSING_PERSONAL_DATA,
          DOCTOR_NEARBY_PROCESSING_PERSONAL_DATA,
          D2_PROCESSING_PERSONAL_DATA,
          SCORISTA_PROCESSING_PERSONAL_DATA
        ].includes(name)
      ) {
        const nextData = mapAgreementToInsurance[name].reduce(
          (acc, cur) => ({
            ...acc,
            [cur]: 0
          }),
          {}
        )
        this.setState((state) => ({
          ...state,
          data: { ...state.data, ...nextData }
        }))
      }

      return super.handleControlChange(event)
    }
  }

  getRelatedWithChild = (name) => {
    if (ABSOLUT_INSURANCE_RELATED.includes(name)) {
      return {
        related: ABSOLUT_INSURANCE_RELATED,
        child: ABSOLUT_PROCESSING_PERSONAL_DATA
      }
    }
    if (DOCTOR_NEARBY_INSURANCE_RELATED.includes(name)) {
      return {
        related: DOCTOR_NEARBY_INSURANCE_RELATED,
        child: DOCTOR_NEARBY_PROCESSING_PERSONAL_DATA
      }
    }
    if (D2_CONSENT_INSURANCE_RELATED.includes(name)) {
      return {
        related: D2_CONSENT_INSURANCE_RELATED,
        child: D2_PROCESSING_PERSONAL_DATA
      }
    }
    if (SCORISTA_PROCESSING_PERSONAL_DATA_RELATED.includes(name)) {
      return {
        related: SCORISTA_PROCESSING_PERSONAL_DATA_RELATED,
        child: SCORISTA_PROCESSING_PERSONAL_DATA
      }
    }
    return null
  }

  sendMetrikaGoals = (name) => {
    const isChecked = !this.state.data[name]
    if (Object.keys(CHECK_VALUES).includes(name) && isChecked)
      metrika.sendGoal(CHECK_VALUES[name]?.set)
    else metrika.sendGoal(CHECK_VALUES[name]?.off)
  }

  /**
   * slov-8253 взаимосвязь дочернего элемента с родительским
   */
  calcRelatedState = (event) => {
    const {
      target: { name }
    } = event
    const mapper = this.getRelatedWithChild(name)
    // изменения происходят в согласии коллективного страхования
    if (mapper) {
      const {
        target: { value }
      } = event
      let setCheckedValue = 0
      if ([ABSOLUT_PROCESSING_PERSONAL_DATA, D2_PROCESSING_PERSONAL_DATA].includes(mapper.child)) {
        mapper.related
          .filter((item) => item !== name)
          .every((itemName) => {
            if (this.state.data[itemName] || value) {
              setCheckedValue = 1
              return false
            }
            return true
          })
      } else if (value) {
        setCheckedValue = 1
      }

      // необходимо проставить checked его родителю
      if (setCheckedValue) {
        this.setCheckedValueToParent(mapper.child)
        // само значение чекбокса absolutInsuranceChecked
        this.setState((state) => ({
          ...state,
          data: {
            ...state.data,
            [mapper.child]: setCheckedValue
          }
        }))
      }
      // само значение чекбокса согласия
      return super.handleControlChange(event)
    }
  }

  handleControlChange = (event) => {
    const {
      target: { name }
    } = event
    // установка значения checked/unchecked для дочерних из списка
    this.calcChildValuesFromParent(event)
    // установка значения checked/unchecked для родителя списка
    this.calcParentValueFromChild(event)
    // установка значения checked/unchecked для "Абсолют Страхование"
    this.calcRelatedState(event)
    this.sendMetrikaGoals(name)
    return super.handleControlChange(event)
  }

  render() {
    const { form, errors, loading } = this.state
    const className = classnames(
      {
        form: true,
        'confirm-form': true
      },
      this.props.className
    )

    return (
      <form className={className} noValidate onSubmit={this.handleSubmit}>
        {form.lines.map((line, key) => (
          <div className='form__line' key={key}>
            {line.items.map((item) => !this.checkDepends(item) && this.renderItem(item))}
          </div>
        ))}
        <div className='form__bottom'>
          {errors.common && <div className='form__error'>{errors.common}</div>}
          <Button type={'button'} size='l' fluid loading={loading}>
            {intl.confirm}
          </Button>
        </div>
      </form>
    )
  }
}

ConfirmForm.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  onSubmit: PropTypes.func
}
