import classnames from 'classnames'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'

import { AML_CFT_AGREEMENT, NBKI_AGREEMENT, PERSON_DATA_AGREEMENT, TERMS } from '#constants/common'
import intl from '#intl'

const commonAgreements = {
  person_data_agreement: {
    getText: () => intl.personDataAgreementLabel,
    getDocument: () => PERSON_DATA_AGREEMENT
  },
  aml_cft_agreement: {
    getText: () => intl.documentFT,
    getDocument: () => AML_CFT_AGREEMENT
  },
  agreements_nbki: {
    getText: () => intl.agreementNBKILabel,
    getDocument: () => NBKI_AGREEMENT
  },
  terms: {
    getText: () => intl.generalTerms,
    getDocument: () => TERMS
  }
}

const linkDisabled = {
  pointerEvents: 'none',
  cursor: 'default',
  color: '#fff'
}

const PersonDataAgreementsLabel = (props) => {
  const {
    onDownload = noop,
    loadingItems = {},
    creditId = null,
    creditType,
    loading,
    documentType
  } = props

  const mappedCommonAgreements = useMemo(() => {
    const data = { creditId, creditType }
    return {
      text: commonAgreements[documentType].getText(data),
      document: commonAgreements[documentType].getDocument(data)
    }
  }, [creditId, creditType, documentType])

  const handleDocumentDownload = useCallback(
    (document, fileName, type) => (event) => {
      event.preventDefault()
      onDownload(document, fileName, type)
    },
    [onDownload]
  )

  const linkClasses = classnames({
    'link--disabled link--pouring': Boolean(loadingItems[mappedCommonAgreements.document])
  })

  return (
    <div className='agreements'>
      <div className='agreements-loan' style={loading ? linkDisabled : null}>
        <a
          className={linkClasses}
          onClick={handleDocumentDownload(
            mappedCommonAgreements.document,
            mappedCommonAgreements.document,
            null
          )}
        >
          {mappedCommonAgreements.text}
        </a>
      </div>
    </div>
  )
}

PersonDataAgreementsLabel.propTypes = {
  onDownload: PropTypes.func,
  loadingItems: PropTypes.object,
  creditId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  creditType: PropTypes.string,
  loading: PropTypes.bool,
  documentType: PropTypes.string
}

export default PersonDataAgreementsLabel
