import { IconButton, withStyles } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { FC } from 'react'

type TDropDownProps = {
  isExpanded: boolean
  onClick: () => void
}
const StyledIconButton = withStyles({
  root: {
    padding: 0
  }
})(IconButton)

export const DropDownIcon: FC<TDropDownProps> = ({ isExpanded, onClick }) => (
  <StyledIconButton onClick={onClick}>
    {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
  </StyledIconButton>
)
