import PropTypes from 'prop-types'

const StatusItem = (props) => (
  <div className='w-100 h-100 d-flex-centered' style={{ backgroundColor: props.color }}>
    <span>{props.tooltip}</span>
  </div>
)

StatusItem.propTypes = {
  tooltip: PropTypes.string,
  color: PropTypes.string
}

export default StatusItem
