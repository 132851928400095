/* eslint-disable no-nested-ternary */
import { isEmpty, size } from 'lodash'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import { CREDIT_STATUS, PERSON_STATUS } from '#constants/common'
import intl from '#intl'
import { fetchPartnerData } from '#reducers/partnerData/effects'
import { update } from '#reducers/partnerData/partnerDataSlice'
import { selectStatList, selectStatLoading } from '#reducers/statisticsSlice'

import { partnerDataDefaultState } from './models/partnerDataHelper'
import StatisticChart from './PartnerSubComponents/StatisticChart'
import StatusItem from './PartnerSubComponents/StatusItem'
import SubComponentEntity from './PartnerSubComponents/SubComponentEntity'

const mapAccountsTableWidthsByHeader = {
  '№': 80,
  [intl.fio]: 225,
  [intl.phone]: 136,
  [intl.amount]: 60,
  [intl.term]: 50,
  [intl.processingStatus]: 116
}

const additionalFilterFields = ['name', 'secondName', 'middleName', 'mobileNumber']
const renderCell = (item = {}, obj = {}) => {
  const props = Object.values(obj).find(({ tooltip }) => tooltip === item.value) || {}
  return <StatusItem {...props} />
}

const renderComment = (item = {}) => {
  const value = item.value || ''
  const lines = value.split('\n')
  if (isEmpty(lines)) return ''
  const [firstLine] = lines
  return size(lines) > 1 ? `${firstLine} ...` : firstLine
}

const withData = (WrappedComponent, part) => {
  class WithData extends Component {
    constructor(props) {
      super(props)
      void this.init()
      this.handleFilterChanged = this.handleFilterChanged.bind(this)
      this.handleExpandedChange = this.handleExpandedChange.bind(this)
      this.state = { expanded: {} }
    }

    shouldComponentUpdate(nextProps) {
      return JSON.stringify(nextProps.partnerData) !== JSON.stringify(this.partnerData)
    }

    async init() {
      const { dispatch } = this.props
      const { columns } = partnerDataDefaultState
      await this.handleDataRequest()
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timezone: 'UTC'
      }
      switch (part) {
        case 'bids':
          dispatch(
            update({
              className: part,
              fullWidth: true,
              tabs: ['handleMoveToCreditForm', 'handleBrokerProcessing'],
              columns: [
                ...columns,
                { Header: intl.partner, accessor: 'currentPartnerName', width: 166 },
                {
                  Header: intl.brokerComment,
                  accessor: 'brokerComment',
                  width: 275,
                  Cell: (item) => renderComment(item)
                },
                { Header: intl.comment, accessor: 'comment', width: 185 },
                {
                  Header: intl.status,
                  id: 'finished',
                  accessor: ({ finished = false }) => PERSON_STATUS[Number(finished)]?.tooltip,
                  Cell: (item) => renderCell(item, PERSON_STATUS),
                  width: 100
                }
              ],
              filters: [
                'treatment',
                'period',
                'periodStart',
                'periodEnd',
                ...additionalFilterFields
              ],
              title: intl.transferredBids
            })
          )
          break
        case 'accounts':
          dispatch(
            update({
              className: part,
              fullWidth: true,
              tabs: ['handleMoveToCreditForm', 'handlePaymentsMethod', 'handleGetNewCredit'],
              columns: [
                ...columns.map((col) =>
                  col.Header ? { ...col, width: mapAccountsTableWidthsByHeader[col.Header] } : col
                ),
                {
                  Header: intl.lastBidStatus,
                  id: 'creditStatus',
                  accessor: ({ creditStatus }) => CREDIT_STATUS[creditStatus]?.tooltip,
                  Cell: (item) => renderCell(item, CREDIT_STATUS),
                  width: 155
                },
                {
                  Header: intl.addDate,
                  id: 'addDate',
                  accessor: (item) =>
                    item.creditCreateTs
                      ? new Date(item.creditCreateTs * 1000).toLocaleDateString('ru', {
                          ...options,
                          ...{
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric'
                          }
                        })
                      : 'Заявка не подана',
                  width: 150
                },
                {
                  Header: intl.modifyDate,
                  id: 'modifyDate',
                  accessor: (item) =>
                    new Date(item.modifyTs * 1000).toLocaleDateString('ru', options),
                  width: 125
                },
                {
                  Header: intl.status,
                  id: 'finished',
                  accessor: ({ finished = false }) => PERSON_STATUS[Number(finished)]?.tooltip,
                  Cell: (item) => renderCell(item, PERSON_STATUS),
                  width: 100
                }
              ],
              filters: ['calendar', ...additionalFilterFields],
              title: intl.allAccounts
            })
          )
          break
        case 'statistics':
          dispatch(
            update({
              className: part,
              tabs: [],
              columns: [
                { Header: intl.date, accessor: 'dateGrouper', width: 120 },
                {
                  Header: intl.clicksUniq,
                  width: 120,
                  id: 'clicks',
                  accessor: (item) => `${item.metrika}(${item.incoming})`
                },
                { Header: intl.bids, accessor: 'requestCount', width: 120 },
                {
                  Header: intl.newConfirmedCount,
                  id: 'confirmedCount',
                  accessor: (item) =>
                    `${item.newConfirmedCount}(${
                      Number(item.newConfirmedCountPercent) > 0
                        ? item.newConfirmedCountPercent.toFixed(2)
                        : Number(item.newConfirmedCountPercent) === 0
                        ? item.newConfirmedCountPercent.toFixed(0)
                        : '-'
                    } %)`,
                  width: 120
                },
                {
                  Header: 'CR1',
                  id: 'cr1',
                  accessor: (item) =>
                    `${item.cr1}(${item.cr1Metrika && item.cr1Metrika.toFixed(2)} %)`,
                  width: 120
                },
                {
                  Header: intl.totalIssues,
                  accessor: 'creditСount',
                  width: 120
                },
                {
                  Header: intl.holdIssues,
                  accessor: 'creditHold',
                  width: 120
                },
                {
                  Header: intl.refusedIssues,
                  accessor: 'creditNo',
                  width: 120
                },
                {
                  Header: intl.acceptedIssues,
                  accessor: 'creditOk',
                  width: 120
                },
                {
                  Header: intl.newConfirmedCount,
                  id: 'newGivenCount',
                  accessor: (item) =>
                    `${item.newGivenCount}(${
                      item.newGivenCountPercent && item.newGivenCountPercent.toFixed(2)
                    } %)`,
                  width: 120
                },
                { Header: 'CR2', accessor: 'cr2', width: 120 },
                { Header: intl.payments, accessor: 'bonus', width: 120 },
                { Header: 'eCPC', accessor: 'ecpc', width: 120 }
              ],
              filters: ['onlyNew', 'dateFrom', 'dateTo', 'groupingType'],
              title: intl.statistics
            })
          )
          break
        case 'loans':
          dispatch(
            update({
              className: part,
              tabs: [],
              fullWidth: true,
              columns: [
                { Header: '#', accessor: 'request_id', width: 75 },
                {
                  Header: 'ФИО',
                  width: 300,
                  accessor: 'personFullName'
                },
                {
                  Header: intl.addDate,
                  accessor: 'createDate',
                  width: 200
                },
                {
                  Header: intl.amount,
                  accessor: 'amount',
                  sortMethod: (a, b) => Number(a) - Number(b),
                  width: 100
                },
                {
                  Header: intl.loanTerm,
                  accessor: 'period',
                  sortMethod: (a, b) => Number(a) - Number(b),
                  width: 100
                },
                {
                  Header: 'Дата возврата',
                  width: 200,
                  accessor: 'returnDate'
                },
                {
                  Header: intl.status,
                  id: 'status',
                  accessor: ({ status }) => CREDIT_STATUS[status]?.tooltip,
                  Cell: (item) => renderCell(item, CREDIT_STATUS),
                  width: 150
                }
              ],
              filters: ['period', 'periodStart', 'periodEnd', 'status', ...additionalFilterFields],
              title: intl.partnerClientBids
            })
          )
          break
        default:
          break
      }
    }

    async handleDataRequest(newRules = null) {
      const { dispatch, partnerData, app } = this.props
      const { requestRules } = partnerData
      let rules
      if (newRules) rules = { ...newRules, ...{ brokerToken: app.loggedInToken } }
      else rules = { ...requestRules[part], ...{ brokerToken: app.loggedInToken } }

      rules.part = part
      await dispatch(fetchPartnerData(rules))
    }

    handleFilterChanged(newRules) {
      newRules.part = part
      return this.handleDataRequest({ ...newRules })
    }

    handleExpandedChange(newExpanded, index) {
      // выставляем в state index выбранной строки
      if (newExpanded[index[0]] === false) {
        newExpanded = {}
      } else {
        Object.keys(newExpanded).map((k) => {
          newExpanded[k] = parseInt(k, 10) === index[0] ? {} : false
        })
      }
      this.setState({ expanded: newExpanded })
    }

    render() {
      const { partnerData, device } = this.props
      const chartLabels = {
        dateGrouper: intl.date,
        metrika: intl.clicksUniq,
        requestCount: intl.bids,
        newGivenCount: intl.newConfirmedCount,
        cr1: 'CR1',
        cr2: 'CR2',
        creditCount: intl.totalIssues,
        creditHold: intl.holdIssues,
        creditNo: intl.refusedIssues,
        creditOk: intl.acceptedIssues,
        bonus: intl.payments,
        ecpc: 'eCPC'
      }
      const chart =
        partnerData.className === 'statistics'
          ? StatisticChart(partnerData.data, chartLabels, 'creditCount')
          : null

      return (
        <WrappedComponent
          onExpandedChange={this.handleExpandedChange}
          expanded={this.state.expanded}
          {...partnerData}
          isMobile={device.isMobile}
          onFilterChange={this.handleFilterChanged}
          SubComponent={SubComponentEntity}
          chart={chart}
          part={part}
        />
      )
    }
  }

  WithData.displayName = `WithData(${getDisplayName(WrappedComponent)})`
  WithData.propTypes = {
    app: PropTypes.object,
    device: PropTypes.object,
    dispatch: PropTypes.func,
    partnerData: PropTypes.object
  }
  return connect((state) => ({
    app: state.app,
    device: state.device,
    partnerData: state.partnerData,
    statisticsLoading: selectStatLoading(state),
    statisticsList: selectStatList(state)
  }))(WithData)
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export default withData
