import { FC } from 'react'
import { connect } from 'react-redux'

import Spinner from '#components/Spinner'
import intl from '#intl'
import { BrokerApiTypes as Types } from '#modules/api/broker/types'
import { TMainDataStateType } from '#src/reducers'
import { TInitialState as TBrokerData } from '#src/reducers/brokerSlice'

const Rate: FC<{ label: string; rate: Types.TInterestRate }> = (props) => {
  const paddingStyle = {
    padding: '10px 0 0 10px'
  }
  const {
    label,
    rate: { periodStart, summaRegister, summaPaymentGray, summaPaymentBlue, summaPaymentGreen }
  } = props
  return (
    <div className='partner-page__rate'>
      <h3>
        {label}
        {':'}
      </h3>
      <div style={paddingStyle}>
        c&nbsp;<b>{periodStart}</b>
      </div>
      <div style={paddingStyle}>{summaRegister}&nbsp;за регистрацию</div>
      <div style={paddingStyle}>{summaPaymentGray}&nbsp;за выдачу обычного займа</div>
      <div style={paddingStyle}>{summaPaymentBlue}&nbsp;за выдачу хорошего займа</div>
      <div style={paddingStyle}>{summaPaymentGreen}&nbsp;за выдачу отличного займа</div>
    </div>
  )
}
const InfoBard: FC<TBrokerData> = (props) => {
  const { interestRate, interestRateReuse, name, contact, email, partnerId } = props
  return (
    <>
      <h2>{name}</h2>
      <section>
        <h2>{intl.contacts}</h2>
        <p>
          Контактное лицо: &nbsp; <b>{contact}</b>
        </p>
        <p>
          Email: &nbsp; <b>{email}</b>
        </p>
        <p>
          Номер брокерского кабинета: &nbsp; <b>{partnerId}</b>
        </p>
      </section>
      {(interestRate || interestRateReuse) && (
        <section>
          <h2>{intl.partnerRates}</h2>
          <div>
            {interestRate && <Rate label='Ставка вознаграждения' rate={interestRate} />}
            {interestRateReuse && (
              <Rate label='Ставка вознаграждения (новые заявки)' rate={interestRateReuse} />
            )}
          </div>
        </section>
      )}
    </>
  )
}
type TPartnerComponentProps = {
  className: string
}
type TPartnerOwnProps = {
  broker: TBrokerData
}
type TPartnerMainProps = TPartnerOwnProps & TPartnerComponentProps
const PartnerMain: FC<TPartnerMainProps> = (props) => {
  const { className, broker } = props
  return (
    <div className={'partner-page__' + className}>
      <h1>{intl.welcomePk}</h1>
      {broker.loading ? <Spinner /> : <InfoBard {...broker} />}
    </div>
  )
}
PartnerMain.displayName = 'PartnerMainPage'

const mapStateToProps = (state: TMainDataStateType): TPartnerOwnProps => ({
  broker: state.brokerInfo
})

export default connect(mapStateToProps)(PartnerMain)
