/* eslint-disable no-nested-ternary */
import 'animate.css/animate.min.css'

import isMobile from 'is-mobile'
import { debounce, noop } from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Portal } from 'react-portal'
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom'
import screenfull from 'screenfull'

import Button from '#components/Button'
import FlashMessage from '#components/FlashMessage'
import Icon from '#components/Icon'
import Overlay from '#components/Overlay'
import Spinner from '#components/Spinner'
import intl from '#intl'
import { nl2br } from '#services/helper'
import useErrorLogger from '#src/hook/useErrorLogger'

import Camera from './Camera'
import CardMask from './CardMask'
import DefaultInactiveStateComponent from './DefaultInactiveStateComponent'
import { CameraBaseError, UserInteractionRequiredError } from './errors'
import QrCode from './QrCode'
import SafariFallBackStateComponent from './SafariFallBackStateComponent'
import TakePhotoButton from './TakePhotoButton'
import { buildAfineMatrix, hasCamera, hasMediaDevicesApi, isAppleDevice } from './utils'

export const CROPPER_INACTIVE_STATE = 'cropperInactive'
export const CROPPER_ACTIVE_STATE = 'cropperActive'
export const NEED_USER_INTERACTION_STATE = 'needUserInteraction'
export const QR_CODE_INACTIVE_STATE = 'qrCodeInactive'
export const QR_CODE_ACTIVE_STATE = 'qrCodeActive'
export const PENDING_STATE = 'pending'
export const INITIAL_STATE = 'initial'
export const LOADING_STATE = 'loading'
export const CAPTURING_IMAGE_STATE = 'capturing'
export const FALLBACK_STATE = 'fallback'
export const FALLBACK_TO_SAFARI_INACTIVE_STATE = 'safariOnlyInactive'
export const FALLBACK_TO_SAFARI_ACTIVE_STATE = 'safariOnlyActive'

const ORIENTATION_CHANGE_DELAY = 500
const VIBRATION_DURATION = 40

// eslint-disable-next-line sonarjs/cognitive-complexity
export const CameraCropperComponent = (props) => {
  const {
    onTakePhoto,
    qrCodeData,
    allowWebcam,
    fallBackComponent: FallBackComponent,
    component: Component,
    onStateChange,
    customizeErrorMessage,
    className,
    style
  } = props

  const [state, setStateFromHook] = useState(INITIAL_STATE)
  const [error, setErrorFromHook] = useState(null)
  const [maskOrientation, setMaskOrientation] = useState(0)

  const [logError] = useErrorLogger()

  const cameraRef = useRef(null)
  const cameraInnerRef = useRef(null)
  const maskRef = useRef(null)
  const historyUnregisterRef = useRef(null)

  const navigate = useNavigate()
  const navigationType = useNavigationType()
  const location = useLocation()
  const { pathname, search } = location

  const setState = useCallback(
    (newState) =>
      setStateFromHook((state) => {
        onStateChange(state, newState)
        return newState
      }),
    [onStateChange]
  )

  const handleFullScreenChange = useCallback(() => {
    if (!screenfull.isFullscreen) {
      setState(CROPPER_INACTIVE_STATE)
      screenfull.off('change', handleFullScreenChange)
    }
  }, [setState])

  const handleWindowOrientationChange = useCallback(({ target: windowObj }) => {
    const orientation = windowObj?.screen?.orientation?.angle || windowObj?.orientation || 0
    if (isMobile({ tablet: true })) return setMaskOrientation(Math.abs(orientation % 180) - 90)
    return setMaskOrientation(orientation)
  }, [])

  const delayedHandleWindowOrientationChange = useMemo(
    () => debounce(handleWindowOrientationChange, ORIENTATION_CHANGE_DELAY),
    [handleWindowOrientationChange]
  )

  useEffect(() => {
    if (screenfull.isEnabled) screenfull.off('change', handleFullScreenChange)
    historyUnregisterRef.current?.()
  }, [handleFullScreenChange])

  useEffect(() => {
    // onorientationchange doesn't work on Firefox for Android
    window.addEventListener('resize', delayedHandleWindowOrientationChange, false)
    handleWindowOrientationChange({ target: window })
    return () => window.removeEventListener('resize', delayedHandleWindowOrientationChange)
  }, [delayedHandleWindowOrientationChange, handleWindowOrientationChange])

  useEffect(() => {
    async function initialize() {
      const isAnyCameraAvailable = await hasCamera()
      const isMobileDevice = isMobile({ tablet: true })
      if (isAnyCameraAvailable === false && qrCodeData) return setState(QR_CODE_INACTIVE_STATE)
      if (isMobileDevice && isAppleDevice() && !hasMediaDevicesApi())
        return setState(FALLBACK_TO_SAFARI_INACTIVE_STATE)
      if (isMobileDevice || allowWebcam) return setState(CROPPER_INACTIVE_STATE)
      if (!isMobileDevice && !allowWebcam && qrCodeData) return setState(QR_CODE_INACTIVE_STATE)
      return setState(FALLBACK_STATE)
    }
    void initialize()
  }, [allowWebcam, qrCodeData, setState])

  const setError = useCallback(
    (error = {}) => {
      if (typeof customizeErrorMessage === 'function') {
        const message = customizeErrorMessage(error)
        if (message) return setErrorFromHook(message)
      }

      const errorMessage = Array.isArray(error?.data)
        ? nl2br(Object.values(error?.data).join('\n'))
        : error?.message || intl.serverError

      setErrorFromHook(errorMessage)
    },
    [customizeErrorMessage]
  )

  const getCroppedPicture = useCallback(async () => {
    const { width: maskWidth, height: maskHeight } = maskRef.current.getSize()
    const {
      topLeft: [maskX, maskY]
    } = maskRef.current.getPosition({ variant: 'relative' })
    const scale = cameraRef.current.getScale?.()
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const indent = (Math.min(maskWidth, maskHeight) * 0.075) / scale
    const cropX = maskX / scale - indent
    const cropY = maskY / scale - indent
    const cropWidth = maskWidth / scale + 2 * indent
    const cropHeight = maskHeight / scale + 2 * indent

    if (window.navigator.vibrate) window.navigator.vibrate(VIBRATION_DURATION)

    const matrix = buildAfineMatrix(cropWidth, cropHeight, -maskOrientation)
    const file = await cameraRef.current.captureImage?.({
      crop: { x: cropX, y: cropY, width: cropWidth, height: cropHeight }
    })

    return { file, matrix }
  }, [maskOrientation])

  const enableFullScreen = useCallback(async () => {
    await screenfull.request()
    screenfull.onchange(handleFullScreenChange)
  }, [handleFullScreenChange])
  const returnBackAction = useCallback(() => {
    const path = [pathname, search].join('')
    const state = { scope: 'cropper' }

    navigate(path, { state, replace: true })
    navigate(path, { state })

    historyUnregisterRef.current = () => {
      if (navigationType.Action !== 'POP') return
      setState((state) => (state === CROPPER_ACTIVE_STATE ? CROPPER_INACTIVE_STATE : state))
      historyUnregisterRef.current?.()
    }
  }, [pathname, search, navigate, navigationType.Action, setState])

  const handleUserInteraction = useCallback(async () => {
    try {
      const promises = []
      promises.push(cameraRef.current.turnOn())
      if (screenfull.isEnabled && !screenfull.isFullscreen)
        promises.push(enableFullScreen().catch(returnBackAction))
      setState(LOADING_STATE)
      await Promise.all(promises)
      setState(CROPPER_ACTIVE_STATE)
      delayedHandleWindowOrientationChange({ target: window })
    } catch (err) {
      setError(err)
      setState(CROPPER_INACTIVE_STATE)
    }
  }, [delayedHandleWindowOrientationChange, returnBackAction, enableFullScreen, setError, setState])

  const handleOpenCropper = useCallback(() => setState(PENDING_STATE), [setState])

  const handleOpenQrCode = useCallback(() => setState(QR_CODE_ACTIVE_STATE), [setState])

  const handleCloseQrCode = useCallback(() => setState(QR_CODE_INACTIVE_STATE), [setState])

  const handleOpenSafariFallback = useCallback(() => setState(FALLBACK_TO_SAFARI_ACTIVE_STATE), [
    setState
  ])

  const handleCloseSafariFallback = useCallback(() => setState(FALLBACK_TO_SAFARI_INACTIVE_STATE), [
    setState
  ])

  const clearError = useCallback(() => setErrorFromHook(null), [])

  const getActivateCropperAction = () =>
    ({
      [CROPPER_INACTIVE_STATE]: handleOpenCropper,
      [QR_CODE_INACTIVE_STATE]: handleOpenQrCode,
      [FALLBACK_TO_SAFARI_ACTIVE_STATE]: handleCloseSafariFallback,
      [FALLBACK_TO_SAFARI_INACTIVE_STATE]: handleOpenSafariFallback
    }[state] || noop)

  const handleCameraReady = useCallback(async () => {
    if (screenfull.isEnabled && !screenfull.isFullscreen) {
      try {
        await enableFullScreen()
      } catch (_e) {
        return setState(NEED_USER_INTERACTION_STATE)
      }
    }
    setState(CROPPER_ACTIVE_STATE)
    delayedHandleWindowOrientationChange({ target: window })
  }, [delayedHandleWindowOrientationChange, enableFullScreen, setState])

  const handleCameraFailed = useCallback(
    (err) => {
      if (err instanceof UserInteractionRequiredError) return setState(NEED_USER_INTERACTION_STATE)
      if (!(err instanceof CameraBaseError)) logError(err)
      setError(err)
      setState(CROPPER_INACTIVE_STATE)
      if (screenfull.isEnabled) void screenfull.exit()
    },
    [logError, setError, setState]
  )

  const handleTakePhoto = useCallback(async () => {
    try {
      setState(CAPTURING_IMAGE_STATE)
      const { file, matrix } = await getCroppedPicture()
      setState(LOADING_STATE)
      await onTakePhoto(file, matrix)
      setState(CROPPER_INACTIVE_STATE)
      screenfull.isEnabled && (await screenfull.exit())
    } catch (err) {
      logError(err)
      setState(CROPPER_ACTIVE_STATE)
      setError(err)
    }
  }, [getCroppedPicture, logError, onTakePhoto, setError, setState])

  const renderCamera = () => (
    <Camera
      ref={cameraRef}
      onCameraReady={handleCameraReady}
      onCameraFailed={handleCameraFailed}
      innerRef={cameraInnerRef}
      prevLocation={pathname}
      style={{
        opacity: [PENDING_STATE, LOADING_STATE, NEED_USER_INTERACTION_STATE].includes(state) ? 0 : 1
      }}
    >
      {renderCropperActivities()}
    </Camera>
  )

  const renderMask = () => {
    if (cameraInnerRef.current) {
      return (
        <CardMask
          ref={maskRef}
          maskedElement={cameraInnerRef.current}
          cameraRef={cameraInnerRef}
          fillCoefficient={0.8}
          rotate={maskOrientation}
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          offset={[`${(Boolean(maskOrientation) || -1) * 5}%`]}
        />
      )
    }
    return null
  }

  const renderTakePhotoButton = () => (
    <TakePhotoButton
      onClick={handleTakePhoto}
      isLoading={state === CAPTURING_IMAGE_STATE}
      disabled={state === CAPTURING_IMAGE_STATE}
      className='position-absolute text-white'
      style={maskOrientation ? { bottom: '4.5%' } : { right: '4.5%' }}
    />
  )

  const renderError = () => {
    if (error) {
      return (
        <Portal>
          <FlashMessage
            onClose={clearError}
            className='position-fixed'
            style={{ zIndex: 1000, top: '15%', left: 0, right: 0, maxWidth: '90vw' }}
            message={error}
            type='info'
            animateIn='fadeIn'
            animateOut='fadeOut'
            alarmIcon={false}
            timeout={7000}
          />
        </Portal>
      )
    }
    return null
  }

  const renderUserInteractionRequest = () => (
    <div className='position-absolute d-flex-centered flex-column w-75'>
      <Icon name='logo' className='w-50 mb-2' />
      <p className='text-center'>
        {'Камера устройства готова к использованию. Нажмите на кнопку чтобы продолжить.'}
      </p>
      <Button inverted fluid onClick={handleUserInteraction}>
        {intl.continue}
      </Button>
    </div>
  )

  const renderCropperActivities = () => {
    switch (state) {
      case PENDING_STATE:
      case LOADING_STATE:
        return (
          <Spinner className='spinner-centered w-100 text-primary spinner-xlg position-absolute' />
        )
      case NEED_USER_INTERACTION_STATE:
        return renderUserInteractionRequest()
      case CROPPER_ACTIVE_STATE:
      case CAPTURING_IMAGE_STATE:
        return (
          <>
            {renderMask()}
            {renderTakePhotoButton()}
          </>
        )
      default:
        return null
    }
  }

  const renderCropper = () => (
    <Overlay
      className='d-flex-centered overflow-hidden w-100 h-100'
      opacity={1}
      isOpen
      style={{ minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%' }}
    >
      {renderCamera()}
    </Overlay>
  )

  switch (state) {
    case INITIAL_STATE:
      return null
    case FALLBACK_STATE:
      return <FallBackComponent className={className} style={style} />
    case CROPPER_INACTIVE_STATE:
    case QR_CODE_INACTIVE_STATE:
    case FALLBACK_TO_SAFARI_INACTIVE_STATE:
      return (
        <Component
          state={state}
          activateCropper={getActivateCropperAction()}
          className={className}
          style={style}
        />
      )
    case CROPPER_ACTIVE_STATE:
    case PENDING_STATE:
    case LOADING_STATE:
    case NEED_USER_INTERACTION_STATE:
    case CAPTURING_IMAGE_STATE:
      return (
        <>
          {renderCropper()}
          {renderError()}
        </>
      )
    case QR_CODE_ACTIVE_STATE:
      return <QrCode data={qrCodeData} onClose={handleCloseQrCode} />
    case FALLBACK_TO_SAFARI_ACTIVE_STATE:
      return (
        <SafariFallBackStateComponent
          className={className}
          style={style}
          onClose={handleCloseSafariFallback}
        />
      )
    default:
      return null
  }
}

CameraCropperComponent.propTypes = {
  onTakePhoto: PropTypes.func,
  onStateChange: PropTypes.func,
  customizeErrorMessage: PropTypes.func,
  qrCodeData: PropTypes.string,
  allowWebcam: PropTypes.bool,
  component: PropTypes.elementType,
  fallBackComponent: PropTypes.elementType,
  className: PropTypes.string,
  style: PropTypes.object
}

CameraCropperComponent.defaultProps = {
  onTakePhoto: noop,
  onStateChange: noop,
  allowWebcam: false,
  component: DefaultInactiveStateComponent,
  fallBackComponent: DefaultInactiveStateComponent,
  style: {}
}

export default CameraCropperComponent
