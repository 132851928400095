import intl from '#intl'

import CameraBaseError from './CameraBaseError'

export default class UserInteractionRequiredError extends CameraBaseError {
  constructor(message = intl.userInteractionRequired) {
    super(message)
    this.name = 'CameraFailedError'
  }
}
