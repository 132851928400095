import intl from '#intl'

import CameraBaseError from './CameraBaseError'

export default class InactiveStreamError extends CameraBaseError {
  constructor(message = intl.inactiveStream) {
    super(message)
    this.name = 'InactiveStreamError'
  }
}
