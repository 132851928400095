import { TModelItem } from '#components/Form/Form'
import intl from '#intl'

export const model: TModelItem[] = [
  {
    line: 1,
    name: 'calendarPicker',
    type: 'calendarPicker',
    required: true
  },
  {
    line: 2,
    name: 'onlyNew',
    type: 'checkbox',
    required: false,
    label: intl.newLoans
  },
  {
    line: 3,
    name: 'groupingType',
    type: 'reactSelect',
    label: intl.groupFormat,
    placeholder: intl.select,
    multiple: false,
    options: [
      { value: 'by_month', text: intl.month },
      { value: 'by_day', text: intl.day }
    ]
  }
]
