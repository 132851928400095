import { FC } from 'react'
import { useSelector } from 'react-redux'

import intl from '#intl'
import { formatValueByCount } from '#services/helper'
import { TMainDataStateType } from '#src/reducers'

type TInsuranceLifeTkbAgreementLabelProps = {
  insurance: IInsuranceItem
  onDownLoad: (data: { documentType: string; documentName: string }) => void
}

export const InsuranceLifeTkbAgreementLabel: FC<TInsuranceLifeTkbAgreementLabelProps> = ({
  insurance: { insurancePricePercent = 0 }
}) => {
  const {
    data: { term, amount },
    dimension
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  } = useSelector(
    (state: TMainDataStateType) =>
      state.loanFormState as { dimension: 'month' | 'day'; data: { term: number; amount: number } }
  )
  const loanTerm =
    dimension === 'month'
      ? formatValueByCount(term, intl.monthsList).toLowerCase()
      : formatValueByCount(term, intl.days).toLowerCase()
  const insuranceLifeTotal = Math.round(((insurancePricePercent as number) * term * amount) / 100)

  return (
    <div className='agreements d-block margin-left-30'>
      {'Прошу ООО МКК «ЧЕСТНОЕ СЛОВО» распространить на меня действие '}
      <span>{'Генерального договора коллективного страхования от несчастных случаев'}</span>
      {', страховщиком по которому является ООО СК «Согласие-Вита», для чего я подаю '}
      <span>{'заявление'}</span>
      {'. С '}
      <span>{'инструкцией'}</span>
      {' и '}
      <span>{'правилами страхования'}</span>
      {` ознакомлен и согласен, в том числе с размером страховой суммы в ${amount} руб.`}
      {` и сроком страхования в ${loanTerm}.`}
      {` Оплату страхования в размере ${insuranceLifeTotal} руб.`}
      {' прошу произвести на основании моего '}
      <span>{'заявления'}</span>
      {'.'}
    </div>
  )
}
