import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import { parseDate } from '#services/datetime'

const StatisticChart = (data = [], chartLabels, chartValue) => {
  const sortedData = data
    .map((el) => el)
    .sort((a, b) => parseDate(a.dateGrouper) - parseDate(b.dateGrouper))
  return (
    <ResponsiveContainer width='100%' height={320}>
      <AreaChart data={sortedData} margin={{ top: 30 }}>
        <XAxis dataKey='dateGrouper' />
        <YAxis label={{ value: chartLabels[chartValue], angle: -90, position: 'insideLeft' }} />
        <Tooltip />
        <Area type='linear' dataKey={chartValue} stroke='#3498DB' fill='#3498DB' />
      </AreaChart>
    </ResponsiveContainer>
  )
}
export default StatisticChart
