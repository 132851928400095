import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import LoginFormPartner from '#components/LoginForm/LoginFormPartner'
import { LoginPage } from '#components/LoginPage/LoginPage'
import intl from '#intl'

const PartnerLoginPage = (props) => (
  <>
    <Helmet>
      <title>{intl.partnerTitleText}</title>
      <meta name='Description' content={intl.partnerDescriptionText} />
    </Helmet>
    <LoginPage {...props}>
      <LoginFormPartner onLogin={props.handlePartnerLogin} />
    </LoginPage>
  </>
)

PartnerLoginPage.propTypes = {
  handlePartnerLogin: PropTypes.func
}

export default PartnerLoginPage
