import './loan-result.scss'

import classnames from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import intl from '#intl'
import { getCalculatedData } from '#reducers/loanConditions/selectors'
import { formatDate, now } from '#services/datetime'
import { formatValueByCount, getModificators } from '#services/helper'

export const LoanResult = (props) => {
  const { loanFormState = {}, calculatedData } = props
  const { data: { term, amount } = {}, dimension } = loanFormState
  const { returnSum } = calculatedData

  const className = classnames({ 'loan-result': true }, props.className)
  const { dateModificator, dateList } = getModificators(dimension)

  return (
    <div className={className}>
      <div className='loan-result__col'>
        <div className='loan-result__col-title'>{intl.loanAmount}</div>
        <div className='loan-result__col-value'>
          {amount || 0} {intl.currency}
        </div>
      </div>
      <div className='loan-result__col'>
        <div className='loan-result__col-title'>{intl.loanTerm}</div>
        <div className='loan-result__col-value'>{formatValueByCount(term || 0, dateList)}</div>
      </div>
      <div className='loan-result__col'>
        <div className='loan-result__col-title'>{intl.youReturn}</div>
        {returnSum && (
          <span className='loan-result__col-value white-space-nowrap'>
            {returnSum} {intl.currency}
          </span>
        )}
        <div className='loan-result__col-subvalue'>
          {formatDate(dateModificator(now(), term), 'd MMMM yyyy')}
        </div>
      </div>
    </div>
  )
}

LoanResult.propTypes = {
  className: PropTypes.string,
  loanFormState: PropTypes.object,
  loanConditions: PropTypes.object,
  calculatedData: PropTypes.object,
  tkbAgreementValue: PropTypes.number
}

const mapStateToProps = (state) => ({
  loanConditions: state.loanConditions.data.products,
  loanFormState: state.loanFormState,
  calculatedData: getCalculatedData(state)
})

export default connect(mapStateToProps)(LoanResult)
