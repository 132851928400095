import { useCallback, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'

import Button from '#components/Button'
import intl from '#intl'

import { Modal } from '../Modal/Modal'

const IS_INSTRUCTIONS_ALREADY_READ = 'cropBriefingPassed'
const INSTRUCTIONS_URL_FULL = '/photo/'
const INSTRUCTIONS_URL_SHORT = '/card-uploading-instruction-short/'

const INITIAL_STATE = 'initial'
const SUCCESS_STATE = 'success'
const FAILURE_STATE = 'failure'

export default (handlerNameToOpenModal, noCookies = false) => (wrappedComponent) =>
  function WithCardPhotoInstructionsModal(props) {
    const [cookies, setCookie] = useCookies([IS_INSTRUCTIONS_ALREADY_READ])
    const [shouldShow, setShouldShow] = useState(false)
    const [state, setState] = useState(INITIAL_STATE)
    const awaitingPromiseRef = useRef(null)

    const onInstructionUploadSuccess = useCallback(() => setState(SUCCESS_STATE), [])
    const onInstructionUploadFailure = useCallback(() => setState(FAILURE_STATE), [])

    const { cardPhotoArticle = 'full' } = useSelector((state) => state.abTest?.data || {})

    const isCookiesEnabled = !noCookies
    const handleOpenModal = useCallback(() => {
      if (isCookiesEnabled && Number(cookies[IS_INSTRUCTIONS_ALREADY_READ])) return
      if (cardPhotoArticle === 'hide') return
      setShouldShow(true)
      return new Promise((resolve) => {
        awaitingPromiseRef.current = { resolve }
      })
    }, [cardPhotoArticle, cookies, isCookiesEnabled])

    const handleCloseModal = useCallback(() => {
      setShouldShow(false)
      state === SUCCESS_STATE && isCookiesEnabled && setCookie(IS_INSTRUCTIONS_ALREADY_READ, 1)
      awaitingPromiseRef.current.resolve('success')
    }, [isCookiesEnabled, setCookie, state])

    const Component = wrappedComponent

    return (
      <>
        <Component {...{ ...props, [handlerNameToOpenModal]: handleOpenModal }} />
        {shouldShow && (
          <Modal
            forceAppendChildrenToBody
            url={cardPhotoArticle === 'short' ? INSTRUCTIONS_URL_SHORT : INSTRUCTIONS_URL_FULL}
            onClose={handleCloseModal}
            onUploadContentSuccess={onInstructionUploadSuccess}
            onUploadContentFailure={onInstructionUploadFailure}
            contentStyle={{ minHeight: 'auto' }}
          >
            <Button
              fluid
              onClick={handleCloseModal}
              size='l'
              className='d-block mt-4 mb-2 mx-auto'
              style={{ maxWidth: 600 }}
            >
              {intl.continue}
            </Button>
          </Modal>
        )}
      </>
    )
  }
