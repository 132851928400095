import classNames from 'classnames'
import { noop } from 'lodash'
import { FC, MouseEventHandler, useCallback, useMemo } from 'react'

const DOCUMENT_TYPES = {
  doctor_nearby_application_money_transfer: {
    text: 'заявления',
    fileName: 'doctor_nearby_application_money_transfer'
  },
  doctor_nearby_application: {
    text: 'Оферты о заключении договора оказания услуг',
    fileName: 'Оферта о заключении договора оказания услуг'
  },
  doctor_nearby_program: {
    text: 'программой оказания медицинских услуг «Здоровье от МКК «ЧЕСТНОЕ СЛОВО»',
    fileName: 'Программа оказания медицинских услуг'
  },
  doctor_nearby_agreement: {
    text: 'Договора об оказании платных медицинских услуг',
    fileName: 'Договор об оказании платных медицинских услуг'
  }
}

type TDocTypes = keyof typeof DOCUMENT_TYPES
type TLoadingItems = {
  [key in TDocTypes]: boolean
}
type TDoctorNearbyAgreement = {
  onDownload: (document: string, fileName: string, type: string) => void
  loadingItems: TLoadingItems
  cost: number | string
  period: number | string
  commission: number | string
}
const DoctorNearbyAgreementLabel: FC<TDoctorNearbyAgreement> = (props) => {
  const { onDownload = noop, loadingItems = {} as TLoadingItems, period, cost, commission } = props
  const fullCost = useMemo(() => Number(cost) + Number(commission), [commission, cost])

  const handleDocumentDownload = useCallback(
    (document: string, fileName: string, type: string): MouseEventHandler<HTMLAnchorElement> => (
      event
    ) => {
      event.preventDefault()
      onDownload(document, fileName, type)
    },
    [onDownload]
  )

  const downLoadLink = (type: TDocTypes): JSX.Element => {
    const linkClasses = classNames({
      'link--disabled link--pouring': Boolean(loadingItems[type])
    })
    return (
      <a
        className={linkClasses}
        onClick={handleDocumentDownload(type, DOCUMENT_TYPES[type].fileName, 'serviceDoctorNearby')}
      >
        {DOCUMENT_TYPES[type].text}
      </a>
    )
  }

  return (
    <div className='agreements d-block'>
      {'Прошу ООО МКК "ЧЕСТНОЕ СЛОВО" оказать мне услугу в рамках'}{' '}
      {downLoadLink('doctor_nearby_application')}
      {' по присоединению к Программе медицинского обслуживания'}{' '}
      {'«Здоровье от МКК «ЧЕСТНОЕ СЛОВО» (далее - Программа) в целях получения мной медицинских'}{' '}
      {'консультаций по вопросам здоровья с применением телемедицинских технологий – с'}{' '}
      {'использованием Сервиса «Доктор рядом. Телемед» и распространению на меня действия'}{' '}
      {downLoadLink('doctor_nearby_agreement')}
      {'. '}
      {'С Офертой о заключении договора оказания услуг,'}{' '}
      {'Программой и Договором об оказании платных медицинских услуг ознакомлен и согласен, в том'}{' '}
      {'числе с размером тарифа за услугу присоединения к Программе в размере '}
      {fullCost}
      {' руб. и сроком оказания медицинских платных услуг в '}
      {period}
      {' дней с даты присоединения к Программе.'}{' '}
      {'Оплату стоимости услуг ООО МКК "ЧЕСТНОЕ СЛОВО" прошу произвести на основании моего'}{' '}
      {downLoadLink('doctor_nearby_application_money_transfer')}
      {'.'}
    </div>
  )
}

export default DoctorNearbyAgreementLabel
