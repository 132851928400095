import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from '#components/Button'
import { adminAuthTokenSelector } from '#reducers/adminPage/auth/authSlice'
import {
  deleteNotification,
  notificationItemSelector
} from '#reducers/adminPage/personNotification/notificationSlice'
import * as AdminApiTypes from '#src/modules/api/admin/types'

export type TConfirmDeleteNotification = {
  closeModal: () => void
  notification: AdminApiTypes.TNotificationListItem
}

const ConfirmDeleteNotification: FC<TConfirmDeleteNotification> = (props) => {
  const { closeModal, notification } = props
  const dispatch = useDispatch()
  const { error, isLoading } = useSelector(notificationItemSelector)
  const token = useSelector(adminAuthTokenSelector)

  const handleCloseClick = useCallback(() => {
    closeModal()
  }, [closeModal])

  const handleConfirmClick = useCallback(() => {
    dispatch(deleteNotification({ notification, token }))
  }, [dispatch, notification, token])

  return (
    <Dialog
      open={true}
      onClose={handleCloseClick}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{''}</DialogTitle>
      <DialogContent>
        <DialogContentText data-qa='alert-dialog-description'>
          {'Вы уверены, что хотите удалить уведомление?'}
        </DialogContentText>
        {error && (
          <div className='form__bottom'>
            <div className='form__error' data-qa='alert-dialog-error'>
              {error}
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button loading={isLoading} onClick={handleConfirmClick} data-qa='confirm'>
          {'Подтвердить'}
        </Button>
        <Button loading={isLoading} onClick={handleCloseClick} color='red' data-qa='decline'>
          {'Отклонить'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDeleteNotification
