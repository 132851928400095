import cn from 'classnames'
import { CSSProperties, FC, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'

import { useIsomorphicLayoutEffect } from '#hook/useIsomorphicLayoutEffect'
// @ts-ignore
import copySvg from '#src/assets/svg/copy.svg'
import { animateCSS, buildDataQa } from '#src/services/helper'
const dataQa = 'data-qa'

interface ICopyText {
  text: string
  legend?: string
  className?: string
  style?: CSSProperties
  size?: number
  [dataQa]?: string
}

enum States {
  initial,
  automaticCopyPending,
  automaticCopySuccess,
  automaticCopyFailure,
  manualCopy
}

const TIMEOUT_MS = 1500
const DEFAULT_SIZE_PX = 32

/**
 * Компонент для копирования текста в буфер обмена
 * @param {ICopyText} props - Пропсы
 * @param props.text - Текст который требуется скопировать
 * @param props.legend - Лейбл поля с текстом
 * @param props.className - CSS классы для кастомизации
 * @param props.className - CSS стили для кастомизации
 */
const CopyText: FC<ICopyText> = (props) => {
  const { className = '', style = {}, text = '', legend = '', size = DEFAULT_SIZE_PX } = props
  const dataQaBase = props['data-qa'] || 'copyText'
  const [state, setState] = useState<number>(States.initial)
  const timerRef = useRef<any>(null)
  const tooltipRef = useRef<HTMLParagraphElement>(null)

  useIsomorphicLayoutEffect(() => {
    const canCopy =
      typeof navigator !== 'undefined' && typeof navigator?.clipboard?.writeText === 'function'
    setState(canCopy ? States.automaticCopyPending : States.manualCopy)
  }, [])

  useEffect(() => () => clearTimeout(timerRef.current), [])

  const setTimer = useCallback(
    () =>
      setTimeout(
        () =>
          animateCSS(
            tooltipRef.current,
            ['fadeOut', 'faster'],
            setState.bind(null, States.automaticCopyPending)
          ),
        TIMEOUT_MS
      ),
    []
  )

  const handleClipboardCopy = useCallback(
    async (evt: MouseEvent) => {
      evt.preventDefault()
      clearTimeout(timerRef.current)
      try {
        await navigator.clipboard.writeText(text)
        setState(States.automaticCopySuccess)
        timerRef.current = setTimer()
        animateCSS(tooltipRef.current, ['fadeInUp', 'faster'])
      } catch (_e) {
        setState(States.automaticCopyFailure)
      }
    },
    [text, setTimer]
  )

  if (!text) return null
  if (state === States.initial) return null

  const classes = cn(
    {
      'd-flex align-items-center flex-fill border border-secondary rounded p-0 m-0': true
    },
    className
  )
  return (
    <fieldset
      data-qa={dataQaBase}
      className={classes}
      style={{ backgroundColor: 'whitesmoke', height: size, ...style }}
    >
      <input
        type='text'
        data-qa={buildDataQa(dataQaBase, 'input')}
        disabled
        className='px-0 pt-1 pb-0 m-2 border-0 flex-fill w-100 h-100 bg-transparent'
        value={text}
      />
      {legend && (
        <legend className='font-size-small ms-2' style={{ lineHeight: 0 }}>
          {'Ссылка'}
        </legend>
      )}
      {state !== States.manualCopy && (
        <button
          data-qa={buildDataQa(dataQaBase, 'copyBtn')}
          className='position-relative btn btn-primary d-flex-centered text-white border-start rounded-end shadow-none'
          style={{ border: 'none', borderRadius: 'unset', height: size, right: -1 }}
          onClick={handleClipboardCopy}
        >
          {[States.automaticCopySuccess, States.automaticCopyFailure].includes(state) && (
            <p
              className='position-absolute border rounded text-secondary font-size-smaller fw-bold text-nowrap m-0 p-1'
              style={{ top: '-100%', right: 0, backgroundColor: 'ghostwhite' }}
              ref={tooltipRef}
            >
              {state === States.automaticCopySuccess && 'Текст скопирован'}
              {state === States.automaticCopyFailure && 'Ошибка'}
            </p>
          )}
          <i
            className='icon'
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            style={{ maxWidth: 0.7 * size, maxHeight: 0.7 * size, width: 24, height: 24 }}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: copySvg as string }}
          />
        </button>
      )}
    </fieldset>
  )
}

export default CopyText
