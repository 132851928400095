import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'

import intl from '#intl'
import {
  fetchStatistic,
  fetchTransferredStatistic,
  selectSortedStatList,
  selectStatLoading
} from '#reducers/statisticsSlice'

import PartnerStatCharts from './PartnerStatCharts'
import PartnerStatFilter from './PartnerStatFilter'
import PartnerStatTable from './PartnerStatTable'

const PartnerStatistics = (props) => {
  const { loading, statisticsList, brokerToken, brokerInfo, transferStat = false } = props
  const isBroker = Boolean(brokerInfo.isBroker)
  const dispatch = useDispatch()
  const getStatistic = (filter) => dispatch(fetchStatistic(filter))
  const getTransferedStatistic = (filter) => dispatch(fetchTransferredStatistic(filter))
  return (
    <div className='partner-page__statistics'>
      <h2>{transferStat ? intl.statisticsBroker : intl.statistics}</h2>
      <PartnerStatFilter
        loading={loading}
        brokerToken={brokerToken}
        brokerInfo={brokerInfo}
        transferStat={transferStat}
        fetchData={transferStat ? getTransferedStatistic : getStatistic}
      />
      <PartnerStatTable loading={loading} data={statisticsList} isBroker={isBroker} />
      <PartnerStatCharts loading={loading} statisticsList={statisticsList} />
    </div>
  )
}

PartnerStatistics.propTypes = {
  loading: PropTypes.bool,
  statisticsList: PropTypes.array,
  brokerToken: PropTypes.string.isRequired,
  brokerInfo: PropTypes.object.isRequired,
  transferStat: PropTypes.bool
}

const mapStateToProps = (state) => ({
  loading: selectStatLoading(state),
  statisticsList: selectSortedStatList(state),
  brokerInfo: state.brokerInfo
})
export default connect(mapStateToProps, null)(PartnerStatistics)
