import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { IContentTableProps } from './types'

export default makeStyles<Theme, IContentTableProps>((theme) =>
  createStyles({
    '@keyframes wave': {
      '0%': {
        transform: 'translateX(-100%)'
      },
      '60%': {
        transform: 'translateX(100%)'
      },
      '100%': {
        transform: 'translateX(100%)'
      }
    },
    '@keyframes blur': {
      '0%': { filter: 'blur(0px)' },
      '100%': { filter: 'blur(3px)' }
    },
    container: ({ fitContainer }) => ({
      width: fitContainer ? '100%' : 'min-content',
      maxWidth: '100%',
      '&::-webkit-scrollbar': {
        width: 5,
        height: 5
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'rgba(0,0,0,0.05)'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,0.15)'
      }
    }),
    containerLoading: {
      pointerEvents: 'none',
      position: 'relative',
      overflowX: 'hidden',
      animation: '$blur 2s forwards',
      '&:after': {
        inset: 0,
        content: '""',
        position: 'absolute',
        animation: '$wave 1.6s linear 0.5s infinite',
        transform: 'translateX(-100%)',
        background: 'linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.04), transparent)'
      }
    },
    table: ({ borders, borderless }) => ({
      borderCollapse: 'collapse',
      '& thead tr th': {
        borderBottomStyle: borderless ? 'none' : 'solid'
      },
      '& tr td:last-child, th:last-child': {
        borderRightStyle: borders && !borderless ? 'solid' : 'none'
      },
      '& tbody tr:last-child td': {
        borderBottomStyle: borderless ? 'none' : 'solid'
      }
    }),
    cell: ({ borders, borderless }) => ({
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      borderWidth: 1,
      borderColor: theme.palette.grey['300'],
      borderTopStyle: borders && !borderless ? 'solid' : 'none',
      borderRightStyle: 'none',
      borderBottomStyle: 'none',
      borderLeftStyle: borders && !borderless ? 'solid' : 'none'
    }),
    expandedRowCell: {
      flexGrow: 1,
      padding: '0 !important'
    },
    tHeadRow: {
      position: 'sticky',
      top: 1,
      backgroundColor: theme.palette.background.default,
      zIndex: 2
    },
    rowStriped: ({ striped }) =>
      striped
        ? {
            '&:nth-of-type(odd)': {
              backgroundColor: theme.palette.action.hover
            }
          }
        : {},
    resizer: {
      display: 'inline-block',
      background: 'transparent',
      width: 10,
      height: '100%',
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 10,
      touchAction: 'none'
    },
    noDataLabel: {
      display: 'flex',
      justifyContent: 'center',
      flex: '1 1',
      textTransform: 'uppercase',
      color: theme.palette.grey['400'],
      marginTop: 10,
      marginBottom: 10,
      fontWeight: 'bold'
    }
  })
)
