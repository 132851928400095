import FormData from 'form-data'
import PropTypes from 'prop-types'
import querystring from 'query-string'
import { createRef } from 'react'
import { Cookies } from 'react-cookie'
import { connect } from 'react-redux'

import DocumentsUploadModalForm from '#components/PersonalAreaPage/components/DocumentsUploadModalForm'
import Spinner from '#components/Spinner'
import { withRouter } from '#hoc/withRouter'
import intl from '#intl'
import { brokerApi } from '#src/modules/api'

import { Button } from '../../Button/Button'
import ConfirmFormPartner from '../../ConfirmForm/ConfirmFormPartner'
import { Form } from '../../Form/Form'
import LoanForm from '../../LoanForm/LoanForm'
import PartnerPaymentItem from './PartnerPaymentItem'
const cookies = new Cookies()
class AddPartnerCreditForm extends Form {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      stage: 1,
      personToken: null,
      amountLimitWithoutDocuments: null,
      errors: {
        common: null
      }
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.modalRef = createRef(null)
  }

  async componentDidMount() {
    const {
      app: { loggedInToken },
      row: { personId }
    } = this.props
    this.setState({ loading: true })
    const { data = {} } = await brokerApi.getPerson2BrokerToken({
      personId,
      brokerToken: loggedInToken
    })
    this.setState({ personToken: data.token, loading: false })
  }

  checkResponseCode(response = {}) {
    const result = {
      errors: {}
    }
    if (response.code === 100 && response.data.amountLimitWithoutDocuments) {
      this.setState({ amountLimitWithoutDocuments: response.data.amountLimitWithoutDocuments })
      this.modalRef.current.show()
      return {}
    }

    if (!response || response.code === 1) {
      result.errors.common = response.message
    } else if ([3, 5, 6].includes(response.code)) {
      result.errors = response.data || {}

      if (!Object.keys(result.errors).length)
        result.errors.common = response.message || intl.serverError
    }

    return result
  }

  async handleSubmit(e) {
    e && e.preventDefault()
    const {
      loanFormState: {
        data: { promoCode: promoString, amount: loanAmount, term: loanTerm, creditType },
        mainAccount
      },
      app,
      row,
      location,
      navigate
    } = this.props

    const { personToken } = this.state

    const fd = new FormData()
    personToken && fd.append('personToken', personToken)
    if (promoString && creditType === 'MicroCredit') fd.append('promoCode', promoString)

    fd.append('creditType', creditType)
    fd.append('brokerToken', app.loggedInToken)
    fd.append('c4s', cookies.get('c4s'))
    fd.append('amount', loanAmount)
    fd.append('period', loanTerm)
    fd.append('personAccountId', mainAccount.id)
    try {
      const response = await brokerApi.brokerAddLoan(fd)
      this.handleResponse(response)
      if (response.code === 0) {
        const queryObj = {
          ...querystring.parse(location.search),
          creditId: response.data.creditId,
          personId: row.personId
        }
        navigate(querystring.stringifyUrl({ url: '/loan', query: queryObj }), { replace: true })
      }
    } catch (err) {
      this.handleResponse(err)
    }
  }

  uploadPersonDocuments = (params) => {
    const { personToken } = this.state
    const brokerToken = this.props.app.loggedInToken
    return brokerApi.brokerUploadPersonDocument({ personToken, brokerToken, ...params })
  }

  render() {
    const { loading, stage, errors, amountLimitWithoutDocuments } = this.state
    const {
      loanFormState: { mainAccount = null },
      row
    } = this.props

    if (!loading) {
      return (
        <>
          <form className='partner-page__loan' onSubmit={this.handleSubmit}>
            <h2>{intl.newLoan}</h2>
            {stage === 1 ? (
              <LoanForm promoCodeEnabled>
                {mainAccount && mainAccount.personId === row.personId ? (
                  <PartnerPaymentItem {...mainAccount} paymentClass='payment-align-center' />
                ) : (
                  <div className='partner-page__loan-error'>{intl.noAccount}</div>
                )}
              </LoanForm>
            ) : (
              <ConfirmFormPartner />
            )}
            {errors.common && (
              <div className='form__bottom partner-credit-error'>
                <div className='form__error'>{errors.common}</div>
              </div>
            )}
            <Button className='form__submit' type='submit' size='l' inverted fluid>
              {intl.getMoney}
            </Button>
          </form>
          <DocumentsUploadModalForm
            ref={this.modalRef}
            uploadDocumentsAction={this.uploadPersonDocuments}
            maxAmount={amountLimitWithoutDocuments}
          />
        </>
      )
    }
    return <Spinner />
  }
}

export default connect((state) => ({
  loanFormState: state.loanFormState,
  app: state.app
}))(withRouter(AddPartnerCreditForm))

AddPartnerCreditForm.propTypes = {
  loanFormState: PropTypes.object,
  app: PropTypes.object
}
